/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const Pair = $root.Pair = (() => {

    /**
     * Properties of a Pair.
     * @exports IPair
     * @interface IPair
     * @property {string} from Pair from
     * @property {string} to Pair to
     */

    /**
     * Constructs a new Pair.
     * @exports Pair
     * @classdesc Represents a Pair.
     * @implements IPair
     * @constructor
     * @param {IPair=} [properties] Properties to set
     */
    function Pair(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Pair from.
     * @member {string} from
     * @memberof Pair
     * @instance
     */
    Pair.prototype.from = "";

    /**
     * Pair to.
     * @member {string} to
     * @memberof Pair
     * @instance
     */
    Pair.prototype.to = "";

    /**
     * Creates a new Pair instance using the specified properties.
     * @function create
     * @memberof Pair
     * @static
     * @param {IPair=} [properties] Properties to set
     * @returns {Pair} Pair instance
     */
    Pair.create = function create(properties) {
        return new Pair(properties);
    };

    /**
     * Encodes the specified Pair message. Does not implicitly {@link Pair.verify|verify} messages.
     * @function encode
     * @memberof Pair
     * @static
     * @param {IPair} message Pair message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Pair.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.from);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
        return writer;
    };

    /**
     * Encodes the specified Pair message, length delimited. Does not implicitly {@link Pair.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Pair
     * @static
     * @param {IPair} message Pair message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Pair.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Pair message from the specified reader or buffer.
     * @function decode
     * @memberof Pair
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Pair} Pair
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Pair.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Pair();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.from = reader.string();
                break;
            case 2:
                message.to = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("from"))
            throw $util.ProtocolError("missing required 'from'", { instance: message });
        if (!message.hasOwnProperty("to"))
            throw $util.ProtocolError("missing required 'to'", { instance: message });
        return message;
    };

    /**
     * Decodes a Pair message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Pair
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Pair} Pair
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Pair.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Pair message.
     * @function verify
     * @memberof Pair
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Pair.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.from))
            return "from: string expected";
        if (!$util.isString(message.to))
            return "to: string expected";
        return null;
    };

    /**
     * Creates a Pair message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Pair
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Pair} Pair
     */
    Pair.fromObject = function fromObject(object) {
        if (object instanceof $root.Pair)
            return object;
        let message = new $root.Pair();
        if (object.from != null)
            message.from = String(object.from);
        if (object.to != null)
            message.to = String(object.to);
        return message;
    };

    /**
     * Creates a plain object from a Pair message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Pair
     * @static
     * @param {Pair} message Pair
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Pair.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.from = "";
            object.to = "";
        }
        if (message.from != null && message.hasOwnProperty("from"))
            object.from = message.from;
        if (message.to != null && message.hasOwnProperty("to"))
            object.to = message.to;
        return object;
    };

    /**
     * Converts this Pair to JSON.
     * @function toJSON
     * @memberof Pair
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Pair.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Pair;
})();

export const Rate = $root.Rate = (() => {

    /**
     * Properties of a Rate.
     * @exports IRate
     * @interface IRate
     * @property {IPair} pair Rate pair
     * @property {number} rate Rate rate
     */

    /**
     * Constructs a new Rate.
     * @exports Rate
     * @classdesc Represents a Rate.
     * @implements IRate
     * @constructor
     * @param {IRate=} [properties] Properties to set
     */
    function Rate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Rate pair.
     * @member {IPair} pair
     * @memberof Rate
     * @instance
     */
    Rate.prototype.pair = null;

    /**
     * Rate rate.
     * @member {number} rate
     * @memberof Rate
     * @instance
     */
    Rate.prototype.rate = 0;

    /**
     * Creates a new Rate instance using the specified properties.
     * @function create
     * @memberof Rate
     * @static
     * @param {IRate=} [properties] Properties to set
     * @returns {Rate} Rate instance
     */
    Rate.create = function create(properties) {
        return new Rate(properties);
    };

    /**
     * Encodes the specified Rate message. Does not implicitly {@link Rate.verify|verify} messages.
     * @function encode
     * @memberof Rate
     * @static
     * @param {IRate} message Rate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Rate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.Pair.encode(message.pair, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        writer.uint32(/* id 2, wireType 1 =*/17).double(message.rate);
        return writer;
    };

    /**
     * Encodes the specified Rate message, length delimited. Does not implicitly {@link Rate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Rate
     * @static
     * @param {IRate} message Rate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Rate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Rate message from the specified reader or buffer.
     * @function decode
     * @memberof Rate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Rate} Rate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Rate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Rate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.pair = $root.Pair.decode(reader, reader.uint32());
                break;
            case 2:
                message.rate = reader.double();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("pair"))
            throw $util.ProtocolError("missing required 'pair'", { instance: message });
        if (!message.hasOwnProperty("rate"))
            throw $util.ProtocolError("missing required 'rate'", { instance: message });
        return message;
    };

    /**
     * Decodes a Rate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Rate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Rate} Rate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Rate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Rate message.
     * @function verify
     * @memberof Rate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Rate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        {
            let error = $root.Pair.verify(message.pair);
            if (error)
                return "pair." + error;
        }
        if (typeof message.rate !== "number")
            return "rate: number expected";
        return null;
    };

    /**
     * Creates a Rate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Rate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Rate} Rate
     */
    Rate.fromObject = function fromObject(object) {
        if (object instanceof $root.Rate)
            return object;
        let message = new $root.Rate();
        if (object.pair != null) {
            if (typeof object.pair !== "object")
                throw TypeError(".Rate.pair: object expected");
            message.pair = $root.Pair.fromObject(object.pair);
        }
        if (object.rate != null)
            message.rate = Number(object.rate);
        return message;
    };

    /**
     * Creates a plain object from a Rate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Rate
     * @static
     * @param {Rate} message Rate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Rate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.pair = null;
            object.rate = 0;
        }
        if (message.pair != null && message.hasOwnProperty("pair"))
            object.pair = $root.Pair.toObject(message.pair, options);
        if (message.rate != null && message.hasOwnProperty("rate"))
            object.rate = options.json && !isFinite(message.rate) ? String(message.rate) : message.rate;
        return object;
    };

    /**
     * Converts this Rate to JSON.
     * @function toJSON
     * @memberof Rate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Rate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Rate;
})();

export const BulkRateRequest = $root.BulkRateRequest = (() => {

    /**
     * Properties of a BulkRateRequest.
     * @exports IBulkRateRequest
     * @interface IBulkRateRequest
     * @property {Array.<IPair>|null} [pairs] BulkRateRequest pairs
     */

    /**
     * Constructs a new BulkRateRequest.
     * @exports BulkRateRequest
     * @classdesc Represents a BulkRateRequest.
     * @implements IBulkRateRequest
     * @constructor
     * @param {IBulkRateRequest=} [properties] Properties to set
     */
    function BulkRateRequest(properties) {
        this.pairs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BulkRateRequest pairs.
     * @member {Array.<IPair>} pairs
     * @memberof BulkRateRequest
     * @instance
     */
    BulkRateRequest.prototype.pairs = $util.emptyArray;

    /**
     * Creates a new BulkRateRequest instance using the specified properties.
     * @function create
     * @memberof BulkRateRequest
     * @static
     * @param {IBulkRateRequest=} [properties] Properties to set
     * @returns {BulkRateRequest} BulkRateRequest instance
     */
    BulkRateRequest.create = function create(properties) {
        return new BulkRateRequest(properties);
    };

    /**
     * Encodes the specified BulkRateRequest message. Does not implicitly {@link BulkRateRequest.verify|verify} messages.
     * @function encode
     * @memberof BulkRateRequest
     * @static
     * @param {IBulkRateRequest} message BulkRateRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BulkRateRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pairs != null && message.pairs.length)
            for (let i = 0; i < message.pairs.length; ++i)
                $root.Pair.encode(message.pairs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BulkRateRequest message, length delimited. Does not implicitly {@link BulkRateRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BulkRateRequest
     * @static
     * @param {IBulkRateRequest} message BulkRateRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BulkRateRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BulkRateRequest message from the specified reader or buffer.
     * @function decode
     * @memberof BulkRateRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BulkRateRequest} BulkRateRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BulkRateRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BulkRateRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.pairs && message.pairs.length))
                    message.pairs = [];
                message.pairs.push($root.Pair.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BulkRateRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BulkRateRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BulkRateRequest} BulkRateRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BulkRateRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BulkRateRequest message.
     * @function verify
     * @memberof BulkRateRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BulkRateRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.pairs != null && message.hasOwnProperty("pairs")) {
            if (!Array.isArray(message.pairs))
                return "pairs: array expected";
            for (let i = 0; i < message.pairs.length; ++i) {
                let error = $root.Pair.verify(message.pairs[i]);
                if (error)
                    return "pairs." + error;
            }
        }
        return null;
    };

    /**
     * Creates a BulkRateRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BulkRateRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BulkRateRequest} BulkRateRequest
     */
    BulkRateRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.BulkRateRequest)
            return object;
        let message = new $root.BulkRateRequest();
        if (object.pairs) {
            if (!Array.isArray(object.pairs))
                throw TypeError(".BulkRateRequest.pairs: array expected");
            message.pairs = [];
            for (let i = 0; i < object.pairs.length; ++i) {
                if (typeof object.pairs[i] !== "object")
                    throw TypeError(".BulkRateRequest.pairs: object expected");
                message.pairs[i] = $root.Pair.fromObject(object.pairs[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a BulkRateRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BulkRateRequest
     * @static
     * @param {BulkRateRequest} message BulkRateRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BulkRateRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.pairs = [];
        if (message.pairs && message.pairs.length) {
            object.pairs = [];
            for (let j = 0; j < message.pairs.length; ++j)
                object.pairs[j] = $root.Pair.toObject(message.pairs[j], options);
        }
        return object;
    };

    /**
     * Converts this BulkRateRequest to JSON.
     * @function toJSON
     * @memberof BulkRateRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BulkRateRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BulkRateRequest;
})();

export const BulkRate = $root.BulkRate = (() => {

    /**
     * Properties of a BulkRate.
     * @exports IBulkRate
     * @interface IBulkRate
     * @property {Array.<IRate>|null} [rates] BulkRate rates
     */

    /**
     * Constructs a new BulkRate.
     * @exports BulkRate
     * @classdesc Represents a BulkRate.
     * @implements IBulkRate
     * @constructor
     * @param {IBulkRate=} [properties] Properties to set
     */
    function BulkRate(properties) {
        this.rates = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BulkRate rates.
     * @member {Array.<IRate>} rates
     * @memberof BulkRate
     * @instance
     */
    BulkRate.prototype.rates = $util.emptyArray;

    /**
     * Creates a new BulkRate instance using the specified properties.
     * @function create
     * @memberof BulkRate
     * @static
     * @param {IBulkRate=} [properties] Properties to set
     * @returns {BulkRate} BulkRate instance
     */
    BulkRate.create = function create(properties) {
        return new BulkRate(properties);
    };

    /**
     * Encodes the specified BulkRate message. Does not implicitly {@link BulkRate.verify|verify} messages.
     * @function encode
     * @memberof BulkRate
     * @static
     * @param {IBulkRate} message BulkRate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BulkRate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.rates != null && message.rates.length)
            for (let i = 0; i < message.rates.length; ++i)
                $root.Rate.encode(message.rates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BulkRate message, length delimited. Does not implicitly {@link BulkRate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BulkRate
     * @static
     * @param {IBulkRate} message BulkRate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BulkRate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BulkRate message from the specified reader or buffer.
     * @function decode
     * @memberof BulkRate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BulkRate} BulkRate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BulkRate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BulkRate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.rates && message.rates.length))
                    message.rates = [];
                message.rates.push($root.Rate.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BulkRate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BulkRate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BulkRate} BulkRate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BulkRate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BulkRate message.
     * @function verify
     * @memberof BulkRate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BulkRate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.rates != null && message.hasOwnProperty("rates")) {
            if (!Array.isArray(message.rates))
                return "rates: array expected";
            for (let i = 0; i < message.rates.length; ++i) {
                let error = $root.Rate.verify(message.rates[i]);
                if (error)
                    return "rates." + error;
            }
        }
        return null;
    };

    /**
     * Creates a BulkRate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BulkRate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BulkRate} BulkRate
     */
    BulkRate.fromObject = function fromObject(object) {
        if (object instanceof $root.BulkRate)
            return object;
        let message = new $root.BulkRate();
        if (object.rates) {
            if (!Array.isArray(object.rates))
                throw TypeError(".BulkRate.rates: array expected");
            message.rates = [];
            for (let i = 0; i < object.rates.length; ++i) {
                if (typeof object.rates[i] !== "object")
                    throw TypeError(".BulkRate.rates: object expected");
                message.rates[i] = $root.Rate.fromObject(object.rates[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a BulkRate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BulkRate
     * @static
     * @param {BulkRate} message BulkRate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BulkRate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.rates = [];
        if (message.rates && message.rates.length) {
            object.rates = [];
            for (let j = 0; j < message.rates.length; ++j)
                object.rates[j] = $root.Rate.toObject(message.rates[j], options);
        }
        return object;
    };

    /**
     * Converts this BulkRate to JSON.
     * @function toJSON
     * @memberof BulkRate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BulkRate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BulkRate;
})();

export { $root as default };
