/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

/**
 * PlatformType enum.
 * @exports PlatformType
 * @enum {number}
 * @property {number} WEB=0 WEB value
 * @property {number} ANDROID=1 ANDROID value
 * @property {number} IOS=2 IOS value
 * @property {number} WP=3 WP value
 */
export const PlatformType = $root.PlatformType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "WEB"] = 0;
    values[valuesById[1] = "ANDROID"] = 1;
    values[valuesById[2] = "IOS"] = 2;
    values[valuesById[3] = "WP"] = 3;
    return values;
})();

/**
 * ModelType enum.
 * @exports ModelType
 * @enum {number}
 * @property {number} Account=0 Account value
 * @property {number} HashTag=1 HashTag value
 * @property {number} Category=2 Category value
 * @property {number} Currency=3 Currency value
 * @property {number} Record=4 Record value
 * @property {number} Budget=5 Budget value
 * @property {number} ShoppingList=6 ShoppingList value
 * @property {number} StandingOrder=7 StandingOrder value
 * @property {number} Debt=8 Debt value
 * @property {number} Template=9 Template value
 * @property {number} IntegrationType=10 IntegrationType value
 * @property {number} FilterType=11 FilterType value
 * @property {number} NoneType=12 NoneType value
 * @property {number} EnvelopBudget=13 EnvelopBudget value
 * @property {number} LoyaltyCard=14 LoyaltyCard value
 * @property {number} Goal=15 Goal value
 */
export const ModelType = $root.ModelType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Account"] = 0;
    values[valuesById[1] = "HashTag"] = 1;
    values[valuesById[2] = "Category"] = 2;
    values[valuesById[3] = "Currency"] = 3;
    values[valuesById[4] = "Record"] = 4;
    values[valuesById[5] = "Budget"] = 5;
    values[valuesById[6] = "ShoppingList"] = 6;
    values[valuesById[7] = "StandingOrder"] = 7;
    values[valuesById[8] = "Debt"] = 8;
    values[valuesById[9] = "Template"] = 9;
    values[valuesById[10] = "IntegrationType"] = 10;
    values[valuesById[11] = "FilterType"] = 11;
    values[valuesById[12] = "NoneType"] = 12;
    values[valuesById[13] = "EnvelopBudget"] = 13;
    values[valuesById[14] = "LoyaltyCard"] = 14;
    values[valuesById[15] = "Goal"] = 15;
    return values;
})();

export const ReplicationEndpoint = $root.ReplicationEndpoint = (() => {

    /**
     * Properties of a ReplicationEndpoint.
     * @exports IReplicationEndpoint
     * @interface IReplicationEndpoint
     * @property {string} dbName ReplicationEndpoint dbName
     * @property {string} url ReplicationEndpoint url
     * @property {string} login ReplicationEndpoint login
     * @property {string} token ReplicationEndpoint token
     * @property {string} ownerId ReplicationEndpoint ownerId
     * @property {string|null} [pullFilter] ReplicationEndpoint pullFilter
     */

    /**
     * Constructs a new ReplicationEndpoint.
     * @exports ReplicationEndpoint
     * @classdesc Represents a ReplicationEndpoint.
     * @implements IReplicationEndpoint
     * @constructor
     * @param {IReplicationEndpoint=} [properties] Properties to set
     */
    function ReplicationEndpoint(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReplicationEndpoint dbName.
     * @member {string} dbName
     * @memberof ReplicationEndpoint
     * @instance
     */
    ReplicationEndpoint.prototype.dbName = "";

    /**
     * ReplicationEndpoint url.
     * @member {string} url
     * @memberof ReplicationEndpoint
     * @instance
     */
    ReplicationEndpoint.prototype.url = "";

    /**
     * ReplicationEndpoint login.
     * @member {string} login
     * @memberof ReplicationEndpoint
     * @instance
     */
    ReplicationEndpoint.prototype.login = "";

    /**
     * ReplicationEndpoint token.
     * @member {string} token
     * @memberof ReplicationEndpoint
     * @instance
     */
    ReplicationEndpoint.prototype.token = "";

    /**
     * ReplicationEndpoint ownerId.
     * @member {string} ownerId
     * @memberof ReplicationEndpoint
     * @instance
     */
    ReplicationEndpoint.prototype.ownerId = "";

    /**
     * ReplicationEndpoint pullFilter.
     * @member {string} pullFilter
     * @memberof ReplicationEndpoint
     * @instance
     */
    ReplicationEndpoint.prototype.pullFilter = "";

    /**
     * Creates a new ReplicationEndpoint instance using the specified properties.
     * @function create
     * @memberof ReplicationEndpoint
     * @static
     * @param {IReplicationEndpoint=} [properties] Properties to set
     * @returns {ReplicationEndpoint} ReplicationEndpoint instance
     */
    ReplicationEndpoint.create = function create(properties) {
        return new ReplicationEndpoint(properties);
    };

    /**
     * Encodes the specified ReplicationEndpoint message. Does not implicitly {@link ReplicationEndpoint.verify|verify} messages.
     * @function encode
     * @memberof ReplicationEndpoint
     * @static
     * @param {IReplicationEndpoint} message ReplicationEndpoint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReplicationEndpoint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.dbName);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.login);
        writer.uint32(/* id 4, wireType 2 =*/34).string(message.token);
        writer.uint32(/* id 5, wireType 2 =*/42).string(message.ownerId);
        if (message.pullFilter != null && Object.hasOwnProperty.call(message, "pullFilter"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.pullFilter);
        return writer;
    };

    /**
     * Encodes the specified ReplicationEndpoint message, length delimited. Does not implicitly {@link ReplicationEndpoint.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ReplicationEndpoint
     * @static
     * @param {IReplicationEndpoint} message ReplicationEndpoint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReplicationEndpoint.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReplicationEndpoint message from the specified reader or buffer.
     * @function decode
     * @memberof ReplicationEndpoint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ReplicationEndpoint} ReplicationEndpoint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReplicationEndpoint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ReplicationEndpoint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.dbName = reader.string();
                break;
            case 2:
                message.url = reader.string();
                break;
            case 3:
                message.login = reader.string();
                break;
            case 4:
                message.token = reader.string();
                break;
            case 5:
                message.ownerId = reader.string();
                break;
            case 7:
                message.pullFilter = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("dbName"))
            throw $util.ProtocolError("missing required 'dbName'", { instance: message });
        if (!message.hasOwnProperty("url"))
            throw $util.ProtocolError("missing required 'url'", { instance: message });
        if (!message.hasOwnProperty("login"))
            throw $util.ProtocolError("missing required 'login'", { instance: message });
        if (!message.hasOwnProperty("token"))
            throw $util.ProtocolError("missing required 'token'", { instance: message });
        if (!message.hasOwnProperty("ownerId"))
            throw $util.ProtocolError("missing required 'ownerId'", { instance: message });
        return message;
    };

    /**
     * Decodes a ReplicationEndpoint message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ReplicationEndpoint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ReplicationEndpoint} ReplicationEndpoint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReplicationEndpoint.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReplicationEndpoint message.
     * @function verify
     * @memberof ReplicationEndpoint
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReplicationEndpoint.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.dbName))
            return "dbName: string expected";
        if (!$util.isString(message.url))
            return "url: string expected";
        if (!$util.isString(message.login))
            return "login: string expected";
        if (!$util.isString(message.token))
            return "token: string expected";
        if (!$util.isString(message.ownerId))
            return "ownerId: string expected";
        if (message.pullFilter != null && message.hasOwnProperty("pullFilter"))
            if (!$util.isString(message.pullFilter))
                return "pullFilter: string expected";
        return null;
    };

    /**
     * Creates a ReplicationEndpoint message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ReplicationEndpoint
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ReplicationEndpoint} ReplicationEndpoint
     */
    ReplicationEndpoint.fromObject = function fromObject(object) {
        if (object instanceof $root.ReplicationEndpoint)
            return object;
        let message = new $root.ReplicationEndpoint();
        if (object.dbName != null)
            message.dbName = String(object.dbName);
        if (object.url != null)
            message.url = String(object.url);
        if (object.login != null)
            message.login = String(object.login);
        if (object.token != null)
            message.token = String(object.token);
        if (object.ownerId != null)
            message.ownerId = String(object.ownerId);
        if (object.pullFilter != null)
            message.pullFilter = String(object.pullFilter);
        return message;
    };

    /**
     * Creates a plain object from a ReplicationEndpoint message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ReplicationEndpoint
     * @static
     * @param {ReplicationEndpoint} message ReplicationEndpoint
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReplicationEndpoint.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.dbName = "";
            object.url = "";
            object.login = "";
            object.token = "";
            object.ownerId = "";
            object.pullFilter = "";
        }
        if (message.dbName != null && message.hasOwnProperty("dbName"))
            object.dbName = message.dbName;
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.login != null && message.hasOwnProperty("login"))
            object.login = message.login;
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
            object.ownerId = message.ownerId;
        if (message.pullFilter != null && message.hasOwnProperty("pullFilter"))
            object.pullFilter = message.pullFilter;
        return object;
    };

    /**
     * Converts this ReplicationEndpoint to JSON.
     * @function toJSON
     * @memberof ReplicationEndpoint
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReplicationEndpoint.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ReplicationEndpoint;
})();

export const CouchbaseReplicationEndpoint = $root.CouchbaseReplicationEndpoint = (() => {

    /**
     * Properties of a CouchbaseReplicationEndpoint.
     * @exports ICouchbaseReplicationEndpoint
     * @interface ICouchbaseReplicationEndpoint
     * @property {string} url CouchbaseReplicationEndpoint url
     * @property {string} login CouchbaseReplicationEndpoint login
     * @property {string} password CouchbaseReplicationEndpoint password
     * @property {boolean|null} [migrationFinished] CouchbaseReplicationEndpoint migrationFinished
     */

    /**
     * Constructs a new CouchbaseReplicationEndpoint.
     * @exports CouchbaseReplicationEndpoint
     * @classdesc Represents a CouchbaseReplicationEndpoint.
     * @implements ICouchbaseReplicationEndpoint
     * @constructor
     * @param {ICouchbaseReplicationEndpoint=} [properties] Properties to set
     */
    function CouchbaseReplicationEndpoint(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CouchbaseReplicationEndpoint url.
     * @member {string} url
     * @memberof CouchbaseReplicationEndpoint
     * @instance
     */
    CouchbaseReplicationEndpoint.prototype.url = "";

    /**
     * CouchbaseReplicationEndpoint login.
     * @member {string} login
     * @memberof CouchbaseReplicationEndpoint
     * @instance
     */
    CouchbaseReplicationEndpoint.prototype.login = "";

    /**
     * CouchbaseReplicationEndpoint password.
     * @member {string} password
     * @memberof CouchbaseReplicationEndpoint
     * @instance
     */
    CouchbaseReplicationEndpoint.prototype.password = "";

    /**
     * CouchbaseReplicationEndpoint migrationFinished.
     * @member {boolean} migrationFinished
     * @memberof CouchbaseReplicationEndpoint
     * @instance
     */
    CouchbaseReplicationEndpoint.prototype.migrationFinished = false;

    /**
     * Creates a new CouchbaseReplicationEndpoint instance using the specified properties.
     * @function create
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {ICouchbaseReplicationEndpoint=} [properties] Properties to set
     * @returns {CouchbaseReplicationEndpoint} CouchbaseReplicationEndpoint instance
     */
    CouchbaseReplicationEndpoint.create = function create(properties) {
        return new CouchbaseReplicationEndpoint(properties);
    };

    /**
     * Encodes the specified CouchbaseReplicationEndpoint message. Does not implicitly {@link CouchbaseReplicationEndpoint.verify|verify} messages.
     * @function encode
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {ICouchbaseReplicationEndpoint} message CouchbaseReplicationEndpoint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CouchbaseReplicationEndpoint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.login);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
        if (message.migrationFinished != null && Object.hasOwnProperty.call(message, "migrationFinished"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.migrationFinished);
        return writer;
    };

    /**
     * Encodes the specified CouchbaseReplicationEndpoint message, length delimited. Does not implicitly {@link CouchbaseReplicationEndpoint.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {ICouchbaseReplicationEndpoint} message CouchbaseReplicationEndpoint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CouchbaseReplicationEndpoint.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CouchbaseReplicationEndpoint message from the specified reader or buffer.
     * @function decode
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CouchbaseReplicationEndpoint} CouchbaseReplicationEndpoint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CouchbaseReplicationEndpoint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CouchbaseReplicationEndpoint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.url = reader.string();
                break;
            case 2:
                message.login = reader.string();
                break;
            case 3:
                message.password = reader.string();
                break;
            case 4:
                message.migrationFinished = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("url"))
            throw $util.ProtocolError("missing required 'url'", { instance: message });
        if (!message.hasOwnProperty("login"))
            throw $util.ProtocolError("missing required 'login'", { instance: message });
        if (!message.hasOwnProperty("password"))
            throw $util.ProtocolError("missing required 'password'", { instance: message });
        return message;
    };

    /**
     * Decodes a CouchbaseReplicationEndpoint message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CouchbaseReplicationEndpoint} CouchbaseReplicationEndpoint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CouchbaseReplicationEndpoint.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CouchbaseReplicationEndpoint message.
     * @function verify
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CouchbaseReplicationEndpoint.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.url))
            return "url: string expected";
        if (!$util.isString(message.login))
            return "login: string expected";
        if (!$util.isString(message.password))
            return "password: string expected";
        if (message.migrationFinished != null && message.hasOwnProperty("migrationFinished"))
            if (typeof message.migrationFinished !== "boolean")
                return "migrationFinished: boolean expected";
        return null;
    };

    /**
     * Creates a CouchbaseReplicationEndpoint message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CouchbaseReplicationEndpoint} CouchbaseReplicationEndpoint
     */
    CouchbaseReplicationEndpoint.fromObject = function fromObject(object) {
        if (object instanceof $root.CouchbaseReplicationEndpoint)
            return object;
        let message = new $root.CouchbaseReplicationEndpoint();
        if (object.url != null)
            message.url = String(object.url);
        if (object.login != null)
            message.login = String(object.login);
        if (object.password != null)
            message.password = String(object.password);
        if (object.migrationFinished != null)
            message.migrationFinished = Boolean(object.migrationFinished);
        return message;
    };

    /**
     * Creates a plain object from a CouchbaseReplicationEndpoint message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CouchbaseReplicationEndpoint
     * @static
     * @param {CouchbaseReplicationEndpoint} message CouchbaseReplicationEndpoint
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CouchbaseReplicationEndpoint.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.url = "";
            object.login = "";
            object.password = "";
            object.migrationFinished = false;
        }
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        if (message.login != null && message.hasOwnProperty("login"))
            object.login = message.login;
        if (message.password != null && message.hasOwnProperty("password"))
            object.password = message.password;
        if (message.migrationFinished != null && message.hasOwnProperty("migrationFinished"))
            object.migrationFinished = message.migrationFinished;
        return object;
    };

    /**
     * Converts this CouchbaseReplicationEndpoint to JSON.
     * @function toJSON
     * @memberof CouchbaseReplicationEndpoint
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CouchbaseReplicationEndpoint.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CouchbaseReplicationEndpoint;
})();

export const Sale = $root.Sale = (() => {

    /**
     * Properties of a Sale.
     * @exports ISale
     * @interface ISale
     * @property {string|null} [id] Sale id
     * @property {string|null} [name] Sale name
     * @property {number|Long|null} [createdAt] Sale createdAt
     * @property {number|null} [durationInDays] Sale durationInDays
     * @property {number|null} [amount] Sale amount
     */

    /**
     * Constructs a new Sale.
     * @exports Sale
     * @classdesc Represents a Sale.
     * @implements ISale
     * @constructor
     * @param {ISale=} [properties] Properties to set
     */
    function Sale(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Sale id.
     * @member {string} id
     * @memberof Sale
     * @instance
     */
    Sale.prototype.id = "";

    /**
     * Sale name.
     * @member {string} name
     * @memberof Sale
     * @instance
     */
    Sale.prototype.name = "";

    /**
     * Sale createdAt.
     * @member {number|Long} createdAt
     * @memberof Sale
     * @instance
     */
    Sale.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Sale durationInDays.
     * @member {number} durationInDays
     * @memberof Sale
     * @instance
     */
    Sale.prototype.durationInDays = 0;

    /**
     * Sale amount.
     * @member {number} amount
     * @memberof Sale
     * @instance
     */
    Sale.prototype.amount = 0;

    /**
     * Creates a new Sale instance using the specified properties.
     * @function create
     * @memberof Sale
     * @static
     * @param {ISale=} [properties] Properties to set
     * @returns {Sale} Sale instance
     */
    Sale.create = function create(properties) {
        return new Sale(properties);
    };

    /**
     * Encodes the specified Sale message. Does not implicitly {@link Sale.verify|verify} messages.
     * @function encode
     * @memberof Sale
     * @static
     * @param {ISale} message Sale message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Sale.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdAt);
        if (message.durationInDays != null && Object.hasOwnProperty.call(message, "durationInDays"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.durationInDays);
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.amount);
        return writer;
    };

    /**
     * Encodes the specified Sale message, length delimited. Does not implicitly {@link Sale.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Sale
     * @static
     * @param {ISale} message Sale message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Sale.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Sale message from the specified reader or buffer.
     * @function decode
     * @memberof Sale
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Sale} Sale
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Sale.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Sale();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.createdAt = reader.int64();
                break;
            case 4:
                message.durationInDays = reader.int32();
                break;
            case 5:
                message.amount = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Sale message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Sale
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Sale} Sale
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Sale.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Sale message.
     * @function verify
     * @memberof Sale
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Sale.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                return "createdAt: integer|Long expected";
        if (message.durationInDays != null && message.hasOwnProperty("durationInDays"))
            if (!$util.isInteger(message.durationInDays))
                return "durationInDays: integer expected";
        if (message.amount != null && message.hasOwnProperty("amount"))
            if (!$util.isInteger(message.amount))
                return "amount: integer expected";
        return null;
    };

    /**
     * Creates a Sale message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Sale
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Sale} Sale
     */
    Sale.fromObject = function fromObject(object) {
        if (object instanceof $root.Sale)
            return object;
        let message = new $root.Sale();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.durationInDays != null)
            message.durationInDays = object.durationInDays | 0;
        if (object.amount != null)
            message.amount = object.amount | 0;
        return message;
    };

    /**
     * Creates a plain object from a Sale message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Sale
     * @static
     * @param {Sale} message Sale
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Sale.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.name = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            object.durationInDays = 0;
            object.amount = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.durationInDays != null && message.hasOwnProperty("durationInDays"))
            object.durationInDays = message.durationInDays;
        if (message.amount != null && message.hasOwnProperty("amount"))
            object.amount = message.amount;
        return object;
    };

    /**
     * Converts this Sale to JSON.
     * @function toJSON
     * @memberof Sale
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Sale.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Sale;
})();

export const Configuration = $root.Configuration = (() => {

    /**
     * Properties of a Configuration.
     * @exports IConfiguration
     * @interface IConfiguration
     * @property {Array.<Configuration.IPlatform>|null} [platforms] Configuration platforms
     */

    /**
     * Constructs a new Configuration.
     * @exports Configuration
     * @classdesc Represents a Configuration.
     * @implements IConfiguration
     * @constructor
     * @param {IConfiguration=} [properties] Properties to set
     */
    function Configuration(properties) {
        this.platforms = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Configuration platforms.
     * @member {Array.<Configuration.IPlatform>} platforms
     * @memberof Configuration
     * @instance
     */
    Configuration.prototype.platforms = $util.emptyArray;

    /**
     * Creates a new Configuration instance using the specified properties.
     * @function create
     * @memberof Configuration
     * @static
     * @param {IConfiguration=} [properties] Properties to set
     * @returns {Configuration} Configuration instance
     */
    Configuration.create = function create(properties) {
        return new Configuration(properties);
    };

    /**
     * Encodes the specified Configuration message. Does not implicitly {@link Configuration.verify|verify} messages.
     * @function encode
     * @memberof Configuration
     * @static
     * @param {IConfiguration} message Configuration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Configuration.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.platforms != null && message.platforms.length)
            for (let i = 0; i < message.platforms.length; ++i)
                $root.Configuration.Platform.encode(message.platforms[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Configuration message, length delimited. Does not implicitly {@link Configuration.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Configuration
     * @static
     * @param {IConfiguration} message Configuration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Configuration.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Configuration message from the specified reader or buffer.
     * @function decode
     * @memberof Configuration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Configuration} Configuration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Configuration.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Configuration();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.platforms && message.platforms.length))
                    message.platforms = [];
                message.platforms.push($root.Configuration.Platform.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Configuration message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Configuration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Configuration} Configuration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Configuration.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Configuration message.
     * @function verify
     * @memberof Configuration
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Configuration.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.platforms != null && message.hasOwnProperty("platforms")) {
            if (!Array.isArray(message.platforms))
                return "platforms: array expected";
            for (let i = 0; i < message.platforms.length; ++i) {
                let error = $root.Configuration.Platform.verify(message.platforms[i]);
                if (error)
                    return "platforms." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Configuration message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Configuration
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Configuration} Configuration
     */
    Configuration.fromObject = function fromObject(object) {
        if (object instanceof $root.Configuration)
            return object;
        let message = new $root.Configuration();
        if (object.platforms) {
            if (!Array.isArray(object.platforms))
                throw TypeError(".Configuration.platforms: array expected");
            message.platforms = [];
            for (let i = 0; i < object.platforms.length; ++i) {
                if (typeof object.platforms[i] !== "object")
                    throw TypeError(".Configuration.platforms: object expected");
                message.platforms[i] = $root.Configuration.Platform.fromObject(object.platforms[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Configuration message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Configuration
     * @static
     * @param {Configuration} message Configuration
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Configuration.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.platforms = [];
        if (message.platforms && message.platforms.length) {
            object.platforms = [];
            for (let j = 0; j < message.platforms.length; ++j)
                object.platforms[j] = $root.Configuration.Platform.toObject(message.platforms[j], options);
        }
        return object;
    };

    /**
     * Converts this Configuration to JSON.
     * @function toJSON
     * @memberof Configuration
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Configuration.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Configuration.Platform = (function() {

        /**
         * Properties of a Platform.
         * @memberof Configuration
         * @interface IPlatform
         * @property {PlatformType} platformType Platform platformType
         * @property {Array.<IKeyValueItem>|null} [items] Platform items
         */

        /**
         * Constructs a new Platform.
         * @memberof Configuration
         * @classdesc Represents a Platform.
         * @implements IPlatform
         * @constructor
         * @param {Configuration.IPlatform=} [properties] Properties to set
         */
        function Platform(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Platform platformType.
         * @member {PlatformType} platformType
         * @memberof Configuration.Platform
         * @instance
         */
        Platform.prototype.platformType = 0;

        /**
         * Platform items.
         * @member {Array.<IKeyValueItem>} items
         * @memberof Configuration.Platform
         * @instance
         */
        Platform.prototype.items = $util.emptyArray;

        /**
         * Creates a new Platform instance using the specified properties.
         * @function create
         * @memberof Configuration.Platform
         * @static
         * @param {Configuration.IPlatform=} [properties] Properties to set
         * @returns {Configuration.Platform} Platform instance
         */
        Platform.create = function create(properties) {
            return new Platform(properties);
        };

        /**
         * Encodes the specified Platform message. Does not implicitly {@link Configuration.Platform.verify|verify} messages.
         * @function encode
         * @memberof Configuration.Platform
         * @static
         * @param {Configuration.IPlatform} message Platform message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Platform.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.platformType);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.KeyValueItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Platform message, length delimited. Does not implicitly {@link Configuration.Platform.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Configuration.Platform
         * @static
         * @param {Configuration.IPlatform} message Platform message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Platform.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Platform message from the specified reader or buffer.
         * @function decode
         * @memberof Configuration.Platform
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Configuration.Platform} Platform
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Platform.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Configuration.Platform();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.platformType = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.KeyValueItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("platformType"))
                throw $util.ProtocolError("missing required 'platformType'", { instance: message });
            return message;
        };

        /**
         * Decodes a Platform message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Configuration.Platform
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Configuration.Platform} Platform
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Platform.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Platform message.
         * @function verify
         * @memberof Configuration.Platform
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Platform.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            switch (message.platformType) {
            default:
                return "platformType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
                break;
            }
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.KeyValueItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Platform message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Configuration.Platform
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Configuration.Platform} Platform
         */
        Platform.fromObject = function fromObject(object) {
            if (object instanceof $root.Configuration.Platform)
                return object;
            let message = new $root.Configuration.Platform();
            switch (object.platformType) {
            case "WEB":
            case 0:
                message.platformType = 0;
                break;
            case "ANDROID":
            case 1:
                message.platformType = 1;
                break;
            case "IOS":
            case 2:
                message.platformType = 2;
                break;
            case "WP":
            case 3:
                message.platformType = 3;
                break;
            }
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Configuration.Platform.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Configuration.Platform.items: object expected");
                    message.items[i] = $root.KeyValueItem.fromObject(object.items[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Platform message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Configuration.Platform
         * @static
         * @param {Configuration.Platform} message Platform
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Platform.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.platformType = options.enums === String ? "WEB" : 0;
            if (message.platformType != null && message.hasOwnProperty("platformType"))
                object.platformType = options.enums === String ? $root.PlatformType[message.platformType] : message.platformType;
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.KeyValueItem.toObject(message.items[j], options);
            }
            return object;
        };

        /**
         * Converts this Platform to JSON.
         * @function toJSON
         * @memberof Configuration.Platform
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Platform.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Platform;
    })();

    return Configuration;
})();

export const Billing = $root.Billing = (() => {

    /**
     * Properties of a Billing.
     * @exports IBilling
     * @interface IBilling
     * @property {Array.<Billing.IPlan>|null} [allPlans] Billing allPlans
     * @property {Billing.IPlan|null} [currentPlan] Billing currentPlan
     * @property {Array.<IKeyValueItem>|null} [specialItems] Billing specialItems
     * @property {string|null} [currentProductId] Billing currentProductId
     * @property {number|Long|null} [currentPlanValidUntil] Billing currentPlanValidUntil
     */

    /**
     * Constructs a new Billing.
     * @exports Billing
     * @classdesc Represents a Billing.
     * @implements IBilling
     * @constructor
     * @param {IBilling=} [properties] Properties to set
     */
    function Billing(properties) {
        this.allPlans = [];
        this.specialItems = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Billing allPlans.
     * @member {Array.<Billing.IPlan>} allPlans
     * @memberof Billing
     * @instance
     */
    Billing.prototype.allPlans = $util.emptyArray;

    /**
     * Billing currentPlan.
     * @member {Billing.IPlan|null|undefined} currentPlan
     * @memberof Billing
     * @instance
     */
    Billing.prototype.currentPlan = null;

    /**
     * Billing specialItems.
     * @member {Array.<IKeyValueItem>} specialItems
     * @memberof Billing
     * @instance
     */
    Billing.prototype.specialItems = $util.emptyArray;

    /**
     * Billing currentProductId.
     * @member {string} currentProductId
     * @memberof Billing
     * @instance
     */
    Billing.prototype.currentProductId = "";

    /**
     * Billing currentPlanValidUntil.
     * @member {number|Long} currentPlanValidUntil
     * @memberof Billing
     * @instance
     */
    Billing.prototype.currentPlanValidUntil = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new Billing instance using the specified properties.
     * @function create
     * @memberof Billing
     * @static
     * @param {IBilling=} [properties] Properties to set
     * @returns {Billing} Billing instance
     */
    Billing.create = function create(properties) {
        return new Billing(properties);
    };

    /**
     * Encodes the specified Billing message. Does not implicitly {@link Billing.verify|verify} messages.
     * @function encode
     * @memberof Billing
     * @static
     * @param {IBilling} message Billing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Billing.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.allPlans != null && message.allPlans.length)
            for (let i = 0; i < message.allPlans.length; ++i)
                $root.Billing.Plan.encode(message.allPlans[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.currentPlan != null && Object.hasOwnProperty.call(message, "currentPlan"))
            $root.Billing.Plan.encode(message.currentPlan, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.specialItems != null && message.specialItems.length)
            for (let i = 0; i < message.specialItems.length; ++i)
                $root.KeyValueItem.encode(message.specialItems[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.currentProductId != null && Object.hasOwnProperty.call(message, "currentProductId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentProductId);
        if (message.currentPlanValidUntil != null && Object.hasOwnProperty.call(message, "currentPlanValidUntil"))
            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.currentPlanValidUntil);
        return writer;
    };

    /**
     * Encodes the specified Billing message, length delimited. Does not implicitly {@link Billing.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Billing
     * @static
     * @param {IBilling} message Billing message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Billing.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Billing message from the specified reader or buffer.
     * @function decode
     * @memberof Billing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Billing} Billing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Billing.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.allPlans && message.allPlans.length))
                    message.allPlans = [];
                message.allPlans.push($root.Billing.Plan.decode(reader, reader.uint32()));
                break;
            case 2:
                message.currentPlan = $root.Billing.Plan.decode(reader, reader.uint32());
                break;
            case 3:
                if (!(message.specialItems && message.specialItems.length))
                    message.specialItems = [];
                message.specialItems.push($root.KeyValueItem.decode(reader, reader.uint32()));
                break;
            case 4:
                message.currentProductId = reader.string();
                break;
            case 5:
                message.currentPlanValidUntil = reader.int64();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Billing message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Billing
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Billing} Billing
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Billing.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Billing message.
     * @function verify
     * @memberof Billing
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Billing.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.allPlans != null && message.hasOwnProperty("allPlans")) {
            if (!Array.isArray(message.allPlans))
                return "allPlans: array expected";
            for (let i = 0; i < message.allPlans.length; ++i) {
                let error = $root.Billing.Plan.verify(message.allPlans[i]);
                if (error)
                    return "allPlans." + error;
            }
        }
        if (message.currentPlan != null && message.hasOwnProperty("currentPlan")) {
            let error = $root.Billing.Plan.verify(message.currentPlan);
            if (error)
                return "currentPlan." + error;
        }
        if (message.specialItems != null && message.hasOwnProperty("specialItems")) {
            if (!Array.isArray(message.specialItems))
                return "specialItems: array expected";
            for (let i = 0; i < message.specialItems.length; ++i) {
                let error = $root.KeyValueItem.verify(message.specialItems[i]);
                if (error)
                    return "specialItems." + error;
            }
        }
        if (message.currentProductId != null && message.hasOwnProperty("currentProductId"))
            if (!$util.isString(message.currentProductId))
                return "currentProductId: string expected";
        if (message.currentPlanValidUntil != null && message.hasOwnProperty("currentPlanValidUntil"))
            if (!$util.isInteger(message.currentPlanValidUntil) && !(message.currentPlanValidUntil && $util.isInteger(message.currentPlanValidUntil.low) && $util.isInteger(message.currentPlanValidUntil.high)))
                return "currentPlanValidUntil: integer|Long expected";
        return null;
    };

    /**
     * Creates a Billing message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Billing
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Billing} Billing
     */
    Billing.fromObject = function fromObject(object) {
        if (object instanceof $root.Billing)
            return object;
        let message = new $root.Billing();
        if (object.allPlans) {
            if (!Array.isArray(object.allPlans))
                throw TypeError(".Billing.allPlans: array expected");
            message.allPlans = [];
            for (let i = 0; i < object.allPlans.length; ++i) {
                if (typeof object.allPlans[i] !== "object")
                    throw TypeError(".Billing.allPlans: object expected");
                message.allPlans[i] = $root.Billing.Plan.fromObject(object.allPlans[i]);
            }
        }
        if (object.currentPlan != null) {
            if (typeof object.currentPlan !== "object")
                throw TypeError(".Billing.currentPlan: object expected");
            message.currentPlan = $root.Billing.Plan.fromObject(object.currentPlan);
        }
        if (object.specialItems) {
            if (!Array.isArray(object.specialItems))
                throw TypeError(".Billing.specialItems: array expected");
            message.specialItems = [];
            for (let i = 0; i < object.specialItems.length; ++i) {
                if (typeof object.specialItems[i] !== "object")
                    throw TypeError(".Billing.specialItems: object expected");
                message.specialItems[i] = $root.KeyValueItem.fromObject(object.specialItems[i]);
            }
        }
        if (object.currentProductId != null)
            message.currentProductId = String(object.currentProductId);
        if (object.currentPlanValidUntil != null)
            if ($util.Long)
                (message.currentPlanValidUntil = $util.Long.fromValue(object.currentPlanValidUntil)).unsigned = false;
            else if (typeof object.currentPlanValidUntil === "string")
                message.currentPlanValidUntil = parseInt(object.currentPlanValidUntil, 10);
            else if (typeof object.currentPlanValidUntil === "number")
                message.currentPlanValidUntil = object.currentPlanValidUntil;
            else if (typeof object.currentPlanValidUntil === "object")
                message.currentPlanValidUntil = new $util.LongBits(object.currentPlanValidUntil.low >>> 0, object.currentPlanValidUntil.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a Billing message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Billing
     * @static
     * @param {Billing} message Billing
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Billing.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.allPlans = [];
            object.specialItems = [];
        }
        if (options.defaults) {
            object.currentPlan = null;
            object.currentProductId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.currentPlanValidUntil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.currentPlanValidUntil = options.longs === String ? "0" : 0;
        }
        if (message.allPlans && message.allPlans.length) {
            object.allPlans = [];
            for (let j = 0; j < message.allPlans.length; ++j)
                object.allPlans[j] = $root.Billing.Plan.toObject(message.allPlans[j], options);
        }
        if (message.currentPlan != null && message.hasOwnProperty("currentPlan"))
            object.currentPlan = $root.Billing.Plan.toObject(message.currentPlan, options);
        if (message.specialItems && message.specialItems.length) {
            object.specialItems = [];
            for (let j = 0; j < message.specialItems.length; ++j)
                object.specialItems[j] = $root.KeyValueItem.toObject(message.specialItems[j], options);
        }
        if (message.currentProductId != null && message.hasOwnProperty("currentProductId"))
            object.currentProductId = message.currentProductId;
        if (message.currentPlanValidUntil != null && message.hasOwnProperty("currentPlanValidUntil"))
            if (typeof message.currentPlanValidUntil === "number")
                object.currentPlanValidUntil = options.longs === String ? String(message.currentPlanValidUntil) : message.currentPlanValidUntil;
            else
                object.currentPlanValidUntil = options.longs === String ? $util.Long.prototype.toString.call(message.currentPlanValidUntil) : options.longs === Number ? new $util.LongBits(message.currentPlanValidUntil.low >>> 0, message.currentPlanValidUntil.high >>> 0).toNumber() : message.currentPlanValidUntil;
        return object;
    };

    /**
     * Converts this Billing to JSON.
     * @function toJSON
     * @memberof Billing
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Billing.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * PlanType enum.
     * @name Billing.PlanType
     * @enum {number}
     * @property {number} FREE=0 FREE value
     * @property {number} FREE_PRE_TRIAL=1 FREE_PRE_TRIAL value
     * @property {number} FREE_POST_TRIAL=2 FREE_POST_TRIAL value
     * @property {number} BASIC=3 BASIC value
     * @property {number} ADVANCED=4 ADVANCED value
     * @property {number} PRO=5 PRO value
     * @property {number} VIP=6 VIP value
     * @property {number} BUSINESS=7 BUSINESS value
     */
    Billing.PlanType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FREE"] = 0;
        values[valuesById[1] = "FREE_PRE_TRIAL"] = 1;
        values[valuesById[2] = "FREE_POST_TRIAL"] = 2;
        values[valuesById[3] = "BASIC"] = 3;
        values[valuesById[4] = "ADVANCED"] = 4;
        values[valuesById[5] = "PRO"] = 5;
        values[valuesById[6] = "VIP"] = 6;
        values[valuesById[7] = "BUSINESS"] = 7;
        return values;
    })();

    /**
     * Method enum.
     * @name Billing.Method
     * @enum {number}
     * @property {number} GOOGLE_PLAY=0 GOOGLE_PLAY value
     * @property {number} APPLE_STORE=1 APPLE_STORE value
     * @property {number} PAYPAL=2 PAYPAL value
     * @property {number} PAYMILL=3 PAYMILL value
     */
    Billing.Method = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GOOGLE_PLAY"] = 0;
        values[valuesById[1] = "APPLE_STORE"] = 1;
        values[valuesById[2] = "PAYPAL"] = 2;
        values[valuesById[3] = "PAYMILL"] = 3;
        return values;
    })();

    /**
     * Period enum.
     * @name Billing.Period
     * @enum {number}
     * @property {number} WEEK=0 WEEK value
     * @property {number} MONTH=1 MONTH value
     * @property {number} QUARTER=2 QUARTER value
     * @property {number} YEAR=3 YEAR value
     * @property {number} UNLIMITED=4 UNLIMITED value
     */
    Billing.Period = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WEEK"] = 0;
        values[valuesById[1] = "MONTH"] = 1;
        values[valuesById[2] = "QUARTER"] = 2;
        values[valuesById[3] = "YEAR"] = 3;
        values[valuesById[4] = "UNLIMITED"] = 4;
        return values;
    })();

    Billing.Product = (function() {

        /**
         * Properties of a Product.
         * @memberof Billing
         * @interface IProduct
         * @property {string|null} [productId] Product productId
         * @property {Billing.Period|null} [period] Product period
         * @property {Billing.Method|null} [method] Product method
         * @property {string|null} [planId] Product planId
         * @property {number|Long|null} [prize] Product prize
         */

        /**
         * Constructs a new Product.
         * @memberof Billing
         * @classdesc Represents a Product.
         * @implements IProduct
         * @constructor
         * @param {Billing.IProduct=} [properties] Properties to set
         */
        function Product(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Product productId.
         * @member {string} productId
         * @memberof Billing.Product
         * @instance
         */
        Product.prototype.productId = "";

        /**
         * Product period.
         * @member {Billing.Period} period
         * @memberof Billing.Product
         * @instance
         */
        Product.prototype.period = 0;

        /**
         * Product method.
         * @member {Billing.Method} method
         * @memberof Billing.Product
         * @instance
         */
        Product.prototype.method = 0;

        /**
         * Product planId.
         * @member {string} planId
         * @memberof Billing.Product
         * @instance
         */
        Product.prototype.planId = "";

        /**
         * Product prize.
         * @member {number|Long} prize
         * @memberof Billing.Product
         * @instance
         */
        Product.prototype.prize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Product instance using the specified properties.
         * @function create
         * @memberof Billing.Product
         * @static
         * @param {Billing.IProduct=} [properties] Properties to set
         * @returns {Billing.Product} Product instance
         */
        Product.create = function create(properties) {
            return new Product(properties);
        };

        /**
         * Encodes the specified Product message. Does not implicitly {@link Billing.Product.verify|verify} messages.
         * @function encode
         * @memberof Billing.Product
         * @static
         * @param {Billing.IProduct} message Product message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Product.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productId);
            if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.period);
            if (message.method != null && Object.hasOwnProperty.call(message, "method"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.method);
            if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.planId);
            if (message.prize != null && Object.hasOwnProperty.call(message, "prize"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.prize);
            return writer;
        };

        /**
         * Encodes the specified Product message, length delimited. Does not implicitly {@link Billing.Product.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Billing.Product
         * @static
         * @param {Billing.IProduct} message Product message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Product.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Product message from the specified reader or buffer.
         * @function decode
         * @memberof Billing.Product
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Billing.Product} Product
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Product.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing.Product();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = reader.string();
                    break;
                case 2:
                    message.period = reader.int32();
                    break;
                case 3:
                    message.method = reader.int32();
                    break;
                case 4:
                    message.planId = reader.string();
                    break;
                case 5:
                    message.prize = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Product message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Billing.Product
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Billing.Product} Product
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Product.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Product message.
         * @function verify
         * @memberof Billing.Product
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Product.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.productId != null && message.hasOwnProperty("productId"))
                if (!$util.isString(message.productId))
                    return "productId: string expected";
            if (message.period != null && message.hasOwnProperty("period"))
                switch (message.period) {
                default:
                    return "period: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.method != null && message.hasOwnProperty("method"))
                switch (message.method) {
                default:
                    return "method: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.planId != null && message.hasOwnProperty("planId"))
                if (!$util.isString(message.planId))
                    return "planId: string expected";
            if (message.prize != null && message.hasOwnProperty("prize"))
                if (!$util.isInteger(message.prize) && !(message.prize && $util.isInteger(message.prize.low) && $util.isInteger(message.prize.high)))
                    return "prize: integer|Long expected";
            return null;
        };

        /**
         * Creates a Product message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Billing.Product
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Billing.Product} Product
         */
        Product.fromObject = function fromObject(object) {
            if (object instanceof $root.Billing.Product)
                return object;
            let message = new $root.Billing.Product();
            if (object.productId != null)
                message.productId = String(object.productId);
            switch (object.period) {
            case "WEEK":
            case 0:
                message.period = 0;
                break;
            case "MONTH":
            case 1:
                message.period = 1;
                break;
            case "QUARTER":
            case 2:
                message.period = 2;
                break;
            case "YEAR":
            case 3:
                message.period = 3;
                break;
            case "UNLIMITED":
            case 4:
                message.period = 4;
                break;
            }
            switch (object.method) {
            case "GOOGLE_PLAY":
            case 0:
                message.method = 0;
                break;
            case "APPLE_STORE":
            case 1:
                message.method = 1;
                break;
            case "PAYPAL":
            case 2:
                message.method = 2;
                break;
            case "PAYMILL":
            case 3:
                message.method = 3;
                break;
            }
            if (object.planId != null)
                message.planId = String(object.planId);
            if (object.prize != null)
                if ($util.Long)
                    (message.prize = $util.Long.fromValue(object.prize)).unsigned = false;
                else if (typeof object.prize === "string")
                    message.prize = parseInt(object.prize, 10);
                else if (typeof object.prize === "number")
                    message.prize = object.prize;
                else if (typeof object.prize === "object")
                    message.prize = new $util.LongBits(object.prize.low >>> 0, object.prize.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Product message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Billing.Product
         * @static
         * @param {Billing.Product} message Product
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Product.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.productId = "";
                object.period = options.enums === String ? "WEEK" : 0;
                object.method = options.enums === String ? "GOOGLE_PLAY" : 0;
                object.planId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.prize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.prize = options.longs === String ? "0" : 0;
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = message.productId;
            if (message.period != null && message.hasOwnProperty("period"))
                object.period = options.enums === String ? $root.Billing.Period[message.period] : message.period;
            if (message.method != null && message.hasOwnProperty("method"))
                object.method = options.enums === String ? $root.Billing.Method[message.method] : message.method;
            if (message.planId != null && message.hasOwnProperty("planId"))
                object.planId = message.planId;
            if (message.prize != null && message.hasOwnProperty("prize"))
                if (typeof message.prize === "number")
                    object.prize = options.longs === String ? String(message.prize) : message.prize;
                else
                    object.prize = options.longs === String ? $util.Long.prototype.toString.call(message.prize) : options.longs === Number ? new $util.LongBits(message.prize.low >>> 0, message.prize.high >>> 0).toNumber() : message.prize;
            return object;
        };

        /**
         * Converts this Product to JSON.
         * @function toJSON
         * @memberof Billing.Product
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Product.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Product;
    })();

    Billing.VoucherCode = (function() {

        /**
         * Properties of a VoucherCode.
         * @memberof Billing
         * @interface IVoucherCode
         * @property {string|null} [code] VoucherCode code
         */

        /**
         * Constructs a new VoucherCode.
         * @memberof Billing
         * @classdesc Represents a VoucherCode.
         * @implements IVoucherCode
         * @constructor
         * @param {Billing.IVoucherCode=} [properties] Properties to set
         */
        function VoucherCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VoucherCode code.
         * @member {string} code
         * @memberof Billing.VoucherCode
         * @instance
         */
        VoucherCode.prototype.code = "";

        /**
         * Creates a new VoucherCode instance using the specified properties.
         * @function create
         * @memberof Billing.VoucherCode
         * @static
         * @param {Billing.IVoucherCode=} [properties] Properties to set
         * @returns {Billing.VoucherCode} VoucherCode instance
         */
        VoucherCode.create = function create(properties) {
            return new VoucherCode(properties);
        };

        /**
         * Encodes the specified VoucherCode message. Does not implicitly {@link Billing.VoucherCode.verify|verify} messages.
         * @function encode
         * @memberof Billing.VoucherCode
         * @static
         * @param {Billing.IVoucherCode} message VoucherCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VoucherCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            return writer;
        };

        /**
         * Encodes the specified VoucherCode message, length delimited. Does not implicitly {@link Billing.VoucherCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Billing.VoucherCode
         * @static
         * @param {Billing.IVoucherCode} message VoucherCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VoucherCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VoucherCode message from the specified reader or buffer.
         * @function decode
         * @memberof Billing.VoucherCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Billing.VoucherCode} VoucherCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VoucherCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing.VoucherCode();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VoucherCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Billing.VoucherCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Billing.VoucherCode} VoucherCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VoucherCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VoucherCode message.
         * @function verify
         * @memberof Billing.VoucherCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VoucherCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };

        /**
         * Creates a VoucherCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Billing.VoucherCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Billing.VoucherCode} VoucherCode
         */
        VoucherCode.fromObject = function fromObject(object) {
            if (object instanceof $root.Billing.VoucherCode)
                return object;
            let message = new $root.Billing.VoucherCode();
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };

        /**
         * Creates a plain object from a VoucherCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Billing.VoucherCode
         * @static
         * @param {Billing.VoucherCode} message VoucherCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VoucherCode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.code = "";
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };

        /**
         * Converts this VoucherCode to JSON.
         * @function toJSON
         * @memberof Billing.VoucherCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VoucherCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VoucherCode;
    })();

    Billing.TrialPlan = (function() {

        /**
         * Properties of a TrialPlan.
         * @memberof Billing
         * @interface ITrialPlan
         * @property {Billing.PlanType|null} [planType] TrialPlan planType
         * @property {number|null} [daysInUse] TrialPlan daysInUse
         */

        /**
         * Constructs a new TrialPlan.
         * @memberof Billing
         * @classdesc Represents a TrialPlan.
         * @implements ITrialPlan
         * @constructor
         * @param {Billing.ITrialPlan=} [properties] Properties to set
         */
        function TrialPlan(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TrialPlan planType.
         * @member {Billing.PlanType} planType
         * @memberof Billing.TrialPlan
         * @instance
         */
        TrialPlan.prototype.planType = 0;

        /**
         * TrialPlan daysInUse.
         * @member {number} daysInUse
         * @memberof Billing.TrialPlan
         * @instance
         */
        TrialPlan.prototype.daysInUse = 0;

        /**
         * Creates a new TrialPlan instance using the specified properties.
         * @function create
         * @memberof Billing.TrialPlan
         * @static
         * @param {Billing.ITrialPlan=} [properties] Properties to set
         * @returns {Billing.TrialPlan} TrialPlan instance
         */
        TrialPlan.create = function create(properties) {
            return new TrialPlan(properties);
        };

        /**
         * Encodes the specified TrialPlan message. Does not implicitly {@link Billing.TrialPlan.verify|verify} messages.
         * @function encode
         * @memberof Billing.TrialPlan
         * @static
         * @param {Billing.ITrialPlan} message TrialPlan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrialPlan.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.planType);
            if (message.daysInUse != null && Object.hasOwnProperty.call(message, "daysInUse"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.daysInUse);
            return writer;
        };

        /**
         * Encodes the specified TrialPlan message, length delimited. Does not implicitly {@link Billing.TrialPlan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Billing.TrialPlan
         * @static
         * @param {Billing.ITrialPlan} message TrialPlan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrialPlan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TrialPlan message from the specified reader or buffer.
         * @function decode
         * @memberof Billing.TrialPlan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Billing.TrialPlan} TrialPlan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrialPlan.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing.TrialPlan();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.planType = reader.int32();
                    break;
                case 2:
                    message.daysInUse = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TrialPlan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Billing.TrialPlan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Billing.TrialPlan} TrialPlan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrialPlan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TrialPlan message.
         * @function verify
         * @memberof Billing.TrialPlan
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TrialPlan.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.planType != null && message.hasOwnProperty("planType"))
                switch (message.planType) {
                default:
                    return "planType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.daysInUse != null && message.hasOwnProperty("daysInUse"))
                if (!$util.isInteger(message.daysInUse))
                    return "daysInUse: integer expected";
            return null;
        };

        /**
         * Creates a TrialPlan message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Billing.TrialPlan
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Billing.TrialPlan} TrialPlan
         */
        TrialPlan.fromObject = function fromObject(object) {
            if (object instanceof $root.Billing.TrialPlan)
                return object;
            let message = new $root.Billing.TrialPlan();
            switch (object.planType) {
            case "FREE":
            case 0:
                message.planType = 0;
                break;
            case "FREE_PRE_TRIAL":
            case 1:
                message.planType = 1;
                break;
            case "FREE_POST_TRIAL":
            case 2:
                message.planType = 2;
                break;
            case "BASIC":
            case 3:
                message.planType = 3;
                break;
            case "ADVANCED":
            case 4:
                message.planType = 4;
                break;
            case "PRO":
            case 5:
                message.planType = 5;
                break;
            case "VIP":
            case 6:
                message.planType = 6;
                break;
            case "BUSINESS":
            case 7:
                message.planType = 7;
                break;
            }
            if (object.daysInUse != null)
                message.daysInUse = object.daysInUse | 0;
            return message;
        };

        /**
         * Creates a plain object from a TrialPlan message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Billing.TrialPlan
         * @static
         * @param {Billing.TrialPlan} message TrialPlan
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TrialPlan.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.planType = options.enums === String ? "FREE" : 0;
                object.daysInUse = 0;
            }
            if (message.planType != null && message.hasOwnProperty("planType"))
                object.planType = options.enums === String ? $root.Billing.PlanType[message.planType] : message.planType;
            if (message.daysInUse != null && message.hasOwnProperty("daysInUse"))
                object.daysInUse = message.daysInUse;
            return object;
        };

        /**
         * Converts this TrialPlan to JSON.
         * @function toJSON
         * @memberof Billing.TrialPlan
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TrialPlan.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TrialPlan;
    })();

    Billing.Plan = (function() {

        /**
         * Properties of a Plan.
         * @memberof Billing
         * @interface IPlan
         * @property {Billing.PlanType|null} [planType] Plan planType
         * @property {string|null} [name] Plan name
         * @property {Array.<Billing.IProduct>|null} [products] Plan products
         * @property {boolean|null} [visible] Plan visible
         * @property {string|null} [id] Plan id
         */

        /**
         * Constructs a new Plan.
         * @memberof Billing
         * @classdesc Represents a Plan.
         * @implements IPlan
         * @constructor
         * @param {Billing.IPlan=} [properties] Properties to set
         */
        function Plan(properties) {
            this.products = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Plan planType.
         * @member {Billing.PlanType} planType
         * @memberof Billing.Plan
         * @instance
         */
        Plan.prototype.planType = 0;

        /**
         * Plan name.
         * @member {string} name
         * @memberof Billing.Plan
         * @instance
         */
        Plan.prototype.name = "";

        /**
         * Plan products.
         * @member {Array.<Billing.IProduct>} products
         * @memberof Billing.Plan
         * @instance
         */
        Plan.prototype.products = $util.emptyArray;

        /**
         * Plan visible.
         * @member {boolean} visible
         * @memberof Billing.Plan
         * @instance
         */
        Plan.prototype.visible = false;

        /**
         * Plan id.
         * @member {string} id
         * @memberof Billing.Plan
         * @instance
         */
        Plan.prototype.id = "";

        /**
         * Creates a new Plan instance using the specified properties.
         * @function create
         * @memberof Billing.Plan
         * @static
         * @param {Billing.IPlan=} [properties] Properties to set
         * @returns {Billing.Plan} Plan instance
         */
        Plan.create = function create(properties) {
            return new Plan(properties);
        };

        /**
         * Encodes the specified Plan message. Does not implicitly {@link Billing.Plan.verify|verify} messages.
         * @function encode
         * @memberof Billing.Plan
         * @static
         * @param {Billing.IPlan} message Plan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Plan.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.planType != null && Object.hasOwnProperty.call(message, "planType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.planType);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.products != null && message.products.length)
                for (let i = 0; i < message.products.length; ++i)
                    $root.Billing.Product.encode(message.products[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.visible != null && Object.hasOwnProperty.call(message, "visible"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.visible);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified Plan message, length delimited. Does not implicitly {@link Billing.Plan.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Billing.Plan
         * @static
         * @param {Billing.IPlan} message Plan message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Plan.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Plan message from the specified reader or buffer.
         * @function decode
         * @memberof Billing.Plan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Billing.Plan} Plan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Plan.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing.Plan();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.planType = reader.int32();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.Billing.Product.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.visible = reader.bool();
                    break;
                case 5:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Plan message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Billing.Plan
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Billing.Plan} Plan
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Plan.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Plan message.
         * @function verify
         * @memberof Billing.Plan
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Plan.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.planType != null && message.hasOwnProperty("planType"))
                switch (message.planType) {
                default:
                    return "planType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    break;
                }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.products != null && message.hasOwnProperty("products")) {
                if (!Array.isArray(message.products))
                    return "products: array expected";
                for (let i = 0; i < message.products.length; ++i) {
                    let error = $root.Billing.Product.verify(message.products[i]);
                    if (error)
                        return "products." + error;
                }
            }
            if (message.visible != null && message.hasOwnProperty("visible"))
                if (typeof message.visible !== "boolean")
                    return "visible: boolean expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a Plan message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Billing.Plan
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Billing.Plan} Plan
         */
        Plan.fromObject = function fromObject(object) {
            if (object instanceof $root.Billing.Plan)
                return object;
            let message = new $root.Billing.Plan();
            switch (object.planType) {
            case "FREE":
            case 0:
                message.planType = 0;
                break;
            case "FREE_PRE_TRIAL":
            case 1:
                message.planType = 1;
                break;
            case "FREE_POST_TRIAL":
            case 2:
                message.planType = 2;
                break;
            case "BASIC":
            case 3:
                message.planType = 3;
                break;
            case "ADVANCED":
            case 4:
                message.planType = 4;
                break;
            case "PRO":
            case 5:
                message.planType = 5;
                break;
            case "VIP":
            case 6:
                message.planType = 6;
                break;
            case "BUSINESS":
            case 7:
                message.planType = 7;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.products) {
                if (!Array.isArray(object.products))
                    throw TypeError(".Billing.Plan.products: array expected");
                message.products = [];
                for (let i = 0; i < object.products.length; ++i) {
                    if (typeof object.products[i] !== "object")
                        throw TypeError(".Billing.Plan.products: object expected");
                    message.products[i] = $root.Billing.Product.fromObject(object.products[i]);
                }
            }
            if (object.visible != null)
                message.visible = Boolean(object.visible);
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a Plan message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Billing.Plan
         * @static
         * @param {Billing.Plan} message Plan
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Plan.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.products = [];
            if (options.defaults) {
                object.planType = options.enums === String ? "FREE" : 0;
                object.name = "";
                object.visible = false;
                object.id = "";
            }
            if (message.planType != null && message.hasOwnProperty("planType"))
                object.planType = options.enums === String ? $root.Billing.PlanType[message.planType] : message.planType;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.products && message.products.length) {
                object.products = [];
                for (let j = 0; j < message.products.length; ++j)
                    object.products[j] = $root.Billing.Product.toObject(message.products[j], options);
            }
            if (message.visible != null && message.hasOwnProperty("visible"))
                object.visible = message.visible;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this Plan to JSON.
         * @function toJSON
         * @memberof Billing.Plan
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Plan.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Plan;
    })();

    Billing.AvailablePlans = (function() {

        /**
         * Properties of an AvailablePlans.
         * @memberof Billing
         * @interface IAvailablePlans
         * @property {Array.<Billing.IPlan>|null} [plans] AvailablePlans plans
         * @property {ISale|null} [sale] AvailablePlans sale
         */

        /**
         * Constructs a new AvailablePlans.
         * @memberof Billing
         * @classdesc Represents an AvailablePlans.
         * @implements IAvailablePlans
         * @constructor
         * @param {Billing.IAvailablePlans=} [properties] Properties to set
         */
        function AvailablePlans(properties) {
            this.plans = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AvailablePlans plans.
         * @member {Array.<Billing.IPlan>} plans
         * @memberof Billing.AvailablePlans
         * @instance
         */
        AvailablePlans.prototype.plans = $util.emptyArray;

        /**
         * AvailablePlans sale.
         * @member {ISale|null|undefined} sale
         * @memberof Billing.AvailablePlans
         * @instance
         */
        AvailablePlans.prototype.sale = null;

        /**
         * Creates a new AvailablePlans instance using the specified properties.
         * @function create
         * @memberof Billing.AvailablePlans
         * @static
         * @param {Billing.IAvailablePlans=} [properties] Properties to set
         * @returns {Billing.AvailablePlans} AvailablePlans instance
         */
        AvailablePlans.create = function create(properties) {
            return new AvailablePlans(properties);
        };

        /**
         * Encodes the specified AvailablePlans message. Does not implicitly {@link Billing.AvailablePlans.verify|verify} messages.
         * @function encode
         * @memberof Billing.AvailablePlans
         * @static
         * @param {Billing.IAvailablePlans} message AvailablePlans message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailablePlans.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.plans != null && message.plans.length)
                for (let i = 0; i < message.plans.length; ++i)
                    $root.Billing.Plan.encode(message.plans[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sale != null && Object.hasOwnProperty.call(message, "sale"))
                $root.Sale.encode(message.sale, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AvailablePlans message, length delimited. Does not implicitly {@link Billing.AvailablePlans.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Billing.AvailablePlans
         * @static
         * @param {Billing.IAvailablePlans} message AvailablePlans message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailablePlans.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AvailablePlans message from the specified reader or buffer.
         * @function decode
         * @memberof Billing.AvailablePlans
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Billing.AvailablePlans} AvailablePlans
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailablePlans.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing.AvailablePlans();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.plans && message.plans.length))
                        message.plans = [];
                    message.plans.push($root.Billing.Plan.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.sale = $root.Sale.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AvailablePlans message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Billing.AvailablePlans
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Billing.AvailablePlans} AvailablePlans
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailablePlans.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AvailablePlans message.
         * @function verify
         * @memberof Billing.AvailablePlans
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailablePlans.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.plans != null && message.hasOwnProperty("plans")) {
                if (!Array.isArray(message.plans))
                    return "plans: array expected";
                for (let i = 0; i < message.plans.length; ++i) {
                    let error = $root.Billing.Plan.verify(message.plans[i]);
                    if (error)
                        return "plans." + error;
                }
            }
            if (message.sale != null && message.hasOwnProperty("sale")) {
                let error = $root.Sale.verify(message.sale);
                if (error)
                    return "sale." + error;
            }
            return null;
        };

        /**
         * Creates an AvailablePlans message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Billing.AvailablePlans
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Billing.AvailablePlans} AvailablePlans
         */
        AvailablePlans.fromObject = function fromObject(object) {
            if (object instanceof $root.Billing.AvailablePlans)
                return object;
            let message = new $root.Billing.AvailablePlans();
            if (object.plans) {
                if (!Array.isArray(object.plans))
                    throw TypeError(".Billing.AvailablePlans.plans: array expected");
                message.plans = [];
                for (let i = 0; i < object.plans.length; ++i) {
                    if (typeof object.plans[i] !== "object")
                        throw TypeError(".Billing.AvailablePlans.plans: object expected");
                    message.plans[i] = $root.Billing.Plan.fromObject(object.plans[i]);
                }
            }
            if (object.sale != null) {
                if (typeof object.sale !== "object")
                    throw TypeError(".Billing.AvailablePlans.sale: object expected");
                message.sale = $root.Sale.fromObject(object.sale);
            }
            return message;
        };

        /**
         * Creates a plain object from an AvailablePlans message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Billing.AvailablePlans
         * @static
         * @param {Billing.AvailablePlans} message AvailablePlans
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailablePlans.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.plans = [];
            if (options.defaults)
                object.sale = null;
            if (message.plans && message.plans.length) {
                object.plans = [];
                for (let j = 0; j < message.plans.length; ++j)
                    object.plans[j] = $root.Billing.Plan.toObject(message.plans[j], options);
            }
            if (message.sale != null && message.hasOwnProperty("sale"))
                object.sale = $root.Sale.toObject(message.sale, options);
            return object;
        };

        /**
         * Converts this AvailablePlans to JSON.
         * @function toJSON
         * @memberof Billing.AvailablePlans
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailablePlans.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AvailablePlans;
    })();

    Billing.Transaction = (function() {

        /**
         * Properties of a Transaction.
         * @memberof Billing
         * @interface ITransaction
         * @property {string|null} [productId] Transaction productId
         * @property {string|null} [token] Transaction token
         * @property {number|Long|null} [createdAt] Transaction createdAt
         * @property {string|null} [transactionId] Transaction transactionId
         */

        /**
         * Constructs a new Transaction.
         * @memberof Billing
         * @classdesc Represents a Transaction.
         * @implements ITransaction
         * @constructor
         * @param {Billing.ITransaction=} [properties] Properties to set
         */
        function Transaction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Transaction productId.
         * @member {string} productId
         * @memberof Billing.Transaction
         * @instance
         */
        Transaction.prototype.productId = "";

        /**
         * Transaction token.
         * @member {string} token
         * @memberof Billing.Transaction
         * @instance
         */
        Transaction.prototype.token = "";

        /**
         * Transaction createdAt.
         * @member {number|Long} createdAt
         * @memberof Billing.Transaction
         * @instance
         */
        Transaction.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Transaction transactionId.
         * @member {string} transactionId
         * @memberof Billing.Transaction
         * @instance
         */
        Transaction.prototype.transactionId = "";

        /**
         * Creates a new Transaction instance using the specified properties.
         * @function create
         * @memberof Billing.Transaction
         * @static
         * @param {Billing.ITransaction=} [properties] Properties to set
         * @returns {Billing.Transaction} Transaction instance
         */
        Transaction.create = function create(properties) {
            return new Transaction(properties);
        };

        /**
         * Encodes the specified Transaction message. Does not implicitly {@link Billing.Transaction.verify|verify} messages.
         * @function encode
         * @memberof Billing.Transaction
         * @static
         * @param {Billing.ITransaction} message Transaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Transaction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdAt);
            if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.transactionId);
            return writer;
        };

        /**
         * Encodes the specified Transaction message, length delimited. Does not implicitly {@link Billing.Transaction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Billing.Transaction
         * @static
         * @param {Billing.ITransaction} message Transaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Transaction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Transaction message from the specified reader or buffer.
         * @function decode
         * @memberof Billing.Transaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Billing.Transaction} Transaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Transaction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Billing.Transaction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.createdAt = reader.int64();
                    break;
                case 4:
                    message.transactionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Transaction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Billing.Transaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Billing.Transaction} Transaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Transaction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Transaction message.
         * @function verify
         * @memberof Billing.Transaction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Transaction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.productId != null && message.hasOwnProperty("productId"))
                if (!$util.isString(message.productId))
                    return "productId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                    return "createdAt: integer|Long expected";
            if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                if (!$util.isString(message.transactionId))
                    return "transactionId: string expected";
            return null;
        };

        /**
         * Creates a Transaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Billing.Transaction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Billing.Transaction} Transaction
         */
        Transaction.fromObject = function fromObject(object) {
            if (object instanceof $root.Billing.Transaction)
                return object;
            let message = new $root.Billing.Transaction();
            if (object.productId != null)
                message.productId = String(object.productId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.createdAt != null)
                if ($util.Long)
                    (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
                else if (typeof object.createdAt === "string")
                    message.createdAt = parseInt(object.createdAt, 10);
                else if (typeof object.createdAt === "number")
                    message.createdAt = object.createdAt;
                else if (typeof object.createdAt === "object")
                    message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
            if (object.transactionId != null)
                message.transactionId = String(object.transactionId);
            return message;
        };

        /**
         * Creates a plain object from a Transaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Billing.Transaction
         * @static
         * @param {Billing.Transaction} message Transaction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Transaction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.productId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAt = options.longs === String ? "0" : 0;
                object.transactionId = "";
            }
            if (message.productId != null && message.hasOwnProperty("productId"))
                object.productId = message.productId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                if (typeof message.createdAt === "number")
                    object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
                else
                    object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
            if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                object.transactionId = message.transactionId;
            return object;
        };

        /**
         * Converts this Transaction to JSON.
         * @function toJSON
         * @memberof Billing.Transaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Transaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Transaction;
    })();

    return Billing;
})();

export const UserConfig = $root.UserConfig = (() => {

    /**
     * Properties of a UserConfig.
     * @exports IUserConfig
     * @interface IUserConfig
     * @property {boolean} retentionEmailSubscribe UserConfig retentionEmailSubscribe
     */

    /**
     * Constructs a new UserConfig.
     * @exports UserConfig
     * @classdesc Represents a UserConfig.
     * @implements IUserConfig
     * @constructor
     * @param {IUserConfig=} [properties] Properties to set
     */
    function UserConfig(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserConfig retentionEmailSubscribe.
     * @member {boolean} retentionEmailSubscribe
     * @memberof UserConfig
     * @instance
     */
    UserConfig.prototype.retentionEmailSubscribe = false;

    /**
     * Creates a new UserConfig instance using the specified properties.
     * @function create
     * @memberof UserConfig
     * @static
     * @param {IUserConfig=} [properties] Properties to set
     * @returns {UserConfig} UserConfig instance
     */
    UserConfig.create = function create(properties) {
        return new UserConfig(properties);
    };

    /**
     * Encodes the specified UserConfig message. Does not implicitly {@link UserConfig.verify|verify} messages.
     * @function encode
     * @memberof UserConfig
     * @static
     * @param {IUserConfig} message UserConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserConfig.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.retentionEmailSubscribe);
        return writer;
    };

    /**
     * Encodes the specified UserConfig message, length delimited. Does not implicitly {@link UserConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UserConfig
     * @static
     * @param {IUserConfig} message UserConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserConfig.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserConfig message from the specified reader or buffer.
     * @function decode
     * @memberof UserConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UserConfig} UserConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserConfig();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.retentionEmailSubscribe = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("retentionEmailSubscribe"))
            throw $util.ProtocolError("missing required 'retentionEmailSubscribe'", { instance: message });
        return message;
    };

    /**
     * Decodes a UserConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UserConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UserConfig} UserConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserConfig.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserConfig message.
     * @function verify
     * @memberof UserConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserConfig.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (typeof message.retentionEmailSubscribe !== "boolean")
            return "retentionEmailSubscribe: boolean expected";
        return null;
    };

    /**
     * Creates a UserConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UserConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UserConfig} UserConfig
     */
    UserConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.UserConfig)
            return object;
        let message = new $root.UserConfig();
        if (object.retentionEmailSubscribe != null)
            message.retentionEmailSubscribe = Boolean(object.retentionEmailSubscribe);
        return message;
    };

    /**
     * Creates a plain object from a UserConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UserConfig
     * @static
     * @param {UserConfig} message UserConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserConfig.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.retentionEmailSubscribe = false;
        if (message.retentionEmailSubscribe != null && message.hasOwnProperty("retentionEmailSubscribe"))
            object.retentionEmailSubscribe = message.retentionEmailSubscribe;
        return object;
    };

    /**
     * Converts this UserConfig to JSON.
     * @function toJSON
     * @memberof UserConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserConfig;
})();

/**
 * FeatureType enum.
 * @exports FeatureType
 * @enum {number}
 * @property {number} A=0 A value
 * @property {number} B=1 B value
 * @property {number} C=2 C value
 * @property {number} D=3 D value
 * @property {number} E=4 E value
 */
export const FeatureType = $root.FeatureType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "A"] = 0;
    values[valuesById[1] = "B"] = 1;
    values[valuesById[2] = "C"] = 2;
    values[valuesById[3] = "D"] = 3;
    values[valuesById[4] = "E"] = 4;
    return values;
})();

export const Feature = $root.Feature = (() => {

    /**
     * Properties of a Feature.
     * @exports IFeature
     * @interface IFeature
     * @property {FeatureType} type Feature type
     * @property {string} name Feature name
     * @property {string|null} [description] Feature description
     */

    /**
     * Constructs a new Feature.
     * @exports Feature
     * @classdesc Represents a Feature.
     * @implements IFeature
     * @constructor
     * @param {IFeature=} [properties] Properties to set
     */
    function Feature(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Feature type.
     * @member {FeatureType} type
     * @memberof Feature
     * @instance
     */
    Feature.prototype.type = 0;

    /**
     * Feature name.
     * @member {string} name
     * @memberof Feature
     * @instance
     */
    Feature.prototype.name = "";

    /**
     * Feature description.
     * @member {string} description
     * @memberof Feature
     * @instance
     */
    Feature.prototype.description = "";

    /**
     * Creates a new Feature instance using the specified properties.
     * @function create
     * @memberof Feature
     * @static
     * @param {IFeature=} [properties] Properties to set
     * @returns {Feature} Feature instance
     */
    Feature.create = function create(properties) {
        return new Feature(properties);
    };

    /**
     * Encodes the specified Feature message. Does not implicitly {@link Feature.verify|verify} messages.
     * @function encode
     * @memberof Feature
     * @static
     * @param {IFeature} message Feature message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Feature.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        return writer;
    };

    /**
     * Encodes the specified Feature message, length delimited. Does not implicitly {@link Feature.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Feature
     * @static
     * @param {IFeature} message Feature message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Feature.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Feature message from the specified reader or buffer.
     * @function decode
     * @memberof Feature
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Feature} Feature
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Feature.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Feature();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.description = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        return message;
    };

    /**
     * Decodes a Feature message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Feature
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Feature} Feature
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Feature.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Feature message.
     * @function verify
     * @memberof Feature
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Feature.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            break;
        }
        if (!$util.isString(message.name))
            return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        return null;
    };

    /**
     * Creates a Feature message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Feature
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Feature} Feature
     */
    Feature.fromObject = function fromObject(object) {
        if (object instanceof $root.Feature)
            return object;
        let message = new $root.Feature();
        switch (object.type) {
        case "A":
        case 0:
            message.type = 0;
            break;
        case "B":
        case 1:
            message.type = 1;
            break;
        case "C":
        case 2:
            message.type = 2;
            break;
        case "D":
        case 3:
            message.type = 3;
            break;
        case "E":
        case 4:
            message.type = 4;
            break;
        }
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        return message;
    };

    /**
     * Creates a plain object from a Feature message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Feature
     * @static
     * @param {Feature} message Feature
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Feature.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "A" : 0;
            object.name = "";
            object.description = "";
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.FeatureType[message.type] : message.type;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        return object;
    };

    /**
     * Converts this Feature to JSON.
     * @function toJSON
     * @memberof Feature
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Feature.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Feature;
})();

export const User = $root.User = (() => {

    /**
     * Properties of a User.
     * @exports IUser
     * @interface IUser
     * @property {string} userId User userId
     * @property {number|Long} createdAt User createdAt
     * @property {number|Long} updatedAt User updatedAt
     * @property {string|null} [firstName] User firstName
     * @property {string|null} [lastName] User lastName
     * @property {string|null} [fullName] User fullName
     * @property {string|null} [email] User email
     * @property {string|null} [password] User password
     * @property {string|null} [birthday] User birthday
     * @property {string|null} [location] User location
     * @property {string|null} [gender] User gender
     * @property {string|null} [token] User token
     * @property {number|Long|null} [expiresInMs] User expiresInMs
     * @property {User.AuthMethod|null} [authMethod] User authMethod
     * @property {IReplicationEndpoint|null} [replication] User replication
     * @property {ICouchbaseReplicationEndpoint|null} [replicationCouchbase] User replicationCouchbase
     * @property {IBilling|null} [billing] User billing
     * @property {IConfiguration|null} [configuration] User configuration
     * @property {Array.<PlatformType>|null} [usedPlatforms] User usedPlatforms
     * @property {string|null} [referralId] User referralId
     * @property {string|null} [timezone] User timezone
     * @property {string|null} [avatarUrl] User avatarUrl
     * @property {IUserConfig|null} [userConfig] User userConfig
     * @property {Array.<IFeature>|null} [features] User features
     * @property {string|null} [referralMedium] User referralMedium
     * @property {string|null} [referralTerm] User referralTerm
     * @property {string|null} [referralContent] User referralContent
     * @property {number|null} [abRatio] User abRatio
     * @property {ISale|null} [sale] User sale
     * @property {Array.<IGroup>|null} [groups] User groups
     * @property {boolean|null} [referrerRewarded] User referrerRewarded
     * @property {IActivePlan|null} [activePlan] User activePlan
     * @property {IUserConsent|null} [consents] User consents
     * @property {string|null} [countryCode] User countryCode
     * @property {Board.ICompanyInfo|null} [companyInfo] User companyInfo
     * @property {ReferralSource|null} [referralSource] User referralSource
     * @property {IVerifiedProfile|null} [verifiedProfile] User verifiedProfile
     * @property {IFreePremium|null} [freePremium] User freePremium
     * @property {string|null} [supportEmail] User supportEmail
     */

    /**
     * Constructs a new User.
     * @exports User
     * @classdesc Represents a User.
     * @implements IUser
     * @constructor
     * @param {IUser=} [properties] Properties to set
     */
    function User(properties) {
        this.usedPlatforms = [];
        this.features = [];
        this.groups = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * User userId.
     * @member {string} userId
     * @memberof User
     * @instance
     */
    User.prototype.userId = "";

    /**
     * User createdAt.
     * @member {number|Long} createdAt
     * @memberof User
     * @instance
     */
    User.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * User updatedAt.
     * @member {number|Long} updatedAt
     * @memberof User
     * @instance
     */
    User.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * User firstName.
     * @member {string} firstName
     * @memberof User
     * @instance
     */
    User.prototype.firstName = "";

    /**
     * User lastName.
     * @member {string} lastName
     * @memberof User
     * @instance
     */
    User.prototype.lastName = "";

    /**
     * User fullName.
     * @member {string} fullName
     * @memberof User
     * @instance
     */
    User.prototype.fullName = "";

    /**
     * User email.
     * @member {string} email
     * @memberof User
     * @instance
     */
    User.prototype.email = "";

    /**
     * User password.
     * @member {string} password
     * @memberof User
     * @instance
     */
    User.prototype.password = "";

    /**
     * User birthday.
     * @member {string} birthday
     * @memberof User
     * @instance
     */
    User.prototype.birthday = "";

    /**
     * User location.
     * @member {string} location
     * @memberof User
     * @instance
     */
    User.prototype.location = "";

    /**
     * User gender.
     * @member {string} gender
     * @memberof User
     * @instance
     */
    User.prototype.gender = "";

    /**
     * User token.
     * @member {string} token
     * @memberof User
     * @instance
     */
    User.prototype.token = "";

    /**
     * User expiresInMs.
     * @member {number|Long} expiresInMs
     * @memberof User
     * @instance
     */
    User.prototype.expiresInMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * User authMethod.
     * @member {User.AuthMethod} authMethod
     * @memberof User
     * @instance
     */
    User.prototype.authMethod = 0;

    /**
     * User replication.
     * @member {IReplicationEndpoint|null|undefined} replication
     * @memberof User
     * @instance
     */
    User.prototype.replication = null;

    /**
     * User replicationCouchbase.
     * @member {ICouchbaseReplicationEndpoint|null|undefined} replicationCouchbase
     * @memberof User
     * @instance
     */
    User.prototype.replicationCouchbase = null;

    /**
     * User billing.
     * @member {IBilling|null|undefined} billing
     * @memberof User
     * @instance
     */
    User.prototype.billing = null;

    /**
     * User configuration.
     * @member {IConfiguration|null|undefined} configuration
     * @memberof User
     * @instance
     */
    User.prototype.configuration = null;

    /**
     * User usedPlatforms.
     * @member {Array.<PlatformType>} usedPlatforms
     * @memberof User
     * @instance
     */
    User.prototype.usedPlatforms = $util.emptyArray;

    /**
     * User referralId.
     * @member {string} referralId
     * @memberof User
     * @instance
     */
    User.prototype.referralId = "";

    /**
     * User timezone.
     * @member {string} timezone
     * @memberof User
     * @instance
     */
    User.prototype.timezone = "";

    /**
     * User avatarUrl.
     * @member {string} avatarUrl
     * @memberof User
     * @instance
     */
    User.prototype.avatarUrl = "";

    /**
     * User userConfig.
     * @member {IUserConfig|null|undefined} userConfig
     * @memberof User
     * @instance
     */
    User.prototype.userConfig = null;

    /**
     * User features.
     * @member {Array.<IFeature>} features
     * @memberof User
     * @instance
     */
    User.prototype.features = $util.emptyArray;

    /**
     * User referralMedium.
     * @member {string} referralMedium
     * @memberof User
     * @instance
     */
    User.prototype.referralMedium = "";

    /**
     * User referralTerm.
     * @member {string} referralTerm
     * @memberof User
     * @instance
     */
    User.prototype.referralTerm = "";

    /**
     * User referralContent.
     * @member {string} referralContent
     * @memberof User
     * @instance
     */
    User.prototype.referralContent = "";

    /**
     * User abRatio.
     * @member {number} abRatio
     * @memberof User
     * @instance
     */
    User.prototype.abRatio = 0;

    /**
     * User sale.
     * @member {ISale|null|undefined} sale
     * @memberof User
     * @instance
     */
    User.prototype.sale = null;

    /**
     * User groups.
     * @member {Array.<IGroup>} groups
     * @memberof User
     * @instance
     */
    User.prototype.groups = $util.emptyArray;

    /**
     * User referrerRewarded.
     * @member {boolean} referrerRewarded
     * @memberof User
     * @instance
     */
    User.prototype.referrerRewarded = false;

    /**
     * User activePlan.
     * @member {IActivePlan|null|undefined} activePlan
     * @memberof User
     * @instance
     */
    User.prototype.activePlan = null;

    /**
     * User consents.
     * @member {IUserConsent|null|undefined} consents
     * @memberof User
     * @instance
     */
    User.prototype.consents = null;

    /**
     * User countryCode.
     * @member {string} countryCode
     * @memberof User
     * @instance
     */
    User.prototype.countryCode = "";

    /**
     * User companyInfo.
     * @member {Board.ICompanyInfo|null|undefined} companyInfo
     * @memberof User
     * @instance
     */
    User.prototype.companyInfo = null;

    /**
     * User referralSource.
     * @member {ReferralSource} referralSource
     * @memberof User
     * @instance
     */
    User.prototype.referralSource = 0;

    /**
     * User verifiedProfile.
     * @member {IVerifiedProfile|null|undefined} verifiedProfile
     * @memberof User
     * @instance
     */
    User.prototype.verifiedProfile = null;

    /**
     * User freePremium.
     * @member {IFreePremium|null|undefined} freePremium
     * @memberof User
     * @instance
     */
    User.prototype.freePremium = null;

    /**
     * User supportEmail.
     * @member {string} supportEmail
     * @memberof User
     * @instance
     */
    User.prototype.supportEmail = "";

    /**
     * Creates a new User instance using the specified properties.
     * @function create
     * @memberof User
     * @static
     * @param {IUser=} [properties] Properties to set
     * @returns {User} User instance
     */
    User.create = function create(properties) {
        return new User(properties);
    };

    /**
     * Encodes the specified User message. Does not implicitly {@link User.verify|verify} messages.
     * @function encode
     * @memberof User
     * @static
     * @param {IUser} message User message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    User.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.createdAt);
        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.updatedAt);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastName);
        if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.fullName);
        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.email);
        if (message.password != null && Object.hasOwnProperty.call(message, "password"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.password);
        if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.birthday);
        if (message.location != null && Object.hasOwnProperty.call(message, "location"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.location);
        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.gender);
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.token);
        if (message.expiresInMs != null && Object.hasOwnProperty.call(message, "expiresInMs"))
            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.expiresInMs);
        if (message.authMethod != null && Object.hasOwnProperty.call(message, "authMethod"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.authMethod);
        if (message.replication != null && Object.hasOwnProperty.call(message, "replication"))
            $root.ReplicationEndpoint.encode(message.replication, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.billing != null && Object.hasOwnProperty.call(message, "billing"))
            $root.Billing.encode(message.billing, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.configuration != null && Object.hasOwnProperty.call(message, "configuration"))
            $root.Configuration.encode(message.configuration, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.usedPlatforms != null && message.usedPlatforms.length)
            for (let i = 0; i < message.usedPlatforms.length; ++i)
                writer.uint32(/* id 19, wireType 0 =*/152).int32(message.usedPlatforms[i]);
        if (message.referralId != null && Object.hasOwnProperty.call(message, "referralId"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.referralId);
        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
            writer.uint32(/* id 21, wireType 2 =*/170).string(message.timezone);
        if (message.avatarUrl != null && Object.hasOwnProperty.call(message, "avatarUrl"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.avatarUrl);
        if (message.userConfig != null && Object.hasOwnProperty.call(message, "userConfig"))
            $root.UserConfig.encode(message.userConfig, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
        if (message.features != null && message.features.length)
            for (let i = 0; i < message.features.length; ++i)
                $root.Feature.encode(message.features[i], writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
        if (message.referralMedium != null && Object.hasOwnProperty.call(message, "referralMedium"))
            writer.uint32(/* id 26, wireType 2 =*/210).string(message.referralMedium);
        if (message.referralTerm != null && Object.hasOwnProperty.call(message, "referralTerm"))
            writer.uint32(/* id 27, wireType 2 =*/218).string(message.referralTerm);
        if (message.referralContent != null && Object.hasOwnProperty.call(message, "referralContent"))
            writer.uint32(/* id 28, wireType 2 =*/226).string(message.referralContent);
        if (message.abRatio != null && Object.hasOwnProperty.call(message, "abRatio"))
            writer.uint32(/* id 29, wireType 0 =*/232).int32(message.abRatio);
        if (message.sale != null && Object.hasOwnProperty.call(message, "sale"))
            $root.Sale.encode(message.sale, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
        if (message.groups != null && message.groups.length)
            for (let i = 0; i < message.groups.length; ++i)
                $root.Group.encode(message.groups[i], writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        if (message.referrerRewarded != null && Object.hasOwnProperty.call(message, "referrerRewarded"))
            writer.uint32(/* id 32, wireType 0 =*/256).bool(message.referrerRewarded);
        if (message.activePlan != null && Object.hasOwnProperty.call(message, "activePlan"))
            $root.ActivePlan.encode(message.activePlan, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
        if (message.consents != null && Object.hasOwnProperty.call(message, "consents"))
            $root.UserConsent.encode(message.consents, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
        if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
            writer.uint32(/* id 35, wireType 2 =*/282).string(message.countryCode);
        if (message.replicationCouchbase != null && Object.hasOwnProperty.call(message, "replicationCouchbase"))
            $root.CouchbaseReplicationEndpoint.encode(message.replicationCouchbase, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
        if (message.companyInfo != null && Object.hasOwnProperty.call(message, "companyInfo"))
            $root.Board.CompanyInfo.encode(message.companyInfo, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
        if (message.referralSource != null && Object.hasOwnProperty.call(message, "referralSource"))
            writer.uint32(/* id 38, wireType 0 =*/304).int32(message.referralSource);
        if (message.verifiedProfile != null && Object.hasOwnProperty.call(message, "verifiedProfile"))
            $root.VerifiedProfile.encode(message.verifiedProfile, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
        if (message.freePremium != null && Object.hasOwnProperty.call(message, "freePremium"))
            $root.FreePremium.encode(message.freePremium, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
        if (message.supportEmail != null && Object.hasOwnProperty.call(message, "supportEmail"))
            writer.uint32(/* id 41, wireType 2 =*/330).string(message.supportEmail);
        return writer;
    };

    /**
     * Encodes the specified User message, length delimited. Does not implicitly {@link User.verify|verify} messages.
     * @function encodeDelimited
     * @memberof User
     * @static
     * @param {IUser} message User message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    User.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a User message from the specified reader or buffer.
     * @function decode
     * @memberof User
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {User} User
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    User.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.User();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.userId = reader.string();
                break;
            case 2:
                message.createdAt = reader.int64();
                break;
            case 3:
                message.updatedAt = reader.int64();
                break;
            case 4:
                message.firstName = reader.string();
                break;
            case 5:
                message.lastName = reader.string();
                break;
            case 6:
                message.fullName = reader.string();
                break;
            case 7:
                message.email = reader.string();
                break;
            case 8:
                message.password = reader.string();
                break;
            case 9:
                message.birthday = reader.string();
                break;
            case 10:
                message.location = reader.string();
                break;
            case 11:
                message.gender = reader.string();
                break;
            case 12:
                message.token = reader.string();
                break;
            case 13:
                message.expiresInMs = reader.int64();
                break;
            case 14:
                message.authMethod = reader.int32();
                break;
            case 15:
                message.replication = $root.ReplicationEndpoint.decode(reader, reader.uint32());
                break;
            case 36:
                message.replicationCouchbase = $root.CouchbaseReplicationEndpoint.decode(reader, reader.uint32());
                break;
            case 17:
                message.billing = $root.Billing.decode(reader, reader.uint32());
                break;
            case 18:
                message.configuration = $root.Configuration.decode(reader, reader.uint32());
                break;
            case 19:
                if (!(message.usedPlatforms && message.usedPlatforms.length))
                    message.usedPlatforms = [];
                if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2)
                        message.usedPlatforms.push(reader.int32());
                } else
                    message.usedPlatforms.push(reader.int32());
                break;
            case 20:
                message.referralId = reader.string();
                break;
            case 21:
                message.timezone = reader.string();
                break;
            case 22:
                message.avatarUrl = reader.string();
                break;
            case 24:
                message.userConfig = $root.UserConfig.decode(reader, reader.uint32());
                break;
            case 25:
                if (!(message.features && message.features.length))
                    message.features = [];
                message.features.push($root.Feature.decode(reader, reader.uint32()));
                break;
            case 26:
                message.referralMedium = reader.string();
                break;
            case 27:
                message.referralTerm = reader.string();
                break;
            case 28:
                message.referralContent = reader.string();
                break;
            case 29:
                message.abRatio = reader.int32();
                break;
            case 30:
                message.sale = $root.Sale.decode(reader, reader.uint32());
                break;
            case 31:
                if (!(message.groups && message.groups.length))
                    message.groups = [];
                message.groups.push($root.Group.decode(reader, reader.uint32()));
                break;
            case 32:
                message.referrerRewarded = reader.bool();
                break;
            case 33:
                message.activePlan = $root.ActivePlan.decode(reader, reader.uint32());
                break;
            case 34:
                message.consents = $root.UserConsent.decode(reader, reader.uint32());
                break;
            case 35:
                message.countryCode = reader.string();
                break;
            case 37:
                message.companyInfo = $root.Board.CompanyInfo.decode(reader, reader.uint32());
                break;
            case 38:
                message.referralSource = reader.int32();
                break;
            case 39:
                message.verifiedProfile = $root.VerifiedProfile.decode(reader, reader.uint32());
                break;
            case 40:
                message.freePremium = $root.FreePremium.decode(reader, reader.uint32());
                break;
            case 41:
                message.supportEmail = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("userId"))
            throw $util.ProtocolError("missing required 'userId'", { instance: message });
        if (!message.hasOwnProperty("createdAt"))
            throw $util.ProtocolError("missing required 'createdAt'", { instance: message });
        if (!message.hasOwnProperty("updatedAt"))
            throw $util.ProtocolError("missing required 'updatedAt'", { instance: message });
        return message;
    };

    /**
     * Decodes a User message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof User
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {User} User
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    User.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a User message.
     * @function verify
     * @memberof User
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    User.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.userId))
            return "userId: string expected";
        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
            return "createdAt: integer|Long expected";
        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
            return "updatedAt: integer|Long expected";
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            if (!$util.isString(message.firstName))
                return "firstName: string expected";
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            if (!$util.isString(message.lastName))
                return "lastName: string expected";
        if (message.fullName != null && message.hasOwnProperty("fullName"))
            if (!$util.isString(message.fullName))
                return "fullName: string expected";
        if (message.email != null && message.hasOwnProperty("email"))
            if (!$util.isString(message.email))
                return "email: string expected";
        if (message.password != null && message.hasOwnProperty("password"))
            if (!$util.isString(message.password))
                return "password: string expected";
        if (message.birthday != null && message.hasOwnProperty("birthday"))
            if (!$util.isString(message.birthday))
                return "birthday: string expected";
        if (message.location != null && message.hasOwnProperty("location"))
            if (!$util.isString(message.location))
                return "location: string expected";
        if (message.gender != null && message.hasOwnProperty("gender"))
            if (!$util.isString(message.gender))
                return "gender: string expected";
        if (message.token != null && message.hasOwnProperty("token"))
            if (!$util.isString(message.token))
                return "token: string expected";
        if (message.expiresInMs != null && message.hasOwnProperty("expiresInMs"))
            if (!$util.isInteger(message.expiresInMs) && !(message.expiresInMs && $util.isInteger(message.expiresInMs.low) && $util.isInteger(message.expiresInMs.high)))
                return "expiresInMs: integer|Long expected";
        if (message.authMethod != null && message.hasOwnProperty("authMethod"))
            switch (message.authMethod) {
            default:
                return "authMethod: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.replication != null && message.hasOwnProperty("replication")) {
            let error = $root.ReplicationEndpoint.verify(message.replication);
            if (error)
                return "replication." + error;
        }
        if (message.replicationCouchbase != null && message.hasOwnProperty("replicationCouchbase")) {
            let error = $root.CouchbaseReplicationEndpoint.verify(message.replicationCouchbase);
            if (error)
                return "replicationCouchbase." + error;
        }
        if (message.billing != null && message.hasOwnProperty("billing")) {
            let error = $root.Billing.verify(message.billing);
            if (error)
                return "billing." + error;
        }
        if (message.configuration != null && message.hasOwnProperty("configuration")) {
            let error = $root.Configuration.verify(message.configuration);
            if (error)
                return "configuration." + error;
        }
        if (message.usedPlatforms != null && message.hasOwnProperty("usedPlatforms")) {
            if (!Array.isArray(message.usedPlatforms))
                return "usedPlatforms: array expected";
            for (let i = 0; i < message.usedPlatforms.length; ++i)
                switch (message.usedPlatforms[i]) {
                default:
                    return "usedPlatforms: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
        }
        if (message.referralId != null && message.hasOwnProperty("referralId"))
            if (!$util.isString(message.referralId))
                return "referralId: string expected";
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            if (!$util.isString(message.timezone))
                return "timezone: string expected";
        if (message.avatarUrl != null && message.hasOwnProperty("avatarUrl"))
            if (!$util.isString(message.avatarUrl))
                return "avatarUrl: string expected";
        if (message.userConfig != null && message.hasOwnProperty("userConfig")) {
            let error = $root.UserConfig.verify(message.userConfig);
            if (error)
                return "userConfig." + error;
        }
        if (message.features != null && message.hasOwnProperty("features")) {
            if (!Array.isArray(message.features))
                return "features: array expected";
            for (let i = 0; i < message.features.length; ++i) {
                let error = $root.Feature.verify(message.features[i]);
                if (error)
                    return "features." + error;
            }
        }
        if (message.referralMedium != null && message.hasOwnProperty("referralMedium"))
            if (!$util.isString(message.referralMedium))
                return "referralMedium: string expected";
        if (message.referralTerm != null && message.hasOwnProperty("referralTerm"))
            if (!$util.isString(message.referralTerm))
                return "referralTerm: string expected";
        if (message.referralContent != null && message.hasOwnProperty("referralContent"))
            if (!$util.isString(message.referralContent))
                return "referralContent: string expected";
        if (message.abRatio != null && message.hasOwnProperty("abRatio"))
            if (!$util.isInteger(message.abRatio))
                return "abRatio: integer expected";
        if (message.sale != null && message.hasOwnProperty("sale")) {
            let error = $root.Sale.verify(message.sale);
            if (error)
                return "sale." + error;
        }
        if (message.groups != null && message.hasOwnProperty("groups")) {
            if (!Array.isArray(message.groups))
                return "groups: array expected";
            for (let i = 0; i < message.groups.length; ++i) {
                let error = $root.Group.verify(message.groups[i]);
                if (error)
                    return "groups." + error;
            }
        }
        if (message.referrerRewarded != null && message.hasOwnProperty("referrerRewarded"))
            if (typeof message.referrerRewarded !== "boolean")
                return "referrerRewarded: boolean expected";
        if (message.activePlan != null && message.hasOwnProperty("activePlan")) {
            let error = $root.ActivePlan.verify(message.activePlan);
            if (error)
                return "activePlan." + error;
        }
        if (message.consents != null && message.hasOwnProperty("consents")) {
            let error = $root.UserConsent.verify(message.consents);
            if (error)
                return "consents." + error;
        }
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            if (!$util.isString(message.countryCode))
                return "countryCode: string expected";
        if (message.companyInfo != null && message.hasOwnProperty("companyInfo")) {
            let error = $root.Board.CompanyInfo.verify(message.companyInfo);
            if (error)
                return "companyInfo." + error;
        }
        if (message.referralSource != null && message.hasOwnProperty("referralSource"))
            switch (message.referralSource) {
            default:
                return "referralSource: enum value expected";
            case 0:
            case 1:
                break;
            }
        if (message.verifiedProfile != null && message.hasOwnProperty("verifiedProfile")) {
            let error = $root.VerifiedProfile.verify(message.verifiedProfile);
            if (error)
                return "verifiedProfile." + error;
        }
        if (message.freePremium != null && message.hasOwnProperty("freePremium")) {
            let error = $root.FreePremium.verify(message.freePremium);
            if (error)
                return "freePremium." + error;
        }
        if (message.supportEmail != null && message.hasOwnProperty("supportEmail"))
            if (!$util.isString(message.supportEmail))
                return "supportEmail: string expected";
        return null;
    };

    /**
     * Creates a User message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof User
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {User} User
     */
    User.fromObject = function fromObject(object) {
        if (object instanceof $root.User)
            return object;
        let message = new $root.User();
        if (object.userId != null)
            message.userId = String(object.userId);
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.updatedAt != null)
            if ($util.Long)
                (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
            else if (typeof object.updatedAt === "string")
                message.updatedAt = parseInt(object.updatedAt, 10);
            else if (typeof object.updatedAt === "number")
                message.updatedAt = object.updatedAt;
            else if (typeof object.updatedAt === "object")
                message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.fullName != null)
            message.fullName = String(object.fullName);
        if (object.email != null)
            message.email = String(object.email);
        if (object.password != null)
            message.password = String(object.password);
        if (object.birthday != null)
            message.birthday = String(object.birthday);
        if (object.location != null)
            message.location = String(object.location);
        if (object.gender != null)
            message.gender = String(object.gender);
        if (object.token != null)
            message.token = String(object.token);
        if (object.expiresInMs != null)
            if ($util.Long)
                (message.expiresInMs = $util.Long.fromValue(object.expiresInMs)).unsigned = false;
            else if (typeof object.expiresInMs === "string")
                message.expiresInMs = parseInt(object.expiresInMs, 10);
            else if (typeof object.expiresInMs === "number")
                message.expiresInMs = object.expiresInMs;
            else if (typeof object.expiresInMs === "object")
                message.expiresInMs = new $util.LongBits(object.expiresInMs.low >>> 0, object.expiresInMs.high >>> 0).toNumber();
        switch (object.authMethod) {
        case "USERPASS":
        case 0:
            message.authMethod = 0;
            break;
        case "FACEBOOK":
        case 1:
            message.authMethod = 1;
            break;
        case "GPLUS":
        case 2:
            message.authMethod = 2;
            break;
        }
        if (object.replication != null) {
            if (typeof object.replication !== "object")
                throw TypeError(".User.replication: object expected");
            message.replication = $root.ReplicationEndpoint.fromObject(object.replication);
        }
        if (object.replicationCouchbase != null) {
            if (typeof object.replicationCouchbase !== "object")
                throw TypeError(".User.replicationCouchbase: object expected");
            message.replicationCouchbase = $root.CouchbaseReplicationEndpoint.fromObject(object.replicationCouchbase);
        }
        if (object.billing != null) {
            if (typeof object.billing !== "object")
                throw TypeError(".User.billing: object expected");
            message.billing = $root.Billing.fromObject(object.billing);
        }
        if (object.configuration != null) {
            if (typeof object.configuration !== "object")
                throw TypeError(".User.configuration: object expected");
            message.configuration = $root.Configuration.fromObject(object.configuration);
        }
        if (object.usedPlatforms) {
            if (!Array.isArray(object.usedPlatforms))
                throw TypeError(".User.usedPlatforms: array expected");
            message.usedPlatforms = [];
            for (let i = 0; i < object.usedPlatforms.length; ++i)
                switch (object.usedPlatforms[i]) {
                default:
                case "WEB":
                case 0:
                    message.usedPlatforms[i] = 0;
                    break;
                case "ANDROID":
                case 1:
                    message.usedPlatforms[i] = 1;
                    break;
                case "IOS":
                case 2:
                    message.usedPlatforms[i] = 2;
                    break;
                case "WP":
                case 3:
                    message.usedPlatforms[i] = 3;
                    break;
                }
        }
        if (object.referralId != null)
            message.referralId = String(object.referralId);
        if (object.timezone != null)
            message.timezone = String(object.timezone);
        if (object.avatarUrl != null)
            message.avatarUrl = String(object.avatarUrl);
        if (object.userConfig != null) {
            if (typeof object.userConfig !== "object")
                throw TypeError(".User.userConfig: object expected");
            message.userConfig = $root.UserConfig.fromObject(object.userConfig);
        }
        if (object.features) {
            if (!Array.isArray(object.features))
                throw TypeError(".User.features: array expected");
            message.features = [];
            for (let i = 0; i < object.features.length; ++i) {
                if (typeof object.features[i] !== "object")
                    throw TypeError(".User.features: object expected");
                message.features[i] = $root.Feature.fromObject(object.features[i]);
            }
        }
        if (object.referralMedium != null)
            message.referralMedium = String(object.referralMedium);
        if (object.referralTerm != null)
            message.referralTerm = String(object.referralTerm);
        if (object.referralContent != null)
            message.referralContent = String(object.referralContent);
        if (object.abRatio != null)
            message.abRatio = object.abRatio | 0;
        if (object.sale != null) {
            if (typeof object.sale !== "object")
                throw TypeError(".User.sale: object expected");
            message.sale = $root.Sale.fromObject(object.sale);
        }
        if (object.groups) {
            if (!Array.isArray(object.groups))
                throw TypeError(".User.groups: array expected");
            message.groups = [];
            for (let i = 0; i < object.groups.length; ++i) {
                if (typeof object.groups[i] !== "object")
                    throw TypeError(".User.groups: object expected");
                message.groups[i] = $root.Group.fromObject(object.groups[i]);
            }
        }
        if (object.referrerRewarded != null)
            message.referrerRewarded = Boolean(object.referrerRewarded);
        if (object.activePlan != null) {
            if (typeof object.activePlan !== "object")
                throw TypeError(".User.activePlan: object expected");
            message.activePlan = $root.ActivePlan.fromObject(object.activePlan);
        }
        if (object.consents != null) {
            if (typeof object.consents !== "object")
                throw TypeError(".User.consents: object expected");
            message.consents = $root.UserConsent.fromObject(object.consents);
        }
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        if (object.companyInfo != null) {
            if (typeof object.companyInfo !== "object")
                throw TypeError(".User.companyInfo: object expected");
            message.companyInfo = $root.Board.CompanyInfo.fromObject(object.companyInfo);
        }
        switch (object.referralSource) {
        case "KB":
        case 0:
            message.referralSource = 0;
            break;
        case "MALL_PAY":
        case 1:
            message.referralSource = 1;
            break;
        }
        if (object.verifiedProfile != null) {
            if (typeof object.verifiedProfile !== "object")
                throw TypeError(".User.verifiedProfile: object expected");
            message.verifiedProfile = $root.VerifiedProfile.fromObject(object.verifiedProfile);
        }
        if (object.freePremium != null) {
            if (typeof object.freePremium !== "object")
                throw TypeError(".User.freePremium: object expected");
            message.freePremium = $root.FreePremium.fromObject(object.freePremium);
        }
        if (object.supportEmail != null)
            message.supportEmail = String(object.supportEmail);
        return message;
    };

    /**
     * Creates a plain object from a User message. Also converts values to other types if specified.
     * @function toObject
     * @memberof User
     * @static
     * @param {User} message User
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    User.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.usedPlatforms = [];
            object.features = [];
            object.groups = [];
        }
        if (options.defaults) {
            object.userId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.updatedAt = options.longs === String ? "0" : 0;
            object.firstName = "";
            object.lastName = "";
            object.fullName = "";
            object.email = "";
            object.password = "";
            object.birthday = "";
            object.location = "";
            object.gender = "";
            object.token = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.expiresInMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.expiresInMs = options.longs === String ? "0" : 0;
            object.authMethod = options.enums === String ? "USERPASS" : 0;
            object.replication = null;
            object.billing = null;
            object.configuration = null;
            object.referralId = "";
            object.timezone = "";
            object.avatarUrl = "";
            object.userConfig = null;
            object.referralMedium = "";
            object.referralTerm = "";
            object.referralContent = "";
            object.abRatio = 0;
            object.sale = null;
            object.referrerRewarded = false;
            object.activePlan = null;
            object.consents = null;
            object.countryCode = "";
            object.replicationCouchbase = null;
            object.companyInfo = null;
            object.referralSource = options.enums === String ? "KB" : 0;
            object.verifiedProfile = null;
            object.freePremium = null;
            object.supportEmail = "";
        }
        if (message.userId != null && message.hasOwnProperty("userId"))
            object.userId = message.userId;
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
            if (typeof message.updatedAt === "number")
                object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
            else
                object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.fullName != null && message.hasOwnProperty("fullName"))
            object.fullName = message.fullName;
        if (message.email != null && message.hasOwnProperty("email"))
            object.email = message.email;
        if (message.password != null && message.hasOwnProperty("password"))
            object.password = message.password;
        if (message.birthday != null && message.hasOwnProperty("birthday"))
            object.birthday = message.birthday;
        if (message.location != null && message.hasOwnProperty("location"))
            object.location = message.location;
        if (message.gender != null && message.hasOwnProperty("gender"))
            object.gender = message.gender;
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.expiresInMs != null && message.hasOwnProperty("expiresInMs"))
            if (typeof message.expiresInMs === "number")
                object.expiresInMs = options.longs === String ? String(message.expiresInMs) : message.expiresInMs;
            else
                object.expiresInMs = options.longs === String ? $util.Long.prototype.toString.call(message.expiresInMs) : options.longs === Number ? new $util.LongBits(message.expiresInMs.low >>> 0, message.expiresInMs.high >>> 0).toNumber() : message.expiresInMs;
        if (message.authMethod != null && message.hasOwnProperty("authMethod"))
            object.authMethod = options.enums === String ? $root.User.AuthMethod[message.authMethod] : message.authMethod;
        if (message.replication != null && message.hasOwnProperty("replication"))
            object.replication = $root.ReplicationEndpoint.toObject(message.replication, options);
        if (message.billing != null && message.hasOwnProperty("billing"))
            object.billing = $root.Billing.toObject(message.billing, options);
        if (message.configuration != null && message.hasOwnProperty("configuration"))
            object.configuration = $root.Configuration.toObject(message.configuration, options);
        if (message.usedPlatforms && message.usedPlatforms.length) {
            object.usedPlatforms = [];
            for (let j = 0; j < message.usedPlatforms.length; ++j)
                object.usedPlatforms[j] = options.enums === String ? $root.PlatformType[message.usedPlatforms[j]] : message.usedPlatforms[j];
        }
        if (message.referralId != null && message.hasOwnProperty("referralId"))
            object.referralId = message.referralId;
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            object.timezone = message.timezone;
        if (message.avatarUrl != null && message.hasOwnProperty("avatarUrl"))
            object.avatarUrl = message.avatarUrl;
        if (message.userConfig != null && message.hasOwnProperty("userConfig"))
            object.userConfig = $root.UserConfig.toObject(message.userConfig, options);
        if (message.features && message.features.length) {
            object.features = [];
            for (let j = 0; j < message.features.length; ++j)
                object.features[j] = $root.Feature.toObject(message.features[j], options);
        }
        if (message.referralMedium != null && message.hasOwnProperty("referralMedium"))
            object.referralMedium = message.referralMedium;
        if (message.referralTerm != null && message.hasOwnProperty("referralTerm"))
            object.referralTerm = message.referralTerm;
        if (message.referralContent != null && message.hasOwnProperty("referralContent"))
            object.referralContent = message.referralContent;
        if (message.abRatio != null && message.hasOwnProperty("abRatio"))
            object.abRatio = message.abRatio;
        if (message.sale != null && message.hasOwnProperty("sale"))
            object.sale = $root.Sale.toObject(message.sale, options);
        if (message.groups && message.groups.length) {
            object.groups = [];
            for (let j = 0; j < message.groups.length; ++j)
                object.groups[j] = $root.Group.toObject(message.groups[j], options);
        }
        if (message.referrerRewarded != null && message.hasOwnProperty("referrerRewarded"))
            object.referrerRewarded = message.referrerRewarded;
        if (message.activePlan != null && message.hasOwnProperty("activePlan"))
            object.activePlan = $root.ActivePlan.toObject(message.activePlan, options);
        if (message.consents != null && message.hasOwnProperty("consents"))
            object.consents = $root.UserConsent.toObject(message.consents, options);
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        if (message.replicationCouchbase != null && message.hasOwnProperty("replicationCouchbase"))
            object.replicationCouchbase = $root.CouchbaseReplicationEndpoint.toObject(message.replicationCouchbase, options);
        if (message.companyInfo != null && message.hasOwnProperty("companyInfo"))
            object.companyInfo = $root.Board.CompanyInfo.toObject(message.companyInfo, options);
        if (message.referralSource != null && message.hasOwnProperty("referralSource"))
            object.referralSource = options.enums === String ? $root.ReferralSource[message.referralSource] : message.referralSource;
        if (message.verifiedProfile != null && message.hasOwnProperty("verifiedProfile"))
            object.verifiedProfile = $root.VerifiedProfile.toObject(message.verifiedProfile, options);
        if (message.freePremium != null && message.hasOwnProperty("freePremium"))
            object.freePremium = $root.FreePremium.toObject(message.freePremium, options);
        if (message.supportEmail != null && message.hasOwnProperty("supportEmail"))
            object.supportEmail = message.supportEmail;
        return object;
    };

    /**
     * Converts this User to JSON.
     * @function toJSON
     * @memberof User
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    User.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * AuthMethod enum.
     * @name User.AuthMethod
     * @enum {number}
     * @property {number} USERPASS=0 USERPASS value
     * @property {number} FACEBOOK=1 FACEBOOK value
     * @property {number} GPLUS=2 GPLUS value
     */
    User.AuthMethod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USERPASS"] = 0;
        values[valuesById[1] = "FACEBOOK"] = 1;
        values[valuesById[2] = "GPLUS"] = 2;
        return values;
    })();

    return User;
})();

/**
 * ReferralSource enum.
 * @exports ReferralSource
 * @enum {number}
 * @property {number} KB=0 KB value
 * @property {number} MALL_PAY=1 MALL_PAY value
 */
export const ReferralSource = $root.ReferralSource = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "KB"] = 0;
    values[valuesById[1] = "MALL_PAY"] = 1;
    return values;
})();

export const Installation = $root.Installation = (() => {

    /**
     * Properties of an Installation.
     * @exports IInstallation
     * @interface IInstallation
     * @property {string} installationId Installation installationId
     * @property {number|Long} createdAt Installation createdAt
     * @property {number|Long} updatedAt Installation updatedAt
     * @property {PlatformType} platform Installation platform
     * @property {string|null} [token] Installation token
     * @property {string|null} [userId] Installation userId
     * @property {Array.<string>|null} [channels] Installation channels
     * @property {string|null} [timezone] Installation timezone
     * @property {string|null} [deviceType] Installation deviceType
     * @property {string|null} [deviceOsVersion] Installation deviceOsVersion
     * @property {number|null} [appVersionCode] Installation appVersionCode
     * @property {string|null} [appVersionName] Installation appVersionName
     * @property {string|null} [locale] Installation locale
     */

    /**
     * Constructs a new Installation.
     * @exports Installation
     * @classdesc Represents an Installation.
     * @implements IInstallation
     * @constructor
     * @param {IInstallation=} [properties] Properties to set
     */
    function Installation(properties) {
        this.channels = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Installation installationId.
     * @member {string} installationId
     * @memberof Installation
     * @instance
     */
    Installation.prototype.installationId = "";

    /**
     * Installation createdAt.
     * @member {number|Long} createdAt
     * @memberof Installation
     * @instance
     */
    Installation.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Installation updatedAt.
     * @member {number|Long} updatedAt
     * @memberof Installation
     * @instance
     */
    Installation.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Installation platform.
     * @member {PlatformType} platform
     * @memberof Installation
     * @instance
     */
    Installation.prototype.platform = 0;

    /**
     * Installation token.
     * @member {string} token
     * @memberof Installation
     * @instance
     */
    Installation.prototype.token = "";

    /**
     * Installation userId.
     * @member {string} userId
     * @memberof Installation
     * @instance
     */
    Installation.prototype.userId = "";

    /**
     * Installation channels.
     * @member {Array.<string>} channels
     * @memberof Installation
     * @instance
     */
    Installation.prototype.channels = $util.emptyArray;

    /**
     * Installation timezone.
     * @member {string} timezone
     * @memberof Installation
     * @instance
     */
    Installation.prototype.timezone = "";

    /**
     * Installation deviceType.
     * @member {string} deviceType
     * @memberof Installation
     * @instance
     */
    Installation.prototype.deviceType = "";

    /**
     * Installation deviceOsVersion.
     * @member {string} deviceOsVersion
     * @memberof Installation
     * @instance
     */
    Installation.prototype.deviceOsVersion = "";

    /**
     * Installation appVersionCode.
     * @member {number} appVersionCode
     * @memberof Installation
     * @instance
     */
    Installation.prototype.appVersionCode = 0;

    /**
     * Installation appVersionName.
     * @member {string} appVersionName
     * @memberof Installation
     * @instance
     */
    Installation.prototype.appVersionName = "";

    /**
     * Installation locale.
     * @member {string} locale
     * @memberof Installation
     * @instance
     */
    Installation.prototype.locale = "";

    /**
     * Creates a new Installation instance using the specified properties.
     * @function create
     * @memberof Installation
     * @static
     * @param {IInstallation=} [properties] Properties to set
     * @returns {Installation} Installation instance
     */
    Installation.create = function create(properties) {
        return new Installation(properties);
    };

    /**
     * Encodes the specified Installation message. Does not implicitly {@link Installation.verify|verify} messages.
     * @function encode
     * @memberof Installation
     * @static
     * @param {IInstallation} message Installation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Installation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.installationId);
        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.createdAt);
        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.updatedAt);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.platform);
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.token);
        if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.userId);
        if (message.channels != null && message.channels.length)
            for (let i = 0; i < message.channels.length; ++i)
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.channels[i]);
        if (message.timezone != null && Object.hasOwnProperty.call(message, "timezone"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.timezone);
        if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.deviceType);
        if (message.deviceOsVersion != null && Object.hasOwnProperty.call(message, "deviceOsVersion"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.deviceOsVersion);
        if (message.appVersionCode != null && Object.hasOwnProperty.call(message, "appVersionCode"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.appVersionCode);
        if (message.appVersionName != null && Object.hasOwnProperty.call(message, "appVersionName"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.appVersionName);
        if (message.locale != null && Object.hasOwnProperty.call(message, "locale"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.locale);
        return writer;
    };

    /**
     * Encodes the specified Installation message, length delimited. Does not implicitly {@link Installation.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Installation
     * @static
     * @param {IInstallation} message Installation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Installation.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Installation message from the specified reader or buffer.
     * @function decode
     * @memberof Installation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Installation} Installation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Installation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Installation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.installationId = reader.string();
                break;
            case 2:
                message.createdAt = reader.int64();
                break;
            case 3:
                message.updatedAt = reader.int64();
                break;
            case 4:
                message.platform = reader.int32();
                break;
            case 5:
                message.token = reader.string();
                break;
            case 6:
                message.userId = reader.string();
                break;
            case 7:
                if (!(message.channels && message.channels.length))
                    message.channels = [];
                message.channels.push(reader.string());
                break;
            case 8:
                message.timezone = reader.string();
                break;
            case 9:
                message.deviceType = reader.string();
                break;
            case 10:
                message.deviceOsVersion = reader.string();
                break;
            case 11:
                message.appVersionCode = reader.int32();
                break;
            case 12:
                message.appVersionName = reader.string();
                break;
            case 13:
                message.locale = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("installationId"))
            throw $util.ProtocolError("missing required 'installationId'", { instance: message });
        if (!message.hasOwnProperty("createdAt"))
            throw $util.ProtocolError("missing required 'createdAt'", { instance: message });
        if (!message.hasOwnProperty("updatedAt"))
            throw $util.ProtocolError("missing required 'updatedAt'", { instance: message });
        if (!message.hasOwnProperty("platform"))
            throw $util.ProtocolError("missing required 'platform'", { instance: message });
        return message;
    };

    /**
     * Decodes an Installation message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Installation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Installation} Installation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Installation.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Installation message.
     * @function verify
     * @memberof Installation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Installation.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.installationId))
            return "installationId: string expected";
        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
            return "createdAt: integer|Long expected";
        if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
            return "updatedAt: integer|Long expected";
        switch (message.platform) {
        default:
            return "platform: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
            break;
        }
        if (message.token != null && message.hasOwnProperty("token"))
            if (!$util.isString(message.token))
                return "token: string expected";
        if (message.userId != null && message.hasOwnProperty("userId"))
            if (!$util.isString(message.userId))
                return "userId: string expected";
        if (message.channels != null && message.hasOwnProperty("channels")) {
            if (!Array.isArray(message.channels))
                return "channels: array expected";
            for (let i = 0; i < message.channels.length; ++i)
                if (!$util.isString(message.channels[i]))
                    return "channels: string[] expected";
        }
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            if (!$util.isString(message.timezone))
                return "timezone: string expected";
        if (message.deviceType != null && message.hasOwnProperty("deviceType"))
            if (!$util.isString(message.deviceType))
                return "deviceType: string expected";
        if (message.deviceOsVersion != null && message.hasOwnProperty("deviceOsVersion"))
            if (!$util.isString(message.deviceOsVersion))
                return "deviceOsVersion: string expected";
        if (message.appVersionCode != null && message.hasOwnProperty("appVersionCode"))
            if (!$util.isInteger(message.appVersionCode))
                return "appVersionCode: integer expected";
        if (message.appVersionName != null && message.hasOwnProperty("appVersionName"))
            if (!$util.isString(message.appVersionName))
                return "appVersionName: string expected";
        if (message.locale != null && message.hasOwnProperty("locale"))
            if (!$util.isString(message.locale))
                return "locale: string expected";
        return null;
    };

    /**
     * Creates an Installation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Installation
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Installation} Installation
     */
    Installation.fromObject = function fromObject(object) {
        if (object instanceof $root.Installation)
            return object;
        let message = new $root.Installation();
        if (object.installationId != null)
            message.installationId = String(object.installationId);
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.updatedAt != null)
            if ($util.Long)
                (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
            else if (typeof object.updatedAt === "string")
                message.updatedAt = parseInt(object.updatedAt, 10);
            else if (typeof object.updatedAt === "number")
                message.updatedAt = object.updatedAt;
            else if (typeof object.updatedAt === "object")
                message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
        switch (object.platform) {
        case "WEB":
        case 0:
            message.platform = 0;
            break;
        case "ANDROID":
        case 1:
            message.platform = 1;
            break;
        case "IOS":
        case 2:
            message.platform = 2;
            break;
        case "WP":
        case 3:
            message.platform = 3;
            break;
        }
        if (object.token != null)
            message.token = String(object.token);
        if (object.userId != null)
            message.userId = String(object.userId);
        if (object.channels) {
            if (!Array.isArray(object.channels))
                throw TypeError(".Installation.channels: array expected");
            message.channels = [];
            for (let i = 0; i < object.channels.length; ++i)
                message.channels[i] = String(object.channels[i]);
        }
        if (object.timezone != null)
            message.timezone = String(object.timezone);
        if (object.deviceType != null)
            message.deviceType = String(object.deviceType);
        if (object.deviceOsVersion != null)
            message.deviceOsVersion = String(object.deviceOsVersion);
        if (object.appVersionCode != null)
            message.appVersionCode = object.appVersionCode | 0;
        if (object.appVersionName != null)
            message.appVersionName = String(object.appVersionName);
        if (object.locale != null)
            message.locale = String(object.locale);
        return message;
    };

    /**
     * Creates a plain object from an Installation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Installation
     * @static
     * @param {Installation} message Installation
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Installation.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.channels = [];
        if (options.defaults) {
            object.installationId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.updatedAt = options.longs === String ? "0" : 0;
            object.platform = options.enums === String ? "WEB" : 0;
            object.token = "";
            object.userId = "";
            object.timezone = "";
            object.deviceType = "";
            object.deviceOsVersion = "";
            object.appVersionCode = 0;
            object.appVersionName = "";
            object.locale = "";
        }
        if (message.installationId != null && message.hasOwnProperty("installationId"))
            object.installationId = message.installationId;
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
            if (typeof message.updatedAt === "number")
                object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
            else
                object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
        if (message.platform != null && message.hasOwnProperty("platform"))
            object.platform = options.enums === String ? $root.PlatformType[message.platform] : message.platform;
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.userId != null && message.hasOwnProperty("userId"))
            object.userId = message.userId;
        if (message.channels && message.channels.length) {
            object.channels = [];
            for (let j = 0; j < message.channels.length; ++j)
                object.channels[j] = message.channels[j];
        }
        if (message.timezone != null && message.hasOwnProperty("timezone"))
            object.timezone = message.timezone;
        if (message.deviceType != null && message.hasOwnProperty("deviceType"))
            object.deviceType = message.deviceType;
        if (message.deviceOsVersion != null && message.hasOwnProperty("deviceOsVersion"))
            object.deviceOsVersion = message.deviceOsVersion;
        if (message.appVersionCode != null && message.hasOwnProperty("appVersionCode"))
            object.appVersionCode = message.appVersionCode;
        if (message.appVersionName != null && message.hasOwnProperty("appVersionName"))
            object.appVersionName = message.appVersionName;
        if (message.locale != null && message.hasOwnProperty("locale"))
            object.locale = message.locale;
        return object;
    };

    /**
     * Converts this Installation to JSON.
     * @function toJSON
     * @memberof Installation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Installation.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Installation;
})();

export const CreateGroupRequest = $root.CreateGroupRequest = (() => {

    /**
     * Properties of a CreateGroupRequest.
     * @exports ICreateGroupRequest
     * @interface ICreateGroupRequest
     * @property {string|null} [ownerId] CreateGroupRequest ownerId
     * @property {string} name CreateGroupRequest name
     * @property {Array.<ICreateGroupRequestMember>|null} [members] CreateGroupRequest members
     */

    /**
     * Constructs a new CreateGroupRequest.
     * @exports CreateGroupRequest
     * @classdesc Represents a CreateGroupRequest.
     * @implements ICreateGroupRequest
     * @constructor
     * @param {ICreateGroupRequest=} [properties] Properties to set
     */
    function CreateGroupRequest(properties) {
        this.members = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateGroupRequest ownerId.
     * @member {string} ownerId
     * @memberof CreateGroupRequest
     * @instance
     */
    CreateGroupRequest.prototype.ownerId = "";

    /**
     * CreateGroupRequest name.
     * @member {string} name
     * @memberof CreateGroupRequest
     * @instance
     */
    CreateGroupRequest.prototype.name = "";

    /**
     * CreateGroupRequest members.
     * @member {Array.<ICreateGroupRequestMember>} members
     * @memberof CreateGroupRequest
     * @instance
     */
    CreateGroupRequest.prototype.members = $util.emptyArray;

    /**
     * Creates a new CreateGroupRequest instance using the specified properties.
     * @function create
     * @memberof CreateGroupRequest
     * @static
     * @param {ICreateGroupRequest=} [properties] Properties to set
     * @returns {CreateGroupRequest} CreateGroupRequest instance
     */
    CreateGroupRequest.create = function create(properties) {
        return new CreateGroupRequest(properties);
    };

    /**
     * Encodes the specified CreateGroupRequest message. Does not implicitly {@link CreateGroupRequest.verify|verify} messages.
     * @function encode
     * @memberof CreateGroupRequest
     * @static
     * @param {ICreateGroupRequest} message CreateGroupRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateGroupRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ownerId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.members != null && message.members.length)
            for (let i = 0; i < message.members.length; ++i)
                $root.CreateGroupRequestMember.encode(message.members[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateGroupRequest message, length delimited. Does not implicitly {@link CreateGroupRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateGroupRequest
     * @static
     * @param {ICreateGroupRequest} message CreateGroupRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateGroupRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateGroupRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CreateGroupRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateGroupRequest} CreateGroupRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateGroupRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateGroupRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.ownerId = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                if (!(message.members && message.members.length))
                    message.members = [];
                message.members.push($root.CreateGroupRequestMember.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        return message;
    };

    /**
     * Decodes a CreateGroupRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateGroupRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateGroupRequest} CreateGroupRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateGroupRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateGroupRequest message.
     * @function verify
     * @memberof CreateGroupRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateGroupRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
            if (!$util.isString(message.ownerId))
                return "ownerId: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (message.members != null && message.hasOwnProperty("members")) {
            if (!Array.isArray(message.members))
                return "members: array expected";
            for (let i = 0; i < message.members.length; ++i) {
                let error = $root.CreateGroupRequestMember.verify(message.members[i]);
                if (error)
                    return "members." + error;
            }
        }
        return null;
    };

    /**
     * Creates a CreateGroupRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateGroupRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateGroupRequest} CreateGroupRequest
     */
    CreateGroupRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateGroupRequest)
            return object;
        let message = new $root.CreateGroupRequest();
        if (object.ownerId != null)
            message.ownerId = String(object.ownerId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.members) {
            if (!Array.isArray(object.members))
                throw TypeError(".CreateGroupRequest.members: array expected");
            message.members = [];
            for (let i = 0; i < object.members.length; ++i) {
                if (typeof object.members[i] !== "object")
                    throw TypeError(".CreateGroupRequest.members: object expected");
                message.members[i] = $root.CreateGroupRequestMember.fromObject(object.members[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateGroupRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateGroupRequest
     * @static
     * @param {CreateGroupRequest} message CreateGroupRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateGroupRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.members = [];
        if (options.defaults) {
            object.ownerId = "";
            object.name = "";
        }
        if (message.ownerId != null && message.hasOwnProperty("ownerId"))
            object.ownerId = message.ownerId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.members && message.members.length) {
            object.members = [];
            for (let j = 0; j < message.members.length; ++j)
                object.members[j] = $root.CreateGroupRequestMember.toObject(message.members[j], options);
        }
        return object;
    };

    /**
     * Converts this CreateGroupRequest to JSON.
     * @function toJSON
     * @memberof CreateGroupRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateGroupRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CreateGroupRequest;
})();

export const CreateGroupRequestMember = $root.CreateGroupRequestMember = (() => {

    /**
     * Properties of a CreateGroupRequestMember.
     * @exports ICreateGroupRequestMember
     * @interface ICreateGroupRequestMember
     * @property {string|null} [id] CreateGroupRequestMember id
     * @property {string|null} [email] CreateGroupRequestMember email
     * @property {Array.<IModulePermission>|null} [modulePermission] CreateGroupRequestMember modulePermission
     */

    /**
     * Constructs a new CreateGroupRequestMember.
     * @exports CreateGroupRequestMember
     * @classdesc Represents a CreateGroupRequestMember.
     * @implements ICreateGroupRequestMember
     * @constructor
     * @param {ICreateGroupRequestMember=} [properties] Properties to set
     */
    function CreateGroupRequestMember(properties) {
        this.modulePermission = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateGroupRequestMember id.
     * @member {string} id
     * @memberof CreateGroupRequestMember
     * @instance
     */
    CreateGroupRequestMember.prototype.id = "";

    /**
     * CreateGroupRequestMember email.
     * @member {string} email
     * @memberof CreateGroupRequestMember
     * @instance
     */
    CreateGroupRequestMember.prototype.email = "";

    /**
     * CreateGroupRequestMember modulePermission.
     * @member {Array.<IModulePermission>} modulePermission
     * @memberof CreateGroupRequestMember
     * @instance
     */
    CreateGroupRequestMember.prototype.modulePermission = $util.emptyArray;

    /**
     * Creates a new CreateGroupRequestMember instance using the specified properties.
     * @function create
     * @memberof CreateGroupRequestMember
     * @static
     * @param {ICreateGroupRequestMember=} [properties] Properties to set
     * @returns {CreateGroupRequestMember} CreateGroupRequestMember instance
     */
    CreateGroupRequestMember.create = function create(properties) {
        return new CreateGroupRequestMember(properties);
    };

    /**
     * Encodes the specified CreateGroupRequestMember message. Does not implicitly {@link CreateGroupRequestMember.verify|verify} messages.
     * @function encode
     * @memberof CreateGroupRequestMember
     * @static
     * @param {ICreateGroupRequestMember} message CreateGroupRequestMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateGroupRequestMember.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.email != null && Object.hasOwnProperty.call(message, "email"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
        if (message.modulePermission != null && message.modulePermission.length)
            for (let i = 0; i < message.modulePermission.length; ++i)
                $root.ModulePermission.encode(message.modulePermission[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified CreateGroupRequestMember message, length delimited. Does not implicitly {@link CreateGroupRequestMember.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CreateGroupRequestMember
     * @static
     * @param {ICreateGroupRequestMember} message CreateGroupRequestMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateGroupRequestMember.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateGroupRequestMember message from the specified reader or buffer.
     * @function decode
     * @memberof CreateGroupRequestMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CreateGroupRequestMember} CreateGroupRequestMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateGroupRequestMember.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CreateGroupRequestMember();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.email = reader.string();
                break;
            case 3:
                if (!(message.modulePermission && message.modulePermission.length))
                    message.modulePermission = [];
                message.modulePermission.push($root.ModulePermission.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CreateGroupRequestMember message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CreateGroupRequestMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CreateGroupRequestMember} CreateGroupRequestMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateGroupRequestMember.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateGroupRequestMember message.
     * @function verify
     * @memberof CreateGroupRequestMember
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateGroupRequestMember.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.email != null && message.hasOwnProperty("email"))
            if (!$util.isString(message.email))
                return "email: string expected";
        if (message.modulePermission != null && message.hasOwnProperty("modulePermission")) {
            if (!Array.isArray(message.modulePermission))
                return "modulePermission: array expected";
            for (let i = 0; i < message.modulePermission.length; ++i) {
                let error = $root.ModulePermission.verify(message.modulePermission[i]);
                if (error)
                    return "modulePermission." + error;
            }
        }
        return null;
    };

    /**
     * Creates a CreateGroupRequestMember message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CreateGroupRequestMember
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CreateGroupRequestMember} CreateGroupRequestMember
     */
    CreateGroupRequestMember.fromObject = function fromObject(object) {
        if (object instanceof $root.CreateGroupRequestMember)
            return object;
        let message = new $root.CreateGroupRequestMember();
        if (object.id != null)
            message.id = String(object.id);
        if (object.email != null)
            message.email = String(object.email);
        if (object.modulePermission) {
            if (!Array.isArray(object.modulePermission))
                throw TypeError(".CreateGroupRequestMember.modulePermission: array expected");
            message.modulePermission = [];
            for (let i = 0; i < object.modulePermission.length; ++i) {
                if (typeof object.modulePermission[i] !== "object")
                    throw TypeError(".CreateGroupRequestMember.modulePermission: object expected");
                message.modulePermission[i] = $root.ModulePermission.fromObject(object.modulePermission[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a CreateGroupRequestMember message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CreateGroupRequestMember
     * @static
     * @param {CreateGroupRequestMember} message CreateGroupRequestMember
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateGroupRequestMember.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.modulePermission = [];
        if (options.defaults) {
            object.id = "";
            object.email = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.email != null && message.hasOwnProperty("email"))
            object.email = message.email;
        if (message.modulePermission && message.modulePermission.length) {
            object.modulePermission = [];
            for (let j = 0; j < message.modulePermission.length; ++j)
                object.modulePermission[j] = $root.ModulePermission.toObject(message.modulePermission[j], options);
        }
        return object;
    };

    /**
     * Converts this CreateGroupRequestMember to JSON.
     * @function toJSON
     * @memberof CreateGroupRequestMember
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateGroupRequestMember.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return CreateGroupRequestMember;
})();

export const Group = $root.Group = (() => {

    /**
     * Properties of a Group.
     * @exports IGroup
     * @interface IGroup
     * @property {string|null} [id] Group id
     * @property {Array.<string>|null} [memberIds] Group memberIds
     * @property {Array.<IUser>|null} [users] Group users
     * @property {string|null} [name] Group name
     * @property {IGroupMember|null} [groupOwner] Group groupOwner
     * @property {Array.<IGroupMember>|null} [groupMember] Group groupMember
     * @property {Array.<IGroupMemberAnonymous>|null} [groupMemberAnonymous] Group groupMemberAnonymous
     * @property {IBilling|null} [billing] Group billing
     * @property {IReplicationEndpoint|null} [replication] Group replication
     * @property {IActivePlan|null} [activePlan] Group activePlan
     * @property {ICouchbaseReplicationEndpoint|null} [replicationCouchbase] Group replicationCouchbase
     */

    /**
     * Constructs a new Group.
     * @exports Group
     * @classdesc Represents a Group.
     * @implements IGroup
     * @constructor
     * @param {IGroup=} [properties] Properties to set
     */
    function Group(properties) {
        this.memberIds = [];
        this.users = [];
        this.groupMember = [];
        this.groupMemberAnonymous = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Group id.
     * @member {string} id
     * @memberof Group
     * @instance
     */
    Group.prototype.id = "";

    /**
     * Group memberIds.
     * @member {Array.<string>} memberIds
     * @memberof Group
     * @instance
     */
    Group.prototype.memberIds = $util.emptyArray;

    /**
     * Group users.
     * @member {Array.<IUser>} users
     * @memberof Group
     * @instance
     */
    Group.prototype.users = $util.emptyArray;

    /**
     * Group name.
     * @member {string} name
     * @memberof Group
     * @instance
     */
    Group.prototype.name = "";

    /**
     * Group groupOwner.
     * @member {IGroupMember|null|undefined} groupOwner
     * @memberof Group
     * @instance
     */
    Group.prototype.groupOwner = null;

    /**
     * Group groupMember.
     * @member {Array.<IGroupMember>} groupMember
     * @memberof Group
     * @instance
     */
    Group.prototype.groupMember = $util.emptyArray;

    /**
     * Group groupMemberAnonymous.
     * @member {Array.<IGroupMemberAnonymous>} groupMemberAnonymous
     * @memberof Group
     * @instance
     */
    Group.prototype.groupMemberAnonymous = $util.emptyArray;

    /**
     * Group billing.
     * @member {IBilling|null|undefined} billing
     * @memberof Group
     * @instance
     */
    Group.prototype.billing = null;

    /**
     * Group replication.
     * @member {IReplicationEndpoint|null|undefined} replication
     * @memberof Group
     * @instance
     */
    Group.prototype.replication = null;

    /**
     * Group activePlan.
     * @member {IActivePlan|null|undefined} activePlan
     * @memberof Group
     * @instance
     */
    Group.prototype.activePlan = null;

    /**
     * Group replicationCouchbase.
     * @member {ICouchbaseReplicationEndpoint|null|undefined} replicationCouchbase
     * @memberof Group
     * @instance
     */
    Group.prototype.replicationCouchbase = null;

    /**
     * Creates a new Group instance using the specified properties.
     * @function create
     * @memberof Group
     * @static
     * @param {IGroup=} [properties] Properties to set
     * @returns {Group} Group instance
     */
    Group.create = function create(properties) {
        return new Group(properties);
    };

    /**
     * Encodes the specified Group message. Does not implicitly {@link Group.verify|verify} messages.
     * @function encode
     * @memberof Group
     * @static
     * @param {IGroup} message Group message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Group.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.memberIds != null && message.memberIds.length)
            for (let i = 0; i < message.memberIds.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.memberIds[i]);
        if (message.users != null && message.users.length)
            for (let i = 0; i < message.users.length; ++i)
                $root.User.encode(message.users[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
        if (message.groupOwner != null && Object.hasOwnProperty.call(message, "groupOwner"))
            $root.GroupMember.encode(message.groupOwner, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.groupMember != null && message.groupMember.length)
            for (let i = 0; i < message.groupMember.length; ++i)
                $root.GroupMember.encode(message.groupMember[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.groupMemberAnonymous != null && message.groupMemberAnonymous.length)
            for (let i = 0; i < message.groupMemberAnonymous.length; ++i)
                $root.GroupMemberAnonymous.encode(message.groupMemberAnonymous[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.billing != null && Object.hasOwnProperty.call(message, "billing"))
            $root.Billing.encode(message.billing, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.replication != null && Object.hasOwnProperty.call(message, "replication"))
            $root.ReplicationEndpoint.encode(message.replication, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.activePlan != null && Object.hasOwnProperty.call(message, "activePlan"))
            $root.ActivePlan.encode(message.activePlan, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.replicationCouchbase != null && Object.hasOwnProperty.call(message, "replicationCouchbase"))
            $root.CouchbaseReplicationEndpoint.encode(message.replicationCouchbase, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Group message, length delimited. Does not implicitly {@link Group.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Group
     * @static
     * @param {IGroup} message Group message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Group.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Group message from the specified reader or buffer.
     * @function decode
     * @memberof Group
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Group} Group
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Group.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Group();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                if (!(message.memberIds && message.memberIds.length))
                    message.memberIds = [];
                message.memberIds.push(reader.string());
                break;
            case 3:
                if (!(message.users && message.users.length))
                    message.users = [];
                message.users.push($root.User.decode(reader, reader.uint32()));
                break;
            case 4:
                message.name = reader.string();
                break;
            case 5:
                message.groupOwner = $root.GroupMember.decode(reader, reader.uint32());
                break;
            case 6:
                if (!(message.groupMember && message.groupMember.length))
                    message.groupMember = [];
                message.groupMember.push($root.GroupMember.decode(reader, reader.uint32()));
                break;
            case 7:
                if (!(message.groupMemberAnonymous && message.groupMemberAnonymous.length))
                    message.groupMemberAnonymous = [];
                message.groupMemberAnonymous.push($root.GroupMemberAnonymous.decode(reader, reader.uint32()));
                break;
            case 8:
                message.billing = $root.Billing.decode(reader, reader.uint32());
                break;
            case 9:
                message.replication = $root.ReplicationEndpoint.decode(reader, reader.uint32());
                break;
            case 10:
                message.activePlan = $root.ActivePlan.decode(reader, reader.uint32());
                break;
            case 11:
                message.replicationCouchbase = $root.CouchbaseReplicationEndpoint.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Group message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Group
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Group} Group
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Group.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Group message.
     * @function verify
     * @memberof Group
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Group.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.memberIds != null && message.hasOwnProperty("memberIds")) {
            if (!Array.isArray(message.memberIds))
                return "memberIds: array expected";
            for (let i = 0; i < message.memberIds.length; ++i)
                if (!$util.isString(message.memberIds[i]))
                    return "memberIds: string[] expected";
        }
        if (message.users != null && message.hasOwnProperty("users")) {
            if (!Array.isArray(message.users))
                return "users: array expected";
            for (let i = 0; i < message.users.length; ++i) {
                let error = $root.User.verify(message.users[i]);
                if (error)
                    return "users." + error;
            }
        }
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.groupOwner != null && message.hasOwnProperty("groupOwner")) {
            let error = $root.GroupMember.verify(message.groupOwner);
            if (error)
                return "groupOwner." + error;
        }
        if (message.groupMember != null && message.hasOwnProperty("groupMember")) {
            if (!Array.isArray(message.groupMember))
                return "groupMember: array expected";
            for (let i = 0; i < message.groupMember.length; ++i) {
                let error = $root.GroupMember.verify(message.groupMember[i]);
                if (error)
                    return "groupMember." + error;
            }
        }
        if (message.groupMemberAnonymous != null && message.hasOwnProperty("groupMemberAnonymous")) {
            if (!Array.isArray(message.groupMemberAnonymous))
                return "groupMemberAnonymous: array expected";
            for (let i = 0; i < message.groupMemberAnonymous.length; ++i) {
                let error = $root.GroupMemberAnonymous.verify(message.groupMemberAnonymous[i]);
                if (error)
                    return "groupMemberAnonymous." + error;
            }
        }
        if (message.billing != null && message.hasOwnProperty("billing")) {
            let error = $root.Billing.verify(message.billing);
            if (error)
                return "billing." + error;
        }
        if (message.replication != null && message.hasOwnProperty("replication")) {
            let error = $root.ReplicationEndpoint.verify(message.replication);
            if (error)
                return "replication." + error;
        }
        if (message.activePlan != null && message.hasOwnProperty("activePlan")) {
            let error = $root.ActivePlan.verify(message.activePlan);
            if (error)
                return "activePlan." + error;
        }
        if (message.replicationCouchbase != null && message.hasOwnProperty("replicationCouchbase")) {
            let error = $root.CouchbaseReplicationEndpoint.verify(message.replicationCouchbase);
            if (error)
                return "replicationCouchbase." + error;
        }
        return null;
    };

    /**
     * Creates a Group message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Group
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Group} Group
     */
    Group.fromObject = function fromObject(object) {
        if (object instanceof $root.Group)
            return object;
        let message = new $root.Group();
        if (object.id != null)
            message.id = String(object.id);
        if (object.memberIds) {
            if (!Array.isArray(object.memberIds))
                throw TypeError(".Group.memberIds: array expected");
            message.memberIds = [];
            for (let i = 0; i < object.memberIds.length; ++i)
                message.memberIds[i] = String(object.memberIds[i]);
        }
        if (object.users) {
            if (!Array.isArray(object.users))
                throw TypeError(".Group.users: array expected");
            message.users = [];
            for (let i = 0; i < object.users.length; ++i) {
                if (typeof object.users[i] !== "object")
                    throw TypeError(".Group.users: object expected");
                message.users[i] = $root.User.fromObject(object.users[i]);
            }
        }
        if (object.name != null)
            message.name = String(object.name);
        if (object.groupOwner != null) {
            if (typeof object.groupOwner !== "object")
                throw TypeError(".Group.groupOwner: object expected");
            message.groupOwner = $root.GroupMember.fromObject(object.groupOwner);
        }
        if (object.groupMember) {
            if (!Array.isArray(object.groupMember))
                throw TypeError(".Group.groupMember: array expected");
            message.groupMember = [];
            for (let i = 0; i < object.groupMember.length; ++i) {
                if (typeof object.groupMember[i] !== "object")
                    throw TypeError(".Group.groupMember: object expected");
                message.groupMember[i] = $root.GroupMember.fromObject(object.groupMember[i]);
            }
        }
        if (object.groupMemberAnonymous) {
            if (!Array.isArray(object.groupMemberAnonymous))
                throw TypeError(".Group.groupMemberAnonymous: array expected");
            message.groupMemberAnonymous = [];
            for (let i = 0; i < object.groupMemberAnonymous.length; ++i) {
                if (typeof object.groupMemberAnonymous[i] !== "object")
                    throw TypeError(".Group.groupMemberAnonymous: object expected");
                message.groupMemberAnonymous[i] = $root.GroupMemberAnonymous.fromObject(object.groupMemberAnonymous[i]);
            }
        }
        if (object.billing != null) {
            if (typeof object.billing !== "object")
                throw TypeError(".Group.billing: object expected");
            message.billing = $root.Billing.fromObject(object.billing);
        }
        if (object.replication != null) {
            if (typeof object.replication !== "object")
                throw TypeError(".Group.replication: object expected");
            message.replication = $root.ReplicationEndpoint.fromObject(object.replication);
        }
        if (object.activePlan != null) {
            if (typeof object.activePlan !== "object")
                throw TypeError(".Group.activePlan: object expected");
            message.activePlan = $root.ActivePlan.fromObject(object.activePlan);
        }
        if (object.replicationCouchbase != null) {
            if (typeof object.replicationCouchbase !== "object")
                throw TypeError(".Group.replicationCouchbase: object expected");
            message.replicationCouchbase = $root.CouchbaseReplicationEndpoint.fromObject(object.replicationCouchbase);
        }
        return message;
    };

    /**
     * Creates a plain object from a Group message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Group
     * @static
     * @param {Group} message Group
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Group.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.memberIds = [];
            object.users = [];
            object.groupMember = [];
            object.groupMemberAnonymous = [];
        }
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.groupOwner = null;
            object.billing = null;
            object.replication = null;
            object.activePlan = null;
            object.replicationCouchbase = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.memberIds && message.memberIds.length) {
            object.memberIds = [];
            for (let j = 0; j < message.memberIds.length; ++j)
                object.memberIds[j] = message.memberIds[j];
        }
        if (message.users && message.users.length) {
            object.users = [];
            for (let j = 0; j < message.users.length; ++j)
                object.users[j] = $root.User.toObject(message.users[j], options);
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.groupOwner != null && message.hasOwnProperty("groupOwner"))
            object.groupOwner = $root.GroupMember.toObject(message.groupOwner, options);
        if (message.groupMember && message.groupMember.length) {
            object.groupMember = [];
            for (let j = 0; j < message.groupMember.length; ++j)
                object.groupMember[j] = $root.GroupMember.toObject(message.groupMember[j], options);
        }
        if (message.groupMemberAnonymous && message.groupMemberAnonymous.length) {
            object.groupMemberAnonymous = [];
            for (let j = 0; j < message.groupMemberAnonymous.length; ++j)
                object.groupMemberAnonymous[j] = $root.GroupMemberAnonymous.toObject(message.groupMemberAnonymous[j], options);
        }
        if (message.billing != null && message.hasOwnProperty("billing"))
            object.billing = $root.Billing.toObject(message.billing, options);
        if (message.replication != null && message.hasOwnProperty("replication"))
            object.replication = $root.ReplicationEndpoint.toObject(message.replication, options);
        if (message.activePlan != null && message.hasOwnProperty("activePlan"))
            object.activePlan = $root.ActivePlan.toObject(message.activePlan, options);
        if (message.replicationCouchbase != null && message.hasOwnProperty("replicationCouchbase"))
            object.replicationCouchbase = $root.CouchbaseReplicationEndpoint.toObject(message.replicationCouchbase, options);
        return object;
    };

    /**
     * Converts this Group to JSON.
     * @function toJSON
     * @memberof Group
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Group.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Group;
})();

export const GroupMember = $root.GroupMember = (() => {

    /**
     * Properties of a GroupMember.
     * @exports IGroupMember
     * @interface IGroupMember
     * @property {IGroupUser|null} [user] GroupMember user
     * @property {Array.<IModulePermission>|null} [modulePermission] GroupMember modulePermission
     */

    /**
     * Constructs a new GroupMember.
     * @exports GroupMember
     * @classdesc Represents a GroupMember.
     * @implements IGroupMember
     * @constructor
     * @param {IGroupMember=} [properties] Properties to set
     */
    function GroupMember(properties) {
        this.modulePermission = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupMember user.
     * @member {IGroupUser|null|undefined} user
     * @memberof GroupMember
     * @instance
     */
    GroupMember.prototype.user = null;

    /**
     * GroupMember modulePermission.
     * @member {Array.<IModulePermission>} modulePermission
     * @memberof GroupMember
     * @instance
     */
    GroupMember.prototype.modulePermission = $util.emptyArray;

    /**
     * Creates a new GroupMember instance using the specified properties.
     * @function create
     * @memberof GroupMember
     * @static
     * @param {IGroupMember=} [properties] Properties to set
     * @returns {GroupMember} GroupMember instance
     */
    GroupMember.create = function create(properties) {
        return new GroupMember(properties);
    };

    /**
     * Encodes the specified GroupMember message. Does not implicitly {@link GroupMember.verify|verify} messages.
     * @function encode
     * @memberof GroupMember
     * @static
     * @param {IGroupMember} message GroupMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupMember.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.user != null && Object.hasOwnProperty.call(message, "user"))
            $root.GroupUser.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.modulePermission != null && message.modulePermission.length)
            for (let i = 0; i < message.modulePermission.length; ++i)
                $root.ModulePermission.encode(message.modulePermission[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GroupMember message, length delimited. Does not implicitly {@link GroupMember.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GroupMember
     * @static
     * @param {IGroupMember} message GroupMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupMember.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GroupMember message from the specified reader or buffer.
     * @function decode
     * @memberof GroupMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupMember} GroupMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupMember.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupMember();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.user = $root.GroupUser.decode(reader, reader.uint32());
                break;
            case 2:
                if (!(message.modulePermission && message.modulePermission.length))
                    message.modulePermission = [];
                message.modulePermission.push($root.ModulePermission.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GroupMember message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GroupMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GroupMember} GroupMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupMember.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GroupMember message.
     * @function verify
     * @memberof GroupMember
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GroupMember.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.user != null && message.hasOwnProperty("user")) {
            let error = $root.GroupUser.verify(message.user);
            if (error)
                return "user." + error;
        }
        if (message.modulePermission != null && message.hasOwnProperty("modulePermission")) {
            if (!Array.isArray(message.modulePermission))
                return "modulePermission: array expected";
            for (let i = 0; i < message.modulePermission.length; ++i) {
                let error = $root.ModulePermission.verify(message.modulePermission[i]);
                if (error)
                    return "modulePermission." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GroupMember message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GroupMember
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GroupMember} GroupMember
     */
    GroupMember.fromObject = function fromObject(object) {
        if (object instanceof $root.GroupMember)
            return object;
        let message = new $root.GroupMember();
        if (object.user != null) {
            if (typeof object.user !== "object")
                throw TypeError(".GroupMember.user: object expected");
            message.user = $root.GroupUser.fromObject(object.user);
        }
        if (object.modulePermission) {
            if (!Array.isArray(object.modulePermission))
                throw TypeError(".GroupMember.modulePermission: array expected");
            message.modulePermission = [];
            for (let i = 0; i < object.modulePermission.length; ++i) {
                if (typeof object.modulePermission[i] !== "object")
                    throw TypeError(".GroupMember.modulePermission: object expected");
                message.modulePermission[i] = $root.ModulePermission.fromObject(object.modulePermission[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GroupMember message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GroupMember
     * @static
     * @param {GroupMember} message GroupMember
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GroupMember.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.modulePermission = [];
        if (options.defaults)
            object.user = null;
        if (message.user != null && message.hasOwnProperty("user"))
            object.user = $root.GroupUser.toObject(message.user, options);
        if (message.modulePermission && message.modulePermission.length) {
            object.modulePermission = [];
            for (let j = 0; j < message.modulePermission.length; ++j)
                object.modulePermission[j] = $root.ModulePermission.toObject(message.modulePermission[j], options);
        }
        return object;
    };

    /**
     * Converts this GroupMember to JSON.
     * @function toJSON
     * @memberof GroupMember
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GroupMember.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GroupMember;
})();

export const GroupMemberAnonymous = $root.GroupMemberAnonymous = (() => {

    /**
     * Properties of a GroupMemberAnonymous.
     * @exports IGroupMemberAnonymous
     * @interface IGroupMemberAnonymous
     * @property {string} email GroupMemberAnonymous email
     * @property {Array.<IModulePermission>|null} [modulePermission] GroupMemberAnonymous modulePermission
     */

    /**
     * Constructs a new GroupMemberAnonymous.
     * @exports GroupMemberAnonymous
     * @classdesc Represents a GroupMemberAnonymous.
     * @implements IGroupMemberAnonymous
     * @constructor
     * @param {IGroupMemberAnonymous=} [properties] Properties to set
     */
    function GroupMemberAnonymous(properties) {
        this.modulePermission = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupMemberAnonymous email.
     * @member {string} email
     * @memberof GroupMemberAnonymous
     * @instance
     */
    GroupMemberAnonymous.prototype.email = "";

    /**
     * GroupMemberAnonymous modulePermission.
     * @member {Array.<IModulePermission>} modulePermission
     * @memberof GroupMemberAnonymous
     * @instance
     */
    GroupMemberAnonymous.prototype.modulePermission = $util.emptyArray;

    /**
     * Creates a new GroupMemberAnonymous instance using the specified properties.
     * @function create
     * @memberof GroupMemberAnonymous
     * @static
     * @param {IGroupMemberAnonymous=} [properties] Properties to set
     * @returns {GroupMemberAnonymous} GroupMemberAnonymous instance
     */
    GroupMemberAnonymous.create = function create(properties) {
        return new GroupMemberAnonymous(properties);
    };

    /**
     * Encodes the specified GroupMemberAnonymous message. Does not implicitly {@link GroupMemberAnonymous.verify|verify} messages.
     * @function encode
     * @memberof GroupMemberAnonymous
     * @static
     * @param {IGroupMemberAnonymous} message GroupMemberAnonymous message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupMemberAnonymous.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
        if (message.modulePermission != null && message.modulePermission.length)
            for (let i = 0; i < message.modulePermission.length; ++i)
                $root.ModulePermission.encode(message.modulePermission[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GroupMemberAnonymous message, length delimited. Does not implicitly {@link GroupMemberAnonymous.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GroupMemberAnonymous
     * @static
     * @param {IGroupMemberAnonymous} message GroupMemberAnonymous message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupMemberAnonymous.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GroupMemberAnonymous message from the specified reader or buffer.
     * @function decode
     * @memberof GroupMemberAnonymous
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupMemberAnonymous} GroupMemberAnonymous
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupMemberAnonymous.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupMemberAnonymous();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.email = reader.string();
                break;
            case 2:
                if (!(message.modulePermission && message.modulePermission.length))
                    message.modulePermission = [];
                message.modulePermission.push($root.ModulePermission.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("email"))
            throw $util.ProtocolError("missing required 'email'", { instance: message });
        return message;
    };

    /**
     * Decodes a GroupMemberAnonymous message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GroupMemberAnonymous
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GroupMemberAnonymous} GroupMemberAnonymous
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupMemberAnonymous.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GroupMemberAnonymous message.
     * @function verify
     * @memberof GroupMemberAnonymous
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GroupMemberAnonymous.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.email))
            return "email: string expected";
        if (message.modulePermission != null && message.hasOwnProperty("modulePermission")) {
            if (!Array.isArray(message.modulePermission))
                return "modulePermission: array expected";
            for (let i = 0; i < message.modulePermission.length; ++i) {
                let error = $root.ModulePermission.verify(message.modulePermission[i]);
                if (error)
                    return "modulePermission." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GroupMemberAnonymous message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GroupMemberAnonymous
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GroupMemberAnonymous} GroupMemberAnonymous
     */
    GroupMemberAnonymous.fromObject = function fromObject(object) {
        if (object instanceof $root.GroupMemberAnonymous)
            return object;
        let message = new $root.GroupMemberAnonymous();
        if (object.email != null)
            message.email = String(object.email);
        if (object.modulePermission) {
            if (!Array.isArray(object.modulePermission))
                throw TypeError(".GroupMemberAnonymous.modulePermission: array expected");
            message.modulePermission = [];
            for (let i = 0; i < object.modulePermission.length; ++i) {
                if (typeof object.modulePermission[i] !== "object")
                    throw TypeError(".GroupMemberAnonymous.modulePermission: object expected");
                message.modulePermission[i] = $root.ModulePermission.fromObject(object.modulePermission[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GroupMemberAnonymous message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GroupMemberAnonymous
     * @static
     * @param {GroupMemberAnonymous} message GroupMemberAnonymous
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GroupMemberAnonymous.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.modulePermission = [];
        if (options.defaults)
            object.email = "";
        if (message.email != null && message.hasOwnProperty("email"))
            object.email = message.email;
        if (message.modulePermission && message.modulePermission.length) {
            object.modulePermission = [];
            for (let j = 0; j < message.modulePermission.length; ++j)
                object.modulePermission[j] = $root.ModulePermission.toObject(message.modulePermission[j], options);
        }
        return object;
    };

    /**
     * Converts this GroupMemberAnonymous to JSON.
     * @function toJSON
     * @memberof GroupMemberAnonymous
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GroupMemberAnonymous.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GroupMemberAnonymous;
})();

export const GroupUser = $root.GroupUser = (() => {

    /**
     * Properties of a GroupUser.
     * @exports IGroupUser
     * @interface IGroupUser
     * @property {string} userId GroupUser userId
     * @property {string|null} [fullName] GroupUser fullName
     * @property {string} email GroupUser email
     * @property {string|null} [avatarUrl] GroupUser avatarUrl
     */

    /**
     * Constructs a new GroupUser.
     * @exports GroupUser
     * @classdesc Represents a GroupUser.
     * @implements IGroupUser
     * @constructor
     * @param {IGroupUser=} [properties] Properties to set
     */
    function GroupUser(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupUser userId.
     * @member {string} userId
     * @memberof GroupUser
     * @instance
     */
    GroupUser.prototype.userId = "";

    /**
     * GroupUser fullName.
     * @member {string} fullName
     * @memberof GroupUser
     * @instance
     */
    GroupUser.prototype.fullName = "";

    /**
     * GroupUser email.
     * @member {string} email
     * @memberof GroupUser
     * @instance
     */
    GroupUser.prototype.email = "";

    /**
     * GroupUser avatarUrl.
     * @member {string} avatarUrl
     * @memberof GroupUser
     * @instance
     */
    GroupUser.prototype.avatarUrl = "";

    /**
     * Creates a new GroupUser instance using the specified properties.
     * @function create
     * @memberof GroupUser
     * @static
     * @param {IGroupUser=} [properties] Properties to set
     * @returns {GroupUser} GroupUser instance
     */
    GroupUser.create = function create(properties) {
        return new GroupUser(properties);
    };

    /**
     * Encodes the specified GroupUser message. Does not implicitly {@link GroupUser.verify|verify} messages.
     * @function encode
     * @memberof GroupUser
     * @static
     * @param {IGroupUser} message GroupUser message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupUser.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
        if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.fullName);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
        if (message.avatarUrl != null && Object.hasOwnProperty.call(message, "avatarUrl"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.avatarUrl);
        return writer;
    };

    /**
     * Encodes the specified GroupUser message, length delimited. Does not implicitly {@link GroupUser.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GroupUser
     * @static
     * @param {IGroupUser} message GroupUser message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupUser.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GroupUser message from the specified reader or buffer.
     * @function decode
     * @memberof GroupUser
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupUser} GroupUser
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupUser.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupUser();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.userId = reader.string();
                break;
            case 2:
                message.fullName = reader.string();
                break;
            case 3:
                message.email = reader.string();
                break;
            case 5:
                message.avatarUrl = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("userId"))
            throw $util.ProtocolError("missing required 'userId'", { instance: message });
        if (!message.hasOwnProperty("email"))
            throw $util.ProtocolError("missing required 'email'", { instance: message });
        return message;
    };

    /**
     * Decodes a GroupUser message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GroupUser
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GroupUser} GroupUser
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupUser.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GroupUser message.
     * @function verify
     * @memberof GroupUser
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GroupUser.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.userId))
            return "userId: string expected";
        if (message.fullName != null && message.hasOwnProperty("fullName"))
            if (!$util.isString(message.fullName))
                return "fullName: string expected";
        if (!$util.isString(message.email))
            return "email: string expected";
        if (message.avatarUrl != null && message.hasOwnProperty("avatarUrl"))
            if (!$util.isString(message.avatarUrl))
                return "avatarUrl: string expected";
        return null;
    };

    /**
     * Creates a GroupUser message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GroupUser
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GroupUser} GroupUser
     */
    GroupUser.fromObject = function fromObject(object) {
        if (object instanceof $root.GroupUser)
            return object;
        let message = new $root.GroupUser();
        if (object.userId != null)
            message.userId = String(object.userId);
        if (object.fullName != null)
            message.fullName = String(object.fullName);
        if (object.email != null)
            message.email = String(object.email);
        if (object.avatarUrl != null)
            message.avatarUrl = String(object.avatarUrl);
        return message;
    };

    /**
     * Creates a plain object from a GroupUser message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GroupUser
     * @static
     * @param {GroupUser} message GroupUser
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GroupUser.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.userId = "";
            object.fullName = "";
            object.email = "";
            object.avatarUrl = "";
        }
        if (message.userId != null && message.hasOwnProperty("userId"))
            object.userId = message.userId;
        if (message.fullName != null && message.hasOwnProperty("fullName"))
            object.fullName = message.fullName;
        if (message.email != null && message.hasOwnProperty("email"))
            object.email = message.email;
        if (message.avatarUrl != null && message.hasOwnProperty("avatarUrl"))
            object.avatarUrl = message.avatarUrl;
        return object;
    };

    /**
     * Converts this GroupUser to JSON.
     * @function toJSON
     * @memberof GroupUser
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GroupUser.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GroupUser;
})();

export const ModulePermission = $root.ModulePermission = (() => {

    /**
     * Properties of a ModulePermission.
     * @exports IModulePermission
     * @interface IModulePermission
     * @property {ModulePermissionType} modulePermissionType ModulePermission modulePermissionType
     * @property {ComponentType|null} [componentType] ModulePermission componentType
     * @property {ModelType|null} [modelType] ModulePermission modelType
     * @property {GroupAccessPermission} modulePermission ModulePermission modulePermission
     * @property {Array.<IModuleObjectPermission>|null} [moduleObjectPermissions] ModulePermission moduleObjectPermissions
     */

    /**
     * Constructs a new ModulePermission.
     * @exports ModulePermission
     * @classdesc Represents a ModulePermission.
     * @implements IModulePermission
     * @constructor
     * @param {IModulePermission=} [properties] Properties to set
     */
    function ModulePermission(properties) {
        this.moduleObjectPermissions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ModulePermission modulePermissionType.
     * @member {ModulePermissionType} modulePermissionType
     * @memberof ModulePermission
     * @instance
     */
    ModulePermission.prototype.modulePermissionType = 0;

    /**
     * ModulePermission componentType.
     * @member {ComponentType} componentType
     * @memberof ModulePermission
     * @instance
     */
    ModulePermission.prototype.componentType = 0;

    /**
     * ModulePermission modelType.
     * @member {ModelType} modelType
     * @memberof ModulePermission
     * @instance
     */
    ModulePermission.prototype.modelType = 12;

    /**
     * ModulePermission modulePermission.
     * @member {GroupAccessPermission} modulePermission
     * @memberof ModulePermission
     * @instance
     */
    ModulePermission.prototype.modulePermission = 0;

    /**
     * ModulePermission moduleObjectPermissions.
     * @member {Array.<IModuleObjectPermission>} moduleObjectPermissions
     * @memberof ModulePermission
     * @instance
     */
    ModulePermission.prototype.moduleObjectPermissions = $util.emptyArray;

    /**
     * Creates a new ModulePermission instance using the specified properties.
     * @function create
     * @memberof ModulePermission
     * @static
     * @param {IModulePermission=} [properties] Properties to set
     * @returns {ModulePermission} ModulePermission instance
     */
    ModulePermission.create = function create(properties) {
        return new ModulePermission(properties);
    };

    /**
     * Encodes the specified ModulePermission message. Does not implicitly {@link ModulePermission.verify|verify} messages.
     * @function encode
     * @memberof ModulePermission
     * @static
     * @param {IModulePermission} message ModulePermission message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ModulePermission.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.modulePermissionType);
        if (message.componentType != null && Object.hasOwnProperty.call(message, "componentType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.componentType);
        if (message.modelType != null && Object.hasOwnProperty.call(message, "modelType"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.modelType);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.modulePermission);
        if (message.moduleObjectPermissions != null && message.moduleObjectPermissions.length)
            for (let i = 0; i < message.moduleObjectPermissions.length; ++i)
                $root.ModuleObjectPermission.encode(message.moduleObjectPermissions[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ModulePermission message, length delimited. Does not implicitly {@link ModulePermission.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ModulePermission
     * @static
     * @param {IModulePermission} message ModulePermission message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ModulePermission.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ModulePermission message from the specified reader or buffer.
     * @function decode
     * @memberof ModulePermission
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ModulePermission} ModulePermission
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ModulePermission.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ModulePermission();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.modulePermissionType = reader.int32();
                break;
            case 2:
                message.componentType = reader.int32();
                break;
            case 3:
                message.modelType = reader.int32();
                break;
            case 4:
                message.modulePermission = reader.int32();
                break;
            case 5:
                if (!(message.moduleObjectPermissions && message.moduleObjectPermissions.length))
                    message.moduleObjectPermissions = [];
                message.moduleObjectPermissions.push($root.ModuleObjectPermission.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("modulePermissionType"))
            throw $util.ProtocolError("missing required 'modulePermissionType'", { instance: message });
        if (!message.hasOwnProperty("modulePermission"))
            throw $util.ProtocolError("missing required 'modulePermission'", { instance: message });
        return message;
    };

    /**
     * Decodes a ModulePermission message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ModulePermission
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ModulePermission} ModulePermission
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ModulePermission.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ModulePermission message.
     * @function verify
     * @memberof ModulePermission
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ModulePermission.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.modulePermissionType) {
        default:
            return "modulePermissionType: enum value expected";
        case 0:
        case 1:
            break;
        }
        if (message.componentType != null && message.hasOwnProperty("componentType"))
            switch (message.componentType) {
            default:
                return "componentType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
                break;
            }
        if (message.modelType != null && message.hasOwnProperty("modelType"))
            switch (message.modelType) {
            default:
                return "modelType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                break;
            }
        switch (message.modulePermission) {
        default:
            return "modulePermission: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
            break;
        }
        if (message.moduleObjectPermissions != null && message.hasOwnProperty("moduleObjectPermissions")) {
            if (!Array.isArray(message.moduleObjectPermissions))
                return "moduleObjectPermissions: array expected";
            for (let i = 0; i < message.moduleObjectPermissions.length; ++i) {
                let error = $root.ModuleObjectPermission.verify(message.moduleObjectPermissions[i]);
                if (error)
                    return "moduleObjectPermissions." + error;
            }
        }
        return null;
    };

    /**
     * Creates a ModulePermission message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ModulePermission
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ModulePermission} ModulePermission
     */
    ModulePermission.fromObject = function fromObject(object) {
        if (object instanceof $root.ModulePermission)
            return object;
        let message = new $root.ModulePermission();
        switch (object.modulePermissionType) {
        case "COMPONENT":
        case 0:
            message.modulePermissionType = 0;
            break;
        case "MODEL":
        case 1:
            message.modulePermissionType = 1;
            break;
        }
        switch (object.componentType) {
        case "NoneComponent":
        case 0:
            message.componentType = 0;
            break;
        case "DashboardComponent":
        case 1:
            message.componentType = 1;
            break;
        case "OverviewComponent":
        case 2:
            message.componentType = 2;
            break;
        case "ChartsComponent":
        case 3:
            message.componentType = 3;
            break;
        case "ReportsComponent":
        case 4:
            message.componentType = 4;
            break;
        case "LocationComponent":
        case 5:
            message.componentType = 5;
            break;
        case "ExportsComponent":
        case 6:
            message.componentType = 6;
            break;
        case "SettingsComponent":
        case 7:
            message.componentType = 7;
            break;
        case "GroupsSettingsComponent":
        case 8:
            message.componentType = 8;
            break;
        case "OtherSettingsComponent":
        case 9:
            message.componentType = 9;
            break;
        case "WarrantiesComponent":
        case 10:
            message.componentType = 10;
            break;
        }
        switch (object.modelType) {
        case "Account":
        case 0:
            message.modelType = 0;
            break;
        case "HashTag":
        case 1:
            message.modelType = 1;
            break;
        case "Category":
        case 2:
            message.modelType = 2;
            break;
        case "Currency":
        case 3:
            message.modelType = 3;
            break;
        case "Record":
        case 4:
            message.modelType = 4;
            break;
        case "Budget":
        case 5:
            message.modelType = 5;
            break;
        case "ShoppingList":
        case 6:
            message.modelType = 6;
            break;
        case "StandingOrder":
        case 7:
            message.modelType = 7;
            break;
        case "Debt":
        case 8:
            message.modelType = 8;
            break;
        case "Template":
        case 9:
            message.modelType = 9;
            break;
        case "IntegrationType":
        case 10:
            message.modelType = 10;
            break;
        case "FilterType":
        case 11:
            message.modelType = 11;
            break;
        case "NoneType":
        case 12:
            message.modelType = 12;
            break;
        case "EnvelopBudget":
        case 13:
            message.modelType = 13;
            break;
        case "LoyaltyCard":
        case 14:
            message.modelType = 14;
            break;
        case "Goal":
        case 15:
            message.modelType = 15;
            break;
        }
        switch (object.modulePermission) {
        case "NONE":
        case 0:
            message.modulePermission = 0;
            break;
        case "READ_ONLY":
        case 1:
            message.modulePermission = 1;
            break;
        case "READ_WRITE":
        case 2:
            message.modulePermission = 2;
            break;
        case "READ_WRITE_MODIFY":
        case 3:
            message.modulePermission = 3;
            break;
        }
        if (object.moduleObjectPermissions) {
            if (!Array.isArray(object.moduleObjectPermissions))
                throw TypeError(".ModulePermission.moduleObjectPermissions: array expected");
            message.moduleObjectPermissions = [];
            for (let i = 0; i < object.moduleObjectPermissions.length; ++i) {
                if (typeof object.moduleObjectPermissions[i] !== "object")
                    throw TypeError(".ModulePermission.moduleObjectPermissions: object expected");
                message.moduleObjectPermissions[i] = $root.ModuleObjectPermission.fromObject(object.moduleObjectPermissions[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a ModulePermission message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ModulePermission
     * @static
     * @param {ModulePermission} message ModulePermission
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ModulePermission.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.moduleObjectPermissions = [];
        if (options.defaults) {
            object.modulePermissionType = options.enums === String ? "COMPONENT" : 0;
            object.componentType = options.enums === String ? "NoneComponent" : 0;
            object.modelType = options.enums === String ? "NoneType" : 12;
            object.modulePermission = options.enums === String ? "NONE" : 0;
        }
        if (message.modulePermissionType != null && message.hasOwnProperty("modulePermissionType"))
            object.modulePermissionType = options.enums === String ? $root.ModulePermissionType[message.modulePermissionType] : message.modulePermissionType;
        if (message.componentType != null && message.hasOwnProperty("componentType"))
            object.componentType = options.enums === String ? $root.ComponentType[message.componentType] : message.componentType;
        if (message.modelType != null && message.hasOwnProperty("modelType"))
            object.modelType = options.enums === String ? $root.ModelType[message.modelType] : message.modelType;
        if (message.modulePermission != null && message.hasOwnProperty("modulePermission"))
            object.modulePermission = options.enums === String ? $root.GroupAccessPermission[message.modulePermission] : message.modulePermission;
        if (message.moduleObjectPermissions && message.moduleObjectPermissions.length) {
            object.moduleObjectPermissions = [];
            for (let j = 0; j < message.moduleObjectPermissions.length; ++j)
                object.moduleObjectPermissions[j] = $root.ModuleObjectPermission.toObject(message.moduleObjectPermissions[j], options);
        }
        return object;
    };

    /**
     * Converts this ModulePermission to JSON.
     * @function toJSON
     * @memberof ModulePermission
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ModulePermission.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ModulePermission;
})();

/**
 * ModulePermissionType enum.
 * @exports ModulePermissionType
 * @enum {number}
 * @property {number} COMPONENT=0 COMPONENT value
 * @property {number} MODEL=1 MODEL value
 */
export const ModulePermissionType = $root.ModulePermissionType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "COMPONENT"] = 0;
    values[valuesById[1] = "MODEL"] = 1;
    return values;
})();

/**
 * ComponentType enum.
 * @exports ComponentType
 * @enum {number}
 * @property {number} NoneComponent=0 NoneComponent value
 * @property {number} DashboardComponent=1 DashboardComponent value
 * @property {number} OverviewComponent=2 OverviewComponent value
 * @property {number} ChartsComponent=3 ChartsComponent value
 * @property {number} ReportsComponent=4 ReportsComponent value
 * @property {number} LocationComponent=5 LocationComponent value
 * @property {number} ExportsComponent=6 ExportsComponent value
 * @property {number} SettingsComponent=7 SettingsComponent value
 * @property {number} GroupsSettingsComponent=8 GroupsSettingsComponent value
 * @property {number} OtherSettingsComponent=9 OtherSettingsComponent value
 * @property {number} WarrantiesComponent=10 WarrantiesComponent value
 */
export const ComponentType = $root.ComponentType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "NoneComponent"] = 0;
    values[valuesById[1] = "DashboardComponent"] = 1;
    values[valuesById[2] = "OverviewComponent"] = 2;
    values[valuesById[3] = "ChartsComponent"] = 3;
    values[valuesById[4] = "ReportsComponent"] = 4;
    values[valuesById[5] = "LocationComponent"] = 5;
    values[valuesById[6] = "ExportsComponent"] = 6;
    values[valuesById[7] = "SettingsComponent"] = 7;
    values[valuesById[8] = "GroupsSettingsComponent"] = 8;
    values[valuesById[9] = "OtherSettingsComponent"] = 9;
    values[valuesById[10] = "WarrantiesComponent"] = 10;
    return values;
})();

export const ModuleObjectPermission = $root.ModuleObjectPermission = (() => {

    /**
     * Properties of a ModuleObjectPermission.
     * @exports IModuleObjectPermission
     * @interface IModuleObjectPermission
     * @property {string} objectId ModuleObjectPermission objectId
     * @property {GroupAccessPermission} groupAccessPermission ModuleObjectPermission groupAccessPermission
     */

    /**
     * Constructs a new ModuleObjectPermission.
     * @exports ModuleObjectPermission
     * @classdesc Represents a ModuleObjectPermission.
     * @implements IModuleObjectPermission
     * @constructor
     * @param {IModuleObjectPermission=} [properties] Properties to set
     */
    function ModuleObjectPermission(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ModuleObjectPermission objectId.
     * @member {string} objectId
     * @memberof ModuleObjectPermission
     * @instance
     */
    ModuleObjectPermission.prototype.objectId = "";

    /**
     * ModuleObjectPermission groupAccessPermission.
     * @member {GroupAccessPermission} groupAccessPermission
     * @memberof ModuleObjectPermission
     * @instance
     */
    ModuleObjectPermission.prototype.groupAccessPermission = 0;

    /**
     * Creates a new ModuleObjectPermission instance using the specified properties.
     * @function create
     * @memberof ModuleObjectPermission
     * @static
     * @param {IModuleObjectPermission=} [properties] Properties to set
     * @returns {ModuleObjectPermission} ModuleObjectPermission instance
     */
    ModuleObjectPermission.create = function create(properties) {
        return new ModuleObjectPermission(properties);
    };

    /**
     * Encodes the specified ModuleObjectPermission message. Does not implicitly {@link ModuleObjectPermission.verify|verify} messages.
     * @function encode
     * @memberof ModuleObjectPermission
     * @static
     * @param {IModuleObjectPermission} message ModuleObjectPermission message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ModuleObjectPermission.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.groupAccessPermission);
        return writer;
    };

    /**
     * Encodes the specified ModuleObjectPermission message, length delimited. Does not implicitly {@link ModuleObjectPermission.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ModuleObjectPermission
     * @static
     * @param {IModuleObjectPermission} message ModuleObjectPermission message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ModuleObjectPermission.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ModuleObjectPermission message from the specified reader or buffer.
     * @function decode
     * @memberof ModuleObjectPermission
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ModuleObjectPermission} ModuleObjectPermission
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ModuleObjectPermission.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ModuleObjectPermission();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.objectId = reader.string();
                break;
            case 2:
                message.groupAccessPermission = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("objectId"))
            throw $util.ProtocolError("missing required 'objectId'", { instance: message });
        if (!message.hasOwnProperty("groupAccessPermission"))
            throw $util.ProtocolError("missing required 'groupAccessPermission'", { instance: message });
        return message;
    };

    /**
     * Decodes a ModuleObjectPermission message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ModuleObjectPermission
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ModuleObjectPermission} ModuleObjectPermission
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ModuleObjectPermission.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ModuleObjectPermission message.
     * @function verify
     * @memberof ModuleObjectPermission
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ModuleObjectPermission.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.objectId))
            return "objectId: string expected";
        switch (message.groupAccessPermission) {
        default:
            return "groupAccessPermission: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
            break;
        }
        return null;
    };

    /**
     * Creates a ModuleObjectPermission message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ModuleObjectPermission
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ModuleObjectPermission} ModuleObjectPermission
     */
    ModuleObjectPermission.fromObject = function fromObject(object) {
        if (object instanceof $root.ModuleObjectPermission)
            return object;
        let message = new $root.ModuleObjectPermission();
        if (object.objectId != null)
            message.objectId = String(object.objectId);
        switch (object.groupAccessPermission) {
        case "NONE":
        case 0:
            message.groupAccessPermission = 0;
            break;
        case "READ_ONLY":
        case 1:
            message.groupAccessPermission = 1;
            break;
        case "READ_WRITE":
        case 2:
            message.groupAccessPermission = 2;
            break;
        case "READ_WRITE_MODIFY":
        case 3:
            message.groupAccessPermission = 3;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a ModuleObjectPermission message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ModuleObjectPermission
     * @static
     * @param {ModuleObjectPermission} message ModuleObjectPermission
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ModuleObjectPermission.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.objectId = "";
            object.groupAccessPermission = options.enums === String ? "NONE" : 0;
        }
        if (message.objectId != null && message.hasOwnProperty("objectId"))
            object.objectId = message.objectId;
        if (message.groupAccessPermission != null && message.hasOwnProperty("groupAccessPermission"))
            object.groupAccessPermission = options.enums === String ? $root.GroupAccessPermission[message.groupAccessPermission] : message.groupAccessPermission;
        return object;
    };

    /**
     * Converts this ModuleObjectPermission to JSON.
     * @function toJSON
     * @memberof ModuleObjectPermission
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ModuleObjectPermission.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ModuleObjectPermission;
})();

/**
 * GroupAccessPermission enum.
 * @exports GroupAccessPermission
 * @enum {number}
 * @property {number} NONE=0 NONE value
 * @property {number} READ_ONLY=1 READ_ONLY value
 * @property {number} READ_WRITE=2 READ_WRITE value
 * @property {number} READ_WRITE_MODIFY=3 READ_WRITE_MODIFY value
 */
export const GroupAccessPermission = $root.GroupAccessPermission = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "NONE"] = 0;
    values[valuesById[1] = "READ_ONLY"] = 1;
    values[valuesById[2] = "READ_WRITE"] = 2;
    values[valuesById[3] = "READ_WRITE_MODIFY"] = 3;
    return values;
})();

export const ExchangeRate = $root.ExchangeRate = (() => {

    /**
     * Properties of an ExchangeRate.
     * @exports IExchangeRate
     * @interface IExchangeRate
     * @property {string} from ExchangeRate from
     * @property {string} to ExchangeRate to
     * @property {number|null} [rate] ExchangeRate rate
     */

    /**
     * Constructs a new ExchangeRate.
     * @exports ExchangeRate
     * @classdesc Represents an ExchangeRate.
     * @implements IExchangeRate
     * @constructor
     * @param {IExchangeRate=} [properties] Properties to set
     */
    function ExchangeRate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExchangeRate from.
     * @member {string} from
     * @memberof ExchangeRate
     * @instance
     */
    ExchangeRate.prototype.from = "";

    /**
     * ExchangeRate to.
     * @member {string} to
     * @memberof ExchangeRate
     * @instance
     */
    ExchangeRate.prototype.to = "";

    /**
     * ExchangeRate rate.
     * @member {number} rate
     * @memberof ExchangeRate
     * @instance
     */
    ExchangeRate.prototype.rate = 0;

    /**
     * Creates a new ExchangeRate instance using the specified properties.
     * @function create
     * @memberof ExchangeRate
     * @static
     * @param {IExchangeRate=} [properties] Properties to set
     * @returns {ExchangeRate} ExchangeRate instance
     */
    ExchangeRate.create = function create(properties) {
        return new ExchangeRate(properties);
    };

    /**
     * Encodes the specified ExchangeRate message. Does not implicitly {@link ExchangeRate.verify|verify} messages.
     * @function encode
     * @memberof ExchangeRate
     * @static
     * @param {IExchangeRate} message ExchangeRate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExchangeRate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.from);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
        if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.rate);
        return writer;
    };

    /**
     * Encodes the specified ExchangeRate message, length delimited. Does not implicitly {@link ExchangeRate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ExchangeRate
     * @static
     * @param {IExchangeRate} message ExchangeRate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExchangeRate.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ExchangeRate message from the specified reader or buffer.
     * @function decode
     * @memberof ExchangeRate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ExchangeRate} ExchangeRate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExchangeRate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExchangeRate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.from = reader.string();
                break;
            case 2:
                message.to = reader.string();
                break;
            case 3:
                message.rate = reader.double();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("from"))
            throw $util.ProtocolError("missing required 'from'", { instance: message });
        if (!message.hasOwnProperty("to"))
            throw $util.ProtocolError("missing required 'to'", { instance: message });
        return message;
    };

    /**
     * Decodes an ExchangeRate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ExchangeRate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ExchangeRate} ExchangeRate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExchangeRate.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ExchangeRate message.
     * @function verify
     * @memberof ExchangeRate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ExchangeRate.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.from))
            return "from: string expected";
        if (!$util.isString(message.to))
            return "to: string expected";
        if (message.rate != null && message.hasOwnProperty("rate"))
            if (typeof message.rate !== "number")
                return "rate: number expected";
        return null;
    };

    /**
     * Creates an ExchangeRate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ExchangeRate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ExchangeRate} ExchangeRate
     */
    ExchangeRate.fromObject = function fromObject(object) {
        if (object instanceof $root.ExchangeRate)
            return object;
        let message = new $root.ExchangeRate();
        if (object.from != null)
            message.from = String(object.from);
        if (object.to != null)
            message.to = String(object.to);
        if (object.rate != null)
            message.rate = Number(object.rate);
        return message;
    };

    /**
     * Creates a plain object from an ExchangeRate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ExchangeRate
     * @static
     * @param {ExchangeRate} message ExchangeRate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ExchangeRate.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.from = "";
            object.to = "";
            object.rate = 0;
        }
        if (message.from != null && message.hasOwnProperty("from"))
            object.from = message.from;
        if (message.to != null && message.hasOwnProperty("to"))
            object.to = message.to;
        if (message.rate != null && message.hasOwnProperty("rate"))
            object.rate = options.json && !isFinite(message.rate) ? String(message.rate) : message.rate;
        return object;
    };

    /**
     * Converts this ExchangeRate to JSON.
     * @function toJSON
     * @memberof ExchangeRate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ExchangeRate.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ExchangeRate;
})();

export const IntegrationRecipeParamSelectOption = $root.IntegrationRecipeParamSelectOption = (() => {

    /**
     * Properties of an IntegrationRecipeParamSelectOption.
     * @exports IIntegrationRecipeParamSelectOption
     * @interface IIntegrationRecipeParamSelectOption
     * @property {string} key IntegrationRecipeParamSelectOption key
     * @property {string} value IntegrationRecipeParamSelectOption value
     * @property {string} text IntegrationRecipeParamSelectOption text
     * @property {boolean|null} [selected] IntegrationRecipeParamSelectOption selected
     */

    /**
     * Constructs a new IntegrationRecipeParamSelectOption.
     * @exports IntegrationRecipeParamSelectOption
     * @classdesc Represents an IntegrationRecipeParamSelectOption.
     * @implements IIntegrationRecipeParamSelectOption
     * @constructor
     * @param {IIntegrationRecipeParamSelectOption=} [properties] Properties to set
     */
    function IntegrationRecipeParamSelectOption(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationRecipeParamSelectOption key.
     * @member {string} key
     * @memberof IntegrationRecipeParamSelectOption
     * @instance
     */
    IntegrationRecipeParamSelectOption.prototype.key = "";

    /**
     * IntegrationRecipeParamSelectOption value.
     * @member {string} value
     * @memberof IntegrationRecipeParamSelectOption
     * @instance
     */
    IntegrationRecipeParamSelectOption.prototype.value = "";

    /**
     * IntegrationRecipeParamSelectOption text.
     * @member {string} text
     * @memberof IntegrationRecipeParamSelectOption
     * @instance
     */
    IntegrationRecipeParamSelectOption.prototype.text = "";

    /**
     * IntegrationRecipeParamSelectOption selected.
     * @member {boolean} selected
     * @memberof IntegrationRecipeParamSelectOption
     * @instance
     */
    IntegrationRecipeParamSelectOption.prototype.selected = false;

    /**
     * Creates a new IntegrationRecipeParamSelectOption instance using the specified properties.
     * @function create
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {IIntegrationRecipeParamSelectOption=} [properties] Properties to set
     * @returns {IntegrationRecipeParamSelectOption} IntegrationRecipeParamSelectOption instance
     */
    IntegrationRecipeParamSelectOption.create = function create(properties) {
        return new IntegrationRecipeParamSelectOption(properties);
    };

    /**
     * Encodes the specified IntegrationRecipeParamSelectOption message. Does not implicitly {@link IntegrationRecipeParamSelectOption.verify|verify} messages.
     * @function encode
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {IIntegrationRecipeParamSelectOption} message IntegrationRecipeParamSelectOption message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationRecipeParamSelectOption.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.text);
        if (message.selected != null && Object.hasOwnProperty.call(message, "selected"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.selected);
        return writer;
    };

    /**
     * Encodes the specified IntegrationRecipeParamSelectOption message, length delimited. Does not implicitly {@link IntegrationRecipeParamSelectOption.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {IIntegrationRecipeParamSelectOption} message IntegrationRecipeParamSelectOption message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationRecipeParamSelectOption.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationRecipeParamSelectOption message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationRecipeParamSelectOption} IntegrationRecipeParamSelectOption
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationRecipeParamSelectOption.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationRecipeParamSelectOption();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.key = reader.string();
                break;
            case 2:
                message.value = reader.string();
                break;
            case 3:
                message.text = reader.string();
                break;
            case 4:
                message.selected = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("key"))
            throw $util.ProtocolError("missing required 'key'", { instance: message });
        if (!message.hasOwnProperty("value"))
            throw $util.ProtocolError("missing required 'value'", { instance: message });
        if (!message.hasOwnProperty("text"))
            throw $util.ProtocolError("missing required 'text'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationRecipeParamSelectOption message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationRecipeParamSelectOption} IntegrationRecipeParamSelectOption
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationRecipeParamSelectOption.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationRecipeParamSelectOption message.
     * @function verify
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationRecipeParamSelectOption.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.key))
            return "key: string expected";
        if (!$util.isString(message.value))
            return "value: string expected";
        if (!$util.isString(message.text))
            return "text: string expected";
        if (message.selected != null && message.hasOwnProperty("selected"))
            if (typeof message.selected !== "boolean")
                return "selected: boolean expected";
        return null;
    };

    /**
     * Creates an IntegrationRecipeParamSelectOption message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationRecipeParamSelectOption} IntegrationRecipeParamSelectOption
     */
    IntegrationRecipeParamSelectOption.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationRecipeParamSelectOption)
            return object;
        let message = new $root.IntegrationRecipeParamSelectOption();
        if (object.key != null)
            message.key = String(object.key);
        if (object.value != null)
            message.value = String(object.value);
        if (object.text != null)
            message.text = String(object.text);
        if (object.selected != null)
            message.selected = Boolean(object.selected);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationRecipeParamSelectOption message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationRecipeParamSelectOption
     * @static
     * @param {IntegrationRecipeParamSelectOption} message IntegrationRecipeParamSelectOption
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationRecipeParamSelectOption.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.key = "";
            object.value = "";
            object.text = "";
            object.selected = false;
        }
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.text != null && message.hasOwnProperty("text"))
            object.text = message.text;
        if (message.selected != null && message.hasOwnProperty("selected"))
            object.selected = message.selected;
        return object;
    };

    /**
     * Converts this IntegrationRecipeParamSelectOption to JSON.
     * @function toJSON
     * @memberof IntegrationRecipeParamSelectOption
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationRecipeParamSelectOption.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationRecipeParamSelectOption;
})();

export const IntegrationRecipeParam = $root.IntegrationRecipeParam = (() => {

    /**
     * Properties of an IntegrationRecipeParam.
     * @exports IIntegrationRecipeParam
     * @interface IIntegrationRecipeParam
     * @property {string} id IntegrationRecipeParam id
     * @property {IntegrationRecipeParam.InputType} type IntegrationRecipeParam type
     * @property {Array.<string>|null} [enumValues] IntegrationRecipeParam enumValues
     * @property {ModelType|null} [modelType] IntegrationRecipeParam modelType
     * @property {IntegrationRecipeParam.Scope} scope IntegrationRecipeParam scope
     * @property {string|null} [label] IntegrationRecipeParam label
     * @property {string|null} [description] IntegrationRecipeParam description
     * @property {boolean|null} [optional] IntegrationRecipeParam optional
     * @property {boolean|null} [visibleInPreview] IntegrationRecipeParam visibleInPreview
     * @property {boolean|null} [barCodeScanner] IntegrationRecipeParam barCodeScanner
     * @property {boolean|null} [notEditable] IntegrationRecipeParam notEditable
     * @property {string|null} [hint] IntegrationRecipeParam hint
     * @property {string|null} [value] IntegrationRecipeParam value
     * @property {Array.<IIntegrationRecipeParamSelectOption>|null} [selectOptions] IntegrationRecipeParam selectOptions
     * @property {number|Long|null} [expiresAt] IntegrationRecipeParam expiresAt
     * @property {string|null} [loginId] IntegrationRecipeParam loginId
     */

    /**
     * Constructs a new IntegrationRecipeParam.
     * @exports IntegrationRecipeParam
     * @classdesc Represents an IntegrationRecipeParam.
     * @implements IIntegrationRecipeParam
     * @constructor
     * @param {IIntegrationRecipeParam=} [properties] Properties to set
     */
    function IntegrationRecipeParam(properties) {
        this.enumValues = [];
        this.selectOptions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationRecipeParam id.
     * @member {string} id
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.id = "";

    /**
     * IntegrationRecipeParam type.
     * @member {IntegrationRecipeParam.InputType} type
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.type = 0;

    /**
     * IntegrationRecipeParam enumValues.
     * @member {Array.<string>} enumValues
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.enumValues = $util.emptyArray;

    /**
     * IntegrationRecipeParam modelType.
     * @member {ModelType} modelType
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.modelType = 0;

    /**
     * IntegrationRecipeParam scope.
     * @member {IntegrationRecipeParam.Scope} scope
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.scope = 0;

    /**
     * IntegrationRecipeParam label.
     * @member {string} label
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.label = "";

    /**
     * IntegrationRecipeParam description.
     * @member {string} description
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.description = "";

    /**
     * IntegrationRecipeParam optional.
     * @member {boolean} optional
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.optional = false;

    /**
     * IntegrationRecipeParam visibleInPreview.
     * @member {boolean} visibleInPreview
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.visibleInPreview = false;

    /**
     * IntegrationRecipeParam barCodeScanner.
     * @member {boolean} barCodeScanner
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.barCodeScanner = false;

    /**
     * IntegrationRecipeParam notEditable.
     * @member {boolean} notEditable
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.notEditable = false;

    /**
     * IntegrationRecipeParam hint.
     * @member {string} hint
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.hint = "";

    /**
     * IntegrationRecipeParam value.
     * @member {string} value
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.value = "";

    /**
     * IntegrationRecipeParam selectOptions.
     * @member {Array.<IIntegrationRecipeParamSelectOption>} selectOptions
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.selectOptions = $util.emptyArray;

    /**
     * IntegrationRecipeParam expiresAt.
     * @member {number|Long} expiresAt
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.expiresAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * IntegrationRecipeParam loginId.
     * @member {string} loginId
     * @memberof IntegrationRecipeParam
     * @instance
     */
    IntegrationRecipeParam.prototype.loginId = "";

    /**
     * Creates a new IntegrationRecipeParam instance using the specified properties.
     * @function create
     * @memberof IntegrationRecipeParam
     * @static
     * @param {IIntegrationRecipeParam=} [properties] Properties to set
     * @returns {IntegrationRecipeParam} IntegrationRecipeParam instance
     */
    IntegrationRecipeParam.create = function create(properties) {
        return new IntegrationRecipeParam(properties);
    };

    /**
     * Encodes the specified IntegrationRecipeParam message. Does not implicitly {@link IntegrationRecipeParam.verify|verify} messages.
     * @function encode
     * @memberof IntegrationRecipeParam
     * @static
     * @param {IIntegrationRecipeParam} message IntegrationRecipeParam message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationRecipeParam.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
        if (message.enumValues != null && message.enumValues.length)
            for (let i = 0; i < message.enumValues.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.enumValues[i]);
        if (message.modelType != null && Object.hasOwnProperty.call(message, "modelType"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.modelType);
        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.scope);
        if (message.label != null && Object.hasOwnProperty.call(message, "label"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.label);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.description);
        if (message.optional != null && Object.hasOwnProperty.call(message, "optional"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.optional);
        if (message.visibleInPreview != null && Object.hasOwnProperty.call(message, "visibleInPreview"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.visibleInPreview);
        if (message.barCodeScanner != null && Object.hasOwnProperty.call(message, "barCodeScanner"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.barCodeScanner);
        if (message.notEditable != null && Object.hasOwnProperty.call(message, "notEditable"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.notEditable);
        if (message.hint != null && Object.hasOwnProperty.call(message, "hint"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.hint);
        if (message.value != null && Object.hasOwnProperty.call(message, "value"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.value);
        if (message.selectOptions != null && message.selectOptions.length)
            for (let i = 0; i < message.selectOptions.length; ++i)
                $root.IntegrationRecipeParamSelectOption.encode(message.selectOptions[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
            writer.uint32(/* id 15, wireType 0 =*/120).int64(message.expiresAt);
        if (message.loginId != null && Object.hasOwnProperty.call(message, "loginId"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.loginId);
        return writer;
    };

    /**
     * Encodes the specified IntegrationRecipeParam message, length delimited. Does not implicitly {@link IntegrationRecipeParam.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationRecipeParam
     * @static
     * @param {IIntegrationRecipeParam} message IntegrationRecipeParam message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationRecipeParam.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationRecipeParam message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationRecipeParam
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationRecipeParam} IntegrationRecipeParam
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationRecipeParam.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationRecipeParam();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.type = reader.int32();
                break;
            case 3:
                if (!(message.enumValues && message.enumValues.length))
                    message.enumValues = [];
                message.enumValues.push(reader.string());
                break;
            case 4:
                message.modelType = reader.int32();
                break;
            case 5:
                message.scope = reader.int32();
                break;
            case 6:
                message.label = reader.string();
                break;
            case 7:
                message.description = reader.string();
                break;
            case 8:
                message.optional = reader.bool();
                break;
            case 9:
                message.visibleInPreview = reader.bool();
                break;
            case 10:
                message.barCodeScanner = reader.bool();
                break;
            case 11:
                message.notEditable = reader.bool();
                break;
            case 12:
                message.hint = reader.string();
                break;
            case 13:
                message.value = reader.string();
                break;
            case 14:
                if (!(message.selectOptions && message.selectOptions.length))
                    message.selectOptions = [];
                message.selectOptions.push($root.IntegrationRecipeParamSelectOption.decode(reader, reader.uint32()));
                break;
            case 15:
                message.expiresAt = reader.int64();
                break;
            case 16:
                message.loginId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("scope"))
            throw $util.ProtocolError("missing required 'scope'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationRecipeParam message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationRecipeParam
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationRecipeParam} IntegrationRecipeParam
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationRecipeParam.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationRecipeParam message.
     * @function verify
     * @memberof IntegrationRecipeParam
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationRecipeParam.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
            break;
        }
        if (message.enumValues != null && message.hasOwnProperty("enumValues")) {
            if (!Array.isArray(message.enumValues))
                return "enumValues: array expected";
            for (let i = 0; i < message.enumValues.length; ++i)
                if (!$util.isString(message.enumValues[i]))
                    return "enumValues: string[] expected";
        }
        if (message.modelType != null && message.hasOwnProperty("modelType"))
            switch (message.modelType) {
            default:
                return "modelType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
                break;
            }
        switch (message.scope) {
        default:
            return "scope: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
            break;
        }
        if (message.label != null && message.hasOwnProperty("label"))
            if (!$util.isString(message.label))
                return "label: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.optional != null && message.hasOwnProperty("optional"))
            if (typeof message.optional !== "boolean")
                return "optional: boolean expected";
        if (message.visibleInPreview != null && message.hasOwnProperty("visibleInPreview"))
            if (typeof message.visibleInPreview !== "boolean")
                return "visibleInPreview: boolean expected";
        if (message.barCodeScanner != null && message.hasOwnProperty("barCodeScanner"))
            if (typeof message.barCodeScanner !== "boolean")
                return "barCodeScanner: boolean expected";
        if (message.notEditable != null && message.hasOwnProperty("notEditable"))
            if (typeof message.notEditable !== "boolean")
                return "notEditable: boolean expected";
        if (message.hint != null && message.hasOwnProperty("hint"))
            if (!$util.isString(message.hint))
                return "hint: string expected";
        if (message.value != null && message.hasOwnProperty("value"))
            if (!$util.isString(message.value))
                return "value: string expected";
        if (message.selectOptions != null && message.hasOwnProperty("selectOptions")) {
            if (!Array.isArray(message.selectOptions))
                return "selectOptions: array expected";
            for (let i = 0; i < message.selectOptions.length; ++i) {
                let error = $root.IntegrationRecipeParamSelectOption.verify(message.selectOptions[i]);
                if (error)
                    return "selectOptions." + error;
            }
        }
        if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
            if (!$util.isInteger(message.expiresAt) && !(message.expiresAt && $util.isInteger(message.expiresAt.low) && $util.isInteger(message.expiresAt.high)))
                return "expiresAt: integer|Long expected";
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            if (!$util.isString(message.loginId))
                return "loginId: string expected";
        return null;
    };

    /**
     * Creates an IntegrationRecipeParam message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationRecipeParam
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationRecipeParam} IntegrationRecipeParam
     */
    IntegrationRecipeParam.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationRecipeParam)
            return object;
        let message = new $root.IntegrationRecipeParam();
        if (object.id != null)
            message.id = String(object.id);
        switch (object.type) {
        case "LIST":
        case 0:
            message.type = 0;
            break;
        case "NUMBER":
        case 1:
            message.type = 1;
            break;
        case "TEXT":
        case 2:
            message.type = 2;
            break;
        case "BOOLEAN":
        case 3:
            message.type = 3;
            break;
        case "DATE":
        case 4:
            message.type = 4;
            break;
        case "TIME":
        case 5:
            message.type = 5;
            break;
        case "DATE_TIME":
        case 6:
            message.type = 6;
            break;
        case "PASSWORD":
        case 8:
            message.type = 8;
            break;
        case "REGEXP":
        case 9:
            message.type = 9;
            break;
        case "SELECT":
        case 10:
            message.type = 10;
            break;
        case "OAUTH_REDIRECT_URL":
        case 11:
            message.type = 11;
            break;
        case "HTML":
        case 12:
            message.type = 12;
            break;
        case "MULTI_SELECT":
        case 13:
            message.type = 13;
            break;
        }
        if (object.enumValues) {
            if (!Array.isArray(object.enumValues))
                throw TypeError(".IntegrationRecipeParam.enumValues: array expected");
            message.enumValues = [];
            for (let i = 0; i < object.enumValues.length; ++i)
                message.enumValues[i] = String(object.enumValues[i]);
        }
        switch (object.modelType) {
        case "Account":
        case 0:
            message.modelType = 0;
            break;
        case "HashTag":
        case 1:
            message.modelType = 1;
            break;
        case "Category":
        case 2:
            message.modelType = 2;
            break;
        case "Currency":
        case 3:
            message.modelType = 3;
            break;
        case "Record":
        case 4:
            message.modelType = 4;
            break;
        case "Budget":
        case 5:
            message.modelType = 5;
            break;
        case "ShoppingList":
        case 6:
            message.modelType = 6;
            break;
        case "StandingOrder":
        case 7:
            message.modelType = 7;
            break;
        case "Debt":
        case 8:
            message.modelType = 8;
            break;
        case "Template":
        case 9:
            message.modelType = 9;
            break;
        case "IntegrationType":
        case 10:
            message.modelType = 10;
            break;
        case "FilterType":
        case 11:
            message.modelType = 11;
            break;
        case "NoneType":
        case 12:
            message.modelType = 12;
            break;
        case "EnvelopBudget":
        case 13:
            message.modelType = 13;
            break;
        case "LoyaltyCard":
        case 14:
            message.modelType = 14;
            break;
        case "Goal":
        case 15:
            message.modelType = 15;
            break;
        }
        switch (object.scope) {
        case "SYSTEM":
        case 0:
            message.scope = 0;
            break;
        case "CUSTOM":
        case 1:
            message.scope = 1;
            break;
        case "GENERATED":
        case 2:
            message.scope = 2;
            break;
        case "OPERATIONAL":
        case 3:
            message.scope = 3;
            break;
        }
        if (object.label != null)
            message.label = String(object.label);
        if (object.description != null)
            message.description = String(object.description);
        if (object.optional != null)
            message.optional = Boolean(object.optional);
        if (object.visibleInPreview != null)
            message.visibleInPreview = Boolean(object.visibleInPreview);
        if (object.barCodeScanner != null)
            message.barCodeScanner = Boolean(object.barCodeScanner);
        if (object.notEditable != null)
            message.notEditable = Boolean(object.notEditable);
        if (object.hint != null)
            message.hint = String(object.hint);
        if (object.value != null)
            message.value = String(object.value);
        if (object.selectOptions) {
            if (!Array.isArray(object.selectOptions))
                throw TypeError(".IntegrationRecipeParam.selectOptions: array expected");
            message.selectOptions = [];
            for (let i = 0; i < object.selectOptions.length; ++i) {
                if (typeof object.selectOptions[i] !== "object")
                    throw TypeError(".IntegrationRecipeParam.selectOptions: object expected");
                message.selectOptions[i] = $root.IntegrationRecipeParamSelectOption.fromObject(object.selectOptions[i]);
            }
        }
        if (object.expiresAt != null)
            if ($util.Long)
                (message.expiresAt = $util.Long.fromValue(object.expiresAt)).unsigned = false;
            else if (typeof object.expiresAt === "string")
                message.expiresAt = parseInt(object.expiresAt, 10);
            else if (typeof object.expiresAt === "number")
                message.expiresAt = object.expiresAt;
            else if (typeof object.expiresAt === "object")
                message.expiresAt = new $util.LongBits(object.expiresAt.low >>> 0, object.expiresAt.high >>> 0).toNumber();
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationRecipeParam message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationRecipeParam
     * @static
     * @param {IntegrationRecipeParam} message IntegrationRecipeParam
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationRecipeParam.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.enumValues = [];
            object.selectOptions = [];
        }
        if (options.defaults) {
            object.id = "";
            object.type = options.enums === String ? "LIST" : 0;
            object.modelType = options.enums === String ? "Account" : 0;
            object.scope = options.enums === String ? "SYSTEM" : 0;
            object.label = "";
            object.description = "";
            object.optional = false;
            object.visibleInPreview = false;
            object.barCodeScanner = false;
            object.notEditable = false;
            object.hint = "";
            object.value = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.expiresAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.expiresAt = options.longs === String ? "0" : 0;
            object.loginId = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.IntegrationRecipeParam.InputType[message.type] : message.type;
        if (message.enumValues && message.enumValues.length) {
            object.enumValues = [];
            for (let j = 0; j < message.enumValues.length; ++j)
                object.enumValues[j] = message.enumValues[j];
        }
        if (message.modelType != null && message.hasOwnProperty("modelType"))
            object.modelType = options.enums === String ? $root.ModelType[message.modelType] : message.modelType;
        if (message.scope != null && message.hasOwnProperty("scope"))
            object.scope = options.enums === String ? $root.IntegrationRecipeParam.Scope[message.scope] : message.scope;
        if (message.label != null && message.hasOwnProperty("label"))
            object.label = message.label;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.optional != null && message.hasOwnProperty("optional"))
            object.optional = message.optional;
        if (message.visibleInPreview != null && message.hasOwnProperty("visibleInPreview"))
            object.visibleInPreview = message.visibleInPreview;
        if (message.barCodeScanner != null && message.hasOwnProperty("barCodeScanner"))
            object.barCodeScanner = message.barCodeScanner;
        if (message.notEditable != null && message.hasOwnProperty("notEditable"))
            object.notEditable = message.notEditable;
        if (message.hint != null && message.hasOwnProperty("hint"))
            object.hint = message.hint;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.selectOptions && message.selectOptions.length) {
            object.selectOptions = [];
            for (let j = 0; j < message.selectOptions.length; ++j)
                object.selectOptions[j] = $root.IntegrationRecipeParamSelectOption.toObject(message.selectOptions[j], options);
        }
        if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
            if (typeof message.expiresAt === "number")
                object.expiresAt = options.longs === String ? String(message.expiresAt) : message.expiresAt;
            else
                object.expiresAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiresAt) : options.longs === Number ? new $util.LongBits(message.expiresAt.low >>> 0, message.expiresAt.high >>> 0).toNumber() : message.expiresAt;
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        return object;
    };

    /**
     * Converts this IntegrationRecipeParam to JSON.
     * @function toJSON
     * @memberof IntegrationRecipeParam
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationRecipeParam.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * InputType enum.
     * @name IntegrationRecipeParam.InputType
     * @enum {number}
     * @property {number} LIST=0 LIST value
     * @property {number} NUMBER=1 NUMBER value
     * @property {number} TEXT=2 TEXT value
     * @property {number} BOOLEAN=3 BOOLEAN value
     * @property {number} DATE=4 DATE value
     * @property {number} TIME=5 TIME value
     * @property {number} DATE_TIME=6 DATE_TIME value
     * @property {number} PASSWORD=8 PASSWORD value
     * @property {number} REGEXP=9 REGEXP value
     * @property {number} SELECT=10 SELECT value
     * @property {number} OAUTH_REDIRECT_URL=11 OAUTH_REDIRECT_URL value
     * @property {number} HTML=12 HTML value
     * @property {number} MULTI_SELECT=13 MULTI_SELECT value
     */
    IntegrationRecipeParam.InputType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LIST"] = 0;
        values[valuesById[1] = "NUMBER"] = 1;
        values[valuesById[2] = "TEXT"] = 2;
        values[valuesById[3] = "BOOLEAN"] = 3;
        values[valuesById[4] = "DATE"] = 4;
        values[valuesById[5] = "TIME"] = 5;
        values[valuesById[6] = "DATE_TIME"] = 6;
        values[valuesById[8] = "PASSWORD"] = 8;
        values[valuesById[9] = "REGEXP"] = 9;
        values[valuesById[10] = "SELECT"] = 10;
        values[valuesById[11] = "OAUTH_REDIRECT_URL"] = 11;
        values[valuesById[12] = "HTML"] = 12;
        values[valuesById[13] = "MULTI_SELECT"] = 13;
        return values;
    })();

    /**
     * Scope enum.
     * @name IntegrationRecipeParam.Scope
     * @enum {number}
     * @property {number} SYSTEM=0 SYSTEM value
     * @property {number} CUSTOM=1 CUSTOM value
     * @property {number} GENERATED=2 GENERATED value
     * @property {number} OPERATIONAL=3 OPERATIONAL value
     */
    IntegrationRecipeParam.Scope = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SYSTEM"] = 0;
        values[valuesById[1] = "CUSTOM"] = 1;
        values[valuesById[2] = "GENERATED"] = 2;
        values[valuesById[3] = "OPERATIONAL"] = 3;
        return values;
    })();

    return IntegrationRecipeParam;
})();

export const IntegrationRecipeGroup = $root.IntegrationRecipeGroup = (() => {

    /**
     * Properties of an IntegrationRecipeGroup.
     * @exports IIntegrationRecipeGroup
     * @interface IIntegrationRecipeGroup
     * @property {string} id IntegrationRecipeGroup id
     * @property {string|null} [title] IntegrationRecipeGroup title
     * @property {IntegrationRecipeGroup.Severity|null} [severity] IntegrationRecipeGroup severity
     * @property {Array.<IIntegrationRecipeParam>|null} [params] IntegrationRecipeGroup params
     */

    /**
     * Constructs a new IntegrationRecipeGroup.
     * @exports IntegrationRecipeGroup
     * @classdesc Represents an IntegrationRecipeGroup.
     * @implements IIntegrationRecipeGroup
     * @constructor
     * @param {IIntegrationRecipeGroup=} [properties] Properties to set
     */
    function IntegrationRecipeGroup(properties) {
        this.params = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationRecipeGroup id.
     * @member {string} id
     * @memberof IntegrationRecipeGroup
     * @instance
     */
    IntegrationRecipeGroup.prototype.id = "";

    /**
     * IntegrationRecipeGroup title.
     * @member {string} title
     * @memberof IntegrationRecipeGroup
     * @instance
     */
    IntegrationRecipeGroup.prototype.title = "";

    /**
     * IntegrationRecipeGroup severity.
     * @member {IntegrationRecipeGroup.Severity} severity
     * @memberof IntegrationRecipeGroup
     * @instance
     */
    IntegrationRecipeGroup.prototype.severity = 0;

    /**
     * IntegrationRecipeGroup params.
     * @member {Array.<IIntegrationRecipeParam>} params
     * @memberof IntegrationRecipeGroup
     * @instance
     */
    IntegrationRecipeGroup.prototype.params = $util.emptyArray;

    /**
     * Creates a new IntegrationRecipeGroup instance using the specified properties.
     * @function create
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {IIntegrationRecipeGroup=} [properties] Properties to set
     * @returns {IntegrationRecipeGroup} IntegrationRecipeGroup instance
     */
    IntegrationRecipeGroup.create = function create(properties) {
        return new IntegrationRecipeGroup(properties);
    };

    /**
     * Encodes the specified IntegrationRecipeGroup message. Does not implicitly {@link IntegrationRecipeGroup.verify|verify} messages.
     * @function encode
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {IIntegrationRecipeGroup} message IntegrationRecipeGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationRecipeGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
        if (message.severity != null && Object.hasOwnProperty.call(message, "severity"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.severity);
        if (message.params != null && message.params.length)
            for (let i = 0; i < message.params.length; ++i)
                $root.IntegrationRecipeParam.encode(message.params[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationRecipeGroup message, length delimited. Does not implicitly {@link IntegrationRecipeGroup.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {IIntegrationRecipeGroup} message IntegrationRecipeGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationRecipeGroup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationRecipeGroup message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationRecipeGroup} IntegrationRecipeGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationRecipeGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationRecipeGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.title = reader.string();
                break;
            case 3:
                message.severity = reader.int32();
                break;
            case 4:
                if (!(message.params && message.params.length))
                    message.params = [];
                message.params.push($root.IntegrationRecipeParam.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationRecipeGroup message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationRecipeGroup} IntegrationRecipeGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationRecipeGroup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationRecipeGroup message.
     * @function verify
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationRecipeGroup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (message.title != null && message.hasOwnProperty("title"))
            if (!$util.isString(message.title))
                return "title: string expected";
        if (message.severity != null && message.hasOwnProperty("severity"))
            switch (message.severity) {
            default:
                return "severity: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                break;
            }
        if (message.params != null && message.hasOwnProperty("params")) {
            if (!Array.isArray(message.params))
                return "params: array expected";
            for (let i = 0; i < message.params.length; ++i) {
                let error = $root.IntegrationRecipeParam.verify(message.params[i]);
                if (error)
                    return "params." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationRecipeGroup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationRecipeGroup} IntegrationRecipeGroup
     */
    IntegrationRecipeGroup.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationRecipeGroup)
            return object;
        let message = new $root.IntegrationRecipeGroup();
        if (object.id != null)
            message.id = String(object.id);
        if (object.title != null)
            message.title = String(object.title);
        switch (object.severity) {
        case "DEFAULT":
        case 0:
            message.severity = 0;
            break;
        case "PRIMARY":
        case 1:
            message.severity = 1;
            break;
        case "SUCCESS":
        case 2:
            message.severity = 2;
            break;
        case "INFO":
        case 3:
            message.severity = 3;
            break;
        case "WARNING":
        case 4:
            message.severity = 4;
            break;
        case "DANGER":
        case 5:
            message.severity = 5;
            break;
        }
        if (object.params) {
            if (!Array.isArray(object.params))
                throw TypeError(".IntegrationRecipeGroup.params: array expected");
            message.params = [];
            for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== "object")
                    throw TypeError(".IntegrationRecipeGroup.params: object expected");
                message.params[i] = $root.IntegrationRecipeParam.fromObject(object.params[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationRecipeGroup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationRecipeGroup
     * @static
     * @param {IntegrationRecipeGroup} message IntegrationRecipeGroup
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationRecipeGroup.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.params = [];
        if (options.defaults) {
            object.id = "";
            object.title = "";
            object.severity = options.enums === String ? "DEFAULT" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.severity != null && message.hasOwnProperty("severity"))
            object.severity = options.enums === String ? $root.IntegrationRecipeGroup.Severity[message.severity] : message.severity;
        if (message.params && message.params.length) {
            object.params = [];
            for (let j = 0; j < message.params.length; ++j)
                object.params[j] = $root.IntegrationRecipeParam.toObject(message.params[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationRecipeGroup to JSON.
     * @function toJSON
     * @memberof IntegrationRecipeGroup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationRecipeGroup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Severity enum.
     * @name IntegrationRecipeGroup.Severity
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} PRIMARY=1 PRIMARY value
     * @property {number} SUCCESS=2 SUCCESS value
     * @property {number} INFO=3 INFO value
     * @property {number} WARNING=4 WARNING value
     * @property {number} DANGER=5 DANGER value
     */
    IntegrationRecipeGroup.Severity = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "PRIMARY"] = 1;
        values[valuesById[2] = "SUCCESS"] = 2;
        values[valuesById[3] = "INFO"] = 3;
        values[valuesById[4] = "WARNING"] = 4;
        values[valuesById[5] = "DANGER"] = 5;
        return values;
    })();

    return IntegrationRecipeGroup;
})();

/**
 * IntegrationSource enum.
 * @exports IntegrationSource
 * @enum {number}
 * @property {number} BUDGETBAKERS=0 BUDGETBAKERS value
 * @property {number} SALTEDGE=1 SALTEDGE value
 * @property {number} CSAS=2 CSAS value
 * @property {number} YODLEE=3 YODLEE value
 * @property {number} SALTEDGE_PARTNERS=4 SALTEDGE_PARTNERS value
 * @property {number} YODLEE_FASTLINK=5 YODLEE_FASTLINK value
 */
export const IntegrationSource = $root.IntegrationSource = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "BUDGETBAKERS"] = 0;
    values[valuesById[1] = "SALTEDGE"] = 1;
    values[valuesById[2] = "CSAS"] = 2;
    values[valuesById[3] = "YODLEE"] = 3;
    values[valuesById[4] = "SALTEDGE_PARTNERS"] = 4;
    values[valuesById[5] = "YODLEE_FASTLINK"] = 5;
    return values;
})();

export const IntegrationError = $root.IntegrationError = (() => {

    /**
     * Properties of an IntegrationError.
     * @exports IIntegrationError
     * @interface IIntegrationError
     * @property {IntegrationError.Type} type IntegrationError type
     * @property {string} message IntegrationError message
     */

    /**
     * Constructs a new IntegrationError.
     * @exports IntegrationError
     * @classdesc Represents an IntegrationError.
     * @implements IIntegrationError
     * @constructor
     * @param {IIntegrationError=} [properties] Properties to set
     */
    function IntegrationError(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationError type.
     * @member {IntegrationError.Type} type
     * @memberof IntegrationError
     * @instance
     */
    IntegrationError.prototype.type = 0;

    /**
     * IntegrationError message.
     * @member {string} message
     * @memberof IntegrationError
     * @instance
     */
    IntegrationError.prototype.message = "";

    /**
     * Creates a new IntegrationError instance using the specified properties.
     * @function create
     * @memberof IntegrationError
     * @static
     * @param {IIntegrationError=} [properties] Properties to set
     * @returns {IntegrationError} IntegrationError instance
     */
    IntegrationError.create = function create(properties) {
        return new IntegrationError(properties);
    };

    /**
     * Encodes the specified IntegrationError message. Does not implicitly {@link IntegrationError.verify|verify} messages.
     * @function encode
     * @memberof IntegrationError
     * @static
     * @param {IIntegrationError} message IntegrationError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationError.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
        return writer;
    };

    /**
     * Encodes the specified IntegrationError message, length delimited. Does not implicitly {@link IntegrationError.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationError
     * @static
     * @param {IIntegrationError} message IntegrationError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationError.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationError message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationError} IntegrationError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationError.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationError();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.message = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("message"))
            throw $util.ProtocolError("missing required 'message'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationError message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationError} IntegrationError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationError.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationError message.
     * @function verify
     * @memberof IntegrationError
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationError.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
            break;
        }
        if (!$util.isString(message.message))
            return "message: string expected";
        return null;
    };

    /**
     * Creates an IntegrationError message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationError
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationError} IntegrationError
     */
    IntegrationError.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationError)
            return object;
        let message = new $root.IntegrationError();
        switch (object.type) {
        case "RemoteApiError":
        case 0:
            message.type = 0;
            break;
        case "SystemError":
        case 1:
            message.type = 1;
            break;
        case "DatabaseError":
        case 2:
            message.type = 2;
            break;
        case "ObjectNotFound":
        case 3:
            message.type = 3;
            break;
        case "IllegalState":
        case 4:
            message.type = 4;
            break;
        case "RefreshNotPossibleTemporary":
        case 5:
            message.type = 5;
            break;
        case "AccountAlreadyConnected":
        case 6:
            message.type = 6;
            break;
        case "AccountNotConnected":
        case 7:
            message.type = 7;
            break;
        case "ProtobufferMappingError":
        case 8:
            message.type = 8;
            break;
        case "LoginDuplicated":
        case 9:
            message.type = 9;
            break;
        case "ConsentExpired":
        case 10:
            message.type = 10;
            break;
        case "ConsentRevoked":
        case 11:
            message.type = 11;
            break;
        case "ConsentNotFound":
        case 12:
            message.type = 12;
            break;
        }
        if (object.message != null)
            message.message = String(object.message);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationError message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationError
     * @static
     * @param {IntegrationError} message IntegrationError
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationError.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "RemoteApiError" : 0;
            object.message = "";
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.IntegrationError.Type[message.type] : message.type;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        return object;
    };

    /**
     * Converts this IntegrationError to JSON.
     * @function toJSON
     * @memberof IntegrationError
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationError.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Type enum.
     * @name IntegrationError.Type
     * @enum {number}
     * @property {number} RemoteApiError=0 RemoteApiError value
     * @property {number} SystemError=1 SystemError value
     * @property {number} DatabaseError=2 DatabaseError value
     * @property {number} ObjectNotFound=3 ObjectNotFound value
     * @property {number} IllegalState=4 IllegalState value
     * @property {number} RefreshNotPossibleTemporary=5 RefreshNotPossibleTemporary value
     * @property {number} AccountAlreadyConnected=6 AccountAlreadyConnected value
     * @property {number} AccountNotConnected=7 AccountNotConnected value
     * @property {number} ProtobufferMappingError=8 ProtobufferMappingError value
     * @property {number} LoginDuplicated=9 LoginDuplicated value
     * @property {number} ConsentExpired=10 ConsentExpired value
     * @property {number} ConsentRevoked=11 ConsentRevoked value
     * @property {number} ConsentNotFound=12 ConsentNotFound value
     */
    IntegrationError.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RemoteApiError"] = 0;
        values[valuesById[1] = "SystemError"] = 1;
        values[valuesById[2] = "DatabaseError"] = 2;
        values[valuesById[3] = "ObjectNotFound"] = 3;
        values[valuesById[4] = "IllegalState"] = 4;
        values[valuesById[5] = "RefreshNotPossibleTemporary"] = 5;
        values[valuesById[6] = "AccountAlreadyConnected"] = 6;
        values[valuesById[7] = "AccountNotConnected"] = 7;
        values[valuesById[8] = "ProtobufferMappingError"] = 8;
        values[valuesById[9] = "LoginDuplicated"] = 9;
        values[valuesById[10] = "ConsentExpired"] = 10;
        values[valuesById[11] = "ConsentRevoked"] = 11;
        values[valuesById[12] = "ConsentNotFound"] = 12;
        return values;
    })();

    return IntegrationError;
})();

export const IntegrationProviderSearchRequest = $root.IntegrationProviderSearchRequest = (() => {

    /**
     * Properties of an IntegrationProviderSearchRequest.
     * @exports IIntegrationProviderSearchRequest
     * @interface IIntegrationProviderSearchRequest
     * @property {string} query IntegrationProviderSearchRequest query
     */

    /**
     * Constructs a new IntegrationProviderSearchRequest.
     * @exports IntegrationProviderSearchRequest
     * @classdesc Represents an IntegrationProviderSearchRequest.
     * @implements IIntegrationProviderSearchRequest
     * @constructor
     * @param {IIntegrationProviderSearchRequest=} [properties] Properties to set
     */
    function IntegrationProviderSearchRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProviderSearchRequest query.
     * @member {string} query
     * @memberof IntegrationProviderSearchRequest
     * @instance
     */
    IntegrationProviderSearchRequest.prototype.query = "";

    /**
     * Creates a new IntegrationProviderSearchRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {IIntegrationProviderSearchRequest=} [properties] Properties to set
     * @returns {IntegrationProviderSearchRequest} IntegrationProviderSearchRequest instance
     */
    IntegrationProviderSearchRequest.create = function create(properties) {
        return new IntegrationProviderSearchRequest(properties);
    };

    /**
     * Encodes the specified IntegrationProviderSearchRequest message. Does not implicitly {@link IntegrationProviderSearchRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {IIntegrationProviderSearchRequest} message IntegrationProviderSearchRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderSearchRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.query);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProviderSearchRequest message, length delimited. Does not implicitly {@link IntegrationProviderSearchRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {IIntegrationProviderSearchRequest} message IntegrationProviderSearchRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProviderSearchRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProviderSearchRequest} IntegrationProviderSearchRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderSearchRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProviderSearchRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.query = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("query"))
            throw $util.ProtocolError("missing required 'query'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProviderSearchRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProviderSearchRequest} IntegrationProviderSearchRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderSearchRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProviderSearchRequest message.
     * @function verify
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProviderSearchRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.query))
            return "query: string expected";
        return null;
    };

    /**
     * Creates an IntegrationProviderSearchRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProviderSearchRequest} IntegrationProviderSearchRequest
     */
    IntegrationProviderSearchRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProviderSearchRequest)
            return object;
        let message = new $root.IntegrationProviderSearchRequest();
        if (object.query != null)
            message.query = String(object.query);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProviderSearchRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProviderSearchRequest
     * @static
     * @param {IntegrationProviderSearchRequest} message IntegrationProviderSearchRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProviderSearchRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.query = "";
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        return object;
    };

    /**
     * Converts this IntegrationProviderSearchRequest to JSON.
     * @function toJSON
     * @memberof IntegrationProviderSearchRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProviderSearchRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProviderSearchRequest;
})();

export const IntegrationProvider = $root.IntegrationProvider = (() => {

    /**
     * Properties of an IntegrationProvider.
     * @exports IIntegrationProvider
     * @interface IIntegrationProvider
     * @property {string} id IntegrationProvider id
     * @property {string} name IntegrationProvider name
     * @property {string} countryCode IntegrationProvider countryCode
     * @property {IntegrationSource} integrationSource IntegrationProvider integrationSource
     * @property {string|null} [providerIcon] IntegrationProvider providerIcon
     * @property {Array.<IntegrationAccount.Type>|null} [supportedAccountTypes] IntegrationProvider supportedAccountTypes
     * @property {string|null} [logoUrl] IntegrationProvider logoUrl
     * @property {boolean|null} [isAutomatic] IntegrationProvider isAutomatic
     * @property {string|null} [code] IntegrationProvider code
     * @property {number|null} [maxTransactionHistoryInDays] IntegrationProvider maxTransactionHistoryInDays
     * @property {boolean|null} [needPremiumToConnect] IntegrationProvider needPremiumToConnect
     */

    /**
     * Constructs a new IntegrationProvider.
     * @exports IntegrationProvider
     * @classdesc Represents an IntegrationProvider.
     * @implements IIntegrationProvider
     * @constructor
     * @param {IIntegrationProvider=} [properties] Properties to set
     */
    function IntegrationProvider(properties) {
        this.supportedAccountTypes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProvider id.
     * @member {string} id
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.id = "";

    /**
     * IntegrationProvider name.
     * @member {string} name
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.name = "";

    /**
     * IntegrationProvider countryCode.
     * @member {string} countryCode
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.countryCode = "";

    /**
     * IntegrationProvider integrationSource.
     * @member {IntegrationSource} integrationSource
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.integrationSource = 0;

    /**
     * IntegrationProvider providerIcon.
     * @member {string} providerIcon
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.providerIcon = "";

    /**
     * IntegrationProvider supportedAccountTypes.
     * @member {Array.<IntegrationAccount.Type>} supportedAccountTypes
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.supportedAccountTypes = $util.emptyArray;

    /**
     * IntegrationProvider logoUrl.
     * @member {string} logoUrl
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.logoUrl = "";

    /**
     * IntegrationProvider isAutomatic.
     * @member {boolean} isAutomatic
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.isAutomatic = false;

    /**
     * IntegrationProvider code.
     * @member {string} code
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.code = "";

    /**
     * IntegrationProvider maxTransactionHistoryInDays.
     * @member {number} maxTransactionHistoryInDays
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.maxTransactionHistoryInDays = 0;

    /**
     * IntegrationProvider needPremiumToConnect.
     * @member {boolean} needPremiumToConnect
     * @memberof IntegrationProvider
     * @instance
     */
    IntegrationProvider.prototype.needPremiumToConnect = false;

    /**
     * Creates a new IntegrationProvider instance using the specified properties.
     * @function create
     * @memberof IntegrationProvider
     * @static
     * @param {IIntegrationProvider=} [properties] Properties to set
     * @returns {IntegrationProvider} IntegrationProvider instance
     */
    IntegrationProvider.create = function create(properties) {
        return new IntegrationProvider(properties);
    };

    /**
     * Encodes the specified IntegrationProvider message. Does not implicitly {@link IntegrationProvider.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProvider
     * @static
     * @param {IIntegrationProvider} message IntegrationProvider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvider.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCode);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.integrationSource);
        if (message.providerIcon != null && Object.hasOwnProperty.call(message, "providerIcon"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerIcon);
        if (message.supportedAccountTypes != null && message.supportedAccountTypes.length)
            for (let i = 0; i < message.supportedAccountTypes.length; ++i)
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.supportedAccountTypes[i]);
        if (message.logoUrl != null && Object.hasOwnProperty.call(message, "logoUrl"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.logoUrl);
        if (message.isAutomatic != null && Object.hasOwnProperty.call(message, "isAutomatic"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isAutomatic);
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.code);
        if (message.maxTransactionHistoryInDays != null && Object.hasOwnProperty.call(message, "maxTransactionHistoryInDays"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.maxTransactionHistoryInDays);
        if (message.needPremiumToConnect != null && Object.hasOwnProperty.call(message, "needPremiumToConnect"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.needPremiumToConnect);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProvider message, length delimited. Does not implicitly {@link IntegrationProvider.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProvider
     * @static
     * @param {IIntegrationProvider} message IntegrationProvider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvider.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProvider message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProvider} IntegrationProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvider.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProvider();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.countryCode = reader.string();
                break;
            case 4:
                message.integrationSource = reader.int32();
                break;
            case 5:
                message.providerIcon = reader.string();
                break;
            case 6:
                if (!(message.supportedAccountTypes && message.supportedAccountTypes.length))
                    message.supportedAccountTypes = [];
                if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2)
                        message.supportedAccountTypes.push(reader.int32());
                } else
                    message.supportedAccountTypes.push(reader.int32());
                break;
            case 7:
                message.logoUrl = reader.string();
                break;
            case 8:
                message.isAutomatic = reader.bool();
                break;
            case 9:
                message.code = reader.string();
                break;
            case 10:
                message.maxTransactionHistoryInDays = reader.int32();
                break;
            case 11:
                message.needPremiumToConnect = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("countryCode"))
            throw $util.ProtocolError("missing required 'countryCode'", { instance: message });
        if (!message.hasOwnProperty("integrationSource"))
            throw $util.ProtocolError("missing required 'integrationSource'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProvider message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProvider} IntegrationProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvider.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProvider message.
     * @function verify
     * @memberof IntegrationProvider
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProvider.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isString(message.countryCode))
            return "countryCode: string expected";
        switch (message.integrationSource) {
        default:
            return "integrationSource: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        if (message.providerIcon != null && message.hasOwnProperty("providerIcon"))
            if (!$util.isString(message.providerIcon))
                return "providerIcon: string expected";
        if (message.supportedAccountTypes != null && message.hasOwnProperty("supportedAccountTypes")) {
            if (!Array.isArray(message.supportedAccountTypes))
                return "supportedAccountTypes: array expected";
            for (let i = 0; i < message.supportedAccountTypes.length; ++i)
                switch (message.supportedAccountTypes[i]) {
                default:
                    return "supportedAccountTypes: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
        }
        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
            if (!$util.isString(message.logoUrl))
                return "logoUrl: string expected";
        if (message.isAutomatic != null && message.hasOwnProperty("isAutomatic"))
            if (typeof message.isAutomatic !== "boolean")
                return "isAutomatic: boolean expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isString(message.code))
                return "code: string expected";
        if (message.maxTransactionHistoryInDays != null && message.hasOwnProperty("maxTransactionHistoryInDays"))
            if (!$util.isInteger(message.maxTransactionHistoryInDays))
                return "maxTransactionHistoryInDays: integer expected";
        if (message.needPremiumToConnect != null && message.hasOwnProperty("needPremiumToConnect"))
            if (typeof message.needPremiumToConnect !== "boolean")
                return "needPremiumToConnect: boolean expected";
        return null;
    };

    /**
     * Creates an IntegrationProvider message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProvider
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProvider} IntegrationProvider
     */
    IntegrationProvider.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProvider)
            return object;
        let message = new $root.IntegrationProvider();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        switch (object.integrationSource) {
        case "BUDGETBAKERS":
        case 0:
            message.integrationSource = 0;
            break;
        case "SALTEDGE":
        case 1:
            message.integrationSource = 1;
            break;
        case "CSAS":
        case 2:
            message.integrationSource = 2;
            break;
        case "YODLEE":
        case 3:
            message.integrationSource = 3;
            break;
        case "SALTEDGE_PARTNERS":
        case 4:
            message.integrationSource = 4;
            break;
        case "YODLEE_FASTLINK":
        case 5:
            message.integrationSource = 5;
            break;
        }
        if (object.providerIcon != null)
            message.providerIcon = String(object.providerIcon);
        if (object.supportedAccountTypes) {
            if (!Array.isArray(object.supportedAccountTypes))
                throw TypeError(".IntegrationProvider.supportedAccountTypes: array expected");
            message.supportedAccountTypes = [];
            for (let i = 0; i < object.supportedAccountTypes.length; ++i)
                switch (object.supportedAccountTypes[i]) {
                default:
                case "Account":
                case 0:
                    message.supportedAccountTypes[i] = 0;
                    break;
                case "Bonus":
                case 1:
                    message.supportedAccountTypes[i] = 1;
                    break;
                case "Card":
                case 2:
                    message.supportedAccountTypes[i] = 2;
                    break;
                case "Checking":
                case 3:
                    message.supportedAccountTypes[i] = 3;
                    break;
                case "Credit":
                case 4:
                    message.supportedAccountTypes[i] = 4;
                    break;
                case "CreditCard":
                case 5:
                    message.supportedAccountTypes[i] = 5;
                    break;
                case "DebitCard":
                case 6:
                    message.supportedAccountTypes[i] = 6;
                    break;
                case "Ewallet":
                case 7:
                    message.supportedAccountTypes[i] = 7;
                    break;
                case "Insurance":
                case 8:
                    message.supportedAccountTypes[i] = 8;
                    break;
                case "Investment":
                case 9:
                    message.supportedAccountTypes[i] = 9;
                    break;
                case "Loan":
                case 10:
                    message.supportedAccountTypes[i] = 10;
                    break;
                case "Mortgage":
                case 11:
                    message.supportedAccountTypes[i] = 11;
                    break;
                case "Savings":
                case 12:
                    message.supportedAccountTypes[i] = 12;
                    break;
                }
        }
        if (object.logoUrl != null)
            message.logoUrl = String(object.logoUrl);
        if (object.isAutomatic != null)
            message.isAutomatic = Boolean(object.isAutomatic);
        if (object.code != null)
            message.code = String(object.code);
        if (object.maxTransactionHistoryInDays != null)
            message.maxTransactionHistoryInDays = object.maxTransactionHistoryInDays | 0;
        if (object.needPremiumToConnect != null)
            message.needPremiumToConnect = Boolean(object.needPremiumToConnect);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProvider message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProvider
     * @static
     * @param {IntegrationProvider} message IntegrationProvider
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProvider.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.supportedAccountTypes = [];
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.countryCode = "";
            object.integrationSource = options.enums === String ? "BUDGETBAKERS" : 0;
            object.providerIcon = "";
            object.logoUrl = "";
            object.isAutomatic = false;
            object.code = "";
            object.maxTransactionHistoryInDays = 0;
            object.needPremiumToConnect = false;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        if (message.integrationSource != null && message.hasOwnProperty("integrationSource"))
            object.integrationSource = options.enums === String ? $root.IntegrationSource[message.integrationSource] : message.integrationSource;
        if (message.providerIcon != null && message.hasOwnProperty("providerIcon"))
            object.providerIcon = message.providerIcon;
        if (message.supportedAccountTypes && message.supportedAccountTypes.length) {
            object.supportedAccountTypes = [];
            for (let j = 0; j < message.supportedAccountTypes.length; ++j)
                object.supportedAccountTypes[j] = options.enums === String ? $root.IntegrationAccount.Type[message.supportedAccountTypes[j]] : message.supportedAccountTypes[j];
        }
        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
            object.logoUrl = message.logoUrl;
        if (message.isAutomatic != null && message.hasOwnProperty("isAutomatic"))
            object.isAutomatic = message.isAutomatic;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.maxTransactionHistoryInDays != null && message.hasOwnProperty("maxTransactionHistoryInDays"))
            object.maxTransactionHistoryInDays = message.maxTransactionHistoryInDays;
        if (message.needPremiumToConnect != null && message.hasOwnProperty("needPremiumToConnect"))
            object.needPremiumToConnect = message.needPremiumToConnect;
        return object;
    };

    /**
     * Converts this IntegrationProvider to JSON.
     * @function toJSON
     * @memberof IntegrationProvider
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProvider.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProvider;
})();

export const IntegrationProviders = $root.IntegrationProviders = (() => {

    /**
     * Properties of an IntegrationProviders.
     * @exports IIntegrationProviders
     * @interface IIntegrationProviders
     * @property {Array.<IIntegrationProvider>|null} [providers] IntegrationProviders providers
     */

    /**
     * Constructs a new IntegrationProviders.
     * @exports IntegrationProviders
     * @classdesc Represents an IntegrationProviders.
     * @implements IIntegrationProviders
     * @constructor
     * @param {IIntegrationProviders=} [properties] Properties to set
     */
    function IntegrationProviders(properties) {
        this.providers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProviders providers.
     * @member {Array.<IIntegrationProvider>} providers
     * @memberof IntegrationProviders
     * @instance
     */
    IntegrationProviders.prototype.providers = $util.emptyArray;

    /**
     * Creates a new IntegrationProviders instance using the specified properties.
     * @function create
     * @memberof IntegrationProviders
     * @static
     * @param {IIntegrationProviders=} [properties] Properties to set
     * @returns {IntegrationProviders} IntegrationProviders instance
     */
    IntegrationProviders.create = function create(properties) {
        return new IntegrationProviders(properties);
    };

    /**
     * Encodes the specified IntegrationProviders message. Does not implicitly {@link IntegrationProviders.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProviders
     * @static
     * @param {IIntegrationProviders} message IntegrationProviders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviders.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providers != null && message.providers.length)
            for (let i = 0; i < message.providers.length; ++i)
                $root.IntegrationProvider.encode(message.providers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationProviders message, length delimited. Does not implicitly {@link IntegrationProviders.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProviders
     * @static
     * @param {IIntegrationProviders} message IntegrationProviders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviders.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProviders message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProviders} IntegrationProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProviders();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.providers && message.providers.length))
                    message.providers = [];
                message.providers.push($root.IntegrationProvider.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationProviders message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProviders} IntegrationProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviders.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProviders message.
     * @function verify
     * @memberof IntegrationProviders
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProviders.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (let i = 0; i < message.providers.length; ++i) {
                let error = $root.IntegrationProvider.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationProviders message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProviders
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProviders} IntegrationProviders
     */
    IntegrationProviders.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProviders)
            return object;
        let message = new $root.IntegrationProviders();
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".IntegrationProviders.providers: array expected");
            message.providers = [];
            for (let i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".IntegrationProviders.providers: object expected");
                message.providers[i] = $root.IntegrationProvider.fromObject(object.providers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProviders message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProviders
     * @static
     * @param {IntegrationProviders} message IntegrationProviders
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProviders.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.providers = [];
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (let j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.IntegrationProvider.toObject(message.providers[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationProviders to JSON.
     * @function toJSON
     * @memberof IntegrationProviders
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProviders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProviders;
})();

export const IntegrationProvidersByCountrySearchRequest = $root.IntegrationProvidersByCountrySearchRequest = (() => {

    /**
     * Properties of an IntegrationProvidersByCountrySearchRequest.
     * @exports IIntegrationProvidersByCountrySearchRequest
     * @interface IIntegrationProvidersByCountrySearchRequest
     * @property {string} countryCode IntegrationProvidersByCountrySearchRequest countryCode
     * @property {string} query IntegrationProvidersByCountrySearchRequest query
     */

    /**
     * Constructs a new IntegrationProvidersByCountrySearchRequest.
     * @exports IntegrationProvidersByCountrySearchRequest
     * @classdesc Represents an IntegrationProvidersByCountrySearchRequest.
     * @implements IIntegrationProvidersByCountrySearchRequest
     * @constructor
     * @param {IIntegrationProvidersByCountrySearchRequest=} [properties] Properties to set
     */
    function IntegrationProvidersByCountrySearchRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProvidersByCountrySearchRequest countryCode.
     * @member {string} countryCode
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @instance
     */
    IntegrationProvidersByCountrySearchRequest.prototype.countryCode = "";

    /**
     * IntegrationProvidersByCountrySearchRequest query.
     * @member {string} query
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @instance
     */
    IntegrationProvidersByCountrySearchRequest.prototype.query = "";

    /**
     * Creates a new IntegrationProvidersByCountrySearchRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {IIntegrationProvidersByCountrySearchRequest=} [properties] Properties to set
     * @returns {IntegrationProvidersByCountrySearchRequest} IntegrationProvidersByCountrySearchRequest instance
     */
    IntegrationProvidersByCountrySearchRequest.create = function create(properties) {
        return new IntegrationProvidersByCountrySearchRequest(properties);
    };

    /**
     * Encodes the specified IntegrationProvidersByCountrySearchRequest message. Does not implicitly {@link IntegrationProvidersByCountrySearchRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {IIntegrationProvidersByCountrySearchRequest} message IntegrationProvidersByCountrySearchRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvidersByCountrySearchRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.countryCode);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProvidersByCountrySearchRequest message, length delimited. Does not implicitly {@link IntegrationProvidersByCountrySearchRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {IIntegrationProvidersByCountrySearchRequest} message IntegrationProvidersByCountrySearchRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvidersByCountrySearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProvidersByCountrySearchRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProvidersByCountrySearchRequest} IntegrationProvidersByCountrySearchRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvidersByCountrySearchRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProvidersByCountrySearchRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.countryCode = reader.string();
                break;
            case 2:
                message.query = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("countryCode"))
            throw $util.ProtocolError("missing required 'countryCode'", { instance: message });
        if (!message.hasOwnProperty("query"))
            throw $util.ProtocolError("missing required 'query'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProvidersByCountrySearchRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProvidersByCountrySearchRequest} IntegrationProvidersByCountrySearchRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvidersByCountrySearchRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProvidersByCountrySearchRequest message.
     * @function verify
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProvidersByCountrySearchRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.countryCode))
            return "countryCode: string expected";
        if (!$util.isString(message.query))
            return "query: string expected";
        return null;
    };

    /**
     * Creates an IntegrationProvidersByCountrySearchRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProvidersByCountrySearchRequest} IntegrationProvidersByCountrySearchRequest
     */
    IntegrationProvidersByCountrySearchRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProvidersByCountrySearchRequest)
            return object;
        let message = new $root.IntegrationProvidersByCountrySearchRequest();
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        if (object.query != null)
            message.query = String(object.query);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProvidersByCountrySearchRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @static
     * @param {IntegrationProvidersByCountrySearchRequest} message IntegrationProvidersByCountrySearchRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProvidersByCountrySearchRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.countryCode = "";
            object.query = "";
        }
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        return object;
    };

    /**
     * Converts this IntegrationProvidersByCountrySearchRequest to JSON.
     * @function toJSON
     * @memberof IntegrationProvidersByCountrySearchRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProvidersByCountrySearchRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProvidersByCountrySearchRequest;
})();

export const IntegrationProvidersByCountrySearchRequestPaginated = $root.IntegrationProvidersByCountrySearchRequestPaginated = (() => {

    /**
     * Properties of an IntegrationProvidersByCountrySearchRequestPaginated.
     * @exports IIntegrationProvidersByCountrySearchRequestPaginated
     * @interface IIntegrationProvidersByCountrySearchRequestPaginated
     * @property {string} countryCode IntegrationProvidersByCountrySearchRequestPaginated countryCode
     * @property {string} query IntegrationProvidersByCountrySearchRequestPaginated query
     * @property {number} offset IntegrationProvidersByCountrySearchRequestPaginated offset
     * @property {number} size IntegrationProvidersByCountrySearchRequestPaginated size
     */

    /**
     * Constructs a new IntegrationProvidersByCountrySearchRequestPaginated.
     * @exports IntegrationProvidersByCountrySearchRequestPaginated
     * @classdesc Represents an IntegrationProvidersByCountrySearchRequestPaginated.
     * @implements IIntegrationProvidersByCountrySearchRequestPaginated
     * @constructor
     * @param {IIntegrationProvidersByCountrySearchRequestPaginated=} [properties] Properties to set
     */
    function IntegrationProvidersByCountrySearchRequestPaginated(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProvidersByCountrySearchRequestPaginated countryCode.
     * @member {string} countryCode
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @instance
     */
    IntegrationProvidersByCountrySearchRequestPaginated.prototype.countryCode = "";

    /**
     * IntegrationProvidersByCountrySearchRequestPaginated query.
     * @member {string} query
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @instance
     */
    IntegrationProvidersByCountrySearchRequestPaginated.prototype.query = "";

    /**
     * IntegrationProvidersByCountrySearchRequestPaginated offset.
     * @member {number} offset
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @instance
     */
    IntegrationProvidersByCountrySearchRequestPaginated.prototype.offset = 0;

    /**
     * IntegrationProvidersByCountrySearchRequestPaginated size.
     * @member {number} size
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @instance
     */
    IntegrationProvidersByCountrySearchRequestPaginated.prototype.size = 0;

    /**
     * Creates a new IntegrationProvidersByCountrySearchRequestPaginated instance using the specified properties.
     * @function create
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {IIntegrationProvidersByCountrySearchRequestPaginated=} [properties] Properties to set
     * @returns {IntegrationProvidersByCountrySearchRequestPaginated} IntegrationProvidersByCountrySearchRequestPaginated instance
     */
    IntegrationProvidersByCountrySearchRequestPaginated.create = function create(properties) {
        return new IntegrationProvidersByCountrySearchRequestPaginated(properties);
    };

    /**
     * Encodes the specified IntegrationProvidersByCountrySearchRequestPaginated message. Does not implicitly {@link IntegrationProvidersByCountrySearchRequestPaginated.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {IIntegrationProvidersByCountrySearchRequestPaginated} message IntegrationProvidersByCountrySearchRequestPaginated message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvidersByCountrySearchRequestPaginated.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.countryCode);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.size);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProvidersByCountrySearchRequestPaginated message, length delimited. Does not implicitly {@link IntegrationProvidersByCountrySearchRequestPaginated.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {IIntegrationProvidersByCountrySearchRequestPaginated} message IntegrationProvidersByCountrySearchRequestPaginated message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvidersByCountrySearchRequestPaginated.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProvidersByCountrySearchRequestPaginated message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProvidersByCountrySearchRequestPaginated} IntegrationProvidersByCountrySearchRequestPaginated
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvidersByCountrySearchRequestPaginated.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProvidersByCountrySearchRequestPaginated();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.countryCode = reader.string();
                break;
            case 2:
                message.query = reader.string();
                break;
            case 3:
                message.offset = reader.int32();
                break;
            case 4:
                message.size = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("countryCode"))
            throw $util.ProtocolError("missing required 'countryCode'", { instance: message });
        if (!message.hasOwnProperty("query"))
            throw $util.ProtocolError("missing required 'query'", { instance: message });
        if (!message.hasOwnProperty("offset"))
            throw $util.ProtocolError("missing required 'offset'", { instance: message });
        if (!message.hasOwnProperty("size"))
            throw $util.ProtocolError("missing required 'size'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProvidersByCountrySearchRequestPaginated message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProvidersByCountrySearchRequestPaginated} IntegrationProvidersByCountrySearchRequestPaginated
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvidersByCountrySearchRequestPaginated.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProvidersByCountrySearchRequestPaginated message.
     * @function verify
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProvidersByCountrySearchRequestPaginated.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.countryCode))
            return "countryCode: string expected";
        if (!$util.isString(message.query))
            return "query: string expected";
        if (!$util.isInteger(message.offset))
            return "offset: integer expected";
        if (!$util.isInteger(message.size))
            return "size: integer expected";
        return null;
    };

    /**
     * Creates an IntegrationProvidersByCountrySearchRequestPaginated message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProvidersByCountrySearchRequestPaginated} IntegrationProvidersByCountrySearchRequestPaginated
     */
    IntegrationProvidersByCountrySearchRequestPaginated.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProvidersByCountrySearchRequestPaginated)
            return object;
        let message = new $root.IntegrationProvidersByCountrySearchRequestPaginated();
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        if (object.query != null)
            message.query = String(object.query);
        if (object.offset != null)
            message.offset = object.offset | 0;
        if (object.size != null)
            message.size = object.size | 0;
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProvidersByCountrySearchRequestPaginated message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @static
     * @param {IntegrationProvidersByCountrySearchRequestPaginated} message IntegrationProvidersByCountrySearchRequestPaginated
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProvidersByCountrySearchRequestPaginated.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.countryCode = "";
            object.query = "";
            object.offset = 0;
            object.size = 0;
        }
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        if (message.query != null && message.hasOwnProperty("query"))
            object.query = message.query;
        if (message.offset != null && message.hasOwnProperty("offset"))
            object.offset = message.offset;
        if (message.size != null && message.hasOwnProperty("size"))
            object.size = message.size;
        return object;
    };

    /**
     * Converts this IntegrationProvidersByCountrySearchRequestPaginated to JSON.
     * @function toJSON
     * @memberof IntegrationProvidersByCountrySearchRequestPaginated
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProvidersByCountrySearchRequestPaginated.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProvidersByCountrySearchRequestPaginated;
})();

export const IntegrationProviderTO = $root.IntegrationProviderTO = (() => {

    /**
     * Properties of an IntegrationProviderTO.
     * @exports IIntegrationProviderTO
     * @interface IIntegrationProviderTO
     * @property {string} id IntegrationProviderTO id
     * @property {string} name IntegrationProviderTO name
     * @property {string} countryCode IntegrationProviderTO countryCode
     */

    /**
     * Constructs a new IntegrationProviderTO.
     * @exports IntegrationProviderTO
     * @classdesc Represents an IntegrationProviderTO.
     * @implements IIntegrationProviderTO
     * @constructor
     * @param {IIntegrationProviderTO=} [properties] Properties to set
     */
    function IntegrationProviderTO(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProviderTO id.
     * @member {string} id
     * @memberof IntegrationProviderTO
     * @instance
     */
    IntegrationProviderTO.prototype.id = "";

    /**
     * IntegrationProviderTO name.
     * @member {string} name
     * @memberof IntegrationProviderTO
     * @instance
     */
    IntegrationProviderTO.prototype.name = "";

    /**
     * IntegrationProviderTO countryCode.
     * @member {string} countryCode
     * @memberof IntegrationProviderTO
     * @instance
     */
    IntegrationProviderTO.prototype.countryCode = "";

    /**
     * Creates a new IntegrationProviderTO instance using the specified properties.
     * @function create
     * @memberof IntegrationProviderTO
     * @static
     * @param {IIntegrationProviderTO=} [properties] Properties to set
     * @returns {IntegrationProviderTO} IntegrationProviderTO instance
     */
    IntegrationProviderTO.create = function create(properties) {
        return new IntegrationProviderTO(properties);
    };

    /**
     * Encodes the specified IntegrationProviderTO message. Does not implicitly {@link IntegrationProviderTO.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProviderTO
     * @static
     * @param {IIntegrationProviderTO} message IntegrationProviderTO message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderTO.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCode);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProviderTO message, length delimited. Does not implicitly {@link IntegrationProviderTO.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProviderTO
     * @static
     * @param {IIntegrationProviderTO} message IntegrationProviderTO message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderTO.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProviderTO message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProviderTO
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProviderTO} IntegrationProviderTO
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderTO.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProviderTO();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.countryCode = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("countryCode"))
            throw $util.ProtocolError("missing required 'countryCode'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProviderTO message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProviderTO
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProviderTO} IntegrationProviderTO
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderTO.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProviderTO message.
     * @function verify
     * @memberof IntegrationProviderTO
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProviderTO.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isString(message.countryCode))
            return "countryCode: string expected";
        return null;
    };

    /**
     * Creates an IntegrationProviderTO message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProviderTO
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProviderTO} IntegrationProviderTO
     */
    IntegrationProviderTO.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProviderTO)
            return object;
        let message = new $root.IntegrationProviderTO();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProviderTO message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProviderTO
     * @static
     * @param {IntegrationProviderTO} message IntegrationProviderTO
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProviderTO.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.countryCode = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        return object;
    };

    /**
     * Converts this IntegrationProviderTO to JSON.
     * @function toJSON
     * @memberof IntegrationProviderTO
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProviderTO.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProviderTO;
})();

export const IntegrationProvidersTO = $root.IntegrationProvidersTO = (() => {

    /**
     * Properties of an IntegrationProvidersTO.
     * @exports IIntegrationProvidersTO
     * @interface IIntegrationProvidersTO
     * @property {Array.<IIntegrationProviderTO>|null} [providers] IntegrationProvidersTO providers
     */

    /**
     * Constructs a new IntegrationProvidersTO.
     * @exports IntegrationProvidersTO
     * @classdesc Represents an IntegrationProvidersTO.
     * @implements IIntegrationProvidersTO
     * @constructor
     * @param {IIntegrationProvidersTO=} [properties] Properties to set
     */
    function IntegrationProvidersTO(properties) {
        this.providers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProvidersTO providers.
     * @member {Array.<IIntegrationProviderTO>} providers
     * @memberof IntegrationProvidersTO
     * @instance
     */
    IntegrationProvidersTO.prototype.providers = $util.emptyArray;

    /**
     * Creates a new IntegrationProvidersTO instance using the specified properties.
     * @function create
     * @memberof IntegrationProvidersTO
     * @static
     * @param {IIntegrationProvidersTO=} [properties] Properties to set
     * @returns {IntegrationProvidersTO} IntegrationProvidersTO instance
     */
    IntegrationProvidersTO.create = function create(properties) {
        return new IntegrationProvidersTO(properties);
    };

    /**
     * Encodes the specified IntegrationProvidersTO message. Does not implicitly {@link IntegrationProvidersTO.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProvidersTO
     * @static
     * @param {IIntegrationProvidersTO} message IntegrationProvidersTO message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvidersTO.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providers != null && message.providers.length)
            for (let i = 0; i < message.providers.length; ++i)
                $root.IntegrationProviderTO.encode(message.providers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationProvidersTO message, length delimited. Does not implicitly {@link IntegrationProvidersTO.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProvidersTO
     * @static
     * @param {IIntegrationProvidersTO} message IntegrationProvidersTO message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProvidersTO.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProvidersTO message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProvidersTO
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProvidersTO} IntegrationProvidersTO
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvidersTO.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProvidersTO();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.providers && message.providers.length))
                    message.providers = [];
                message.providers.push($root.IntegrationProviderTO.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationProvidersTO message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProvidersTO
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProvidersTO} IntegrationProvidersTO
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProvidersTO.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProvidersTO message.
     * @function verify
     * @memberof IntegrationProvidersTO
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProvidersTO.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (let i = 0; i < message.providers.length; ++i) {
                let error = $root.IntegrationProviderTO.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationProvidersTO message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProvidersTO
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProvidersTO} IntegrationProvidersTO
     */
    IntegrationProvidersTO.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProvidersTO)
            return object;
        let message = new $root.IntegrationProvidersTO();
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".IntegrationProvidersTO.providers: array expected");
            message.providers = [];
            for (let i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".IntegrationProvidersTO.providers: object expected");
                message.providers[i] = $root.IntegrationProviderTO.fromObject(object.providers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProvidersTO message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProvidersTO
     * @static
     * @param {IntegrationProvidersTO} message IntegrationProvidersTO
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProvidersTO.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.providers = [];
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (let j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.IntegrationProviderTO.toObject(message.providers[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationProvidersTO to JSON.
     * @function toJSON
     * @memberof IntegrationProvidersTO
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProvidersTO.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProvidersTO;
})();

export const IntegrationProviderCountry = $root.IntegrationProviderCountry = (() => {

    /**
     * Properties of an IntegrationProviderCountry.
     * @exports IIntegrationProviderCountry
     * @interface IIntegrationProviderCountry
     * @property {string} code IntegrationProviderCountry code
     */

    /**
     * Constructs a new IntegrationProviderCountry.
     * @exports IntegrationProviderCountry
     * @classdesc Represents an IntegrationProviderCountry.
     * @implements IIntegrationProviderCountry
     * @constructor
     * @param {IIntegrationProviderCountry=} [properties] Properties to set
     */
    function IntegrationProviderCountry(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProviderCountry code.
     * @member {string} code
     * @memberof IntegrationProviderCountry
     * @instance
     */
    IntegrationProviderCountry.prototype.code = "";

    /**
     * Creates a new IntegrationProviderCountry instance using the specified properties.
     * @function create
     * @memberof IntegrationProviderCountry
     * @static
     * @param {IIntegrationProviderCountry=} [properties] Properties to set
     * @returns {IntegrationProviderCountry} IntegrationProviderCountry instance
     */
    IntegrationProviderCountry.create = function create(properties) {
        return new IntegrationProviderCountry(properties);
    };

    /**
     * Encodes the specified IntegrationProviderCountry message. Does not implicitly {@link IntegrationProviderCountry.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProviderCountry
     * @static
     * @param {IIntegrationProviderCountry} message IntegrationProviderCountry message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderCountry.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProviderCountry message, length delimited. Does not implicitly {@link IntegrationProviderCountry.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProviderCountry
     * @static
     * @param {IIntegrationProviderCountry} message IntegrationProviderCountry message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderCountry.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProviderCountry message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProviderCountry
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProviderCountry} IntegrationProviderCountry
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderCountry.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProviderCountry();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.code = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProviderCountry message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProviderCountry
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProviderCountry} IntegrationProviderCountry
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderCountry.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProviderCountry message.
     * @function verify
     * @memberof IntegrationProviderCountry
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProviderCountry.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.code))
            return "code: string expected";
        return null;
    };

    /**
     * Creates an IntegrationProviderCountry message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProviderCountry
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProviderCountry} IntegrationProviderCountry
     */
    IntegrationProviderCountry.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProviderCountry)
            return object;
        let message = new $root.IntegrationProviderCountry();
        if (object.code != null)
            message.code = String(object.code);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProviderCountry message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProviderCountry
     * @static
     * @param {IntegrationProviderCountry} message IntegrationProviderCountry
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProviderCountry.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = "";
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        return object;
    };

    /**
     * Converts this IntegrationProviderCountry to JSON.
     * @function toJSON
     * @memberof IntegrationProviderCountry
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProviderCountry.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProviderCountry;
})();

export const IntegrationProviderCountries = $root.IntegrationProviderCountries = (() => {

    /**
     * Properties of an IntegrationProviderCountries.
     * @exports IIntegrationProviderCountries
     * @interface IIntegrationProviderCountries
     * @property {Array.<IIntegrationProviderCountry>|null} [countries] IntegrationProviderCountries countries
     */

    /**
     * Constructs a new IntegrationProviderCountries.
     * @exports IntegrationProviderCountries
     * @classdesc Represents an IntegrationProviderCountries.
     * @implements IIntegrationProviderCountries
     * @constructor
     * @param {IIntegrationProviderCountries=} [properties] Properties to set
     */
    function IntegrationProviderCountries(properties) {
        this.countries = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProviderCountries countries.
     * @member {Array.<IIntegrationProviderCountry>} countries
     * @memberof IntegrationProviderCountries
     * @instance
     */
    IntegrationProviderCountries.prototype.countries = $util.emptyArray;

    /**
     * Creates a new IntegrationProviderCountries instance using the specified properties.
     * @function create
     * @memberof IntegrationProviderCountries
     * @static
     * @param {IIntegrationProviderCountries=} [properties] Properties to set
     * @returns {IntegrationProviderCountries} IntegrationProviderCountries instance
     */
    IntegrationProviderCountries.create = function create(properties) {
        return new IntegrationProviderCountries(properties);
    };

    /**
     * Encodes the specified IntegrationProviderCountries message. Does not implicitly {@link IntegrationProviderCountries.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProviderCountries
     * @static
     * @param {IIntegrationProviderCountries} message IntegrationProviderCountries message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderCountries.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.countries != null && message.countries.length)
            for (let i = 0; i < message.countries.length; ++i)
                $root.IntegrationProviderCountry.encode(message.countries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationProviderCountries message, length delimited. Does not implicitly {@link IntegrationProviderCountries.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProviderCountries
     * @static
     * @param {IIntegrationProviderCountries} message IntegrationProviderCountries message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderCountries.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProviderCountries message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProviderCountries
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProviderCountries} IntegrationProviderCountries
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderCountries.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProviderCountries();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.countries && message.countries.length))
                    message.countries = [];
                message.countries.push($root.IntegrationProviderCountry.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationProviderCountries message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProviderCountries
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProviderCountries} IntegrationProviderCountries
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderCountries.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProviderCountries message.
     * @function verify
     * @memberof IntegrationProviderCountries
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProviderCountries.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.countries != null && message.hasOwnProperty("countries")) {
            if (!Array.isArray(message.countries))
                return "countries: array expected";
            for (let i = 0; i < message.countries.length; ++i) {
                let error = $root.IntegrationProviderCountry.verify(message.countries[i]);
                if (error)
                    return "countries." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationProviderCountries message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProviderCountries
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProviderCountries} IntegrationProviderCountries
     */
    IntegrationProviderCountries.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProviderCountries)
            return object;
        let message = new $root.IntegrationProviderCountries();
        if (object.countries) {
            if (!Array.isArray(object.countries))
                throw TypeError(".IntegrationProviderCountries.countries: array expected");
            message.countries = [];
            for (let i = 0; i < object.countries.length; ++i) {
                if (typeof object.countries[i] !== "object")
                    throw TypeError(".IntegrationProviderCountries.countries: object expected");
                message.countries[i] = $root.IntegrationProviderCountry.fromObject(object.countries[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProviderCountries message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProviderCountries
     * @static
     * @param {IntegrationProviderCountries} message IntegrationProviderCountries
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProviderCountries.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.countries = [];
        if (message.countries && message.countries.length) {
            object.countries = [];
            for (let j = 0; j < message.countries.length; ++j)
                object.countries[j] = $root.IntegrationProviderCountry.toObject(message.countries[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationProviderCountries to JSON.
     * @function toJSON
     * @memberof IntegrationProviderCountries
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProviderCountries.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProviderCountries;
})();

export const IntegrationProviderDetail = $root.IntegrationProviderDetail = (() => {

    /**
     * Properties of an IntegrationProviderDetail.
     * @exports IIntegrationProviderDetail
     * @interface IIntegrationProviderDetail
     * @property {string} id IntegrationProviderDetail id
     * @property {string} name IntegrationProviderDetail name
     * @property {string} countryCode IntegrationProviderDetail countryCode
     * @property {IntegrationSource} integrationSource IntegrationProviderDetail integrationSource
     * @property {string|null} [instruction] IntegrationProviderDetail instruction
     * @property {Array.<IIntegrationRecipeGroup>|null} [formAttributeGroups] IntegrationProviderDetail formAttributeGroups
     * @property {string|null} [providerIcon] IntegrationProviderDetail providerIcon
     * @property {Array.<IntegrationAccount.Type>|null} [supportedAccountTypes] IntegrationProviderDetail supportedAccountTypes
     * @property {string|null} [logoUrl] IntegrationProviderDetail logoUrl
     * @property {string|null} [code] IntegrationProviderDetail code
     * @property {number|null} [maxTransactionHistoryInDays] IntegrationProviderDetail maxTransactionHistoryInDays
     * @property {boolean|null} [needPremiumToConnect] IntegrationProviderDetail needPremiumToConnect
     * @property {boolean|null} [openInExternalBrowser] IntegrationProviderDetail openInExternalBrowser
     */

    /**
     * Constructs a new IntegrationProviderDetail.
     * @exports IntegrationProviderDetail
     * @classdesc Represents an IntegrationProviderDetail.
     * @implements IIntegrationProviderDetail
     * @constructor
     * @param {IIntegrationProviderDetail=} [properties] Properties to set
     */
    function IntegrationProviderDetail(properties) {
        this.formAttributeGroups = [];
        this.supportedAccountTypes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationProviderDetail id.
     * @member {string} id
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.id = "";

    /**
     * IntegrationProviderDetail name.
     * @member {string} name
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.name = "";

    /**
     * IntegrationProviderDetail countryCode.
     * @member {string} countryCode
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.countryCode = "";

    /**
     * IntegrationProviderDetail integrationSource.
     * @member {IntegrationSource} integrationSource
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.integrationSource = 0;

    /**
     * IntegrationProviderDetail instruction.
     * @member {string} instruction
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.instruction = "";

    /**
     * IntegrationProviderDetail formAttributeGroups.
     * @member {Array.<IIntegrationRecipeGroup>} formAttributeGroups
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.formAttributeGroups = $util.emptyArray;

    /**
     * IntegrationProviderDetail providerIcon.
     * @member {string} providerIcon
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.providerIcon = "";

    /**
     * IntegrationProviderDetail supportedAccountTypes.
     * @member {Array.<IntegrationAccount.Type>} supportedAccountTypes
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.supportedAccountTypes = $util.emptyArray;

    /**
     * IntegrationProviderDetail logoUrl.
     * @member {string} logoUrl
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.logoUrl = "";

    /**
     * IntegrationProviderDetail code.
     * @member {string} code
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.code = "";

    /**
     * IntegrationProviderDetail maxTransactionHistoryInDays.
     * @member {number} maxTransactionHistoryInDays
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.maxTransactionHistoryInDays = 0;

    /**
     * IntegrationProviderDetail needPremiumToConnect.
     * @member {boolean} needPremiumToConnect
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.needPremiumToConnect = false;

    /**
     * IntegrationProviderDetail openInExternalBrowser.
     * @member {boolean} openInExternalBrowser
     * @memberof IntegrationProviderDetail
     * @instance
     */
    IntegrationProviderDetail.prototype.openInExternalBrowser = false;

    /**
     * Creates a new IntegrationProviderDetail instance using the specified properties.
     * @function create
     * @memberof IntegrationProviderDetail
     * @static
     * @param {IIntegrationProviderDetail=} [properties] Properties to set
     * @returns {IntegrationProviderDetail} IntegrationProviderDetail instance
     */
    IntegrationProviderDetail.create = function create(properties) {
        return new IntegrationProviderDetail(properties);
    };

    /**
     * Encodes the specified IntegrationProviderDetail message. Does not implicitly {@link IntegrationProviderDetail.verify|verify} messages.
     * @function encode
     * @memberof IntegrationProviderDetail
     * @static
     * @param {IIntegrationProviderDetail} message IntegrationProviderDetail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderDetail.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCode);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.integrationSource);
        if (message.instruction != null && Object.hasOwnProperty.call(message, "instruction"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.instruction);
        if (message.formAttributeGroups != null && message.formAttributeGroups.length)
            for (let i = 0; i < message.formAttributeGroups.length; ++i)
                $root.IntegrationRecipeGroup.encode(message.formAttributeGroups[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.providerIcon != null && Object.hasOwnProperty.call(message, "providerIcon"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.providerIcon);
        if (message.supportedAccountTypes != null && message.supportedAccountTypes.length)
            for (let i = 0; i < message.supportedAccountTypes.length; ++i)
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.supportedAccountTypes[i]);
        if (message.logoUrl != null && Object.hasOwnProperty.call(message, "logoUrl"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.logoUrl);
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.code);
        if (message.maxTransactionHistoryInDays != null && Object.hasOwnProperty.call(message, "maxTransactionHistoryInDays"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.maxTransactionHistoryInDays);
        if (message.needPremiumToConnect != null && Object.hasOwnProperty.call(message, "needPremiumToConnect"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.needPremiumToConnect);
        if (message.openInExternalBrowser != null && Object.hasOwnProperty.call(message, "openInExternalBrowser"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.openInExternalBrowser);
        return writer;
    };

    /**
     * Encodes the specified IntegrationProviderDetail message, length delimited. Does not implicitly {@link IntegrationProviderDetail.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationProviderDetail
     * @static
     * @param {IIntegrationProviderDetail} message IntegrationProviderDetail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationProviderDetail.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationProviderDetail message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationProviderDetail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationProviderDetail} IntegrationProviderDetail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderDetail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationProviderDetail();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.countryCode = reader.string();
                break;
            case 4:
                message.integrationSource = reader.int32();
                break;
            case 5:
                message.instruction = reader.string();
                break;
            case 6:
                if (!(message.formAttributeGroups && message.formAttributeGroups.length))
                    message.formAttributeGroups = [];
                message.formAttributeGroups.push($root.IntegrationRecipeGroup.decode(reader, reader.uint32()));
                break;
            case 7:
                message.providerIcon = reader.string();
                break;
            case 8:
                if (!(message.supportedAccountTypes && message.supportedAccountTypes.length))
                    message.supportedAccountTypes = [];
                if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2)
                        message.supportedAccountTypes.push(reader.int32());
                } else
                    message.supportedAccountTypes.push(reader.int32());
                break;
            case 9:
                message.logoUrl = reader.string();
                break;
            case 10:
                message.code = reader.string();
                break;
            case 11:
                message.maxTransactionHistoryInDays = reader.int32();
                break;
            case 12:
                message.needPremiumToConnect = reader.bool();
                break;
            case 13:
                message.openInExternalBrowser = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("countryCode"))
            throw $util.ProtocolError("missing required 'countryCode'", { instance: message });
        if (!message.hasOwnProperty("integrationSource"))
            throw $util.ProtocolError("missing required 'integrationSource'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationProviderDetail message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationProviderDetail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationProviderDetail} IntegrationProviderDetail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationProviderDetail.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationProviderDetail message.
     * @function verify
     * @memberof IntegrationProviderDetail
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationProviderDetail.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isString(message.countryCode))
            return "countryCode: string expected";
        switch (message.integrationSource) {
        default:
            return "integrationSource: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        if (message.instruction != null && message.hasOwnProperty("instruction"))
            if (!$util.isString(message.instruction))
                return "instruction: string expected";
        if (message.formAttributeGroups != null && message.hasOwnProperty("formAttributeGroups")) {
            if (!Array.isArray(message.formAttributeGroups))
                return "formAttributeGroups: array expected";
            for (let i = 0; i < message.formAttributeGroups.length; ++i) {
                let error = $root.IntegrationRecipeGroup.verify(message.formAttributeGroups[i]);
                if (error)
                    return "formAttributeGroups." + error;
            }
        }
        if (message.providerIcon != null && message.hasOwnProperty("providerIcon"))
            if (!$util.isString(message.providerIcon))
                return "providerIcon: string expected";
        if (message.supportedAccountTypes != null && message.hasOwnProperty("supportedAccountTypes")) {
            if (!Array.isArray(message.supportedAccountTypes))
                return "supportedAccountTypes: array expected";
            for (let i = 0; i < message.supportedAccountTypes.length; ++i)
                switch (message.supportedAccountTypes[i]) {
                default:
                    return "supportedAccountTypes: enum value[] expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                    break;
                }
        }
        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
            if (!$util.isString(message.logoUrl))
                return "logoUrl: string expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isString(message.code))
                return "code: string expected";
        if (message.maxTransactionHistoryInDays != null && message.hasOwnProperty("maxTransactionHistoryInDays"))
            if (!$util.isInteger(message.maxTransactionHistoryInDays))
                return "maxTransactionHistoryInDays: integer expected";
        if (message.needPremiumToConnect != null && message.hasOwnProperty("needPremiumToConnect"))
            if (typeof message.needPremiumToConnect !== "boolean")
                return "needPremiumToConnect: boolean expected";
        if (message.openInExternalBrowser != null && message.hasOwnProperty("openInExternalBrowser"))
            if (typeof message.openInExternalBrowser !== "boolean")
                return "openInExternalBrowser: boolean expected";
        return null;
    };

    /**
     * Creates an IntegrationProviderDetail message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationProviderDetail
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationProviderDetail} IntegrationProviderDetail
     */
    IntegrationProviderDetail.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationProviderDetail)
            return object;
        let message = new $root.IntegrationProviderDetail();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        switch (object.integrationSource) {
        case "BUDGETBAKERS":
        case 0:
            message.integrationSource = 0;
            break;
        case "SALTEDGE":
        case 1:
            message.integrationSource = 1;
            break;
        case "CSAS":
        case 2:
            message.integrationSource = 2;
            break;
        case "YODLEE":
        case 3:
            message.integrationSource = 3;
            break;
        case "SALTEDGE_PARTNERS":
        case 4:
            message.integrationSource = 4;
            break;
        case "YODLEE_FASTLINK":
        case 5:
            message.integrationSource = 5;
            break;
        }
        if (object.instruction != null)
            message.instruction = String(object.instruction);
        if (object.formAttributeGroups) {
            if (!Array.isArray(object.formAttributeGroups))
                throw TypeError(".IntegrationProviderDetail.formAttributeGroups: array expected");
            message.formAttributeGroups = [];
            for (let i = 0; i < object.formAttributeGroups.length; ++i) {
                if (typeof object.formAttributeGroups[i] !== "object")
                    throw TypeError(".IntegrationProviderDetail.formAttributeGroups: object expected");
                message.formAttributeGroups[i] = $root.IntegrationRecipeGroup.fromObject(object.formAttributeGroups[i]);
            }
        }
        if (object.providerIcon != null)
            message.providerIcon = String(object.providerIcon);
        if (object.supportedAccountTypes) {
            if (!Array.isArray(object.supportedAccountTypes))
                throw TypeError(".IntegrationProviderDetail.supportedAccountTypes: array expected");
            message.supportedAccountTypes = [];
            for (let i = 0; i < object.supportedAccountTypes.length; ++i)
                switch (object.supportedAccountTypes[i]) {
                default:
                case "Account":
                case 0:
                    message.supportedAccountTypes[i] = 0;
                    break;
                case "Bonus":
                case 1:
                    message.supportedAccountTypes[i] = 1;
                    break;
                case "Card":
                case 2:
                    message.supportedAccountTypes[i] = 2;
                    break;
                case "Checking":
                case 3:
                    message.supportedAccountTypes[i] = 3;
                    break;
                case "Credit":
                case 4:
                    message.supportedAccountTypes[i] = 4;
                    break;
                case "CreditCard":
                case 5:
                    message.supportedAccountTypes[i] = 5;
                    break;
                case "DebitCard":
                case 6:
                    message.supportedAccountTypes[i] = 6;
                    break;
                case "Ewallet":
                case 7:
                    message.supportedAccountTypes[i] = 7;
                    break;
                case "Insurance":
                case 8:
                    message.supportedAccountTypes[i] = 8;
                    break;
                case "Investment":
                case 9:
                    message.supportedAccountTypes[i] = 9;
                    break;
                case "Loan":
                case 10:
                    message.supportedAccountTypes[i] = 10;
                    break;
                case "Mortgage":
                case 11:
                    message.supportedAccountTypes[i] = 11;
                    break;
                case "Savings":
                case 12:
                    message.supportedAccountTypes[i] = 12;
                    break;
                }
        }
        if (object.logoUrl != null)
            message.logoUrl = String(object.logoUrl);
        if (object.code != null)
            message.code = String(object.code);
        if (object.maxTransactionHistoryInDays != null)
            message.maxTransactionHistoryInDays = object.maxTransactionHistoryInDays | 0;
        if (object.needPremiumToConnect != null)
            message.needPremiumToConnect = Boolean(object.needPremiumToConnect);
        if (object.openInExternalBrowser != null)
            message.openInExternalBrowser = Boolean(object.openInExternalBrowser);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationProviderDetail message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationProviderDetail
     * @static
     * @param {IntegrationProviderDetail} message IntegrationProviderDetail
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationProviderDetail.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.formAttributeGroups = [];
            object.supportedAccountTypes = [];
        }
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.countryCode = "";
            object.integrationSource = options.enums === String ? "BUDGETBAKERS" : 0;
            object.instruction = "";
            object.providerIcon = "";
            object.logoUrl = "";
            object.code = "";
            object.maxTransactionHistoryInDays = 0;
            object.needPremiumToConnect = false;
            object.openInExternalBrowser = false;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        if (message.integrationSource != null && message.hasOwnProperty("integrationSource"))
            object.integrationSource = options.enums === String ? $root.IntegrationSource[message.integrationSource] : message.integrationSource;
        if (message.instruction != null && message.hasOwnProperty("instruction"))
            object.instruction = message.instruction;
        if (message.formAttributeGroups && message.formAttributeGroups.length) {
            object.formAttributeGroups = [];
            for (let j = 0; j < message.formAttributeGroups.length; ++j)
                object.formAttributeGroups[j] = $root.IntegrationRecipeGroup.toObject(message.formAttributeGroups[j], options);
        }
        if (message.providerIcon != null && message.hasOwnProperty("providerIcon"))
            object.providerIcon = message.providerIcon;
        if (message.supportedAccountTypes && message.supportedAccountTypes.length) {
            object.supportedAccountTypes = [];
            for (let j = 0; j < message.supportedAccountTypes.length; ++j)
                object.supportedAccountTypes[j] = options.enums === String ? $root.IntegrationAccount.Type[message.supportedAccountTypes[j]] : message.supportedAccountTypes[j];
        }
        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
            object.logoUrl = message.logoUrl;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.maxTransactionHistoryInDays != null && message.hasOwnProperty("maxTransactionHistoryInDays"))
            object.maxTransactionHistoryInDays = message.maxTransactionHistoryInDays;
        if (message.needPremiumToConnect != null && message.hasOwnProperty("needPremiumToConnect"))
            object.needPremiumToConnect = message.needPremiumToConnect;
        if (message.openInExternalBrowser != null && message.hasOwnProperty("openInExternalBrowser"))
            object.openInExternalBrowser = message.openInExternalBrowser;
        return object;
    };

    /**
     * Converts this IntegrationProviderDetail to JSON.
     * @function toJSON
     * @memberof IntegrationProviderDetail
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationProviderDetail.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationProviderDetail;
})();

export const IntegrationFormValues = $root.IntegrationFormValues = (() => {

    /**
     * Properties of an IntegrationFormValues.
     * @exports IIntegrationFormValues
     * @interface IIntegrationFormValues
     * @property {string} name IntegrationFormValues name
     * @property {string} value IntegrationFormValues value
     * @property {string|null} [description] IntegrationFormValues description
     */

    /**
     * Constructs a new IntegrationFormValues.
     * @exports IntegrationFormValues
     * @classdesc Represents an IntegrationFormValues.
     * @implements IIntegrationFormValues
     * @constructor
     * @param {IIntegrationFormValues=} [properties] Properties to set
     */
    function IntegrationFormValues(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationFormValues name.
     * @member {string} name
     * @memberof IntegrationFormValues
     * @instance
     */
    IntegrationFormValues.prototype.name = "";

    /**
     * IntegrationFormValues value.
     * @member {string} value
     * @memberof IntegrationFormValues
     * @instance
     */
    IntegrationFormValues.prototype.value = "";

    /**
     * IntegrationFormValues description.
     * @member {string} description
     * @memberof IntegrationFormValues
     * @instance
     */
    IntegrationFormValues.prototype.description = "";

    /**
     * Creates a new IntegrationFormValues instance using the specified properties.
     * @function create
     * @memberof IntegrationFormValues
     * @static
     * @param {IIntegrationFormValues=} [properties] Properties to set
     * @returns {IntegrationFormValues} IntegrationFormValues instance
     */
    IntegrationFormValues.create = function create(properties) {
        return new IntegrationFormValues(properties);
    };

    /**
     * Encodes the specified IntegrationFormValues message. Does not implicitly {@link IntegrationFormValues.verify|verify} messages.
     * @function encode
     * @memberof IntegrationFormValues
     * @static
     * @param {IIntegrationFormValues} message IntegrationFormValues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationFormValues.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        return writer;
    };

    /**
     * Encodes the specified IntegrationFormValues message, length delimited. Does not implicitly {@link IntegrationFormValues.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationFormValues
     * @static
     * @param {IIntegrationFormValues} message IntegrationFormValues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationFormValues.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationFormValues message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationFormValues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationFormValues} IntegrationFormValues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationFormValues.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationFormValues();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.name = reader.string();
                break;
            case 2:
                message.value = reader.string();
                break;
            case 3:
                message.description = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("value"))
            throw $util.ProtocolError("missing required 'value'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationFormValues message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationFormValues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationFormValues} IntegrationFormValues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationFormValues.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationFormValues message.
     * @function verify
     * @memberof IntegrationFormValues
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationFormValues.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isString(message.value))
            return "value: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        return null;
    };

    /**
     * Creates an IntegrationFormValues message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationFormValues
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationFormValues} IntegrationFormValues
     */
    IntegrationFormValues.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationFormValues)
            return object;
        let message = new $root.IntegrationFormValues();
        if (object.name != null)
            message.name = String(object.name);
        if (object.value != null)
            message.value = String(object.value);
        if (object.description != null)
            message.description = String(object.description);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationFormValues message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationFormValues
     * @static
     * @param {IntegrationFormValues} message IntegrationFormValues
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationFormValues.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            object.value = "";
            object.description = "";
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        return object;
    };

    /**
     * Converts this IntegrationFormValues to JSON.
     * @function toJSON
     * @memberof IntegrationFormValues
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationFormValues.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationFormValues;
})();

export const IntegrationConnectedProviders = $root.IntegrationConnectedProviders = (() => {

    /**
     * Properties of an IntegrationConnectedProviders.
     * @exports IIntegrationConnectedProviders
     * @interface IIntegrationConnectedProviders
     * @property {Array.<IIntegrationConnectedProvider>|null} [providers] IntegrationConnectedProviders providers
     */

    /**
     * Constructs a new IntegrationConnectedProviders.
     * @exports IntegrationConnectedProviders
     * @classdesc Represents an IntegrationConnectedProviders.
     * @implements IIntegrationConnectedProviders
     * @constructor
     * @param {IIntegrationConnectedProviders=} [properties] Properties to set
     */
    function IntegrationConnectedProviders(properties) {
        this.providers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationConnectedProviders providers.
     * @member {Array.<IIntegrationConnectedProvider>} providers
     * @memberof IntegrationConnectedProviders
     * @instance
     */
    IntegrationConnectedProviders.prototype.providers = $util.emptyArray;

    /**
     * Creates a new IntegrationConnectedProviders instance using the specified properties.
     * @function create
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {IIntegrationConnectedProviders=} [properties] Properties to set
     * @returns {IntegrationConnectedProviders} IntegrationConnectedProviders instance
     */
    IntegrationConnectedProviders.create = function create(properties) {
        return new IntegrationConnectedProviders(properties);
    };

    /**
     * Encodes the specified IntegrationConnectedProviders message. Does not implicitly {@link IntegrationConnectedProviders.verify|verify} messages.
     * @function encode
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {IIntegrationConnectedProviders} message IntegrationConnectedProviders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConnectedProviders.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providers != null && message.providers.length)
            for (let i = 0; i < message.providers.length; ++i)
                $root.IntegrationConnectedProvider.encode(message.providers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationConnectedProviders message, length delimited. Does not implicitly {@link IntegrationConnectedProviders.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {IIntegrationConnectedProviders} message IntegrationConnectedProviders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConnectedProviders.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationConnectedProviders message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationConnectedProviders} IntegrationConnectedProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConnectedProviders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationConnectedProviders();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.providers && message.providers.length))
                    message.providers = [];
                message.providers.push($root.IntegrationConnectedProvider.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationConnectedProviders message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationConnectedProviders} IntegrationConnectedProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConnectedProviders.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationConnectedProviders message.
     * @function verify
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationConnectedProviders.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (let i = 0; i < message.providers.length; ++i) {
                let error = $root.IntegrationConnectedProvider.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationConnectedProviders message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationConnectedProviders} IntegrationConnectedProviders
     */
    IntegrationConnectedProviders.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationConnectedProviders)
            return object;
        let message = new $root.IntegrationConnectedProviders();
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".IntegrationConnectedProviders.providers: array expected");
            message.providers = [];
            for (let i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".IntegrationConnectedProviders.providers: object expected");
                message.providers[i] = $root.IntegrationConnectedProvider.fromObject(object.providers[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationConnectedProviders message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationConnectedProviders
     * @static
     * @param {IntegrationConnectedProviders} message IntegrationConnectedProviders
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationConnectedProviders.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.providers = [];
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (let j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.IntegrationConnectedProvider.toObject(message.providers[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationConnectedProviders to JSON.
     * @function toJSON
     * @memberof IntegrationConnectedProviders
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationConnectedProviders.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationConnectedProviders;
})();

export const IntegrationConnectedProvider = $root.IntegrationConnectedProvider = (() => {

    /**
     * Properties of an IntegrationConnectedProvider.
     * @exports IIntegrationConnectedProvider
     * @interface IIntegrationConnectedProvider
     * @property {string} id IntegrationConnectedProvider id
     * @property {string} name IntegrationConnectedProvider name
     * @property {string} countryCode IntegrationConnectedProvider countryCode
     * @property {IntegrationSource} integrationSource IntegrationConnectedProvider integrationSource
     * @property {string} loginId IntegrationConnectedProvider loginId
     * @property {IntegrationConnectedProvider.ConnectedProviderStatus} loginStatus IntegrationConnectedProvider loginStatus
     * @property {string|null} [providerIcon] IntegrationConnectedProvider providerIcon
     * @property {string|null} [logoUrl] IntegrationConnectedProvider logoUrl
     * @property {string|null} [code] IntegrationConnectedProvider code
     * @property {number|null} [maxTransactionHistoryInDays] IntegrationConnectedProvider maxTransactionHistoryInDays
     */

    /**
     * Constructs a new IntegrationConnectedProvider.
     * @exports IntegrationConnectedProvider
     * @classdesc Represents an IntegrationConnectedProvider.
     * @implements IIntegrationConnectedProvider
     * @constructor
     * @param {IIntegrationConnectedProvider=} [properties] Properties to set
     */
    function IntegrationConnectedProvider(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationConnectedProvider id.
     * @member {string} id
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.id = "";

    /**
     * IntegrationConnectedProvider name.
     * @member {string} name
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.name = "";

    /**
     * IntegrationConnectedProvider countryCode.
     * @member {string} countryCode
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.countryCode = "";

    /**
     * IntegrationConnectedProvider integrationSource.
     * @member {IntegrationSource} integrationSource
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.integrationSource = 0;

    /**
     * IntegrationConnectedProvider loginId.
     * @member {string} loginId
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.loginId = "";

    /**
     * IntegrationConnectedProvider loginStatus.
     * @member {IntegrationConnectedProvider.ConnectedProviderStatus} loginStatus
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.loginStatus = 0;

    /**
     * IntegrationConnectedProvider providerIcon.
     * @member {string} providerIcon
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.providerIcon = "";

    /**
     * IntegrationConnectedProvider logoUrl.
     * @member {string} logoUrl
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.logoUrl = "";

    /**
     * IntegrationConnectedProvider code.
     * @member {string} code
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.code = "";

    /**
     * IntegrationConnectedProvider maxTransactionHistoryInDays.
     * @member {number} maxTransactionHistoryInDays
     * @memberof IntegrationConnectedProvider
     * @instance
     */
    IntegrationConnectedProvider.prototype.maxTransactionHistoryInDays = 0;

    /**
     * Creates a new IntegrationConnectedProvider instance using the specified properties.
     * @function create
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {IIntegrationConnectedProvider=} [properties] Properties to set
     * @returns {IntegrationConnectedProvider} IntegrationConnectedProvider instance
     */
    IntegrationConnectedProvider.create = function create(properties) {
        return new IntegrationConnectedProvider(properties);
    };

    /**
     * Encodes the specified IntegrationConnectedProvider message. Does not implicitly {@link IntegrationConnectedProvider.verify|verify} messages.
     * @function encode
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {IIntegrationConnectedProvider} message IntegrationConnectedProvider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConnectedProvider.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCode);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.integrationSource);
        writer.uint32(/* id 5, wireType 2 =*/42).string(message.loginId);
        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.loginStatus);
        if (message.providerIcon != null && Object.hasOwnProperty.call(message, "providerIcon"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.providerIcon);
        if (message.logoUrl != null && Object.hasOwnProperty.call(message, "logoUrl"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.logoUrl);
        if (message.code != null && Object.hasOwnProperty.call(message, "code"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.code);
        if (message.maxTransactionHistoryInDays != null && Object.hasOwnProperty.call(message, "maxTransactionHistoryInDays"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.maxTransactionHistoryInDays);
        return writer;
    };

    /**
     * Encodes the specified IntegrationConnectedProvider message, length delimited. Does not implicitly {@link IntegrationConnectedProvider.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {IIntegrationConnectedProvider} message IntegrationConnectedProvider message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConnectedProvider.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationConnectedProvider message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationConnectedProvider} IntegrationConnectedProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConnectedProvider.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationConnectedProvider();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.countryCode = reader.string();
                break;
            case 4:
                message.integrationSource = reader.int32();
                break;
            case 5:
                message.loginId = reader.string();
                break;
            case 6:
                message.loginStatus = reader.int32();
                break;
            case 7:
                message.providerIcon = reader.string();
                break;
            case 8:
                message.logoUrl = reader.string();
                break;
            case 9:
                message.code = reader.string();
                break;
            case 10:
                message.maxTransactionHistoryInDays = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("countryCode"))
            throw $util.ProtocolError("missing required 'countryCode'", { instance: message });
        if (!message.hasOwnProperty("integrationSource"))
            throw $util.ProtocolError("missing required 'integrationSource'", { instance: message });
        if (!message.hasOwnProperty("loginId"))
            throw $util.ProtocolError("missing required 'loginId'", { instance: message });
        if (!message.hasOwnProperty("loginStatus"))
            throw $util.ProtocolError("missing required 'loginStatus'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationConnectedProvider message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationConnectedProvider} IntegrationConnectedProvider
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConnectedProvider.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationConnectedProvider message.
     * @function verify
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationConnectedProvider.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isString(message.countryCode))
            return "countryCode: string expected";
        switch (message.integrationSource) {
        default:
            return "integrationSource: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        if (!$util.isString(message.loginId))
            return "loginId: string expected";
        switch (message.loginStatus) {
        default:
            return "loginStatus: enum value expected";
        case 0:
        case 1:
        case 3:
            break;
        }
        if (message.providerIcon != null && message.hasOwnProperty("providerIcon"))
            if (!$util.isString(message.providerIcon))
                return "providerIcon: string expected";
        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
            if (!$util.isString(message.logoUrl))
                return "logoUrl: string expected";
        if (message.code != null && message.hasOwnProperty("code"))
            if (!$util.isString(message.code))
                return "code: string expected";
        if (message.maxTransactionHistoryInDays != null && message.hasOwnProperty("maxTransactionHistoryInDays"))
            if (!$util.isInteger(message.maxTransactionHistoryInDays))
                return "maxTransactionHistoryInDays: integer expected";
        return null;
    };

    /**
     * Creates an IntegrationConnectedProvider message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationConnectedProvider} IntegrationConnectedProvider
     */
    IntegrationConnectedProvider.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationConnectedProvider)
            return object;
        let message = new $root.IntegrationConnectedProvider();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        if (object.countryCode != null)
            message.countryCode = String(object.countryCode);
        switch (object.integrationSource) {
        case "BUDGETBAKERS":
        case 0:
            message.integrationSource = 0;
            break;
        case "SALTEDGE":
        case 1:
            message.integrationSource = 1;
            break;
        case "CSAS":
        case 2:
            message.integrationSource = 2;
            break;
        case "YODLEE":
        case 3:
            message.integrationSource = 3;
            break;
        case "SALTEDGE_PARTNERS":
        case 4:
            message.integrationSource = 4;
            break;
        case "YODLEE_FASTLINK":
        case 5:
            message.integrationSource = 5;
            break;
        }
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        switch (object.loginStatus) {
        case "INACTIVE":
        case 0:
            message.loginStatus = 0;
            break;
        case "ACTIVE":
        case 1:
            message.loginStatus = 1;
            break;
        case "DISABLED":
        case 3:
            message.loginStatus = 3;
            break;
        }
        if (object.providerIcon != null)
            message.providerIcon = String(object.providerIcon);
        if (object.logoUrl != null)
            message.logoUrl = String(object.logoUrl);
        if (object.code != null)
            message.code = String(object.code);
        if (object.maxTransactionHistoryInDays != null)
            message.maxTransactionHistoryInDays = object.maxTransactionHistoryInDays | 0;
        return message;
    };

    /**
     * Creates a plain object from an IntegrationConnectedProvider message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationConnectedProvider
     * @static
     * @param {IntegrationConnectedProvider} message IntegrationConnectedProvider
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationConnectedProvider.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.countryCode = "";
            object.integrationSource = options.enums === String ? "BUDGETBAKERS" : 0;
            object.loginId = "";
            object.loginStatus = options.enums === String ? "INACTIVE" : 0;
            object.providerIcon = "";
            object.logoUrl = "";
            object.code = "";
            object.maxTransactionHistoryInDays = 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.countryCode != null && message.hasOwnProperty("countryCode"))
            object.countryCode = message.countryCode;
        if (message.integrationSource != null && message.hasOwnProperty("integrationSource"))
            object.integrationSource = options.enums === String ? $root.IntegrationSource[message.integrationSource] : message.integrationSource;
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.loginStatus != null && message.hasOwnProperty("loginStatus"))
            object.loginStatus = options.enums === String ? $root.IntegrationConnectedProvider.ConnectedProviderStatus[message.loginStatus] : message.loginStatus;
        if (message.providerIcon != null && message.hasOwnProperty("providerIcon"))
            object.providerIcon = message.providerIcon;
        if (message.logoUrl != null && message.hasOwnProperty("logoUrl"))
            object.logoUrl = message.logoUrl;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.maxTransactionHistoryInDays != null && message.hasOwnProperty("maxTransactionHistoryInDays"))
            object.maxTransactionHistoryInDays = message.maxTransactionHistoryInDays;
        return object;
    };

    /**
     * Converts this IntegrationConnectedProvider to JSON.
     * @function toJSON
     * @memberof IntegrationConnectedProvider
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationConnectedProvider.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * ConnectedProviderStatus enum.
     * @name IntegrationConnectedProvider.ConnectedProviderStatus
     * @enum {number}
     * @property {number} INACTIVE=0 INACTIVE value
     * @property {number} ACTIVE=1 ACTIVE value
     * @property {number} DISABLED=3 DISABLED value
     */
    IntegrationConnectedProvider.ConnectedProviderStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "INACTIVE"] = 0;
        values[valuesById[1] = "ACTIVE"] = 1;
        values[valuesById[3] = "DISABLED"] = 3;
        return values;
    })();

    return IntegrationConnectedProvider;
})();

/**
 * IntegrationLoginStatus enum.
 * @exports IntegrationLoginStatus
 * @enum {number}
 * @property {number} OK=0 OK value
 * @property {number} MFA=1 MFA value
 * @property {number} ERROR=2 ERROR value
 * @property {number} CHECK_LATER=3 CHECK_LATER value
 * @property {number} INVALID_CREDENTIALS=4 INVALID_CREDENTIALS value
 * @property {number} LOGIN_DUPLICATED=5 LOGIN_DUPLICATED value
 */
export const IntegrationLoginStatus = $root.IntegrationLoginStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "OK"] = 0;
    values[valuesById[1] = "MFA"] = 1;
    values[valuesById[2] = "ERROR"] = 2;
    values[valuesById[3] = "CHECK_LATER"] = 3;
    values[valuesById[4] = "INVALID_CREDENTIALS"] = 4;
    values[valuesById[5] = "LOGIN_DUPLICATED"] = 5;
    return values;
})();

export const IntegrationLoginRequest = $root.IntegrationLoginRequest = (() => {

    /**
     * Properties of an IntegrationLoginRequest.
     * @exports IIntegrationLoginRequest
     * @interface IIntegrationLoginRequest
     * @property {Array.<IIntegrationFormValues>|null} [values] IntegrationLoginRequest values
     * @property {string|null} [id] IntegrationLoginRequest id
     * @property {IntegrationRecipeParam.InputType|null} [type] IntegrationLoginRequest type
     */

    /**
     * Constructs a new IntegrationLoginRequest.
     * @exports IntegrationLoginRequest
     * @classdesc Represents an IntegrationLoginRequest.
     * @implements IIntegrationLoginRequest
     * @constructor
     * @param {IIntegrationLoginRequest=} [properties] Properties to set
     */
    function IntegrationLoginRequest(properties) {
        this.values = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationLoginRequest values.
     * @member {Array.<IIntegrationFormValues>} values
     * @memberof IntegrationLoginRequest
     * @instance
     */
    IntegrationLoginRequest.prototype.values = $util.emptyArray;

    /**
     * IntegrationLoginRequest id.
     * @member {string} id
     * @memberof IntegrationLoginRequest
     * @instance
     */
    IntegrationLoginRequest.prototype.id = "";

    /**
     * IntegrationLoginRequest type.
     * @member {IntegrationRecipeParam.InputType} type
     * @memberof IntegrationLoginRequest
     * @instance
     */
    IntegrationLoginRequest.prototype.type = 0;

    /**
     * Creates a new IntegrationLoginRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationLoginRequest
     * @static
     * @param {IIntegrationLoginRequest=} [properties] Properties to set
     * @returns {IntegrationLoginRequest} IntegrationLoginRequest instance
     */
    IntegrationLoginRequest.create = function create(properties) {
        return new IntegrationLoginRequest(properties);
    };

    /**
     * Encodes the specified IntegrationLoginRequest message. Does not implicitly {@link IntegrationLoginRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationLoginRequest
     * @static
     * @param {IIntegrationLoginRequest} message IntegrationLoginRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationLoginRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.values != null && message.values.length)
            for (let i = 0; i < message.values.length; ++i)
                $root.IntegrationFormValues.encode(message.values[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
        return writer;
    };

    /**
     * Encodes the specified IntegrationLoginRequest message, length delimited. Does not implicitly {@link IntegrationLoginRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationLoginRequest
     * @static
     * @param {IIntegrationLoginRequest} message IntegrationLoginRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationLoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationLoginRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationLoginRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationLoginRequest} IntegrationLoginRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationLoginRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationLoginRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.values && message.values.length))
                    message.values = [];
                message.values.push($root.IntegrationFormValues.decode(reader, reader.uint32()));
                break;
            case 2:
                message.id = reader.string();
                break;
            case 3:
                message.type = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationLoginRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationLoginRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationLoginRequest} IntegrationLoginRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationLoginRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationLoginRequest message.
     * @function verify
     * @memberof IntegrationLoginRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationLoginRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.values != null && message.hasOwnProperty("values")) {
            if (!Array.isArray(message.values))
                return "values: array expected";
            for (let i = 0; i < message.values.length; ++i) {
                let error = $root.IntegrationFormValues.verify(message.values[i]);
                if (error)
                    return "values." + error;
            }
        }
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            switch (message.type) {
            default:
                return "type: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
                break;
            }
        return null;
    };

    /**
     * Creates an IntegrationLoginRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationLoginRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationLoginRequest} IntegrationLoginRequest
     */
    IntegrationLoginRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationLoginRequest)
            return object;
        let message = new $root.IntegrationLoginRequest();
        if (object.values) {
            if (!Array.isArray(object.values))
                throw TypeError(".IntegrationLoginRequest.values: array expected");
            message.values = [];
            for (let i = 0; i < object.values.length; ++i) {
                if (typeof object.values[i] !== "object")
                    throw TypeError(".IntegrationLoginRequest.values: object expected");
                message.values[i] = $root.IntegrationFormValues.fromObject(object.values[i]);
            }
        }
        if (object.id != null)
            message.id = String(object.id);
        switch (object.type) {
        case "LIST":
        case 0:
            message.type = 0;
            break;
        case "NUMBER":
        case 1:
            message.type = 1;
            break;
        case "TEXT":
        case 2:
            message.type = 2;
            break;
        case "BOOLEAN":
        case 3:
            message.type = 3;
            break;
        case "DATE":
        case 4:
            message.type = 4;
            break;
        case "TIME":
        case 5:
            message.type = 5;
            break;
        case "DATE_TIME":
        case 6:
            message.type = 6;
            break;
        case "PASSWORD":
        case 8:
            message.type = 8;
            break;
        case "REGEXP":
        case 9:
            message.type = 9;
            break;
        case "SELECT":
        case 10:
            message.type = 10;
            break;
        case "OAUTH_REDIRECT_URL":
        case 11:
            message.type = 11;
            break;
        case "HTML":
        case 12:
            message.type = 12;
            break;
        case "MULTI_SELECT":
        case 13:
            message.type = 13;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationLoginRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationLoginRequest
     * @static
     * @param {IntegrationLoginRequest} message IntegrationLoginRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationLoginRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.values = [];
        if (options.defaults) {
            object.id = "";
            object.type = options.enums === String ? "LIST" : 0;
        }
        if (message.values && message.values.length) {
            object.values = [];
            for (let j = 0; j < message.values.length; ++j)
                object.values[j] = $root.IntegrationFormValues.toObject(message.values[j], options);
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.IntegrationRecipeParam.InputType[message.type] : message.type;
        return object;
    };

    /**
     * Converts this IntegrationLoginRequest to JSON.
     * @function toJSON
     * @memberof IntegrationLoginRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationLoginRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationLoginRequest;
})();

export const IntegrationLoginResponse = $root.IntegrationLoginResponse = (() => {

    /**
     * Properties of an IntegrationLoginResponse.
     * @exports IIntegrationLoginResponse
     * @interface IIntegrationLoginResponse
     * @property {string} loginId IntegrationLoginResponse loginId
     * @property {IntegrationLoginStatus} status IntegrationLoginResponse status
     * @property {IntegrationSource} integrationSource IntegrationLoginResponse integrationSource
     * @property {Array.<IIntegrationRecipeGroup>|null} [formAttributeGroups] IntegrationLoginResponse formAttributeGroups
     * @property {string|null} [errorMessage] IntegrationLoginResponse errorMessage
     */

    /**
     * Constructs a new IntegrationLoginResponse.
     * @exports IntegrationLoginResponse
     * @classdesc Represents an IntegrationLoginResponse.
     * @implements IIntegrationLoginResponse
     * @constructor
     * @param {IIntegrationLoginResponse=} [properties] Properties to set
     */
    function IntegrationLoginResponse(properties) {
        this.formAttributeGroups = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationLoginResponse loginId.
     * @member {string} loginId
     * @memberof IntegrationLoginResponse
     * @instance
     */
    IntegrationLoginResponse.prototype.loginId = "";

    /**
     * IntegrationLoginResponse status.
     * @member {IntegrationLoginStatus} status
     * @memberof IntegrationLoginResponse
     * @instance
     */
    IntegrationLoginResponse.prototype.status = 0;

    /**
     * IntegrationLoginResponse integrationSource.
     * @member {IntegrationSource} integrationSource
     * @memberof IntegrationLoginResponse
     * @instance
     */
    IntegrationLoginResponse.prototype.integrationSource = 0;

    /**
     * IntegrationLoginResponse formAttributeGroups.
     * @member {Array.<IIntegrationRecipeGroup>} formAttributeGroups
     * @memberof IntegrationLoginResponse
     * @instance
     */
    IntegrationLoginResponse.prototype.formAttributeGroups = $util.emptyArray;

    /**
     * IntegrationLoginResponse errorMessage.
     * @member {string} errorMessage
     * @memberof IntegrationLoginResponse
     * @instance
     */
    IntegrationLoginResponse.prototype.errorMessage = "";

    /**
     * Creates a new IntegrationLoginResponse instance using the specified properties.
     * @function create
     * @memberof IntegrationLoginResponse
     * @static
     * @param {IIntegrationLoginResponse=} [properties] Properties to set
     * @returns {IntegrationLoginResponse} IntegrationLoginResponse instance
     */
    IntegrationLoginResponse.create = function create(properties) {
        return new IntegrationLoginResponse(properties);
    };

    /**
     * Encodes the specified IntegrationLoginResponse message. Does not implicitly {@link IntegrationLoginResponse.verify|verify} messages.
     * @function encode
     * @memberof IntegrationLoginResponse
     * @static
     * @param {IIntegrationLoginResponse} message IntegrationLoginResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationLoginResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.loginId);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.integrationSource);
        if (message.formAttributeGroups != null && message.formAttributeGroups.length)
            for (let i = 0; i < message.formAttributeGroups.length; ++i)
                $root.IntegrationRecipeGroup.encode(message.formAttributeGroups[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
        return writer;
    };

    /**
     * Encodes the specified IntegrationLoginResponse message, length delimited. Does not implicitly {@link IntegrationLoginResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationLoginResponse
     * @static
     * @param {IIntegrationLoginResponse} message IntegrationLoginResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationLoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationLoginResponse message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationLoginResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationLoginResponse} IntegrationLoginResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationLoginResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationLoginResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.loginId = reader.string();
                break;
            case 2:
                message.status = reader.int32();
                break;
            case 3:
                message.integrationSource = reader.int32();
                break;
            case 4:
                if (!(message.formAttributeGroups && message.formAttributeGroups.length))
                    message.formAttributeGroups = [];
                message.formAttributeGroups.push($root.IntegrationRecipeGroup.decode(reader, reader.uint32()));
                break;
            case 5:
                message.errorMessage = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("loginId"))
            throw $util.ProtocolError("missing required 'loginId'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        if (!message.hasOwnProperty("integrationSource"))
            throw $util.ProtocolError("missing required 'integrationSource'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationLoginResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationLoginResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationLoginResponse} IntegrationLoginResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationLoginResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationLoginResponse message.
     * @function verify
     * @memberof IntegrationLoginResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationLoginResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.loginId))
            return "loginId: string expected";
        switch (message.status) {
        default:
            return "status: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        switch (message.integrationSource) {
        default:
            return "integrationSource: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        if (message.formAttributeGroups != null && message.hasOwnProperty("formAttributeGroups")) {
            if (!Array.isArray(message.formAttributeGroups))
                return "formAttributeGroups: array expected";
            for (let i = 0; i < message.formAttributeGroups.length; ++i) {
                let error = $root.IntegrationRecipeGroup.verify(message.formAttributeGroups[i]);
                if (error)
                    return "formAttributeGroups." + error;
            }
        }
        if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
            if (!$util.isString(message.errorMessage))
                return "errorMessage: string expected";
        return null;
    };

    /**
     * Creates an IntegrationLoginResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationLoginResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationLoginResponse} IntegrationLoginResponse
     */
    IntegrationLoginResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationLoginResponse)
            return object;
        let message = new $root.IntegrationLoginResponse();
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        switch (object.status) {
        case "OK":
        case 0:
            message.status = 0;
            break;
        case "MFA":
        case 1:
            message.status = 1;
            break;
        case "ERROR":
        case 2:
            message.status = 2;
            break;
        case "CHECK_LATER":
        case 3:
            message.status = 3;
            break;
        case "INVALID_CREDENTIALS":
        case 4:
            message.status = 4;
            break;
        case "LOGIN_DUPLICATED":
        case 5:
            message.status = 5;
            break;
        }
        switch (object.integrationSource) {
        case "BUDGETBAKERS":
        case 0:
            message.integrationSource = 0;
            break;
        case "SALTEDGE":
        case 1:
            message.integrationSource = 1;
            break;
        case "CSAS":
        case 2:
            message.integrationSource = 2;
            break;
        case "YODLEE":
        case 3:
            message.integrationSource = 3;
            break;
        case "SALTEDGE_PARTNERS":
        case 4:
            message.integrationSource = 4;
            break;
        case "YODLEE_FASTLINK":
        case 5:
            message.integrationSource = 5;
            break;
        }
        if (object.formAttributeGroups) {
            if (!Array.isArray(object.formAttributeGroups))
                throw TypeError(".IntegrationLoginResponse.formAttributeGroups: array expected");
            message.formAttributeGroups = [];
            for (let i = 0; i < object.formAttributeGroups.length; ++i) {
                if (typeof object.formAttributeGroups[i] !== "object")
                    throw TypeError(".IntegrationLoginResponse.formAttributeGroups: object expected");
                message.formAttributeGroups[i] = $root.IntegrationRecipeGroup.fromObject(object.formAttributeGroups[i]);
            }
        }
        if (object.errorMessage != null)
            message.errorMessage = String(object.errorMessage);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationLoginResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationLoginResponse
     * @static
     * @param {IntegrationLoginResponse} message IntegrationLoginResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationLoginResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.formAttributeGroups = [];
        if (options.defaults) {
            object.loginId = "";
            object.status = options.enums === String ? "OK" : 0;
            object.integrationSource = options.enums === String ? "BUDGETBAKERS" : 0;
            object.errorMessage = "";
        }
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.IntegrationLoginStatus[message.status] : message.status;
        if (message.integrationSource != null && message.hasOwnProperty("integrationSource"))
            object.integrationSource = options.enums === String ? $root.IntegrationSource[message.integrationSource] : message.integrationSource;
        if (message.formAttributeGroups && message.formAttributeGroups.length) {
            object.formAttributeGroups = [];
            for (let j = 0; j < message.formAttributeGroups.length; ++j)
                object.formAttributeGroups[j] = $root.IntegrationRecipeGroup.toObject(message.formAttributeGroups[j], options);
        }
        if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
            object.errorMessage = message.errorMessage;
        return object;
    };

    /**
     * Converts this IntegrationLoginResponse to JSON.
     * @function toJSON
     * @memberof IntegrationLoginResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationLoginResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationLoginResponse;
})();

export const IntegrationLoginOAuthFinish = $root.IntegrationLoginOAuthFinish = (() => {

    /**
     * Properties of an IntegrationLoginOAuthFinish.
     * @exports IIntegrationLoginOAuthFinish
     * @interface IIntegrationLoginOAuthFinish
     * @property {string} queryParams IntegrationLoginOAuthFinish queryParams
     */

    /**
     * Constructs a new IntegrationLoginOAuthFinish.
     * @exports IntegrationLoginOAuthFinish
     * @classdesc Represents an IntegrationLoginOAuthFinish.
     * @implements IIntegrationLoginOAuthFinish
     * @constructor
     * @param {IIntegrationLoginOAuthFinish=} [properties] Properties to set
     */
    function IntegrationLoginOAuthFinish(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationLoginOAuthFinish queryParams.
     * @member {string} queryParams
     * @memberof IntegrationLoginOAuthFinish
     * @instance
     */
    IntegrationLoginOAuthFinish.prototype.queryParams = "";

    /**
     * Creates a new IntegrationLoginOAuthFinish instance using the specified properties.
     * @function create
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {IIntegrationLoginOAuthFinish=} [properties] Properties to set
     * @returns {IntegrationLoginOAuthFinish} IntegrationLoginOAuthFinish instance
     */
    IntegrationLoginOAuthFinish.create = function create(properties) {
        return new IntegrationLoginOAuthFinish(properties);
    };

    /**
     * Encodes the specified IntegrationLoginOAuthFinish message. Does not implicitly {@link IntegrationLoginOAuthFinish.verify|verify} messages.
     * @function encode
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {IIntegrationLoginOAuthFinish} message IntegrationLoginOAuthFinish message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationLoginOAuthFinish.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.queryParams);
        return writer;
    };

    /**
     * Encodes the specified IntegrationLoginOAuthFinish message, length delimited. Does not implicitly {@link IntegrationLoginOAuthFinish.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {IIntegrationLoginOAuthFinish} message IntegrationLoginOAuthFinish message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationLoginOAuthFinish.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationLoginOAuthFinish message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationLoginOAuthFinish} IntegrationLoginOAuthFinish
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationLoginOAuthFinish.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationLoginOAuthFinish();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.queryParams = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("queryParams"))
            throw $util.ProtocolError("missing required 'queryParams'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationLoginOAuthFinish message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationLoginOAuthFinish} IntegrationLoginOAuthFinish
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationLoginOAuthFinish.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationLoginOAuthFinish message.
     * @function verify
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationLoginOAuthFinish.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.queryParams))
            return "queryParams: string expected";
        return null;
    };

    /**
     * Creates an IntegrationLoginOAuthFinish message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationLoginOAuthFinish} IntegrationLoginOAuthFinish
     */
    IntegrationLoginOAuthFinish.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationLoginOAuthFinish)
            return object;
        let message = new $root.IntegrationLoginOAuthFinish();
        if (object.queryParams != null)
            message.queryParams = String(object.queryParams);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationLoginOAuthFinish message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationLoginOAuthFinish
     * @static
     * @param {IntegrationLoginOAuthFinish} message IntegrationLoginOAuthFinish
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationLoginOAuthFinish.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.queryParams = "";
        if (message.queryParams != null && message.hasOwnProperty("queryParams"))
            object.queryParams = message.queryParams;
        return object;
    };

    /**
     * Converts this IntegrationLoginOAuthFinish to JSON.
     * @function toJSON
     * @memberof IntegrationLoginOAuthFinish
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationLoginOAuthFinish.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationLoginOAuthFinish;
})();

export const IntegrationAccount = $root.IntegrationAccount = (() => {

    /**
     * Properties of an IntegrationAccount.
     * @exports IIntegrationAccount
     * @interface IIntegrationAccount
     * @property {string} id IntegrationAccount id
     * @property {string} name IntegrationAccount name
     * @property {IntegrationAccount.Type} type IntegrationAccount type
     * @property {number} balance IntegrationAccount balance
     * @property {string} currencyCode IntegrationAccount currencyCode
     * @property {string|null} [connectedAppAccountId] IntegrationAccount connectedAppAccountId
     */

    /**
     * Constructs a new IntegrationAccount.
     * @exports IntegrationAccount
     * @classdesc Represents an IntegrationAccount.
     * @implements IIntegrationAccount
     * @constructor
     * @param {IIntegrationAccount=} [properties] Properties to set
     */
    function IntegrationAccount(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationAccount id.
     * @member {string} id
     * @memberof IntegrationAccount
     * @instance
     */
    IntegrationAccount.prototype.id = "";

    /**
     * IntegrationAccount name.
     * @member {string} name
     * @memberof IntegrationAccount
     * @instance
     */
    IntegrationAccount.prototype.name = "";

    /**
     * IntegrationAccount type.
     * @member {IntegrationAccount.Type} type
     * @memberof IntegrationAccount
     * @instance
     */
    IntegrationAccount.prototype.type = 0;

    /**
     * IntegrationAccount balance.
     * @member {number} balance
     * @memberof IntegrationAccount
     * @instance
     */
    IntegrationAccount.prototype.balance = 0;

    /**
     * IntegrationAccount currencyCode.
     * @member {string} currencyCode
     * @memberof IntegrationAccount
     * @instance
     */
    IntegrationAccount.prototype.currencyCode = "";

    /**
     * IntegrationAccount connectedAppAccountId.
     * @member {string} connectedAppAccountId
     * @memberof IntegrationAccount
     * @instance
     */
    IntegrationAccount.prototype.connectedAppAccountId = "";

    /**
     * Creates a new IntegrationAccount instance using the specified properties.
     * @function create
     * @memberof IntegrationAccount
     * @static
     * @param {IIntegrationAccount=} [properties] Properties to set
     * @returns {IntegrationAccount} IntegrationAccount instance
     */
    IntegrationAccount.create = function create(properties) {
        return new IntegrationAccount(properties);
    };

    /**
     * Encodes the specified IntegrationAccount message. Does not implicitly {@link IntegrationAccount.verify|verify} messages.
     * @function encode
     * @memberof IntegrationAccount
     * @static
     * @param {IIntegrationAccount} message IntegrationAccount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
        writer.uint32(/* id 4, wireType 1 =*/33).double(message.balance);
        writer.uint32(/* id 5, wireType 2 =*/42).string(message.currencyCode);
        if (message.connectedAppAccountId != null && Object.hasOwnProperty.call(message, "connectedAppAccountId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.connectedAppAccountId);
        return writer;
    };

    /**
     * Encodes the specified IntegrationAccount message, length delimited. Does not implicitly {@link IntegrationAccount.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationAccount
     * @static
     * @param {IIntegrationAccount} message IntegrationAccount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccount.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationAccount message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationAccount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationAccount} IntegrationAccount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationAccount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.type = reader.int32();
                break;
            case 4:
                message.balance = reader.double();
                break;
            case 5:
                message.currencyCode = reader.string();
                break;
            case 6:
                message.connectedAppAccountId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("balance"))
            throw $util.ProtocolError("missing required 'balance'", { instance: message });
        if (!message.hasOwnProperty("currencyCode"))
            throw $util.ProtocolError("missing required 'currencyCode'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationAccount message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationAccount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationAccount} IntegrationAccount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccount.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationAccount message.
     * @function verify
     * @memberof IntegrationAccount
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationAccount.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
            break;
        }
        if (typeof message.balance !== "number")
            return "balance: number expected";
        if (!$util.isString(message.currencyCode))
            return "currencyCode: string expected";
        if (message.connectedAppAccountId != null && message.hasOwnProperty("connectedAppAccountId"))
            if (!$util.isString(message.connectedAppAccountId))
                return "connectedAppAccountId: string expected";
        return null;
    };

    /**
     * Creates an IntegrationAccount message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationAccount
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationAccount} IntegrationAccount
     */
    IntegrationAccount.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationAccount)
            return object;
        let message = new $root.IntegrationAccount();
        if (object.id != null)
            message.id = String(object.id);
        if (object.name != null)
            message.name = String(object.name);
        switch (object.type) {
        case "Account":
        case 0:
            message.type = 0;
            break;
        case "Bonus":
        case 1:
            message.type = 1;
            break;
        case "Card":
        case 2:
            message.type = 2;
            break;
        case "Checking":
        case 3:
            message.type = 3;
            break;
        case "Credit":
        case 4:
            message.type = 4;
            break;
        case "CreditCard":
        case 5:
            message.type = 5;
            break;
        case "DebitCard":
        case 6:
            message.type = 6;
            break;
        case "Ewallet":
        case 7:
            message.type = 7;
            break;
        case "Insurance":
        case 8:
            message.type = 8;
            break;
        case "Investment":
        case 9:
            message.type = 9;
            break;
        case "Loan":
        case 10:
            message.type = 10;
            break;
        case "Mortgage":
        case 11:
            message.type = 11;
            break;
        case "Savings":
        case 12:
            message.type = 12;
            break;
        }
        if (object.balance != null)
            message.balance = Number(object.balance);
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        if (object.connectedAppAccountId != null)
            message.connectedAppAccountId = String(object.connectedAppAccountId);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationAccount message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationAccount
     * @static
     * @param {IntegrationAccount} message IntegrationAccount
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationAccount.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.name = "";
            object.type = options.enums === String ? "Account" : 0;
            object.balance = 0;
            object.currencyCode = "";
            object.connectedAppAccountId = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.IntegrationAccount.Type[message.type] : message.type;
        if (message.balance != null && message.hasOwnProperty("balance"))
            object.balance = options.json && !isFinite(message.balance) ? String(message.balance) : message.balance;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        if (message.connectedAppAccountId != null && message.hasOwnProperty("connectedAppAccountId"))
            object.connectedAppAccountId = message.connectedAppAccountId;
        return object;
    };

    /**
     * Converts this IntegrationAccount to JSON.
     * @function toJSON
     * @memberof IntegrationAccount
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationAccount.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Type enum.
     * @name IntegrationAccount.Type
     * @enum {number}
     * @property {number} Account=0 Account value
     * @property {number} Bonus=1 Bonus value
     * @property {number} Card=2 Card value
     * @property {number} Checking=3 Checking value
     * @property {number} Credit=4 Credit value
     * @property {number} CreditCard=5 CreditCard value
     * @property {number} DebitCard=6 DebitCard value
     * @property {number} Ewallet=7 Ewallet value
     * @property {number} Insurance=8 Insurance value
     * @property {number} Investment=9 Investment value
     * @property {number} Loan=10 Loan value
     * @property {number} Mortgage=11 Mortgage value
     * @property {number} Savings=12 Savings value
     */
    IntegrationAccount.Type = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Account"] = 0;
        values[valuesById[1] = "Bonus"] = 1;
        values[valuesById[2] = "Card"] = 2;
        values[valuesById[3] = "Checking"] = 3;
        values[valuesById[4] = "Credit"] = 4;
        values[valuesById[5] = "CreditCard"] = 5;
        values[valuesById[6] = "DebitCard"] = 6;
        values[valuesById[7] = "Ewallet"] = 7;
        values[valuesById[8] = "Insurance"] = 8;
        values[valuesById[9] = "Investment"] = 9;
        values[valuesById[10] = "Loan"] = 10;
        values[valuesById[11] = "Mortgage"] = 11;
        values[valuesById[12] = "Savings"] = 12;
        return values;
    })();

    return IntegrationAccount;
})();

export const IntegrationAccounts = $root.IntegrationAccounts = (() => {

    /**
     * Properties of an IntegrationAccounts.
     * @exports IIntegrationAccounts
     * @interface IIntegrationAccounts
     * @property {Array.<IIntegrationAccount>|null} [accounts] IntegrationAccounts accounts
     */

    /**
     * Constructs a new IntegrationAccounts.
     * @exports IntegrationAccounts
     * @classdesc Represents an IntegrationAccounts.
     * @implements IIntegrationAccounts
     * @constructor
     * @param {IIntegrationAccounts=} [properties] Properties to set
     */
    function IntegrationAccounts(properties) {
        this.accounts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationAccounts accounts.
     * @member {Array.<IIntegrationAccount>} accounts
     * @memberof IntegrationAccounts
     * @instance
     */
    IntegrationAccounts.prototype.accounts = $util.emptyArray;

    /**
     * Creates a new IntegrationAccounts instance using the specified properties.
     * @function create
     * @memberof IntegrationAccounts
     * @static
     * @param {IIntegrationAccounts=} [properties] Properties to set
     * @returns {IntegrationAccounts} IntegrationAccounts instance
     */
    IntegrationAccounts.create = function create(properties) {
        return new IntegrationAccounts(properties);
    };

    /**
     * Encodes the specified IntegrationAccounts message. Does not implicitly {@link IntegrationAccounts.verify|verify} messages.
     * @function encode
     * @memberof IntegrationAccounts
     * @static
     * @param {IIntegrationAccounts} message IntegrationAccounts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccounts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.accounts != null && message.accounts.length)
            for (let i = 0; i < message.accounts.length; ++i)
                $root.IntegrationAccount.encode(message.accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationAccounts message, length delimited. Does not implicitly {@link IntegrationAccounts.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationAccounts
     * @static
     * @param {IIntegrationAccounts} message IntegrationAccounts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccounts.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationAccounts message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationAccounts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationAccounts} IntegrationAccounts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccounts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationAccounts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.accounts && message.accounts.length))
                    message.accounts = [];
                message.accounts.push($root.IntegrationAccount.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationAccounts message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationAccounts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationAccounts} IntegrationAccounts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccounts.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationAccounts message.
     * @function verify
     * @memberof IntegrationAccounts
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationAccounts.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.accounts != null && message.hasOwnProperty("accounts")) {
            if (!Array.isArray(message.accounts))
                return "accounts: array expected";
            for (let i = 0; i < message.accounts.length; ++i) {
                let error = $root.IntegrationAccount.verify(message.accounts[i]);
                if (error)
                    return "accounts." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationAccounts message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationAccounts
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationAccounts} IntegrationAccounts
     */
    IntegrationAccounts.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationAccounts)
            return object;
        let message = new $root.IntegrationAccounts();
        if (object.accounts) {
            if (!Array.isArray(object.accounts))
                throw TypeError(".IntegrationAccounts.accounts: array expected");
            message.accounts = [];
            for (let i = 0; i < object.accounts.length; ++i) {
                if (typeof object.accounts[i] !== "object")
                    throw TypeError(".IntegrationAccounts.accounts: object expected");
                message.accounts[i] = $root.IntegrationAccount.fromObject(object.accounts[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationAccounts message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationAccounts
     * @static
     * @param {IntegrationAccounts} message IntegrationAccounts
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationAccounts.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.accounts = [];
        if (message.accounts && message.accounts.length) {
            object.accounts = [];
            for (let j = 0; j < message.accounts.length; ++j)
                object.accounts[j] = $root.IntegrationAccount.toObject(message.accounts[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationAccounts to JSON.
     * @function toJSON
     * @memberof IntegrationAccounts
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationAccounts.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationAccounts;
})();

export const IntegrationAccountCreationBlueprint = $root.IntegrationAccountCreationBlueprint = (() => {

    /**
     * Properties of an IntegrationAccountCreationBlueprint.
     * @exports IIntegrationAccountCreationBlueprint
     * @interface IIntegrationAccountCreationBlueprint
     * @property {string} color IntegrationAccountCreationBlueprint color
     * @property {string} name IntegrationAccountCreationBlueprint name
     * @property {string} remoteAccountId IntegrationAccountCreationBlueprint remoteAccountId
     * @property {string|null} [cashAccountId] IntegrationAccountCreationBlueprint cashAccountId
     */

    /**
     * Constructs a new IntegrationAccountCreationBlueprint.
     * @exports IntegrationAccountCreationBlueprint
     * @classdesc Represents an IntegrationAccountCreationBlueprint.
     * @implements IIntegrationAccountCreationBlueprint
     * @constructor
     * @param {IIntegrationAccountCreationBlueprint=} [properties] Properties to set
     */
    function IntegrationAccountCreationBlueprint(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationAccountCreationBlueprint color.
     * @member {string} color
     * @memberof IntegrationAccountCreationBlueprint
     * @instance
     */
    IntegrationAccountCreationBlueprint.prototype.color = "";

    /**
     * IntegrationAccountCreationBlueprint name.
     * @member {string} name
     * @memberof IntegrationAccountCreationBlueprint
     * @instance
     */
    IntegrationAccountCreationBlueprint.prototype.name = "";

    /**
     * IntegrationAccountCreationBlueprint remoteAccountId.
     * @member {string} remoteAccountId
     * @memberof IntegrationAccountCreationBlueprint
     * @instance
     */
    IntegrationAccountCreationBlueprint.prototype.remoteAccountId = "";

    /**
     * IntegrationAccountCreationBlueprint cashAccountId.
     * @member {string} cashAccountId
     * @memberof IntegrationAccountCreationBlueprint
     * @instance
     */
    IntegrationAccountCreationBlueprint.prototype.cashAccountId = "";

    /**
     * Creates a new IntegrationAccountCreationBlueprint instance using the specified properties.
     * @function create
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {IIntegrationAccountCreationBlueprint=} [properties] Properties to set
     * @returns {IntegrationAccountCreationBlueprint} IntegrationAccountCreationBlueprint instance
     */
    IntegrationAccountCreationBlueprint.create = function create(properties) {
        return new IntegrationAccountCreationBlueprint(properties);
    };

    /**
     * Encodes the specified IntegrationAccountCreationBlueprint message. Does not implicitly {@link IntegrationAccountCreationBlueprint.verify|verify} messages.
     * @function encode
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {IIntegrationAccountCreationBlueprint} message IntegrationAccountCreationBlueprint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountCreationBlueprint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.color);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.remoteAccountId);
        if (message.cashAccountId != null && Object.hasOwnProperty.call(message, "cashAccountId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.cashAccountId);
        return writer;
    };

    /**
     * Encodes the specified IntegrationAccountCreationBlueprint message, length delimited. Does not implicitly {@link IntegrationAccountCreationBlueprint.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {IIntegrationAccountCreationBlueprint} message IntegrationAccountCreationBlueprint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountCreationBlueprint.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationAccountCreationBlueprint message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationAccountCreationBlueprint} IntegrationAccountCreationBlueprint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountCreationBlueprint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationAccountCreationBlueprint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.color = reader.string();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.remoteAccountId = reader.string();
                break;
            case 4:
                message.cashAccountId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("color"))
            throw $util.ProtocolError("missing required 'color'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("remoteAccountId"))
            throw $util.ProtocolError("missing required 'remoteAccountId'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationAccountCreationBlueprint message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationAccountCreationBlueprint} IntegrationAccountCreationBlueprint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountCreationBlueprint.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationAccountCreationBlueprint message.
     * @function verify
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationAccountCreationBlueprint.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.color))
            return "color: string expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isString(message.remoteAccountId))
            return "remoteAccountId: string expected";
        if (message.cashAccountId != null && message.hasOwnProperty("cashAccountId"))
            if (!$util.isString(message.cashAccountId))
                return "cashAccountId: string expected";
        return null;
    };

    /**
     * Creates an IntegrationAccountCreationBlueprint message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationAccountCreationBlueprint} IntegrationAccountCreationBlueprint
     */
    IntegrationAccountCreationBlueprint.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationAccountCreationBlueprint)
            return object;
        let message = new $root.IntegrationAccountCreationBlueprint();
        if (object.color != null)
            message.color = String(object.color);
        if (object.name != null)
            message.name = String(object.name);
        if (object.remoteAccountId != null)
            message.remoteAccountId = String(object.remoteAccountId);
        if (object.cashAccountId != null)
            message.cashAccountId = String(object.cashAccountId);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationAccountCreationBlueprint message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationAccountCreationBlueprint
     * @static
     * @param {IntegrationAccountCreationBlueprint} message IntegrationAccountCreationBlueprint
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationAccountCreationBlueprint.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.color = "";
            object.name = "";
            object.remoteAccountId = "";
            object.cashAccountId = "";
        }
        if (message.color != null && message.hasOwnProperty("color"))
            object.color = message.color;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.remoteAccountId != null && message.hasOwnProperty("remoteAccountId"))
            object.remoteAccountId = message.remoteAccountId;
        if (message.cashAccountId != null && message.hasOwnProperty("cashAccountId"))
            object.cashAccountId = message.cashAccountId;
        return object;
    };

    /**
     * Converts this IntegrationAccountCreationBlueprint to JSON.
     * @function toJSON
     * @memberof IntegrationAccountCreationBlueprint
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationAccountCreationBlueprint.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationAccountCreationBlueprint;
})();

export const IntegrationAccountCreationRequest = $root.IntegrationAccountCreationRequest = (() => {

    /**
     * Properties of an IntegrationAccountCreationRequest.
     * @exports IIntegrationAccountCreationRequest
     * @interface IIntegrationAccountCreationRequest
     * @property {Array.<IIntegrationAccountCreationBlueprint>|null} [accounts] IntegrationAccountCreationRequest accounts
     */

    /**
     * Constructs a new IntegrationAccountCreationRequest.
     * @exports IntegrationAccountCreationRequest
     * @classdesc Represents an IntegrationAccountCreationRequest.
     * @implements IIntegrationAccountCreationRequest
     * @constructor
     * @param {IIntegrationAccountCreationRequest=} [properties] Properties to set
     */
    function IntegrationAccountCreationRequest(properties) {
        this.accounts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationAccountCreationRequest accounts.
     * @member {Array.<IIntegrationAccountCreationBlueprint>} accounts
     * @memberof IntegrationAccountCreationRequest
     * @instance
     */
    IntegrationAccountCreationRequest.prototype.accounts = $util.emptyArray;

    /**
     * Creates a new IntegrationAccountCreationRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {IIntegrationAccountCreationRequest=} [properties] Properties to set
     * @returns {IntegrationAccountCreationRequest} IntegrationAccountCreationRequest instance
     */
    IntegrationAccountCreationRequest.create = function create(properties) {
        return new IntegrationAccountCreationRequest(properties);
    };

    /**
     * Encodes the specified IntegrationAccountCreationRequest message. Does not implicitly {@link IntegrationAccountCreationRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {IIntegrationAccountCreationRequest} message IntegrationAccountCreationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountCreationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.accounts != null && message.accounts.length)
            for (let i = 0; i < message.accounts.length; ++i)
                $root.IntegrationAccountCreationBlueprint.encode(message.accounts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationAccountCreationRequest message, length delimited. Does not implicitly {@link IntegrationAccountCreationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {IIntegrationAccountCreationRequest} message IntegrationAccountCreationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountCreationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationAccountCreationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationAccountCreationRequest} IntegrationAccountCreationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountCreationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationAccountCreationRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2:
                if (!(message.accounts && message.accounts.length))
                    message.accounts = [];
                message.accounts.push($root.IntegrationAccountCreationBlueprint.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationAccountCreationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationAccountCreationRequest} IntegrationAccountCreationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountCreationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationAccountCreationRequest message.
     * @function verify
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationAccountCreationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.accounts != null && message.hasOwnProperty("accounts")) {
            if (!Array.isArray(message.accounts))
                return "accounts: array expected";
            for (let i = 0; i < message.accounts.length; ++i) {
                let error = $root.IntegrationAccountCreationBlueprint.verify(message.accounts[i]);
                if (error)
                    return "accounts." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationAccountCreationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationAccountCreationRequest} IntegrationAccountCreationRequest
     */
    IntegrationAccountCreationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationAccountCreationRequest)
            return object;
        let message = new $root.IntegrationAccountCreationRequest();
        if (object.accounts) {
            if (!Array.isArray(object.accounts))
                throw TypeError(".IntegrationAccountCreationRequest.accounts: array expected");
            message.accounts = [];
            for (let i = 0; i < object.accounts.length; ++i) {
                if (typeof object.accounts[i] !== "object")
                    throw TypeError(".IntegrationAccountCreationRequest.accounts: object expected");
                message.accounts[i] = $root.IntegrationAccountCreationBlueprint.fromObject(object.accounts[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationAccountCreationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationAccountCreationRequest
     * @static
     * @param {IntegrationAccountCreationRequest} message IntegrationAccountCreationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationAccountCreationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.accounts = [];
        if (message.accounts && message.accounts.length) {
            object.accounts = [];
            for (let j = 0; j < message.accounts.length; ++j)
                object.accounts[j] = $root.IntegrationAccountCreationBlueprint.toObject(message.accounts[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationAccountCreationRequest to JSON.
     * @function toJSON
     * @memberof IntegrationAccountCreationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationAccountCreationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationAccountCreationRequest;
})();

export const IntegrationAccountConnectionRequest = $root.IntegrationAccountConnectionRequest = (() => {

    /**
     * Properties of an IntegrationAccountConnectionRequest.
     * @exports IIntegrationAccountConnectionRequest
     * @interface IIntegrationAccountConnectionRequest
     * @property {Array.<IIntegrationAccountConnectionPair>|null} [accounts] IntegrationAccountConnectionRequest accounts
     */

    /**
     * Constructs a new IntegrationAccountConnectionRequest.
     * @exports IntegrationAccountConnectionRequest
     * @classdesc Represents an IntegrationAccountConnectionRequest.
     * @implements IIntegrationAccountConnectionRequest
     * @constructor
     * @param {IIntegrationAccountConnectionRequest=} [properties] Properties to set
     */
    function IntegrationAccountConnectionRequest(properties) {
        this.accounts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationAccountConnectionRequest accounts.
     * @member {Array.<IIntegrationAccountConnectionPair>} accounts
     * @memberof IntegrationAccountConnectionRequest
     * @instance
     */
    IntegrationAccountConnectionRequest.prototype.accounts = $util.emptyArray;

    /**
     * Creates a new IntegrationAccountConnectionRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {IIntegrationAccountConnectionRequest=} [properties] Properties to set
     * @returns {IntegrationAccountConnectionRequest} IntegrationAccountConnectionRequest instance
     */
    IntegrationAccountConnectionRequest.create = function create(properties) {
        return new IntegrationAccountConnectionRequest(properties);
    };

    /**
     * Encodes the specified IntegrationAccountConnectionRequest message. Does not implicitly {@link IntegrationAccountConnectionRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {IIntegrationAccountConnectionRequest} message IntegrationAccountConnectionRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountConnectionRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.accounts != null && message.accounts.length)
            for (let i = 0; i < message.accounts.length; ++i)
                $root.IntegrationAccountConnectionPair.encode(message.accounts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationAccountConnectionRequest message, length delimited. Does not implicitly {@link IntegrationAccountConnectionRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {IIntegrationAccountConnectionRequest} message IntegrationAccountConnectionRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountConnectionRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationAccountConnectionRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationAccountConnectionRequest} IntegrationAccountConnectionRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountConnectionRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationAccountConnectionRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2:
                if (!(message.accounts && message.accounts.length))
                    message.accounts = [];
                message.accounts.push($root.IntegrationAccountConnectionPair.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationAccountConnectionRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationAccountConnectionRequest} IntegrationAccountConnectionRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountConnectionRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationAccountConnectionRequest message.
     * @function verify
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationAccountConnectionRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.accounts != null && message.hasOwnProperty("accounts")) {
            if (!Array.isArray(message.accounts))
                return "accounts: array expected";
            for (let i = 0; i < message.accounts.length; ++i) {
                let error = $root.IntegrationAccountConnectionPair.verify(message.accounts[i]);
                if (error)
                    return "accounts." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationAccountConnectionRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationAccountConnectionRequest} IntegrationAccountConnectionRequest
     */
    IntegrationAccountConnectionRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationAccountConnectionRequest)
            return object;
        let message = new $root.IntegrationAccountConnectionRequest();
        if (object.accounts) {
            if (!Array.isArray(object.accounts))
                throw TypeError(".IntegrationAccountConnectionRequest.accounts: array expected");
            message.accounts = [];
            for (let i = 0; i < object.accounts.length; ++i) {
                if (typeof object.accounts[i] !== "object")
                    throw TypeError(".IntegrationAccountConnectionRequest.accounts: object expected");
                message.accounts[i] = $root.IntegrationAccountConnectionPair.fromObject(object.accounts[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationAccountConnectionRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationAccountConnectionRequest
     * @static
     * @param {IntegrationAccountConnectionRequest} message IntegrationAccountConnectionRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationAccountConnectionRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.accounts = [];
        if (message.accounts && message.accounts.length) {
            object.accounts = [];
            for (let j = 0; j < message.accounts.length; ++j)
                object.accounts[j] = $root.IntegrationAccountConnectionPair.toObject(message.accounts[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationAccountConnectionRequest to JSON.
     * @function toJSON
     * @memberof IntegrationAccountConnectionRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationAccountConnectionRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationAccountConnectionRequest;
})();

export const IntegrationAccountConnectionPair = $root.IntegrationAccountConnectionPair = (() => {

    /**
     * Properties of an IntegrationAccountConnectionPair.
     * @exports IIntegrationAccountConnectionPair
     * @interface IIntegrationAccountConnectionPair
     * @property {string} remoteAccountId IntegrationAccountConnectionPair remoteAccountId
     * @property {string} appAccountId IntegrationAccountConnectionPair appAccountId
     * @property {string|null} [cashAccountId] IntegrationAccountConnectionPair cashAccountId
     */

    /**
     * Constructs a new IntegrationAccountConnectionPair.
     * @exports IntegrationAccountConnectionPair
     * @classdesc Represents an IntegrationAccountConnectionPair.
     * @implements IIntegrationAccountConnectionPair
     * @constructor
     * @param {IIntegrationAccountConnectionPair=} [properties] Properties to set
     */
    function IntegrationAccountConnectionPair(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationAccountConnectionPair remoteAccountId.
     * @member {string} remoteAccountId
     * @memberof IntegrationAccountConnectionPair
     * @instance
     */
    IntegrationAccountConnectionPair.prototype.remoteAccountId = "";

    /**
     * IntegrationAccountConnectionPair appAccountId.
     * @member {string} appAccountId
     * @memberof IntegrationAccountConnectionPair
     * @instance
     */
    IntegrationAccountConnectionPair.prototype.appAccountId = "";

    /**
     * IntegrationAccountConnectionPair cashAccountId.
     * @member {string} cashAccountId
     * @memberof IntegrationAccountConnectionPair
     * @instance
     */
    IntegrationAccountConnectionPair.prototype.cashAccountId = "";

    /**
     * Creates a new IntegrationAccountConnectionPair instance using the specified properties.
     * @function create
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {IIntegrationAccountConnectionPair=} [properties] Properties to set
     * @returns {IntegrationAccountConnectionPair} IntegrationAccountConnectionPair instance
     */
    IntegrationAccountConnectionPair.create = function create(properties) {
        return new IntegrationAccountConnectionPair(properties);
    };

    /**
     * Encodes the specified IntegrationAccountConnectionPair message. Does not implicitly {@link IntegrationAccountConnectionPair.verify|verify} messages.
     * @function encode
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {IIntegrationAccountConnectionPair} message IntegrationAccountConnectionPair message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountConnectionPair.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.remoteAccountId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.appAccountId);
        if (message.cashAccountId != null && Object.hasOwnProperty.call(message, "cashAccountId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.cashAccountId);
        return writer;
    };

    /**
     * Encodes the specified IntegrationAccountConnectionPair message, length delimited. Does not implicitly {@link IntegrationAccountConnectionPair.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {IIntegrationAccountConnectionPair} message IntegrationAccountConnectionPair message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationAccountConnectionPair.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationAccountConnectionPair message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationAccountConnectionPair} IntegrationAccountConnectionPair
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountConnectionPair.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationAccountConnectionPair();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.remoteAccountId = reader.string();
                break;
            case 2:
                message.appAccountId = reader.string();
                break;
            case 3:
                message.cashAccountId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("remoteAccountId"))
            throw $util.ProtocolError("missing required 'remoteAccountId'", { instance: message });
        if (!message.hasOwnProperty("appAccountId"))
            throw $util.ProtocolError("missing required 'appAccountId'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationAccountConnectionPair message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationAccountConnectionPair} IntegrationAccountConnectionPair
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationAccountConnectionPair.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationAccountConnectionPair message.
     * @function verify
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationAccountConnectionPair.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.remoteAccountId))
            return "remoteAccountId: string expected";
        if (!$util.isString(message.appAccountId))
            return "appAccountId: string expected";
        if (message.cashAccountId != null && message.hasOwnProperty("cashAccountId"))
            if (!$util.isString(message.cashAccountId))
                return "cashAccountId: string expected";
        return null;
    };

    /**
     * Creates an IntegrationAccountConnectionPair message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationAccountConnectionPair} IntegrationAccountConnectionPair
     */
    IntegrationAccountConnectionPair.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationAccountConnectionPair)
            return object;
        let message = new $root.IntegrationAccountConnectionPair();
        if (object.remoteAccountId != null)
            message.remoteAccountId = String(object.remoteAccountId);
        if (object.appAccountId != null)
            message.appAccountId = String(object.appAccountId);
        if (object.cashAccountId != null)
            message.cashAccountId = String(object.cashAccountId);
        return message;
    };

    /**
     * Creates a plain object from an IntegrationAccountConnectionPair message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationAccountConnectionPair
     * @static
     * @param {IntegrationAccountConnectionPair} message IntegrationAccountConnectionPair
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationAccountConnectionPair.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.remoteAccountId = "";
            object.appAccountId = "";
            object.cashAccountId = "";
        }
        if (message.remoteAccountId != null && message.hasOwnProperty("remoteAccountId"))
            object.remoteAccountId = message.remoteAccountId;
        if (message.appAccountId != null && message.hasOwnProperty("appAccountId"))
            object.appAccountId = message.appAccountId;
        if (message.cashAccountId != null && message.hasOwnProperty("cashAccountId"))
            object.cashAccountId = message.cashAccountId;
        return object;
    };

    /**
     * Converts this IntegrationAccountConnectionPair to JSON.
     * @function toJSON
     * @memberof IntegrationAccountConnectionPair
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationAccountConnectionPair.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationAccountConnectionPair;
})();

export const IntegrationTransactionDuplicityRequest = $root.IntegrationTransactionDuplicityRequest = (() => {

    /**
     * Properties of an IntegrationTransactionDuplicityRequest.
     * @exports IIntegrationTransactionDuplicityRequest
     * @interface IIntegrationTransactionDuplicityRequest
     * @property {Array.<string>|null} [remoteTrxId] IntegrationTransactionDuplicityRequest remoteTrxId
     */

    /**
     * Constructs a new IntegrationTransactionDuplicityRequest.
     * @exports IntegrationTransactionDuplicityRequest
     * @classdesc Represents an IntegrationTransactionDuplicityRequest.
     * @implements IIntegrationTransactionDuplicityRequest
     * @constructor
     * @param {IIntegrationTransactionDuplicityRequest=} [properties] Properties to set
     */
    function IntegrationTransactionDuplicityRequest(properties) {
        this.remoteTrxId = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationTransactionDuplicityRequest remoteTrxId.
     * @member {Array.<string>} remoteTrxId
     * @memberof IntegrationTransactionDuplicityRequest
     * @instance
     */
    IntegrationTransactionDuplicityRequest.prototype.remoteTrxId = $util.emptyArray;

    /**
     * Creates a new IntegrationTransactionDuplicityRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {IIntegrationTransactionDuplicityRequest=} [properties] Properties to set
     * @returns {IntegrationTransactionDuplicityRequest} IntegrationTransactionDuplicityRequest instance
     */
    IntegrationTransactionDuplicityRequest.create = function create(properties) {
        return new IntegrationTransactionDuplicityRequest(properties);
    };

    /**
     * Encodes the specified IntegrationTransactionDuplicityRequest message. Does not implicitly {@link IntegrationTransactionDuplicityRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {IIntegrationTransactionDuplicityRequest} message IntegrationTransactionDuplicityRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationTransactionDuplicityRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.remoteTrxId != null && message.remoteTrxId.length)
            for (let i = 0; i < message.remoteTrxId.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.remoteTrxId[i]);
        return writer;
    };

    /**
     * Encodes the specified IntegrationTransactionDuplicityRequest message, length delimited. Does not implicitly {@link IntegrationTransactionDuplicityRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {IIntegrationTransactionDuplicityRequest} message IntegrationTransactionDuplicityRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationTransactionDuplicityRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationTransactionDuplicityRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationTransactionDuplicityRequest} IntegrationTransactionDuplicityRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationTransactionDuplicityRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationTransactionDuplicityRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.remoteTrxId && message.remoteTrxId.length))
                    message.remoteTrxId = [];
                message.remoteTrxId.push(reader.string());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationTransactionDuplicityRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationTransactionDuplicityRequest} IntegrationTransactionDuplicityRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationTransactionDuplicityRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationTransactionDuplicityRequest message.
     * @function verify
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationTransactionDuplicityRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.remoteTrxId != null && message.hasOwnProperty("remoteTrxId")) {
            if (!Array.isArray(message.remoteTrxId))
                return "remoteTrxId: array expected";
            for (let i = 0; i < message.remoteTrxId.length; ++i)
                if (!$util.isString(message.remoteTrxId[i]))
                    return "remoteTrxId: string[] expected";
        }
        return null;
    };

    /**
     * Creates an IntegrationTransactionDuplicityRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationTransactionDuplicityRequest} IntegrationTransactionDuplicityRequest
     */
    IntegrationTransactionDuplicityRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationTransactionDuplicityRequest)
            return object;
        let message = new $root.IntegrationTransactionDuplicityRequest();
        if (object.remoteTrxId) {
            if (!Array.isArray(object.remoteTrxId))
                throw TypeError(".IntegrationTransactionDuplicityRequest.remoteTrxId: array expected");
            message.remoteTrxId = [];
            for (let i = 0; i < object.remoteTrxId.length; ++i)
                message.remoteTrxId[i] = String(object.remoteTrxId[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationTransactionDuplicityRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationTransactionDuplicityRequest
     * @static
     * @param {IntegrationTransactionDuplicityRequest} message IntegrationTransactionDuplicityRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationTransactionDuplicityRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.remoteTrxId = [];
        if (message.remoteTrxId && message.remoteTrxId.length) {
            object.remoteTrxId = [];
            for (let j = 0; j < message.remoteTrxId.length; ++j)
                object.remoteTrxId[j] = message.remoteTrxId[j];
        }
        return object;
    };

    /**
     * Converts this IntegrationTransactionDuplicityRequest to JSON.
     * @function toJSON
     * @memberof IntegrationTransactionDuplicityRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationTransactionDuplicityRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationTransactionDuplicityRequest;
})();

/**
 * IntegrationConsentScope enum.
 * @exports IntegrationConsentScope
 * @enum {number}
 * @property {number} TRANSACTION_INFO=0 TRANSACTION_INFO value
 * @property {number} ACCOUNT_INFO=1 ACCOUNT_INFO value
 * @property {number} HOLDER_INFO=2 HOLDER_INFO value
 */
export const IntegrationConsentScope = $root.IntegrationConsentScope = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "TRANSACTION_INFO"] = 0;
    values[valuesById[1] = "ACCOUNT_INFO"] = 1;
    values[valuesById[2] = "HOLDER_INFO"] = 2;
    return values;
})();

export const IntegrationConsent = $root.IntegrationConsent = (() => {

    /**
     * Properties of an IntegrationConsent.
     * @exports IIntegrationConsent
     * @interface IIntegrationConsent
     * @property {string} loginId IntegrationConsent loginId
     * @property {Array.<IntegrationConsentScope>|null} [scopes] IntegrationConsent scopes
     * @property {IntegrationSource} source IntegrationConsent source
     * @property {number|Long} createdAt IntegrationConsent createdAt
     * @property {number|Long|null} [updatedAt] IntegrationConsent updatedAt
     * @property {number|Long|null} [expiresAt] IntegrationConsent expiresAt
     * @property {number|Long|null} [revokedAt] IntegrationConsent revokedAt
     */

    /**
     * Constructs a new IntegrationConsent.
     * @exports IntegrationConsent
     * @classdesc Represents an IntegrationConsent.
     * @implements IIntegrationConsent
     * @constructor
     * @param {IIntegrationConsent=} [properties] Properties to set
     */
    function IntegrationConsent(properties) {
        this.scopes = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationConsent loginId.
     * @member {string} loginId
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.loginId = "";

    /**
     * IntegrationConsent scopes.
     * @member {Array.<IntegrationConsentScope>} scopes
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.scopes = $util.emptyArray;

    /**
     * IntegrationConsent source.
     * @member {IntegrationSource} source
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.source = 0;

    /**
     * IntegrationConsent createdAt.
     * @member {number|Long} createdAt
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * IntegrationConsent updatedAt.
     * @member {number|Long} updatedAt
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.updatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * IntegrationConsent expiresAt.
     * @member {number|Long} expiresAt
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.expiresAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * IntegrationConsent revokedAt.
     * @member {number|Long} revokedAt
     * @memberof IntegrationConsent
     * @instance
     */
    IntegrationConsent.prototype.revokedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new IntegrationConsent instance using the specified properties.
     * @function create
     * @memberof IntegrationConsent
     * @static
     * @param {IIntegrationConsent=} [properties] Properties to set
     * @returns {IntegrationConsent} IntegrationConsent instance
     */
    IntegrationConsent.create = function create(properties) {
        return new IntegrationConsent(properties);
    };

    /**
     * Encodes the specified IntegrationConsent message. Does not implicitly {@link IntegrationConsent.verify|verify} messages.
     * @function encode
     * @memberof IntegrationConsent
     * @static
     * @param {IIntegrationConsent} message IntegrationConsent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConsent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.loginId);
        if (message.scopes != null && message.scopes.length)
            for (let i = 0; i < message.scopes.length; ++i)
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.scopes[i]);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.source);
        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createdAt);
        if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updatedAt);
        if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.expiresAt);
        if (message.revokedAt != null && Object.hasOwnProperty.call(message, "revokedAt"))
            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.revokedAt);
        return writer;
    };

    /**
     * Encodes the specified IntegrationConsent message, length delimited. Does not implicitly {@link IntegrationConsent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationConsent
     * @static
     * @param {IIntegrationConsent} message IntegrationConsent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConsent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationConsent message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationConsent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationConsent} IntegrationConsent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConsent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationConsent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.loginId = reader.string();
                break;
            case 2:
                if (!(message.scopes && message.scopes.length))
                    message.scopes = [];
                if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2)
                        message.scopes.push(reader.int32());
                } else
                    message.scopes.push(reader.int32());
                break;
            case 3:
                message.source = reader.int32();
                break;
            case 4:
                message.createdAt = reader.int64();
                break;
            case 5:
                message.updatedAt = reader.int64();
                break;
            case 6:
                message.expiresAt = reader.int64();
                break;
            case 7:
                message.revokedAt = reader.int64();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("loginId"))
            throw $util.ProtocolError("missing required 'loginId'", { instance: message });
        if (!message.hasOwnProperty("source"))
            throw $util.ProtocolError("missing required 'source'", { instance: message });
        if (!message.hasOwnProperty("createdAt"))
            throw $util.ProtocolError("missing required 'createdAt'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationConsent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationConsent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationConsent} IntegrationConsent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConsent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationConsent message.
     * @function verify
     * @memberof IntegrationConsent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationConsent.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.loginId))
            return "loginId: string expected";
        if (message.scopes != null && message.hasOwnProperty("scopes")) {
            if (!Array.isArray(message.scopes))
                return "scopes: array expected";
            for (let i = 0; i < message.scopes.length; ++i)
                switch (message.scopes[i]) {
                default:
                    return "scopes: enum value[] expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
        }
        switch (message.source) {
        default:
            return "source: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
            return "createdAt: integer|Long expected";
        if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
            if (!$util.isInteger(message.updatedAt) && !(message.updatedAt && $util.isInteger(message.updatedAt.low) && $util.isInteger(message.updatedAt.high)))
                return "updatedAt: integer|Long expected";
        if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
            if (!$util.isInteger(message.expiresAt) && !(message.expiresAt && $util.isInteger(message.expiresAt.low) && $util.isInteger(message.expiresAt.high)))
                return "expiresAt: integer|Long expected";
        if (message.revokedAt != null && message.hasOwnProperty("revokedAt"))
            if (!$util.isInteger(message.revokedAt) && !(message.revokedAt && $util.isInteger(message.revokedAt.low) && $util.isInteger(message.revokedAt.high)))
                return "revokedAt: integer|Long expected";
        return null;
    };

    /**
     * Creates an IntegrationConsent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationConsent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationConsent} IntegrationConsent
     */
    IntegrationConsent.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationConsent)
            return object;
        let message = new $root.IntegrationConsent();
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        if (object.scopes) {
            if (!Array.isArray(object.scopes))
                throw TypeError(".IntegrationConsent.scopes: array expected");
            message.scopes = [];
            for (let i = 0; i < object.scopes.length; ++i)
                switch (object.scopes[i]) {
                default:
                case "TRANSACTION_INFO":
                case 0:
                    message.scopes[i] = 0;
                    break;
                case "ACCOUNT_INFO":
                case 1:
                    message.scopes[i] = 1;
                    break;
                case "HOLDER_INFO":
                case 2:
                    message.scopes[i] = 2;
                    break;
                }
        }
        switch (object.source) {
        case "BUDGETBAKERS":
        case 0:
            message.source = 0;
            break;
        case "SALTEDGE":
        case 1:
            message.source = 1;
            break;
        case "CSAS":
        case 2:
            message.source = 2;
            break;
        case "YODLEE":
        case 3:
            message.source = 3;
            break;
        case "SALTEDGE_PARTNERS":
        case 4:
            message.source = 4;
            break;
        case "YODLEE_FASTLINK":
        case 5:
            message.source = 5;
            break;
        }
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.updatedAt != null)
            if ($util.Long)
                (message.updatedAt = $util.Long.fromValue(object.updatedAt)).unsigned = false;
            else if (typeof object.updatedAt === "string")
                message.updatedAt = parseInt(object.updatedAt, 10);
            else if (typeof object.updatedAt === "number")
                message.updatedAt = object.updatedAt;
            else if (typeof object.updatedAt === "object")
                message.updatedAt = new $util.LongBits(object.updatedAt.low >>> 0, object.updatedAt.high >>> 0).toNumber();
        if (object.expiresAt != null)
            if ($util.Long)
                (message.expiresAt = $util.Long.fromValue(object.expiresAt)).unsigned = false;
            else if (typeof object.expiresAt === "string")
                message.expiresAt = parseInt(object.expiresAt, 10);
            else if (typeof object.expiresAt === "number")
                message.expiresAt = object.expiresAt;
            else if (typeof object.expiresAt === "object")
                message.expiresAt = new $util.LongBits(object.expiresAt.low >>> 0, object.expiresAt.high >>> 0).toNumber();
        if (object.revokedAt != null)
            if ($util.Long)
                (message.revokedAt = $util.Long.fromValue(object.revokedAt)).unsigned = false;
            else if (typeof object.revokedAt === "string")
                message.revokedAt = parseInt(object.revokedAt, 10);
            else if (typeof object.revokedAt === "number")
                message.revokedAt = object.revokedAt;
            else if (typeof object.revokedAt === "object")
                message.revokedAt = new $util.LongBits(object.revokedAt.low >>> 0, object.revokedAt.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an IntegrationConsent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationConsent
     * @static
     * @param {IntegrationConsent} message IntegrationConsent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationConsent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.scopes = [];
        if (options.defaults) {
            object.loginId = "";
            object.source = options.enums === String ? "BUDGETBAKERS" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.updatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.updatedAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.expiresAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.expiresAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.revokedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.revokedAt = options.longs === String ? "0" : 0;
        }
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.scopes && message.scopes.length) {
            object.scopes = [];
            for (let j = 0; j < message.scopes.length; ++j)
                object.scopes[j] = options.enums === String ? $root.IntegrationConsentScope[message.scopes[j]] : message.scopes[j];
        }
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = options.enums === String ? $root.IntegrationSource[message.source] : message.source;
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.updatedAt != null && message.hasOwnProperty("updatedAt"))
            if (typeof message.updatedAt === "number")
                object.updatedAt = options.longs === String ? String(message.updatedAt) : message.updatedAt;
            else
                object.updatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAt) : options.longs === Number ? new $util.LongBits(message.updatedAt.low >>> 0, message.updatedAt.high >>> 0).toNumber() : message.updatedAt;
        if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
            if (typeof message.expiresAt === "number")
                object.expiresAt = options.longs === String ? String(message.expiresAt) : message.expiresAt;
            else
                object.expiresAt = options.longs === String ? $util.Long.prototype.toString.call(message.expiresAt) : options.longs === Number ? new $util.LongBits(message.expiresAt.low >>> 0, message.expiresAt.high >>> 0).toNumber() : message.expiresAt;
        if (message.revokedAt != null && message.hasOwnProperty("revokedAt"))
            if (typeof message.revokedAt === "number")
                object.revokedAt = options.longs === String ? String(message.revokedAt) : message.revokedAt;
            else
                object.revokedAt = options.longs === String ? $util.Long.prototype.toString.call(message.revokedAt) : options.longs === Number ? new $util.LongBits(message.revokedAt.low >>> 0, message.revokedAt.high >>> 0).toNumber() : message.revokedAt;
        return object;
    };

    /**
     * Converts this IntegrationConsent to JSON.
     * @function toJSON
     * @memberof IntegrationConsent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationConsent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationConsent;
})();

export const IntegrationConsents = $root.IntegrationConsents = (() => {

    /**
     * Properties of an IntegrationConsents.
     * @exports IIntegrationConsents
     * @interface IIntegrationConsents
     * @property {Array.<IIntegrationConsent>|null} [consents] IntegrationConsents consents
     */

    /**
     * Constructs a new IntegrationConsents.
     * @exports IntegrationConsents
     * @classdesc Represents an IntegrationConsents.
     * @implements IIntegrationConsents
     * @constructor
     * @param {IIntegrationConsents=} [properties] Properties to set
     */
    function IntegrationConsents(properties) {
        this.consents = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationConsents consents.
     * @member {Array.<IIntegrationConsent>} consents
     * @memberof IntegrationConsents
     * @instance
     */
    IntegrationConsents.prototype.consents = $util.emptyArray;

    /**
     * Creates a new IntegrationConsents instance using the specified properties.
     * @function create
     * @memberof IntegrationConsents
     * @static
     * @param {IIntegrationConsents=} [properties] Properties to set
     * @returns {IntegrationConsents} IntegrationConsents instance
     */
    IntegrationConsents.create = function create(properties) {
        return new IntegrationConsents(properties);
    };

    /**
     * Encodes the specified IntegrationConsents message. Does not implicitly {@link IntegrationConsents.verify|verify} messages.
     * @function encode
     * @memberof IntegrationConsents
     * @static
     * @param {IIntegrationConsents} message IntegrationConsents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConsents.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.consents != null && message.consents.length)
            for (let i = 0; i < message.consents.length; ++i)
                $root.IntegrationConsent.encode(message.consents[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified IntegrationConsents message, length delimited. Does not implicitly {@link IntegrationConsents.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationConsents
     * @static
     * @param {IIntegrationConsents} message IntegrationConsents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConsents.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationConsents message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationConsents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationConsents} IntegrationConsents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConsents.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationConsents();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.consents && message.consents.length))
                    message.consents = [];
                message.consents.push($root.IntegrationConsent.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an IntegrationConsents message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationConsents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationConsents} IntegrationConsents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConsents.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationConsents message.
     * @function verify
     * @memberof IntegrationConsents
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationConsents.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.consents != null && message.hasOwnProperty("consents")) {
            if (!Array.isArray(message.consents))
                return "consents: array expected";
            for (let i = 0; i < message.consents.length; ++i) {
                let error = $root.IntegrationConsent.verify(message.consents[i]);
                if (error)
                    return "consents." + error;
            }
        }
        return null;
    };

    /**
     * Creates an IntegrationConsents message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationConsents
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationConsents} IntegrationConsents
     */
    IntegrationConsents.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationConsents)
            return object;
        let message = new $root.IntegrationConsents();
        if (object.consents) {
            if (!Array.isArray(object.consents))
                throw TypeError(".IntegrationConsents.consents: array expected");
            message.consents = [];
            for (let i = 0; i < object.consents.length; ++i) {
                if (typeof object.consents[i] !== "object")
                    throw TypeError(".IntegrationConsents.consents: object expected");
                message.consents[i] = $root.IntegrationConsent.fromObject(object.consents[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationConsents message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationConsents
     * @static
     * @param {IntegrationConsents} message IntegrationConsents
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationConsents.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.consents = [];
        if (message.consents && message.consents.length) {
            object.consents = [];
            for (let j = 0; j < message.consents.length; ++j)
                object.consents[j] = $root.IntegrationConsent.toObject(message.consents[j], options);
        }
        return object;
    };

    /**
     * Converts this IntegrationConsents to JSON.
     * @function toJSON
     * @memberof IntegrationConsents
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationConsents.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationConsents;
})();

export const IntegrationConsentRevokeRequest = $root.IntegrationConsentRevokeRequest = (() => {

    /**
     * Properties of an IntegrationConsentRevokeRequest.
     * @exports IIntegrationConsentRevokeRequest
     * @interface IIntegrationConsentRevokeRequest
     * @property {string} loginId IntegrationConsentRevokeRequest loginId
     * @property {IntegrationSource} source IntegrationConsentRevokeRequest source
     */

    /**
     * Constructs a new IntegrationConsentRevokeRequest.
     * @exports IntegrationConsentRevokeRequest
     * @classdesc Represents an IntegrationConsentRevokeRequest.
     * @implements IIntegrationConsentRevokeRequest
     * @constructor
     * @param {IIntegrationConsentRevokeRequest=} [properties] Properties to set
     */
    function IntegrationConsentRevokeRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IntegrationConsentRevokeRequest loginId.
     * @member {string} loginId
     * @memberof IntegrationConsentRevokeRequest
     * @instance
     */
    IntegrationConsentRevokeRequest.prototype.loginId = "";

    /**
     * IntegrationConsentRevokeRequest source.
     * @member {IntegrationSource} source
     * @memberof IntegrationConsentRevokeRequest
     * @instance
     */
    IntegrationConsentRevokeRequest.prototype.source = 0;

    /**
     * Creates a new IntegrationConsentRevokeRequest instance using the specified properties.
     * @function create
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {IIntegrationConsentRevokeRequest=} [properties] Properties to set
     * @returns {IntegrationConsentRevokeRequest} IntegrationConsentRevokeRequest instance
     */
    IntegrationConsentRevokeRequest.create = function create(properties) {
        return new IntegrationConsentRevokeRequest(properties);
    };

    /**
     * Encodes the specified IntegrationConsentRevokeRequest message. Does not implicitly {@link IntegrationConsentRevokeRequest.verify|verify} messages.
     * @function encode
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {IIntegrationConsentRevokeRequest} message IntegrationConsentRevokeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConsentRevokeRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.loginId);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.source);
        return writer;
    };

    /**
     * Encodes the specified IntegrationConsentRevokeRequest message, length delimited. Does not implicitly {@link IntegrationConsentRevokeRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {IIntegrationConsentRevokeRequest} message IntegrationConsentRevokeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IntegrationConsentRevokeRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IntegrationConsentRevokeRequest message from the specified reader or buffer.
     * @function decode
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IntegrationConsentRevokeRequest} IntegrationConsentRevokeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConsentRevokeRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IntegrationConsentRevokeRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.loginId = reader.string();
                break;
            case 2:
                message.source = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("loginId"))
            throw $util.ProtocolError("missing required 'loginId'", { instance: message });
        if (!message.hasOwnProperty("source"))
            throw $util.ProtocolError("missing required 'source'", { instance: message });
        return message;
    };

    /**
     * Decodes an IntegrationConsentRevokeRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IntegrationConsentRevokeRequest} IntegrationConsentRevokeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IntegrationConsentRevokeRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IntegrationConsentRevokeRequest message.
     * @function verify
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IntegrationConsentRevokeRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.loginId))
            return "loginId: string expected";
        switch (message.source) {
        default:
            return "source: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        return null;
    };

    /**
     * Creates an IntegrationConsentRevokeRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IntegrationConsentRevokeRequest} IntegrationConsentRevokeRequest
     */
    IntegrationConsentRevokeRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.IntegrationConsentRevokeRequest)
            return object;
        let message = new $root.IntegrationConsentRevokeRequest();
        if (object.loginId != null)
            message.loginId = String(object.loginId);
        switch (object.source) {
        case "BUDGETBAKERS":
        case 0:
            message.source = 0;
            break;
        case "SALTEDGE":
        case 1:
            message.source = 1;
            break;
        case "CSAS":
        case 2:
            message.source = 2;
            break;
        case "YODLEE":
        case 3:
            message.source = 3;
            break;
        case "SALTEDGE_PARTNERS":
        case 4:
            message.source = 4;
            break;
        case "YODLEE_FASTLINK":
        case 5:
            message.source = 5;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from an IntegrationConsentRevokeRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IntegrationConsentRevokeRequest
     * @static
     * @param {IntegrationConsentRevokeRequest} message IntegrationConsentRevokeRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IntegrationConsentRevokeRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.loginId = "";
            object.source = options.enums === String ? "BUDGETBAKERS" : 0;
        }
        if (message.loginId != null && message.hasOwnProperty("loginId"))
            object.loginId = message.loginId;
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = options.enums === String ? $root.IntegrationSource[message.source] : message.source;
        return object;
    };

    /**
     * Converts this IntegrationConsentRevokeRequest to JSON.
     * @function toJSON
     * @memberof IntegrationConsentRevokeRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IntegrationConsentRevokeRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IntegrationConsentRevokeRequest;
})();

export const UsersProviderRestrictions = $root.UsersProviderRestrictions = (() => {

    /**
     * Properties of a UsersProviderRestrictions.
     * @exports IUsersProviderRestrictions
     * @interface IUsersProviderRestrictions
     * @property {Array.<IProviderRestrictions>|null} [restrictions] UsersProviderRestrictions restrictions
     */

    /**
     * Constructs a new UsersProviderRestrictions.
     * @exports UsersProviderRestrictions
     * @classdesc Represents a UsersProviderRestrictions.
     * @implements IUsersProviderRestrictions
     * @constructor
     * @param {IUsersProviderRestrictions=} [properties] Properties to set
     */
    function UsersProviderRestrictions(properties) {
        this.restrictions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UsersProviderRestrictions restrictions.
     * @member {Array.<IProviderRestrictions>} restrictions
     * @memberof UsersProviderRestrictions
     * @instance
     */
    UsersProviderRestrictions.prototype.restrictions = $util.emptyArray;

    /**
     * Creates a new UsersProviderRestrictions instance using the specified properties.
     * @function create
     * @memberof UsersProviderRestrictions
     * @static
     * @param {IUsersProviderRestrictions=} [properties] Properties to set
     * @returns {UsersProviderRestrictions} UsersProviderRestrictions instance
     */
    UsersProviderRestrictions.create = function create(properties) {
        return new UsersProviderRestrictions(properties);
    };

    /**
     * Encodes the specified UsersProviderRestrictions message. Does not implicitly {@link UsersProviderRestrictions.verify|verify} messages.
     * @function encode
     * @memberof UsersProviderRestrictions
     * @static
     * @param {IUsersProviderRestrictions} message UsersProviderRestrictions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UsersProviderRestrictions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.restrictions != null && message.restrictions.length)
            for (let i = 0; i < message.restrictions.length; ++i)
                $root.ProviderRestrictions.encode(message.restrictions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UsersProviderRestrictions message, length delimited. Does not implicitly {@link UsersProviderRestrictions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UsersProviderRestrictions
     * @static
     * @param {IUsersProviderRestrictions} message UsersProviderRestrictions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UsersProviderRestrictions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UsersProviderRestrictions message from the specified reader or buffer.
     * @function decode
     * @memberof UsersProviderRestrictions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UsersProviderRestrictions} UsersProviderRestrictions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UsersProviderRestrictions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UsersProviderRestrictions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.restrictions && message.restrictions.length))
                    message.restrictions = [];
                message.restrictions.push($root.ProviderRestrictions.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a UsersProviderRestrictions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UsersProviderRestrictions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UsersProviderRestrictions} UsersProviderRestrictions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UsersProviderRestrictions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UsersProviderRestrictions message.
     * @function verify
     * @memberof UsersProviderRestrictions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UsersProviderRestrictions.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.restrictions != null && message.hasOwnProperty("restrictions")) {
            if (!Array.isArray(message.restrictions))
                return "restrictions: array expected";
            for (let i = 0; i < message.restrictions.length; ++i) {
                let error = $root.ProviderRestrictions.verify(message.restrictions[i]);
                if (error)
                    return "restrictions." + error;
            }
        }
        return null;
    };

    /**
     * Creates a UsersProviderRestrictions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UsersProviderRestrictions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UsersProviderRestrictions} UsersProviderRestrictions
     */
    UsersProviderRestrictions.fromObject = function fromObject(object) {
        if (object instanceof $root.UsersProviderRestrictions)
            return object;
        let message = new $root.UsersProviderRestrictions();
        if (object.restrictions) {
            if (!Array.isArray(object.restrictions))
                throw TypeError(".UsersProviderRestrictions.restrictions: array expected");
            message.restrictions = [];
            for (let i = 0; i < object.restrictions.length; ++i) {
                if (typeof object.restrictions[i] !== "object")
                    throw TypeError(".UsersProviderRestrictions.restrictions: object expected");
                message.restrictions[i] = $root.ProviderRestrictions.fromObject(object.restrictions[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a UsersProviderRestrictions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UsersProviderRestrictions
     * @static
     * @param {UsersProviderRestrictions} message UsersProviderRestrictions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UsersProviderRestrictions.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.restrictions = [];
        if (message.restrictions && message.restrictions.length) {
            object.restrictions = [];
            for (let j = 0; j < message.restrictions.length; ++j)
                object.restrictions[j] = $root.ProviderRestrictions.toObject(message.restrictions[j], options);
        }
        return object;
    };

    /**
     * Converts this UsersProviderRestrictions to JSON.
     * @function toJSON
     * @memberof UsersProviderRestrictions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UsersProviderRestrictions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UsersProviderRestrictions;
})();

export const ProviderRestrictions = $root.ProviderRestrictions = (() => {

    /**
     * Properties of a ProviderRestrictions.
     * @exports IProviderRestrictions
     * @interface IProviderRestrictions
     * @property {Array.<string>|null} [loginIds] ProviderRestrictions loginIds
     * @property {string} providerName ProviderRestrictions providerName
     * @property {ProviderRestrictions.ProviderStatus} status ProviderRestrictions status
     * @property {Array.<ProviderRestrictions.PossibleIssue>|null} [possibleIssues] ProviderRestrictions possibleIssues
     * @property {string|null} [knowledgeBaseUrl] ProviderRestrictions knowledgeBaseUrl
     * @property {number|Long|null} [validUntil] ProviderRestrictions validUntil
     * @property {boolean|null} [supportedByOtherProvider] ProviderRestrictions supportedByOtherProvider
     */

    /**
     * Constructs a new ProviderRestrictions.
     * @exports ProviderRestrictions
     * @classdesc Represents a ProviderRestrictions.
     * @implements IProviderRestrictions
     * @constructor
     * @param {IProviderRestrictions=} [properties] Properties to set
     */
    function ProviderRestrictions(properties) {
        this.loginIds = [];
        this.possibleIssues = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderRestrictions loginIds.
     * @member {Array.<string>} loginIds
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.loginIds = $util.emptyArray;

    /**
     * ProviderRestrictions providerName.
     * @member {string} providerName
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.providerName = "";

    /**
     * ProviderRestrictions status.
     * @member {ProviderRestrictions.ProviderStatus} status
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.status = 0;

    /**
     * ProviderRestrictions possibleIssues.
     * @member {Array.<ProviderRestrictions.PossibleIssue>} possibleIssues
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.possibleIssues = $util.emptyArray;

    /**
     * ProviderRestrictions knowledgeBaseUrl.
     * @member {string} knowledgeBaseUrl
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.knowledgeBaseUrl = "";

    /**
     * ProviderRestrictions validUntil.
     * @member {number|Long} validUntil
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.validUntil = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ProviderRestrictions supportedByOtherProvider.
     * @member {boolean} supportedByOtherProvider
     * @memberof ProviderRestrictions
     * @instance
     */
    ProviderRestrictions.prototype.supportedByOtherProvider = false;

    /**
     * Creates a new ProviderRestrictions instance using the specified properties.
     * @function create
     * @memberof ProviderRestrictions
     * @static
     * @param {IProviderRestrictions=} [properties] Properties to set
     * @returns {ProviderRestrictions} ProviderRestrictions instance
     */
    ProviderRestrictions.create = function create(properties) {
        return new ProviderRestrictions(properties);
    };

    /**
     * Encodes the specified ProviderRestrictions message. Does not implicitly {@link ProviderRestrictions.verify|verify} messages.
     * @function encode
     * @memberof ProviderRestrictions
     * @static
     * @param {IProviderRestrictions} message ProviderRestrictions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderRestrictions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.loginIds != null && message.loginIds.length)
            for (let i = 0; i < message.loginIds.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.loginIds[i]);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerName);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
        if (message.possibleIssues != null && message.possibleIssues.length)
            for (let i = 0; i < message.possibleIssues.length; ++i)
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.possibleIssues[i]);
        if (message.knowledgeBaseUrl != null && Object.hasOwnProperty.call(message, "knowledgeBaseUrl"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.knowledgeBaseUrl);
        if (message.validUntil != null && Object.hasOwnProperty.call(message, "validUntil"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.validUntil);
        if (message.supportedByOtherProvider != null && Object.hasOwnProperty.call(message, "supportedByOtherProvider"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.supportedByOtherProvider);
        return writer;
    };

    /**
     * Encodes the specified ProviderRestrictions message, length delimited. Does not implicitly {@link ProviderRestrictions.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderRestrictions
     * @static
     * @param {IProviderRestrictions} message ProviderRestrictions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderRestrictions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderRestrictions message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderRestrictions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderRestrictions} ProviderRestrictions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderRestrictions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderRestrictions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.loginIds && message.loginIds.length))
                    message.loginIds = [];
                message.loginIds.push(reader.string());
                break;
            case 2:
                message.providerName = reader.string();
                break;
            case 3:
                message.status = reader.int32();
                break;
            case 4:
                if (!(message.possibleIssues && message.possibleIssues.length))
                    message.possibleIssues = [];
                if ((tag & 7) === 2) {
                    let end2 = reader.uint32() + reader.pos;
                    while (reader.pos < end2)
                        message.possibleIssues.push(reader.int32());
                } else
                    message.possibleIssues.push(reader.int32());
                break;
            case 5:
                message.knowledgeBaseUrl = reader.string();
                break;
            case 6:
                message.validUntil = reader.int64();
                break;
            case 7:
                message.supportedByOtherProvider = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("providerName"))
            throw $util.ProtocolError("missing required 'providerName'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        return message;
    };

    /**
     * Decodes a ProviderRestrictions message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderRestrictions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderRestrictions} ProviderRestrictions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderRestrictions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderRestrictions message.
     * @function verify
     * @memberof ProviderRestrictions
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderRestrictions.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.loginIds != null && message.hasOwnProperty("loginIds")) {
            if (!Array.isArray(message.loginIds))
                return "loginIds: array expected";
            for (let i = 0; i < message.loginIds.length; ++i)
                if (!$util.isString(message.loginIds[i]))
                    return "loginIds: string[] expected";
        }
        if (!$util.isString(message.providerName))
            return "providerName: string expected";
        switch (message.status) {
        default:
            return "status: enum value expected";
        case 0:
        case 1:
        case 2:
            break;
        }
        if (message.possibleIssues != null && message.hasOwnProperty("possibleIssues")) {
            if (!Array.isArray(message.possibleIssues))
                return "possibleIssues: array expected";
            for (let i = 0; i < message.possibleIssues.length; ++i)
                switch (message.possibleIssues[i]) {
                default:
                    return "possibleIssues: enum value[] expected";
                case 0:
                case 1:
                    break;
                }
        }
        if (message.knowledgeBaseUrl != null && message.hasOwnProperty("knowledgeBaseUrl"))
            if (!$util.isString(message.knowledgeBaseUrl))
                return "knowledgeBaseUrl: string expected";
        if (message.validUntil != null && message.hasOwnProperty("validUntil"))
            if (!$util.isInteger(message.validUntil) && !(message.validUntil && $util.isInteger(message.validUntil.low) && $util.isInteger(message.validUntil.high)))
                return "validUntil: integer|Long expected";
        if (message.supportedByOtherProvider != null && message.hasOwnProperty("supportedByOtherProvider"))
            if (typeof message.supportedByOtherProvider !== "boolean")
                return "supportedByOtherProvider: boolean expected";
        return null;
    };

    /**
     * Creates a ProviderRestrictions message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderRestrictions
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderRestrictions} ProviderRestrictions
     */
    ProviderRestrictions.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderRestrictions)
            return object;
        let message = new $root.ProviderRestrictions();
        if (object.loginIds) {
            if (!Array.isArray(object.loginIds))
                throw TypeError(".ProviderRestrictions.loginIds: array expected");
            message.loginIds = [];
            for (let i = 0; i < object.loginIds.length; ++i)
                message.loginIds[i] = String(object.loginIds[i]);
        }
        if (object.providerName != null)
            message.providerName = String(object.providerName);
        switch (object.status) {
        case "Active":
        case 0:
            message.status = 0;
            break;
        case "TemporaryDisabled":
        case 1:
            message.status = 1;
            break;
        case "Disabled":
        case 2:
            message.status = 2;
            break;
        }
        if (object.possibleIssues) {
            if (!Array.isArray(object.possibleIssues))
                throw TypeError(".ProviderRestrictions.possibleIssues: array expected");
            message.possibleIssues = [];
            for (let i = 0; i < object.possibleIssues.length; ++i)
                switch (object.possibleIssues[i]) {
                default:
                case "General":
                case 0:
                    message.possibleIssues[i] = 0;
                    break;
                case "TransactionDuplicity":
                case 1:
                    message.possibleIssues[i] = 1;
                    break;
                }
        }
        if (object.knowledgeBaseUrl != null)
            message.knowledgeBaseUrl = String(object.knowledgeBaseUrl);
        if (object.validUntil != null)
            if ($util.Long)
                (message.validUntil = $util.Long.fromValue(object.validUntil)).unsigned = false;
            else if (typeof object.validUntil === "string")
                message.validUntil = parseInt(object.validUntil, 10);
            else if (typeof object.validUntil === "number")
                message.validUntil = object.validUntil;
            else if (typeof object.validUntil === "object")
                message.validUntil = new $util.LongBits(object.validUntil.low >>> 0, object.validUntil.high >>> 0).toNumber();
        if (object.supportedByOtherProvider != null)
            message.supportedByOtherProvider = Boolean(object.supportedByOtherProvider);
        return message;
    };

    /**
     * Creates a plain object from a ProviderRestrictions message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderRestrictions
     * @static
     * @param {ProviderRestrictions} message ProviderRestrictions
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderRestrictions.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.loginIds = [];
            object.possibleIssues = [];
        }
        if (options.defaults) {
            object.providerName = "";
            object.status = options.enums === String ? "Active" : 0;
            object.knowledgeBaseUrl = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.validUntil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.validUntil = options.longs === String ? "0" : 0;
            object.supportedByOtherProvider = false;
        }
        if (message.loginIds && message.loginIds.length) {
            object.loginIds = [];
            for (let j = 0; j < message.loginIds.length; ++j)
                object.loginIds[j] = message.loginIds[j];
        }
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            object.providerName = message.providerName;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.ProviderRestrictions.ProviderStatus[message.status] : message.status;
        if (message.possibleIssues && message.possibleIssues.length) {
            object.possibleIssues = [];
            for (let j = 0; j < message.possibleIssues.length; ++j)
                object.possibleIssues[j] = options.enums === String ? $root.ProviderRestrictions.PossibleIssue[message.possibleIssues[j]] : message.possibleIssues[j];
        }
        if (message.knowledgeBaseUrl != null && message.hasOwnProperty("knowledgeBaseUrl"))
            object.knowledgeBaseUrl = message.knowledgeBaseUrl;
        if (message.validUntil != null && message.hasOwnProperty("validUntil"))
            if (typeof message.validUntil === "number")
                object.validUntil = options.longs === String ? String(message.validUntil) : message.validUntil;
            else
                object.validUntil = options.longs === String ? $util.Long.prototype.toString.call(message.validUntil) : options.longs === Number ? new $util.LongBits(message.validUntil.low >>> 0, message.validUntil.high >>> 0).toNumber() : message.validUntil;
        if (message.supportedByOtherProvider != null && message.hasOwnProperty("supportedByOtherProvider"))
            object.supportedByOtherProvider = message.supportedByOtherProvider;
        return object;
    };

    /**
     * Converts this ProviderRestrictions to JSON.
     * @function toJSON
     * @memberof ProviderRestrictions
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderRestrictions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * ProviderStatus enum.
     * @name ProviderRestrictions.ProviderStatus
     * @enum {number}
     * @property {number} Active=0 Active value
     * @property {number} TemporaryDisabled=1 TemporaryDisabled value
     * @property {number} Disabled=2 Disabled value
     */
    ProviderRestrictions.ProviderStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Active"] = 0;
        values[valuesById[1] = "TemporaryDisabled"] = 1;
        values[valuesById[2] = "Disabled"] = 2;
        return values;
    })();

    /**
     * PossibleIssue enum.
     * @name ProviderRestrictions.PossibleIssue
     * @enum {number}
     * @property {number} General=0 General value
     * @property {number} TransactionDuplicity=1 TransactionDuplicity value
     */
    ProviderRestrictions.PossibleIssue = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "General"] = 0;
        values[valuesById[1] = "TransactionDuplicity"] = 1;
        return values;
    })();

    return ProviderRestrictions;
})();

export const ApiToken = $root.ApiToken = (() => {

    /**
     * Properties of an ApiToken.
     * @exports IApiToken
     * @interface IApiToken
     * @property {string} token ApiToken token
     */

    /**
     * Constructs a new ApiToken.
     * @exports ApiToken
     * @classdesc Represents an ApiToken.
     * @implements IApiToken
     * @constructor
     * @param {IApiToken=} [properties] Properties to set
     */
    function ApiToken(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ApiToken token.
     * @member {string} token
     * @memberof ApiToken
     * @instance
     */
    ApiToken.prototype.token = "";

    /**
     * Creates a new ApiToken instance using the specified properties.
     * @function create
     * @memberof ApiToken
     * @static
     * @param {IApiToken=} [properties] Properties to set
     * @returns {ApiToken} ApiToken instance
     */
    ApiToken.create = function create(properties) {
        return new ApiToken(properties);
    };

    /**
     * Encodes the specified ApiToken message. Does not implicitly {@link ApiToken.verify|verify} messages.
     * @function encode
     * @memberof ApiToken
     * @static
     * @param {IApiToken} message ApiToken message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiToken.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
        return writer;
    };

    /**
     * Encodes the specified ApiToken message, length delimited. Does not implicitly {@link ApiToken.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ApiToken
     * @static
     * @param {IApiToken} message ApiToken message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ApiToken.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ApiToken message from the specified reader or buffer.
     * @function decode
     * @memberof ApiToken
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ApiToken} ApiToken
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiToken.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ApiToken();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.token = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("token"))
            throw $util.ProtocolError("missing required 'token'", { instance: message });
        return message;
    };

    /**
     * Decodes an ApiToken message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ApiToken
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ApiToken} ApiToken
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ApiToken.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ApiToken message.
     * @function verify
     * @memberof ApiToken
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ApiToken.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.token))
            return "token: string expected";
        return null;
    };

    /**
     * Creates an ApiToken message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ApiToken
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ApiToken} ApiToken
     */
    ApiToken.fromObject = function fromObject(object) {
        if (object instanceof $root.ApiToken)
            return object;
        let message = new $root.ApiToken();
        if (object.token != null)
            message.token = String(object.token);
        return message;
    };

    /**
     * Creates a plain object from an ApiToken message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ApiToken
     * @static
     * @param {ApiToken} message ApiToken
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ApiToken.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.token = "";
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        return object;
    };

    /**
     * Converts this ApiToken to JSON.
     * @function toJSON
     * @memberof ApiToken
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ApiToken.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ApiToken;
})();

export const IdResponse = $root.IdResponse = (() => {

    /**
     * Properties of an IdResponse.
     * @exports IIdResponse
     * @interface IIdResponse
     * @property {string} id IdResponse id
     */

    /**
     * Constructs a new IdResponse.
     * @exports IdResponse
     * @classdesc Represents an IdResponse.
     * @implements IIdResponse
     * @constructor
     * @param {IIdResponse=} [properties] Properties to set
     */
    function IdResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IdResponse id.
     * @member {string} id
     * @memberof IdResponse
     * @instance
     */
    IdResponse.prototype.id = "";

    /**
     * Creates a new IdResponse instance using the specified properties.
     * @function create
     * @memberof IdResponse
     * @static
     * @param {IIdResponse=} [properties] Properties to set
     * @returns {IdResponse} IdResponse instance
     */
    IdResponse.create = function create(properties) {
        return new IdResponse(properties);
    };

    /**
     * Encodes the specified IdResponse message. Does not implicitly {@link IdResponse.verify|verify} messages.
     * @function encode
     * @memberof IdResponse
     * @static
     * @param {IIdResponse} message IdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IdResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        return writer;
    };

    /**
     * Encodes the specified IdResponse message, length delimited. Does not implicitly {@link IdResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof IdResponse
     * @static
     * @param {IIdResponse} message IdResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IdResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an IdResponse message from the specified reader or buffer.
     * @function decode
     * @memberof IdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IdResponse} IdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IdResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IdResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        return message;
    };

    /**
     * Decodes an IdResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof IdResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {IdResponse} IdResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IdResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an IdResponse message.
     * @function verify
     * @memberof IdResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    IdResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        return null;
    };

    /**
     * Creates an IdResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof IdResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {IdResponse} IdResponse
     */
    IdResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.IdResponse)
            return object;
        let message = new $root.IdResponse();
        if (object.id != null)
            message.id = String(object.id);
        return message;
    };

    /**
     * Creates a plain object from an IdResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof IdResponse
     * @static
     * @param {IdResponse} message IdResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    IdResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.id = "";
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        return object;
    };

    /**
     * Converts this IdResponse to JSON.
     * @function toJSON
     * @memberof IdResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    IdResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return IdResponse;
})();

/**
 * ImportErrorType enum.
 * @exports ImportErrorType
 * @enum {number}
 * @property {number} ItemNotFound=0 ItemNotFound value
 * @property {number} FileNotFound=1 FileNotFound value
 * @property {number} CurrencyNotFound=2 CurrencyNotFound value
 * @property {number} SettingsNotFound=3 SettingsNotFound value
 * @property {number} Conflict=4 Conflict value
 * @property {number} PreconditionFailed=5 PreconditionFailed value
 * @property {number} InternalError=6 InternalError value
 * @property {number} MissingRequiredField=7 MissingRequiredField value
 * @property {number} BadDelimiter=8 BadDelimiter value
 * @property {number} NumberFormat=9 NumberFormat value
 * @property {number} DateFormat=10 DateFormat value
 * @property {number} CurrencyFormat=11 CurrencyFormat value
 * @property {number} GenerateEmailError=12 GenerateEmailError value
 * @property {number} SavedSettingsNotFound=13 SavedSettingsNotFound value
 * @property {number} IncomeExpenseFilled=14 IncomeExpenseFilled value
 * @property {number} UnexpectedLineLength=15 UnexpectedLineLength value
 */
export const ImportErrorType = $root.ImportErrorType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "ItemNotFound"] = 0;
    values[valuesById[1] = "FileNotFound"] = 1;
    values[valuesById[2] = "CurrencyNotFound"] = 2;
    values[valuesById[3] = "SettingsNotFound"] = 3;
    values[valuesById[4] = "Conflict"] = 4;
    values[valuesById[5] = "PreconditionFailed"] = 5;
    values[valuesById[6] = "InternalError"] = 6;
    values[valuesById[7] = "MissingRequiredField"] = 7;
    values[valuesById[8] = "BadDelimiter"] = 8;
    values[valuesById[9] = "NumberFormat"] = 9;
    values[valuesById[10] = "DateFormat"] = 10;
    values[valuesById[11] = "CurrencyFormat"] = 11;
    values[valuesById[12] = "GenerateEmailError"] = 12;
    values[valuesById[13] = "SavedSettingsNotFound"] = 13;
    values[valuesById[14] = "IncomeExpenseFilled"] = 14;
    values[valuesById[15] = "UnexpectedLineLength"] = 15;
    return values;
})();

export const ImportError = $root.ImportError = (() => {

    /**
     * Properties of an ImportError.
     * @exports IImportError
     * @interface IImportError
     * @property {ImportErrorType} type ImportError type
     * @property {IImportParseError|null} [error] ImportError error
     */

    /**
     * Constructs a new ImportError.
     * @exports ImportError
     * @classdesc Represents an ImportError.
     * @implements IImportError
     * @constructor
     * @param {IImportError=} [properties] Properties to set
     */
    function ImportError(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportError type.
     * @member {ImportErrorType} type
     * @memberof ImportError
     * @instance
     */
    ImportError.prototype.type = 0;

    /**
     * ImportError error.
     * @member {IImportParseError|null|undefined} error
     * @memberof ImportError
     * @instance
     */
    ImportError.prototype.error = null;

    /**
     * Creates a new ImportError instance using the specified properties.
     * @function create
     * @memberof ImportError
     * @static
     * @param {IImportError=} [properties] Properties to set
     * @returns {ImportError} ImportError instance
     */
    ImportError.create = function create(properties) {
        return new ImportError(properties);
    };

    /**
     * Encodes the specified ImportError message. Does not implicitly {@link ImportError.verify|verify} messages.
     * @function encode
     * @memberof ImportError
     * @static
     * @param {IImportError} message ImportError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportError.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            $root.ImportParseError.encode(message.error, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ImportError message, length delimited. Does not implicitly {@link ImportError.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportError
     * @static
     * @param {IImportError} message ImportError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportError.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportError message from the specified reader or buffer.
     * @function decode
     * @memberof ImportError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportError} ImportError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportError.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportError();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.error = $root.ImportParseError.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportError message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportError} ImportError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportError.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportError message.
     * @function verify
     * @memberof ImportError
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportError.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
            break;
        }
        if (message.error != null && message.hasOwnProperty("error")) {
            let error = $root.ImportParseError.verify(message.error);
            if (error)
                return "error." + error;
        }
        return null;
    };

    /**
     * Creates an ImportError message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportError
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportError} ImportError
     */
    ImportError.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportError)
            return object;
        let message = new $root.ImportError();
        switch (object.type) {
        case "ItemNotFound":
        case 0:
            message.type = 0;
            break;
        case "FileNotFound":
        case 1:
            message.type = 1;
            break;
        case "CurrencyNotFound":
        case 2:
            message.type = 2;
            break;
        case "SettingsNotFound":
        case 3:
            message.type = 3;
            break;
        case "Conflict":
        case 4:
            message.type = 4;
            break;
        case "PreconditionFailed":
        case 5:
            message.type = 5;
            break;
        case "InternalError":
        case 6:
            message.type = 6;
            break;
        case "MissingRequiredField":
        case 7:
            message.type = 7;
            break;
        case "BadDelimiter":
        case 8:
            message.type = 8;
            break;
        case "NumberFormat":
        case 9:
            message.type = 9;
            break;
        case "DateFormat":
        case 10:
            message.type = 10;
            break;
        case "CurrencyFormat":
        case 11:
            message.type = 11;
            break;
        case "GenerateEmailError":
        case 12:
            message.type = 12;
            break;
        case "SavedSettingsNotFound":
        case 13:
            message.type = 13;
            break;
        case "IncomeExpenseFilled":
        case 14:
            message.type = 14;
            break;
        case "UnexpectedLineLength":
        case 15:
            message.type = 15;
            break;
        }
        if (object.error != null) {
            if (typeof object.error !== "object")
                throw TypeError(".ImportError.error: object expected");
            message.error = $root.ImportParseError.fromObject(object.error);
        }
        return message;
    };

    /**
     * Creates a plain object from an ImportError message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportError
     * @static
     * @param {ImportError} message ImportError
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportError.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "ItemNotFound" : 0;
            object.error = null;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ImportErrorType[message.type] : message.type;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = $root.ImportParseError.toObject(message.error, options);
        return object;
    };

    /**
     * Converts this ImportError to JSON.
     * @function toJSON
     * @memberof ImportError
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportError.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportError;
})();

export const ImportParseError = $root.ImportParseError = (() => {

    /**
     * Properties of an ImportParseError.
     * @exports IImportParseError
     * @interface IImportParseError
     * @property {Array.<IImportErrorToken>|null} [tokens] ImportParseError tokens
     * @property {number|null} [line] ImportParseError line
     */

    /**
     * Constructs a new ImportParseError.
     * @exports ImportParseError
     * @classdesc Represents an ImportParseError.
     * @implements IImportParseError
     * @constructor
     * @param {IImportParseError=} [properties] Properties to set
     */
    function ImportParseError(properties) {
        this.tokens = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportParseError tokens.
     * @member {Array.<IImportErrorToken>} tokens
     * @memberof ImportParseError
     * @instance
     */
    ImportParseError.prototype.tokens = $util.emptyArray;

    /**
     * ImportParseError line.
     * @member {number} line
     * @memberof ImportParseError
     * @instance
     */
    ImportParseError.prototype.line = 0;

    /**
     * Creates a new ImportParseError instance using the specified properties.
     * @function create
     * @memberof ImportParseError
     * @static
     * @param {IImportParseError=} [properties] Properties to set
     * @returns {ImportParseError} ImportParseError instance
     */
    ImportParseError.create = function create(properties) {
        return new ImportParseError(properties);
    };

    /**
     * Encodes the specified ImportParseError message. Does not implicitly {@link ImportParseError.verify|verify} messages.
     * @function encode
     * @memberof ImportParseError
     * @static
     * @param {IImportParseError} message ImportParseError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParseError.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tokens != null && message.tokens.length)
            for (let i = 0; i < message.tokens.length; ++i)
                $root.ImportErrorToken.encode(message.tokens[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.line != null && Object.hasOwnProperty.call(message, "line"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.line);
        return writer;
    };

    /**
     * Encodes the specified ImportParseError message, length delimited. Does not implicitly {@link ImportParseError.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportParseError
     * @static
     * @param {IImportParseError} message ImportParseError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParseError.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportParseError message from the specified reader or buffer.
     * @function decode
     * @memberof ImportParseError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportParseError} ImportParseError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParseError.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportParseError();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.tokens && message.tokens.length))
                    message.tokens = [];
                message.tokens.push($root.ImportErrorToken.decode(reader, reader.uint32()));
                break;
            case 2:
                message.line = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ImportParseError message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportParseError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportParseError} ImportParseError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParseError.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportParseError message.
     * @function verify
     * @memberof ImportParseError
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportParseError.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tokens != null && message.hasOwnProperty("tokens")) {
            if (!Array.isArray(message.tokens))
                return "tokens: array expected";
            for (let i = 0; i < message.tokens.length; ++i) {
                let error = $root.ImportErrorToken.verify(message.tokens[i]);
                if (error)
                    return "tokens." + error;
            }
        }
        if (message.line != null && message.hasOwnProperty("line"))
            if (!$util.isInteger(message.line))
                return "line: integer expected";
        return null;
    };

    /**
     * Creates an ImportParseError message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportParseError
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportParseError} ImportParseError
     */
    ImportParseError.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportParseError)
            return object;
        let message = new $root.ImportParseError();
        if (object.tokens) {
            if (!Array.isArray(object.tokens))
                throw TypeError(".ImportParseError.tokens: array expected");
            message.tokens = [];
            for (let i = 0; i < object.tokens.length; ++i) {
                if (typeof object.tokens[i] !== "object")
                    throw TypeError(".ImportParseError.tokens: object expected");
                message.tokens[i] = $root.ImportErrorToken.fromObject(object.tokens[i]);
            }
        }
        if (object.line != null)
            message.line = object.line | 0;
        return message;
    };

    /**
     * Creates a plain object from an ImportParseError message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportParseError
     * @static
     * @param {ImportParseError} message ImportParseError
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportParseError.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.tokens = [];
        if (options.defaults)
            object.line = 0;
        if (message.tokens && message.tokens.length) {
            object.tokens = [];
            for (let j = 0; j < message.tokens.length; ++j)
                object.tokens[j] = $root.ImportErrorToken.toObject(message.tokens[j], options);
        }
        if (message.line != null && message.hasOwnProperty("line"))
            object.line = message.line;
        return object;
    };

    /**
     * Converts this ImportParseError to JSON.
     * @function toJSON
     * @memberof ImportParseError
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportParseError.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportParseError;
})();

export const ImportErrorToken = $root.ImportErrorToken = (() => {

    /**
     * Properties of an ImportErrorToken.
     * @exports IImportErrorToken
     * @interface IImportErrorToken
     * @property {ImportErrorType} type ImportErrorToken type
     * @property {string} value ImportErrorToken value
     * @property {number|null} [colIndex] ImportErrorToken colIndex
     */

    /**
     * Constructs a new ImportErrorToken.
     * @exports ImportErrorToken
     * @classdesc Represents an ImportErrorToken.
     * @implements IImportErrorToken
     * @constructor
     * @param {IImportErrorToken=} [properties] Properties to set
     */
    function ImportErrorToken(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportErrorToken type.
     * @member {ImportErrorType} type
     * @memberof ImportErrorToken
     * @instance
     */
    ImportErrorToken.prototype.type = 0;

    /**
     * ImportErrorToken value.
     * @member {string} value
     * @memberof ImportErrorToken
     * @instance
     */
    ImportErrorToken.prototype.value = "";

    /**
     * ImportErrorToken colIndex.
     * @member {number} colIndex
     * @memberof ImportErrorToken
     * @instance
     */
    ImportErrorToken.prototype.colIndex = 0;

    /**
     * Creates a new ImportErrorToken instance using the specified properties.
     * @function create
     * @memberof ImportErrorToken
     * @static
     * @param {IImportErrorToken=} [properties] Properties to set
     * @returns {ImportErrorToken} ImportErrorToken instance
     */
    ImportErrorToken.create = function create(properties) {
        return new ImportErrorToken(properties);
    };

    /**
     * Encodes the specified ImportErrorToken message. Does not implicitly {@link ImportErrorToken.verify|verify} messages.
     * @function encode
     * @memberof ImportErrorToken
     * @static
     * @param {IImportErrorToken} message ImportErrorToken message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportErrorToken.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        if (message.colIndex != null && Object.hasOwnProperty.call(message, "colIndex"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.colIndex);
        return writer;
    };

    /**
     * Encodes the specified ImportErrorToken message, length delimited. Does not implicitly {@link ImportErrorToken.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportErrorToken
     * @static
     * @param {IImportErrorToken} message ImportErrorToken message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportErrorToken.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportErrorToken message from the specified reader or buffer.
     * @function decode
     * @memberof ImportErrorToken
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportErrorToken} ImportErrorToken
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportErrorToken.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportErrorToken();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.value = reader.string();
                break;
            case 3:
                message.colIndex = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("value"))
            throw $util.ProtocolError("missing required 'value'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportErrorToken message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportErrorToken
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportErrorToken} ImportErrorToken
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportErrorToken.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportErrorToken message.
     * @function verify
     * @memberof ImportErrorToken
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportErrorToken.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
            break;
        }
        if (!$util.isString(message.value))
            return "value: string expected";
        if (message.colIndex != null && message.hasOwnProperty("colIndex"))
            if (!$util.isInteger(message.colIndex))
                return "colIndex: integer expected";
        return null;
    };

    /**
     * Creates an ImportErrorToken message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportErrorToken
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportErrorToken} ImportErrorToken
     */
    ImportErrorToken.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportErrorToken)
            return object;
        let message = new $root.ImportErrorToken();
        switch (object.type) {
        case "ItemNotFound":
        case 0:
            message.type = 0;
            break;
        case "FileNotFound":
        case 1:
            message.type = 1;
            break;
        case "CurrencyNotFound":
        case 2:
            message.type = 2;
            break;
        case "SettingsNotFound":
        case 3:
            message.type = 3;
            break;
        case "Conflict":
        case 4:
            message.type = 4;
            break;
        case "PreconditionFailed":
        case 5:
            message.type = 5;
            break;
        case "InternalError":
        case 6:
            message.type = 6;
            break;
        case "MissingRequiredField":
        case 7:
            message.type = 7;
            break;
        case "BadDelimiter":
        case 8:
            message.type = 8;
            break;
        case "NumberFormat":
        case 9:
            message.type = 9;
            break;
        case "DateFormat":
        case 10:
            message.type = 10;
            break;
        case "CurrencyFormat":
        case 11:
            message.type = 11;
            break;
        case "GenerateEmailError":
        case 12:
            message.type = 12;
            break;
        case "SavedSettingsNotFound":
        case 13:
            message.type = 13;
            break;
        case "IncomeExpenseFilled":
        case 14:
            message.type = 14;
            break;
        case "UnexpectedLineLength":
        case 15:
            message.type = 15;
            break;
        }
        if (object.value != null)
            message.value = String(object.value);
        if (object.colIndex != null)
            message.colIndex = object.colIndex | 0;
        return message;
    };

    /**
     * Creates a plain object from an ImportErrorToken message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportErrorToken
     * @static
     * @param {ImportErrorToken} message ImportErrorToken
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportErrorToken.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "ItemNotFound" : 0;
            object.value = "";
            object.colIndex = 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ImportErrorType[message.type] : message.type;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        if (message.colIndex != null && message.hasOwnProperty("colIndex"))
            object.colIndex = message.colIndex;
        return object;
    };

    /**
     * Converts this ImportErrorToken to JSON.
     * @function toJSON
     * @memberof ImportErrorToken
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportErrorToken.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportErrorToken;
})();

export const ImportActivateRequest = $root.ImportActivateRequest = (() => {

    /**
     * Properties of an ImportActivateRequest.
     * @exports IImportActivateRequest
     * @interface IImportActivateRequest
     * @property {string} accountId ImportActivateRequest accountId
     */

    /**
     * Constructs a new ImportActivateRequest.
     * @exports ImportActivateRequest
     * @classdesc Represents an ImportActivateRequest.
     * @implements IImportActivateRequest
     * @constructor
     * @param {IImportActivateRequest=} [properties] Properties to set
     */
    function ImportActivateRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportActivateRequest accountId.
     * @member {string} accountId
     * @memberof ImportActivateRequest
     * @instance
     */
    ImportActivateRequest.prototype.accountId = "";

    /**
     * Creates a new ImportActivateRequest instance using the specified properties.
     * @function create
     * @memberof ImportActivateRequest
     * @static
     * @param {IImportActivateRequest=} [properties] Properties to set
     * @returns {ImportActivateRequest} ImportActivateRequest instance
     */
    ImportActivateRequest.create = function create(properties) {
        return new ImportActivateRequest(properties);
    };

    /**
     * Encodes the specified ImportActivateRequest message. Does not implicitly {@link ImportActivateRequest.verify|verify} messages.
     * @function encode
     * @memberof ImportActivateRequest
     * @static
     * @param {IImportActivateRequest} message ImportActivateRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportActivateRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
        return writer;
    };

    /**
     * Encodes the specified ImportActivateRequest message, length delimited. Does not implicitly {@link ImportActivateRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportActivateRequest
     * @static
     * @param {IImportActivateRequest} message ImportActivateRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportActivateRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportActivateRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ImportActivateRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportActivateRequest} ImportActivateRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportActivateRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportActivateRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.accountId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportActivateRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportActivateRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportActivateRequest} ImportActivateRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportActivateRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportActivateRequest message.
     * @function verify
     * @memberof ImportActivateRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportActivateRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.accountId))
            return "accountId: string expected";
        return null;
    };

    /**
     * Creates an ImportActivateRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportActivateRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportActivateRequest} ImportActivateRequest
     */
    ImportActivateRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportActivateRequest)
            return object;
        let message = new $root.ImportActivateRequest();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        return message;
    };

    /**
     * Creates a plain object from an ImportActivateRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportActivateRequest
     * @static
     * @param {ImportActivateRequest} message ImportActivateRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportActivateRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.accountId = "";
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        return object;
    };

    /**
     * Converts this ImportActivateRequest to JSON.
     * @function toJSON
     * @memberof ImportActivateRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportActivateRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportActivateRequest;
})();

export const ImportItem = $root.ImportItem = (() => {

    /**
     * Properties of an ImportItem.
     * @exports IImportItem
     * @interface IImportItem
     * @property {string} itemId ImportItem itemId
     * @property {string} settingsId ImportItem settingsId
     * @property {string} accountId ImportItem accountId
     * @property {number|Long} itemCreatedAt ImportItem itemCreatedAt
     * @property {string|null} [transactionId] ImportItem transactionId
     * @property {string|null} [fileName] ImportItem fileName
     */

    /**
     * Constructs a new ImportItem.
     * @exports ImportItem
     * @classdesc Represents an ImportItem.
     * @implements IImportItem
     * @constructor
     * @param {IImportItem=} [properties] Properties to set
     */
    function ImportItem(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportItem itemId.
     * @member {string} itemId
     * @memberof ImportItem
     * @instance
     */
    ImportItem.prototype.itemId = "";

    /**
     * ImportItem settingsId.
     * @member {string} settingsId
     * @memberof ImportItem
     * @instance
     */
    ImportItem.prototype.settingsId = "";

    /**
     * ImportItem accountId.
     * @member {string} accountId
     * @memberof ImportItem
     * @instance
     */
    ImportItem.prototype.accountId = "";

    /**
     * ImportItem itemCreatedAt.
     * @member {number|Long} itemCreatedAt
     * @memberof ImportItem
     * @instance
     */
    ImportItem.prototype.itemCreatedAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ImportItem transactionId.
     * @member {string} transactionId
     * @memberof ImportItem
     * @instance
     */
    ImportItem.prototype.transactionId = "";

    /**
     * ImportItem fileName.
     * @member {string} fileName
     * @memberof ImportItem
     * @instance
     */
    ImportItem.prototype.fileName = "";

    /**
     * Creates a new ImportItem instance using the specified properties.
     * @function create
     * @memberof ImportItem
     * @static
     * @param {IImportItem=} [properties] Properties to set
     * @returns {ImportItem} ImportItem instance
     */
    ImportItem.create = function create(properties) {
        return new ImportItem(properties);
    };

    /**
     * Encodes the specified ImportItem message. Does not implicitly {@link ImportItem.verify|verify} messages.
     * @function encode
     * @memberof ImportItem
     * @static
     * @param {IImportItem} message ImportItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportItem.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.settingsId);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.accountId);
        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.itemCreatedAt);
        if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.transactionId);
        if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.fileName);
        return writer;
    };

    /**
     * Encodes the specified ImportItem message, length delimited. Does not implicitly {@link ImportItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportItem
     * @static
     * @param {IImportItem} message ImportItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportItem message from the specified reader or buffer.
     * @function decode
     * @memberof ImportItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportItem} ImportItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportItem();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.itemId = reader.string();
                break;
            case 2:
                message.settingsId = reader.string();
                break;
            case 3:
                message.accountId = reader.string();
                break;
            case 4:
                message.itemCreatedAt = reader.int64();
                break;
            case 5:
                message.transactionId = reader.string();
                break;
            case 6:
                message.fileName = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("itemId"))
            throw $util.ProtocolError("missing required 'itemId'", { instance: message });
        if (!message.hasOwnProperty("settingsId"))
            throw $util.ProtocolError("missing required 'settingsId'", { instance: message });
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        if (!message.hasOwnProperty("itemCreatedAt"))
            throw $util.ProtocolError("missing required 'itemCreatedAt'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportItem} ImportItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportItem message.
     * @function verify
     * @memberof ImportItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.itemId))
            return "itemId: string expected";
        if (!$util.isString(message.settingsId))
            return "settingsId: string expected";
        if (!$util.isString(message.accountId))
            return "accountId: string expected";
        if (!$util.isInteger(message.itemCreatedAt) && !(message.itemCreatedAt && $util.isInteger(message.itemCreatedAt.low) && $util.isInteger(message.itemCreatedAt.high)))
            return "itemCreatedAt: integer|Long expected";
        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
            if (!$util.isString(message.transactionId))
                return "transactionId: string expected";
        if (message.fileName != null && message.hasOwnProperty("fileName"))
            if (!$util.isString(message.fileName))
                return "fileName: string expected";
        return null;
    };

    /**
     * Creates an ImportItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportItem} ImportItem
     */
    ImportItem.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportItem)
            return object;
        let message = new $root.ImportItem();
        if (object.itemId != null)
            message.itemId = String(object.itemId);
        if (object.settingsId != null)
            message.settingsId = String(object.settingsId);
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        if (object.itemCreatedAt != null)
            if ($util.Long)
                (message.itemCreatedAt = $util.Long.fromValue(object.itemCreatedAt)).unsigned = false;
            else if (typeof object.itemCreatedAt === "string")
                message.itemCreatedAt = parseInt(object.itemCreatedAt, 10);
            else if (typeof object.itemCreatedAt === "number")
                message.itemCreatedAt = object.itemCreatedAt;
            else if (typeof object.itemCreatedAt === "object")
                message.itemCreatedAt = new $util.LongBits(object.itemCreatedAt.low >>> 0, object.itemCreatedAt.high >>> 0).toNumber();
        if (object.transactionId != null)
            message.transactionId = String(object.transactionId);
        if (object.fileName != null)
            message.fileName = String(object.fileName);
        return message;
    };

    /**
     * Creates a plain object from an ImportItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportItem
     * @static
     * @param {ImportItem} message ImportItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportItem.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.itemId = "";
            object.settingsId = "";
            object.accountId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.itemCreatedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.itemCreatedAt = options.longs === String ? "0" : 0;
            object.transactionId = "";
            object.fileName = "";
        }
        if (message.itemId != null && message.hasOwnProperty("itemId"))
            object.itemId = message.itemId;
        if (message.settingsId != null && message.hasOwnProperty("settingsId"))
            object.settingsId = message.settingsId;
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        if (message.itemCreatedAt != null && message.hasOwnProperty("itemCreatedAt"))
            if (typeof message.itemCreatedAt === "number")
                object.itemCreatedAt = options.longs === String ? String(message.itemCreatedAt) : message.itemCreatedAt;
            else
                object.itemCreatedAt = options.longs === String ? $util.Long.prototype.toString.call(message.itemCreatedAt) : options.longs === Number ? new $util.LongBits(message.itemCreatedAt.low >>> 0, message.itemCreatedAt.high >>> 0).toNumber() : message.itemCreatedAt;
        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
            object.transactionId = message.transactionId;
        if (message.fileName != null && message.hasOwnProperty("fileName"))
            object.fileName = message.fileName;
        return object;
    };

    /**
     * Converts this ImportItem to JSON.
     * @function toJSON
     * @memberof ImportItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportItem;
})();

export const GetUserImportsResponse = $root.GetUserImportsResponse = (() => {

    /**
     * Properties of a GetUserImportsResponse.
     * @exports IGetUserImportsResponse
     * @interface IGetUserImportsResponse
     * @property {Array.<IImportItem>|null} [items] GetUserImportsResponse items
     */

    /**
     * Constructs a new GetUserImportsResponse.
     * @exports GetUserImportsResponse
     * @classdesc Represents a GetUserImportsResponse.
     * @implements IGetUserImportsResponse
     * @constructor
     * @param {IGetUserImportsResponse=} [properties] Properties to set
     */
    function GetUserImportsResponse(properties) {
        this.items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserImportsResponse items.
     * @member {Array.<IImportItem>} items
     * @memberof GetUserImportsResponse
     * @instance
     */
    GetUserImportsResponse.prototype.items = $util.emptyArray;

    /**
     * Creates a new GetUserImportsResponse instance using the specified properties.
     * @function create
     * @memberof GetUserImportsResponse
     * @static
     * @param {IGetUserImportsResponse=} [properties] Properties to set
     * @returns {GetUserImportsResponse} GetUserImportsResponse instance
     */
    GetUserImportsResponse.create = function create(properties) {
        return new GetUserImportsResponse(properties);
    };

    /**
     * Encodes the specified GetUserImportsResponse message. Does not implicitly {@link GetUserImportsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetUserImportsResponse
     * @static
     * @param {IGetUserImportsResponse} message GetUserImportsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserImportsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.items != null && message.items.length)
            for (let i = 0; i < message.items.length; ++i)
                $root.ImportItem.encode(message.items[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetUserImportsResponse message, length delimited. Does not implicitly {@link GetUserImportsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserImportsResponse
     * @static
     * @param {IGetUserImportsResponse} message GetUserImportsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserImportsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserImportsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserImportsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserImportsResponse} GetUserImportsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserImportsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserImportsResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 4:
                if (!(message.items && message.items.length))
                    message.items = [];
                message.items.push($root.ImportItem.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserImportsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserImportsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserImportsResponse} GetUserImportsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserImportsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserImportsResponse message.
     * @function verify
     * @memberof GetUserImportsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserImportsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.items != null && message.hasOwnProperty("items")) {
            if (!Array.isArray(message.items))
                return "items: array expected";
            for (let i = 0; i < message.items.length; ++i) {
                let error = $root.ImportItem.verify(message.items[i]);
                if (error)
                    return "items." + error;
            }
        }
        return null;
    };

    /**
     * Creates a GetUserImportsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserImportsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserImportsResponse} GetUserImportsResponse
     */
    GetUserImportsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserImportsResponse)
            return object;
        let message = new $root.GetUserImportsResponse();
        if (object.items) {
            if (!Array.isArray(object.items))
                throw TypeError(".GetUserImportsResponse.items: array expected");
            message.items = [];
            for (let i = 0; i < object.items.length; ++i) {
                if (typeof object.items[i] !== "object")
                    throw TypeError(".GetUserImportsResponse.items: object expected");
                message.items[i] = $root.ImportItem.fromObject(object.items[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a GetUserImportsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserImportsResponse
     * @static
     * @param {GetUserImportsResponse} message GetUserImportsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserImportsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.items = [];
        if (message.items && message.items.length) {
            object.items = [];
            for (let j = 0; j < message.items.length; ++j)
                object.items[j] = $root.ImportItem.toObject(message.items[j], options);
        }
        return object;
    };

    /**
     * Converts this GetUserImportsResponse to JSON.
     * @function toJSON
     * @memberof GetUserImportsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserImportsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GetUserImportsResponse;
})();

/**
 * ImportMappingAttributeType enum.
 * @exports ImportMappingAttributeType
 * @enum {number}
 * @property {number} CurrencyCode=0 CurrencyCode value
 * @property {number} Amount=1 Amount value
 * @property {number} Note=2 Note value
 * @property {number} RecordDate=3 RecordDate value
 * @property {number} Payee=4 Payee value
 * @property {number} Unknown=5 Unknown value
 * @property {number} Expense=6 Expense value
 * @property {number} Fee=7 Fee value
 * @property {number} CategoryMapping=8 CategoryMapping value
 */
export const ImportMappingAttributeType = $root.ImportMappingAttributeType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CurrencyCode"] = 0;
    values[valuesById[1] = "Amount"] = 1;
    values[valuesById[2] = "Note"] = 2;
    values[valuesById[3] = "RecordDate"] = 3;
    values[valuesById[4] = "Payee"] = 4;
    values[valuesById[5] = "Unknown"] = 5;
    values[valuesById[6] = "Expense"] = 6;
    values[valuesById[7] = "Fee"] = 7;
    values[valuesById[8] = "CategoryMapping"] = 8;
    return values;
})();

/**
 * ImportInitResultType enum.
 * @exports ImportInitResultType
 * @enum {number}
 * @property {number} FirstTime=0 FirstTime value
 * @property {number} LastSuccess=1 LastSuccess value
 * @property {number} LastError=2 LastError value
 */
export const ImportInitResultType = $root.ImportInitResultType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FirstTime"] = 0;
    values[valuesById[1] = "LastSuccess"] = 1;
    values[valuesById[2] = "LastError"] = 2;
    return values;
})();

export const ImportMappingAttribute = $root.ImportMappingAttribute = (() => {

    /**
     * Properties of an ImportMappingAttribute.
     * @exports IImportMappingAttribute
     * @interface IImportMappingAttribute
     * @property {ImportMappingAttributeType} type ImportMappingAttribute type
     * @property {string} name ImportMappingAttribute name
     * @property {boolean} isRequired ImportMappingAttribute isRequired
     * @property {number|Long|null} [limit] ImportMappingAttribute limit
     */

    /**
     * Constructs a new ImportMappingAttribute.
     * @exports ImportMappingAttribute
     * @classdesc Represents an ImportMappingAttribute.
     * @implements IImportMappingAttribute
     * @constructor
     * @param {IImportMappingAttribute=} [properties] Properties to set
     */
    function ImportMappingAttribute(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportMappingAttribute type.
     * @member {ImportMappingAttributeType} type
     * @memberof ImportMappingAttribute
     * @instance
     */
    ImportMappingAttribute.prototype.type = 0;

    /**
     * ImportMappingAttribute name.
     * @member {string} name
     * @memberof ImportMappingAttribute
     * @instance
     */
    ImportMappingAttribute.prototype.name = "";

    /**
     * ImportMappingAttribute isRequired.
     * @member {boolean} isRequired
     * @memberof ImportMappingAttribute
     * @instance
     */
    ImportMappingAttribute.prototype.isRequired = false;

    /**
     * ImportMappingAttribute limit.
     * @member {number|Long} limit
     * @memberof ImportMappingAttribute
     * @instance
     */
    ImportMappingAttribute.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new ImportMappingAttribute instance using the specified properties.
     * @function create
     * @memberof ImportMappingAttribute
     * @static
     * @param {IImportMappingAttribute=} [properties] Properties to set
     * @returns {ImportMappingAttribute} ImportMappingAttribute instance
     */
    ImportMappingAttribute.create = function create(properties) {
        return new ImportMappingAttribute(properties);
    };

    /**
     * Encodes the specified ImportMappingAttribute message. Does not implicitly {@link ImportMappingAttribute.verify|verify} messages.
     * @function encode
     * @memberof ImportMappingAttribute
     * @static
     * @param {IImportMappingAttribute} message ImportMappingAttribute message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportMappingAttribute.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isRequired);
        if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.limit);
        return writer;
    };

    /**
     * Encodes the specified ImportMappingAttribute message, length delimited. Does not implicitly {@link ImportMappingAttribute.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportMappingAttribute
     * @static
     * @param {IImportMappingAttribute} message ImportMappingAttribute message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportMappingAttribute.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportMappingAttribute message from the specified reader or buffer.
     * @function decode
     * @memberof ImportMappingAttribute
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportMappingAttribute} ImportMappingAttribute
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportMappingAttribute.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportMappingAttribute();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.name = reader.string();
                break;
            case 3:
                message.isRequired = reader.bool();
                break;
            case 4:
                message.limit = reader.int64();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("isRequired"))
            throw $util.ProtocolError("missing required 'isRequired'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportMappingAttribute message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportMappingAttribute
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportMappingAttribute} ImportMappingAttribute
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportMappingAttribute.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportMappingAttribute message.
     * @function verify
     * @memberof ImportMappingAttribute
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportMappingAttribute.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
            break;
        }
        if (!$util.isString(message.name))
            return "name: string expected";
        if (typeof message.isRequired !== "boolean")
            return "isRequired: boolean expected";
        if (message.limit != null && message.hasOwnProperty("limit"))
            if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                return "limit: integer|Long expected";
        return null;
    };

    /**
     * Creates an ImportMappingAttribute message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportMappingAttribute
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportMappingAttribute} ImportMappingAttribute
     */
    ImportMappingAttribute.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportMappingAttribute)
            return object;
        let message = new $root.ImportMappingAttribute();
        switch (object.type) {
        case "CurrencyCode":
        case 0:
            message.type = 0;
            break;
        case "Amount":
        case 1:
            message.type = 1;
            break;
        case "Note":
        case 2:
            message.type = 2;
            break;
        case "RecordDate":
        case 3:
            message.type = 3;
            break;
        case "Payee":
        case 4:
            message.type = 4;
            break;
        case "Unknown":
        case 5:
            message.type = 5;
            break;
        case "Expense":
        case 6:
            message.type = 6;
            break;
        case "Fee":
        case 7:
            message.type = 7;
            break;
        case "CategoryMapping":
        case 8:
            message.type = 8;
            break;
        }
        if (object.name != null)
            message.name = String(object.name);
        if (object.isRequired != null)
            message.isRequired = Boolean(object.isRequired);
        if (object.limit != null)
            if ($util.Long)
                (message.limit = $util.Long.fromValue(object.limit)).unsigned = false;
            else if (typeof object.limit === "string")
                message.limit = parseInt(object.limit, 10);
            else if (typeof object.limit === "number")
                message.limit = object.limit;
            else if (typeof object.limit === "object")
                message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from an ImportMappingAttribute message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportMappingAttribute
     * @static
     * @param {ImportMappingAttribute} message ImportMappingAttribute
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportMappingAttribute.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "CurrencyCode" : 0;
            object.name = "";
            object.isRequired = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.limit = options.longs === String ? "0" : 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ImportMappingAttributeType[message.type] : message.type;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.isRequired != null && message.hasOwnProperty("isRequired"))
            object.isRequired = message.isRequired;
        if (message.limit != null && message.hasOwnProperty("limit"))
            if (typeof message.limit === "number")
                object.limit = options.longs === String ? String(message.limit) : message.limit;
            else
                object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber() : message.limit;
        return object;
    };

    /**
     * Converts this ImportMappingAttribute to JSON.
     * @function toJSON
     * @memberof ImportMappingAttribute
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportMappingAttribute.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportMappingAttribute;
})();

export const ImportParsedToken = $root.ImportParsedToken = (() => {

    /**
     * Properties of an ImportParsedToken.
     * @exports IImportParsedToken
     * @interface IImportParsedToken
     * @property {string|null} [colName] ImportParsedToken colName
     * @property {string} rawValue ImportParsedToken rawValue
     * @property {string|null} [parseError] ImportParsedToken parseError
     * @property {ImportMappingAttributeType} type ImportParsedToken type
     * @property {number|null} [colIndex] ImportParsedToken colIndex
     */

    /**
     * Constructs a new ImportParsedToken.
     * @exports ImportParsedToken
     * @classdesc Represents an ImportParsedToken.
     * @implements IImportParsedToken
     * @constructor
     * @param {IImportParsedToken=} [properties] Properties to set
     */
    function ImportParsedToken(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportParsedToken colName.
     * @member {string} colName
     * @memberof ImportParsedToken
     * @instance
     */
    ImportParsedToken.prototype.colName = "";

    /**
     * ImportParsedToken rawValue.
     * @member {string} rawValue
     * @memberof ImportParsedToken
     * @instance
     */
    ImportParsedToken.prototype.rawValue = "";

    /**
     * ImportParsedToken parseError.
     * @member {string} parseError
     * @memberof ImportParsedToken
     * @instance
     */
    ImportParsedToken.prototype.parseError = "";

    /**
     * ImportParsedToken type.
     * @member {ImportMappingAttributeType} type
     * @memberof ImportParsedToken
     * @instance
     */
    ImportParsedToken.prototype.type = 0;

    /**
     * ImportParsedToken colIndex.
     * @member {number} colIndex
     * @memberof ImportParsedToken
     * @instance
     */
    ImportParsedToken.prototype.colIndex = 0;

    /**
     * Creates a new ImportParsedToken instance using the specified properties.
     * @function create
     * @memberof ImportParsedToken
     * @static
     * @param {IImportParsedToken=} [properties] Properties to set
     * @returns {ImportParsedToken} ImportParsedToken instance
     */
    ImportParsedToken.create = function create(properties) {
        return new ImportParsedToken(properties);
    };

    /**
     * Encodes the specified ImportParsedToken message. Does not implicitly {@link ImportParsedToken.verify|verify} messages.
     * @function encode
     * @memberof ImportParsedToken
     * @static
     * @param {IImportParsedToken} message ImportParsedToken message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParsedToken.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.colName != null && Object.hasOwnProperty.call(message, "colName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.colName);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.rawValue);
        if (message.parseError != null && Object.hasOwnProperty.call(message, "parseError"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.parseError);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
        if (message.colIndex != null && Object.hasOwnProperty.call(message, "colIndex"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.colIndex);
        return writer;
    };

    /**
     * Encodes the specified ImportParsedToken message, length delimited. Does not implicitly {@link ImportParsedToken.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportParsedToken
     * @static
     * @param {IImportParsedToken} message ImportParsedToken message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParsedToken.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportParsedToken message from the specified reader or buffer.
     * @function decode
     * @memberof ImportParsedToken
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportParsedToken} ImportParsedToken
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParsedToken.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportParsedToken();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.colName = reader.string();
                break;
            case 2:
                message.rawValue = reader.string();
                break;
            case 3:
                message.parseError = reader.string();
                break;
            case 4:
                message.type = reader.int32();
                break;
            case 5:
                message.colIndex = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("rawValue"))
            throw $util.ProtocolError("missing required 'rawValue'", { instance: message });
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportParsedToken message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportParsedToken
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportParsedToken} ImportParsedToken
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParsedToken.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportParsedToken message.
     * @function verify
     * @memberof ImportParsedToken
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportParsedToken.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.colName != null && message.hasOwnProperty("colName"))
            if (!$util.isString(message.colName))
                return "colName: string expected";
        if (!$util.isString(message.rawValue))
            return "rawValue: string expected";
        if (message.parseError != null && message.hasOwnProperty("parseError"))
            if (!$util.isString(message.parseError))
                return "parseError: string expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
            break;
        }
        if (message.colIndex != null && message.hasOwnProperty("colIndex"))
            if (!$util.isInteger(message.colIndex))
                return "colIndex: integer expected";
        return null;
    };

    /**
     * Creates an ImportParsedToken message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportParsedToken
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportParsedToken} ImportParsedToken
     */
    ImportParsedToken.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportParsedToken)
            return object;
        let message = new $root.ImportParsedToken();
        if (object.colName != null)
            message.colName = String(object.colName);
        if (object.rawValue != null)
            message.rawValue = String(object.rawValue);
        if (object.parseError != null)
            message.parseError = String(object.parseError);
        switch (object.type) {
        case "CurrencyCode":
        case 0:
            message.type = 0;
            break;
        case "Amount":
        case 1:
            message.type = 1;
            break;
        case "Note":
        case 2:
            message.type = 2;
            break;
        case "RecordDate":
        case 3:
            message.type = 3;
            break;
        case "Payee":
        case 4:
            message.type = 4;
            break;
        case "Unknown":
        case 5:
            message.type = 5;
            break;
        case "Expense":
        case 6:
            message.type = 6;
            break;
        case "Fee":
        case 7:
            message.type = 7;
            break;
        case "CategoryMapping":
        case 8:
            message.type = 8;
            break;
        }
        if (object.colIndex != null)
            message.colIndex = object.colIndex | 0;
        return message;
    };

    /**
     * Creates a plain object from an ImportParsedToken message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportParsedToken
     * @static
     * @param {ImportParsedToken} message ImportParsedToken
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportParsedToken.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.colName = "";
            object.rawValue = "";
            object.parseError = "";
            object.type = options.enums === String ? "CurrencyCode" : 0;
            object.colIndex = 0;
        }
        if (message.colName != null && message.hasOwnProperty("colName"))
            object.colName = message.colName;
        if (message.rawValue != null && message.hasOwnProperty("rawValue"))
            object.rawValue = message.rawValue;
        if (message.parseError != null && message.hasOwnProperty("parseError"))
            object.parseError = message.parseError;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ImportMappingAttributeType[message.type] : message.type;
        if (message.colIndex != null && message.hasOwnProperty("colIndex"))
            object.colIndex = message.colIndex;
        return object;
    };

    /**
     * Converts this ImportParsedToken to JSON.
     * @function toJSON
     * @memberof ImportParsedToken
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportParsedToken.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportParsedToken;
})();

export const ImportMappingSettings = $root.ImportMappingSettings = (() => {

    /**
     * Properties of an ImportMappingSettings.
     * @exports IImportMappingSettings
     * @interface IImportMappingSettings
     * @property {number|Long} firstRecord ImportMappingSettings firstRecord
     * @property {number|Long} lastRecord ImportMappingSettings lastRecord
     * @property {boolean} skipHeader ImportMappingSettings skipHeader
     * @property {string} delimiter ImportMappingSettings delimiter
     * @property {string} timeZone ImportMappingSettings timeZone
     * @property {number|Long} lastPossibleRecord ImportMappingSettings lastPossibleRecord
     * @property {string|null} [dateFormatterPattern] ImportMappingSettings dateFormatterPattern
     * @property {boolean|null} [hasExpenseColumn] ImportMappingSettings hasExpenseColumn
     * @property {boolean|null} [hasFeeColumn] ImportMappingSettings hasFeeColumn
     */

    /**
     * Constructs a new ImportMappingSettings.
     * @exports ImportMappingSettings
     * @classdesc Represents an ImportMappingSettings.
     * @implements IImportMappingSettings
     * @constructor
     * @param {IImportMappingSettings=} [properties] Properties to set
     */
    function ImportMappingSettings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportMappingSettings firstRecord.
     * @member {number|Long} firstRecord
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.firstRecord = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ImportMappingSettings lastRecord.
     * @member {number|Long} lastRecord
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.lastRecord = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ImportMappingSettings skipHeader.
     * @member {boolean} skipHeader
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.skipHeader = false;

    /**
     * ImportMappingSettings delimiter.
     * @member {string} delimiter
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.delimiter = "";

    /**
     * ImportMappingSettings timeZone.
     * @member {string} timeZone
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.timeZone = "";

    /**
     * ImportMappingSettings lastPossibleRecord.
     * @member {number|Long} lastPossibleRecord
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.lastPossibleRecord = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ImportMappingSettings dateFormatterPattern.
     * @member {string} dateFormatterPattern
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.dateFormatterPattern = "";

    /**
     * ImportMappingSettings hasExpenseColumn.
     * @member {boolean} hasExpenseColumn
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.hasExpenseColumn = false;

    /**
     * ImportMappingSettings hasFeeColumn.
     * @member {boolean} hasFeeColumn
     * @memberof ImportMappingSettings
     * @instance
     */
    ImportMappingSettings.prototype.hasFeeColumn = false;

    /**
     * Creates a new ImportMappingSettings instance using the specified properties.
     * @function create
     * @memberof ImportMappingSettings
     * @static
     * @param {IImportMappingSettings=} [properties] Properties to set
     * @returns {ImportMappingSettings} ImportMappingSettings instance
     */
    ImportMappingSettings.create = function create(properties) {
        return new ImportMappingSettings(properties);
    };

    /**
     * Encodes the specified ImportMappingSettings message. Does not implicitly {@link ImportMappingSettings.verify|verify} messages.
     * @function encode
     * @memberof ImportMappingSettings
     * @static
     * @param {IImportMappingSettings} message ImportMappingSettings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportMappingSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.firstRecord);
        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.lastRecord);
        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipHeader);
        writer.uint32(/* id 4, wireType 2 =*/34).string(message.delimiter);
        writer.uint32(/* id 5, wireType 2 =*/42).string(message.timeZone);
        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.lastPossibleRecord);
        if (message.dateFormatterPattern != null && Object.hasOwnProperty.call(message, "dateFormatterPattern"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.dateFormatterPattern);
        if (message.hasExpenseColumn != null && Object.hasOwnProperty.call(message, "hasExpenseColumn"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.hasExpenseColumn);
        if (message.hasFeeColumn != null && Object.hasOwnProperty.call(message, "hasFeeColumn"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.hasFeeColumn);
        return writer;
    };

    /**
     * Encodes the specified ImportMappingSettings message, length delimited. Does not implicitly {@link ImportMappingSettings.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportMappingSettings
     * @static
     * @param {IImportMappingSettings} message ImportMappingSettings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportMappingSettings.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportMappingSettings message from the specified reader or buffer.
     * @function decode
     * @memberof ImportMappingSettings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportMappingSettings} ImportMappingSettings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportMappingSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportMappingSettings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.firstRecord = reader.int64();
                break;
            case 2:
                message.lastRecord = reader.int64();
                break;
            case 3:
                message.skipHeader = reader.bool();
                break;
            case 4:
                message.delimiter = reader.string();
                break;
            case 5:
                message.timeZone = reader.string();
                break;
            case 6:
                message.lastPossibleRecord = reader.int64();
                break;
            case 7:
                message.dateFormatterPattern = reader.string();
                break;
            case 8:
                message.hasExpenseColumn = reader.bool();
                break;
            case 9:
                message.hasFeeColumn = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("firstRecord"))
            throw $util.ProtocolError("missing required 'firstRecord'", { instance: message });
        if (!message.hasOwnProperty("lastRecord"))
            throw $util.ProtocolError("missing required 'lastRecord'", { instance: message });
        if (!message.hasOwnProperty("skipHeader"))
            throw $util.ProtocolError("missing required 'skipHeader'", { instance: message });
        if (!message.hasOwnProperty("delimiter"))
            throw $util.ProtocolError("missing required 'delimiter'", { instance: message });
        if (!message.hasOwnProperty("timeZone"))
            throw $util.ProtocolError("missing required 'timeZone'", { instance: message });
        if (!message.hasOwnProperty("lastPossibleRecord"))
            throw $util.ProtocolError("missing required 'lastPossibleRecord'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportMappingSettings message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportMappingSettings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportMappingSettings} ImportMappingSettings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportMappingSettings.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportMappingSettings message.
     * @function verify
     * @memberof ImportMappingSettings
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportMappingSettings.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isInteger(message.firstRecord) && !(message.firstRecord && $util.isInteger(message.firstRecord.low) && $util.isInteger(message.firstRecord.high)))
            return "firstRecord: integer|Long expected";
        if (!$util.isInteger(message.lastRecord) && !(message.lastRecord && $util.isInteger(message.lastRecord.low) && $util.isInteger(message.lastRecord.high)))
            return "lastRecord: integer|Long expected";
        if (typeof message.skipHeader !== "boolean")
            return "skipHeader: boolean expected";
        if (!$util.isString(message.delimiter))
            return "delimiter: string expected";
        if (!$util.isString(message.timeZone))
            return "timeZone: string expected";
        if (!$util.isInteger(message.lastPossibleRecord) && !(message.lastPossibleRecord && $util.isInteger(message.lastPossibleRecord.low) && $util.isInteger(message.lastPossibleRecord.high)))
            return "lastPossibleRecord: integer|Long expected";
        if (message.dateFormatterPattern != null && message.hasOwnProperty("dateFormatterPattern"))
            if (!$util.isString(message.dateFormatterPattern))
                return "dateFormatterPattern: string expected";
        if (message.hasExpenseColumn != null && message.hasOwnProperty("hasExpenseColumn"))
            if (typeof message.hasExpenseColumn !== "boolean")
                return "hasExpenseColumn: boolean expected";
        if (message.hasFeeColumn != null && message.hasOwnProperty("hasFeeColumn"))
            if (typeof message.hasFeeColumn !== "boolean")
                return "hasFeeColumn: boolean expected";
        return null;
    };

    /**
     * Creates an ImportMappingSettings message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportMappingSettings
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportMappingSettings} ImportMappingSettings
     */
    ImportMappingSettings.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportMappingSettings)
            return object;
        let message = new $root.ImportMappingSettings();
        if (object.firstRecord != null)
            if ($util.Long)
                (message.firstRecord = $util.Long.fromValue(object.firstRecord)).unsigned = false;
            else if (typeof object.firstRecord === "string")
                message.firstRecord = parseInt(object.firstRecord, 10);
            else if (typeof object.firstRecord === "number")
                message.firstRecord = object.firstRecord;
            else if (typeof object.firstRecord === "object")
                message.firstRecord = new $util.LongBits(object.firstRecord.low >>> 0, object.firstRecord.high >>> 0).toNumber();
        if (object.lastRecord != null)
            if ($util.Long)
                (message.lastRecord = $util.Long.fromValue(object.lastRecord)).unsigned = false;
            else if (typeof object.lastRecord === "string")
                message.lastRecord = parseInt(object.lastRecord, 10);
            else if (typeof object.lastRecord === "number")
                message.lastRecord = object.lastRecord;
            else if (typeof object.lastRecord === "object")
                message.lastRecord = new $util.LongBits(object.lastRecord.low >>> 0, object.lastRecord.high >>> 0).toNumber();
        if (object.skipHeader != null)
            message.skipHeader = Boolean(object.skipHeader);
        if (object.delimiter != null)
            message.delimiter = String(object.delimiter);
        if (object.timeZone != null)
            message.timeZone = String(object.timeZone);
        if (object.lastPossibleRecord != null)
            if ($util.Long)
                (message.lastPossibleRecord = $util.Long.fromValue(object.lastPossibleRecord)).unsigned = false;
            else if (typeof object.lastPossibleRecord === "string")
                message.lastPossibleRecord = parseInt(object.lastPossibleRecord, 10);
            else if (typeof object.lastPossibleRecord === "number")
                message.lastPossibleRecord = object.lastPossibleRecord;
            else if (typeof object.lastPossibleRecord === "object")
                message.lastPossibleRecord = new $util.LongBits(object.lastPossibleRecord.low >>> 0, object.lastPossibleRecord.high >>> 0).toNumber();
        if (object.dateFormatterPattern != null)
            message.dateFormatterPattern = String(object.dateFormatterPattern);
        if (object.hasExpenseColumn != null)
            message.hasExpenseColumn = Boolean(object.hasExpenseColumn);
        if (object.hasFeeColumn != null)
            message.hasFeeColumn = Boolean(object.hasFeeColumn);
        return message;
    };

    /**
     * Creates a plain object from an ImportMappingSettings message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportMappingSettings
     * @static
     * @param {ImportMappingSettings} message ImportMappingSettings
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportMappingSettings.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.firstRecord = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.firstRecord = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.lastRecord = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastRecord = options.longs === String ? "0" : 0;
            object.skipHeader = false;
            object.delimiter = "";
            object.timeZone = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.lastPossibleRecord = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.lastPossibleRecord = options.longs === String ? "0" : 0;
            object.dateFormatterPattern = "";
            object.hasExpenseColumn = false;
            object.hasFeeColumn = false;
        }
        if (message.firstRecord != null && message.hasOwnProperty("firstRecord"))
            if (typeof message.firstRecord === "number")
                object.firstRecord = options.longs === String ? String(message.firstRecord) : message.firstRecord;
            else
                object.firstRecord = options.longs === String ? $util.Long.prototype.toString.call(message.firstRecord) : options.longs === Number ? new $util.LongBits(message.firstRecord.low >>> 0, message.firstRecord.high >>> 0).toNumber() : message.firstRecord;
        if (message.lastRecord != null && message.hasOwnProperty("lastRecord"))
            if (typeof message.lastRecord === "number")
                object.lastRecord = options.longs === String ? String(message.lastRecord) : message.lastRecord;
            else
                object.lastRecord = options.longs === String ? $util.Long.prototype.toString.call(message.lastRecord) : options.longs === Number ? new $util.LongBits(message.lastRecord.low >>> 0, message.lastRecord.high >>> 0).toNumber() : message.lastRecord;
        if (message.skipHeader != null && message.hasOwnProperty("skipHeader"))
            object.skipHeader = message.skipHeader;
        if (message.delimiter != null && message.hasOwnProperty("delimiter"))
            object.delimiter = message.delimiter;
        if (message.timeZone != null && message.hasOwnProperty("timeZone"))
            object.timeZone = message.timeZone;
        if (message.lastPossibleRecord != null && message.hasOwnProperty("lastPossibleRecord"))
            if (typeof message.lastPossibleRecord === "number")
                object.lastPossibleRecord = options.longs === String ? String(message.lastPossibleRecord) : message.lastPossibleRecord;
            else
                object.lastPossibleRecord = options.longs === String ? $util.Long.prototype.toString.call(message.lastPossibleRecord) : options.longs === Number ? new $util.LongBits(message.lastPossibleRecord.low >>> 0, message.lastPossibleRecord.high >>> 0).toNumber() : message.lastPossibleRecord;
        if (message.dateFormatterPattern != null && message.hasOwnProperty("dateFormatterPattern"))
            object.dateFormatterPattern = message.dateFormatterPattern;
        if (message.hasExpenseColumn != null && message.hasOwnProperty("hasExpenseColumn"))
            object.hasExpenseColumn = message.hasExpenseColumn;
        if (message.hasFeeColumn != null && message.hasOwnProperty("hasFeeColumn"))
            object.hasFeeColumn = message.hasFeeColumn;
        return object;
    };

    /**
     * Converts this ImportMappingSettings to JSON.
     * @function toJSON
     * @memberof ImportMappingSettings
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportMappingSettings.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportMappingSettings;
})();

export const ImportParserResponse = $root.ImportParserResponse = (() => {

    /**
     * Properties of an ImportParserResponse.
     * @exports IImportParserResponse
     * @interface IImportParserResponse
     * @property {Array.<IImportMappingAttribute>|null} [allowedAttributes] ImportParserResponse allowedAttributes
     * @property {Array.<IImportParsedToken>|null} [parsedExample] ImportParserResponse parsedExample
     * @property {IImportMappingSettings} settings ImportParserResponse settings
     * @property {IImportAllowedDelimiters|null} [allowedDelimiters] ImportParserResponse allowedDelimiters
     * @property {ImportInitResultType|null} [initResult] ImportParserResponse initResult
     * @property {Array.<string>|null} [allowedDateFormatterPatterns] ImportParserResponse allowedDateFormatterPatterns
     * @property {Array.<IImportRowValues>|null} [rowValues] ImportParserResponse rowValues
     */

    /**
     * Constructs a new ImportParserResponse.
     * @exports ImportParserResponse
     * @classdesc Represents an ImportParserResponse.
     * @implements IImportParserResponse
     * @constructor
     * @param {IImportParserResponse=} [properties] Properties to set
     */
    function ImportParserResponse(properties) {
        this.allowedAttributes = [];
        this.parsedExample = [];
        this.allowedDateFormatterPatterns = [];
        this.rowValues = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportParserResponse allowedAttributes.
     * @member {Array.<IImportMappingAttribute>} allowedAttributes
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.allowedAttributes = $util.emptyArray;

    /**
     * ImportParserResponse parsedExample.
     * @member {Array.<IImportParsedToken>} parsedExample
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.parsedExample = $util.emptyArray;

    /**
     * ImportParserResponse settings.
     * @member {IImportMappingSettings} settings
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.settings = null;

    /**
     * ImportParserResponse allowedDelimiters.
     * @member {IImportAllowedDelimiters|null|undefined} allowedDelimiters
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.allowedDelimiters = null;

    /**
     * ImportParserResponse initResult.
     * @member {ImportInitResultType} initResult
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.initResult = 0;

    /**
     * ImportParserResponse allowedDateFormatterPatterns.
     * @member {Array.<string>} allowedDateFormatterPatterns
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.allowedDateFormatterPatterns = $util.emptyArray;

    /**
     * ImportParserResponse rowValues.
     * @member {Array.<IImportRowValues>} rowValues
     * @memberof ImportParserResponse
     * @instance
     */
    ImportParserResponse.prototype.rowValues = $util.emptyArray;

    /**
     * Creates a new ImportParserResponse instance using the specified properties.
     * @function create
     * @memberof ImportParserResponse
     * @static
     * @param {IImportParserResponse=} [properties] Properties to set
     * @returns {ImportParserResponse} ImportParserResponse instance
     */
    ImportParserResponse.create = function create(properties) {
        return new ImportParserResponse(properties);
    };

    /**
     * Encodes the specified ImportParserResponse message. Does not implicitly {@link ImportParserResponse.verify|verify} messages.
     * @function encode
     * @memberof ImportParserResponse
     * @static
     * @param {IImportParserResponse} message ImportParserResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParserResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.allowedAttributes != null && message.allowedAttributes.length)
            for (let i = 0; i < message.allowedAttributes.length; ++i)
                $root.ImportMappingAttribute.encode(message.allowedAttributes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.parsedExample != null && message.parsedExample.length)
            for (let i = 0; i < message.parsedExample.length; ++i)
                $root.ImportParsedToken.encode(message.parsedExample[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        $root.ImportMappingSettings.encode(message.settings, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.allowedDelimiters != null && Object.hasOwnProperty.call(message, "allowedDelimiters"))
            $root.ImportAllowedDelimiters.encode(message.allowedDelimiters, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.initResult != null && Object.hasOwnProperty.call(message, "initResult"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.initResult);
        if (message.allowedDateFormatterPatterns != null && message.allowedDateFormatterPatterns.length)
            for (let i = 0; i < message.allowedDateFormatterPatterns.length; ++i)
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.allowedDateFormatterPatterns[i]);
        if (message.rowValues != null && message.rowValues.length)
            for (let i = 0; i < message.rowValues.length; ++i)
                $root.ImportRowValues.encode(message.rowValues[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ImportParserResponse message, length delimited. Does not implicitly {@link ImportParserResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportParserResponse
     * @static
     * @param {IImportParserResponse} message ImportParserResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParserResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportParserResponse message from the specified reader or buffer.
     * @function decode
     * @memberof ImportParserResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportParserResponse} ImportParserResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParserResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportParserResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.allowedAttributes && message.allowedAttributes.length))
                    message.allowedAttributes = [];
                message.allowedAttributes.push($root.ImportMappingAttribute.decode(reader, reader.uint32()));
                break;
            case 2:
                if (!(message.parsedExample && message.parsedExample.length))
                    message.parsedExample = [];
                message.parsedExample.push($root.ImportParsedToken.decode(reader, reader.uint32()));
                break;
            case 3:
                message.settings = $root.ImportMappingSettings.decode(reader, reader.uint32());
                break;
            case 4:
                message.allowedDelimiters = $root.ImportAllowedDelimiters.decode(reader, reader.uint32());
                break;
            case 5:
                message.initResult = reader.int32();
                break;
            case 6:
                if (!(message.allowedDateFormatterPatterns && message.allowedDateFormatterPatterns.length))
                    message.allowedDateFormatterPatterns = [];
                message.allowedDateFormatterPatterns.push(reader.string());
                break;
            case 7:
                if (!(message.rowValues && message.rowValues.length))
                    message.rowValues = [];
                message.rowValues.push($root.ImportRowValues.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("settings"))
            throw $util.ProtocolError("missing required 'settings'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportParserResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportParserResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportParserResponse} ImportParserResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParserResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportParserResponse message.
     * @function verify
     * @memberof ImportParserResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportParserResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.allowedAttributes != null && message.hasOwnProperty("allowedAttributes")) {
            if (!Array.isArray(message.allowedAttributes))
                return "allowedAttributes: array expected";
            for (let i = 0; i < message.allowedAttributes.length; ++i) {
                let error = $root.ImportMappingAttribute.verify(message.allowedAttributes[i]);
                if (error)
                    return "allowedAttributes." + error;
            }
        }
        if (message.parsedExample != null && message.hasOwnProperty("parsedExample")) {
            if (!Array.isArray(message.parsedExample))
                return "parsedExample: array expected";
            for (let i = 0; i < message.parsedExample.length; ++i) {
                let error = $root.ImportParsedToken.verify(message.parsedExample[i]);
                if (error)
                    return "parsedExample." + error;
            }
        }
        {
            let error = $root.ImportMappingSettings.verify(message.settings);
            if (error)
                return "settings." + error;
        }
        if (message.allowedDelimiters != null && message.hasOwnProperty("allowedDelimiters")) {
            let error = $root.ImportAllowedDelimiters.verify(message.allowedDelimiters);
            if (error)
                return "allowedDelimiters." + error;
        }
        if (message.initResult != null && message.hasOwnProperty("initResult"))
            switch (message.initResult) {
            default:
                return "initResult: enum value expected";
            case 0:
            case 1:
            case 2:
                break;
            }
        if (message.allowedDateFormatterPatterns != null && message.hasOwnProperty("allowedDateFormatterPatterns")) {
            if (!Array.isArray(message.allowedDateFormatterPatterns))
                return "allowedDateFormatterPatterns: array expected";
            for (let i = 0; i < message.allowedDateFormatterPatterns.length; ++i)
                if (!$util.isString(message.allowedDateFormatterPatterns[i]))
                    return "allowedDateFormatterPatterns: string[] expected";
        }
        if (message.rowValues != null && message.hasOwnProperty("rowValues")) {
            if (!Array.isArray(message.rowValues))
                return "rowValues: array expected";
            for (let i = 0; i < message.rowValues.length; ++i) {
                let error = $root.ImportRowValues.verify(message.rowValues[i]);
                if (error)
                    return "rowValues." + error;
            }
        }
        return null;
    };

    /**
     * Creates an ImportParserResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportParserResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportParserResponse} ImportParserResponse
     */
    ImportParserResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportParserResponse)
            return object;
        let message = new $root.ImportParserResponse();
        if (object.allowedAttributes) {
            if (!Array.isArray(object.allowedAttributes))
                throw TypeError(".ImportParserResponse.allowedAttributes: array expected");
            message.allowedAttributes = [];
            for (let i = 0; i < object.allowedAttributes.length; ++i) {
                if (typeof object.allowedAttributes[i] !== "object")
                    throw TypeError(".ImportParserResponse.allowedAttributes: object expected");
                message.allowedAttributes[i] = $root.ImportMappingAttribute.fromObject(object.allowedAttributes[i]);
            }
        }
        if (object.parsedExample) {
            if (!Array.isArray(object.parsedExample))
                throw TypeError(".ImportParserResponse.parsedExample: array expected");
            message.parsedExample = [];
            for (let i = 0; i < object.parsedExample.length; ++i) {
                if (typeof object.parsedExample[i] !== "object")
                    throw TypeError(".ImportParserResponse.parsedExample: object expected");
                message.parsedExample[i] = $root.ImportParsedToken.fromObject(object.parsedExample[i]);
            }
        }
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".ImportParserResponse.settings: object expected");
            message.settings = $root.ImportMappingSettings.fromObject(object.settings);
        }
        if (object.allowedDelimiters != null) {
            if (typeof object.allowedDelimiters !== "object")
                throw TypeError(".ImportParserResponse.allowedDelimiters: object expected");
            message.allowedDelimiters = $root.ImportAllowedDelimiters.fromObject(object.allowedDelimiters);
        }
        switch (object.initResult) {
        case "FirstTime":
        case 0:
            message.initResult = 0;
            break;
        case "LastSuccess":
        case 1:
            message.initResult = 1;
            break;
        case "LastError":
        case 2:
            message.initResult = 2;
            break;
        }
        if (object.allowedDateFormatterPatterns) {
            if (!Array.isArray(object.allowedDateFormatterPatterns))
                throw TypeError(".ImportParserResponse.allowedDateFormatterPatterns: array expected");
            message.allowedDateFormatterPatterns = [];
            for (let i = 0; i < object.allowedDateFormatterPatterns.length; ++i)
                message.allowedDateFormatterPatterns[i] = String(object.allowedDateFormatterPatterns[i]);
        }
        if (object.rowValues) {
            if (!Array.isArray(object.rowValues))
                throw TypeError(".ImportParserResponse.rowValues: array expected");
            message.rowValues = [];
            for (let i = 0; i < object.rowValues.length; ++i) {
                if (typeof object.rowValues[i] !== "object")
                    throw TypeError(".ImportParserResponse.rowValues: object expected");
                message.rowValues[i] = $root.ImportRowValues.fromObject(object.rowValues[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an ImportParserResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportParserResponse
     * @static
     * @param {ImportParserResponse} message ImportParserResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportParserResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.allowedAttributes = [];
            object.parsedExample = [];
            object.allowedDateFormatterPatterns = [];
            object.rowValues = [];
        }
        if (options.defaults) {
            object.settings = null;
            object.allowedDelimiters = null;
            object.initResult = options.enums === String ? "FirstTime" : 0;
        }
        if (message.allowedAttributes && message.allowedAttributes.length) {
            object.allowedAttributes = [];
            for (let j = 0; j < message.allowedAttributes.length; ++j)
                object.allowedAttributes[j] = $root.ImportMappingAttribute.toObject(message.allowedAttributes[j], options);
        }
        if (message.parsedExample && message.parsedExample.length) {
            object.parsedExample = [];
            for (let j = 0; j < message.parsedExample.length; ++j)
                object.parsedExample[j] = $root.ImportParsedToken.toObject(message.parsedExample[j], options);
        }
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.ImportMappingSettings.toObject(message.settings, options);
        if (message.allowedDelimiters != null && message.hasOwnProperty("allowedDelimiters"))
            object.allowedDelimiters = $root.ImportAllowedDelimiters.toObject(message.allowedDelimiters, options);
        if (message.initResult != null && message.hasOwnProperty("initResult"))
            object.initResult = options.enums === String ? $root.ImportInitResultType[message.initResult] : message.initResult;
        if (message.allowedDateFormatterPatterns && message.allowedDateFormatterPatterns.length) {
            object.allowedDateFormatterPatterns = [];
            for (let j = 0; j < message.allowedDateFormatterPatterns.length; ++j)
                object.allowedDateFormatterPatterns[j] = message.allowedDateFormatterPatterns[j];
        }
        if (message.rowValues && message.rowValues.length) {
            object.rowValues = [];
            for (let j = 0; j < message.rowValues.length; ++j)
                object.rowValues[j] = $root.ImportRowValues.toObject(message.rowValues[j], options);
        }
        return object;
    };

    /**
     * Converts this ImportParserResponse to JSON.
     * @function toJSON
     * @memberof ImportParserResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportParserResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportParserResponse;
})();

export const ImportParserResultResponse = $root.ImportParserResultResponse = (() => {

    /**
     * Properties of an ImportParserResultResponse.
     * @exports IImportParserResultResponse
     * @interface IImportParserResultResponse
     * @property {string} transactionId ImportParserResultResponse transactionId
     */

    /**
     * Constructs a new ImportParserResultResponse.
     * @exports ImportParserResultResponse
     * @classdesc Represents an ImportParserResultResponse.
     * @implements IImportParserResultResponse
     * @constructor
     * @param {IImportParserResultResponse=} [properties] Properties to set
     */
    function ImportParserResultResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportParserResultResponse transactionId.
     * @member {string} transactionId
     * @memberof ImportParserResultResponse
     * @instance
     */
    ImportParserResultResponse.prototype.transactionId = "";

    /**
     * Creates a new ImportParserResultResponse instance using the specified properties.
     * @function create
     * @memberof ImportParserResultResponse
     * @static
     * @param {IImportParserResultResponse=} [properties] Properties to set
     * @returns {ImportParserResultResponse} ImportParserResultResponse instance
     */
    ImportParserResultResponse.create = function create(properties) {
        return new ImportParserResultResponse(properties);
    };

    /**
     * Encodes the specified ImportParserResultResponse message. Does not implicitly {@link ImportParserResultResponse.verify|verify} messages.
     * @function encode
     * @memberof ImportParserResultResponse
     * @static
     * @param {IImportParserResultResponse} message ImportParserResultResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParserResultResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionId);
        return writer;
    };

    /**
     * Encodes the specified ImportParserResultResponse message, length delimited. Does not implicitly {@link ImportParserResultResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportParserResultResponse
     * @static
     * @param {IImportParserResultResponse} message ImportParserResultResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParserResultResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportParserResultResponse message from the specified reader or buffer.
     * @function decode
     * @memberof ImportParserResultResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportParserResultResponse} ImportParserResultResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParserResultResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportParserResultResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.transactionId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("transactionId"))
            throw $util.ProtocolError("missing required 'transactionId'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportParserResultResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportParserResultResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportParserResultResponse} ImportParserResultResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParserResultResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportParserResultResponse message.
     * @function verify
     * @memberof ImportParserResultResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportParserResultResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.transactionId))
            return "transactionId: string expected";
        return null;
    };

    /**
     * Creates an ImportParserResultResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportParserResultResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportParserResultResponse} ImportParserResultResponse
     */
    ImportParserResultResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportParserResultResponse)
            return object;
        let message = new $root.ImportParserResultResponse();
        if (object.transactionId != null)
            message.transactionId = String(object.transactionId);
        return message;
    };

    /**
     * Creates a plain object from an ImportParserResultResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportParserResultResponse
     * @static
     * @param {ImportParserResultResponse} message ImportParserResultResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportParserResultResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.transactionId = "";
        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
            object.transactionId = message.transactionId;
        return object;
    };

    /**
     * Converts this ImportParserResultResponse to JSON.
     * @function toJSON
     * @memberof ImportParserResultResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportParserResultResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportParserResultResponse;
})();

export const ImportParseStatementRequest = $root.ImportParseStatementRequest = (() => {

    /**
     * Properties of an ImportParseStatementRequest.
     * @exports IImportParseStatementRequest
     * @interface IImportParseStatementRequest
     * @property {Array.<IImportMappingAttributeTypeWithIndex>|null} [mapping] ImportParseStatementRequest mapping
     * @property {IImportMappingSettings} settings ImportParseStatementRequest settings
     */

    /**
     * Constructs a new ImportParseStatementRequest.
     * @exports ImportParseStatementRequest
     * @classdesc Represents an ImportParseStatementRequest.
     * @implements IImportParseStatementRequest
     * @constructor
     * @param {IImportParseStatementRequest=} [properties] Properties to set
     */
    function ImportParseStatementRequest(properties) {
        this.mapping = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportParseStatementRequest mapping.
     * @member {Array.<IImportMappingAttributeTypeWithIndex>} mapping
     * @memberof ImportParseStatementRequest
     * @instance
     */
    ImportParseStatementRequest.prototype.mapping = $util.emptyArray;

    /**
     * ImportParseStatementRequest settings.
     * @member {IImportMappingSettings} settings
     * @memberof ImportParseStatementRequest
     * @instance
     */
    ImportParseStatementRequest.prototype.settings = null;

    /**
     * Creates a new ImportParseStatementRequest instance using the specified properties.
     * @function create
     * @memberof ImportParseStatementRequest
     * @static
     * @param {IImportParseStatementRequest=} [properties] Properties to set
     * @returns {ImportParseStatementRequest} ImportParseStatementRequest instance
     */
    ImportParseStatementRequest.create = function create(properties) {
        return new ImportParseStatementRequest(properties);
    };

    /**
     * Encodes the specified ImportParseStatementRequest message. Does not implicitly {@link ImportParseStatementRequest.verify|verify} messages.
     * @function encode
     * @memberof ImportParseStatementRequest
     * @static
     * @param {IImportParseStatementRequest} message ImportParseStatementRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParseStatementRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.mapping != null && message.mapping.length)
            for (let i = 0; i < message.mapping.length; ++i)
                $root.ImportMappingAttributeTypeWithIndex.encode(message.mapping[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        $root.ImportMappingSettings.encode(message.settings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ImportParseStatementRequest message, length delimited. Does not implicitly {@link ImportParseStatementRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportParseStatementRequest
     * @static
     * @param {IImportParseStatementRequest} message ImportParseStatementRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportParseStatementRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportParseStatementRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ImportParseStatementRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportParseStatementRequest} ImportParseStatementRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParseStatementRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportParseStatementRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.mapping && message.mapping.length))
                    message.mapping = [];
                message.mapping.push($root.ImportMappingAttributeTypeWithIndex.decode(reader, reader.uint32()));
                break;
            case 2:
                message.settings = $root.ImportMappingSettings.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("settings"))
            throw $util.ProtocolError("missing required 'settings'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportParseStatementRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportParseStatementRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportParseStatementRequest} ImportParseStatementRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportParseStatementRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportParseStatementRequest message.
     * @function verify
     * @memberof ImportParseStatementRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportParseStatementRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.mapping != null && message.hasOwnProperty("mapping")) {
            if (!Array.isArray(message.mapping))
                return "mapping: array expected";
            for (let i = 0; i < message.mapping.length; ++i) {
                let error = $root.ImportMappingAttributeTypeWithIndex.verify(message.mapping[i]);
                if (error)
                    return "mapping." + error;
            }
        }
        {
            let error = $root.ImportMappingSettings.verify(message.settings);
            if (error)
                return "settings." + error;
        }
        return null;
    };

    /**
     * Creates an ImportParseStatementRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportParseStatementRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportParseStatementRequest} ImportParseStatementRequest
     */
    ImportParseStatementRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportParseStatementRequest)
            return object;
        let message = new $root.ImportParseStatementRequest();
        if (object.mapping) {
            if (!Array.isArray(object.mapping))
                throw TypeError(".ImportParseStatementRequest.mapping: array expected");
            message.mapping = [];
            for (let i = 0; i < object.mapping.length; ++i) {
                if (typeof object.mapping[i] !== "object")
                    throw TypeError(".ImportParseStatementRequest.mapping: object expected");
                message.mapping[i] = $root.ImportMappingAttributeTypeWithIndex.fromObject(object.mapping[i]);
            }
        }
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".ImportParseStatementRequest.settings: object expected");
            message.settings = $root.ImportMappingSettings.fromObject(object.settings);
        }
        return message;
    };

    /**
     * Creates a plain object from an ImportParseStatementRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportParseStatementRequest
     * @static
     * @param {ImportParseStatementRequest} message ImportParseStatementRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportParseStatementRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.mapping = [];
        if (options.defaults)
            object.settings = null;
        if (message.mapping && message.mapping.length) {
            object.mapping = [];
            for (let j = 0; j < message.mapping.length; ++j)
                object.mapping[j] = $root.ImportMappingAttributeTypeWithIndex.toObject(message.mapping[j], options);
        }
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.ImportMappingSettings.toObject(message.settings, options);
        return object;
    };

    /**
     * Converts this ImportParseStatementRequest to JSON.
     * @function toJSON
     * @memberof ImportParseStatementRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportParseStatementRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportParseStatementRequest;
})();

export const ImportMappingAttributeTypeWithIndex = $root.ImportMappingAttributeTypeWithIndex = (() => {

    /**
     * Properties of an ImportMappingAttributeTypeWithIndex.
     * @exports IImportMappingAttributeTypeWithIndex
     * @interface IImportMappingAttributeTypeWithIndex
     * @property {ImportMappingAttributeType} type ImportMappingAttributeTypeWithIndex type
     * @property {number} colIndex ImportMappingAttributeTypeWithIndex colIndex
     * @property {number|null} [priority] ImportMappingAttributeTypeWithIndex priority
     */

    /**
     * Constructs a new ImportMappingAttributeTypeWithIndex.
     * @exports ImportMappingAttributeTypeWithIndex
     * @classdesc Represents an ImportMappingAttributeTypeWithIndex.
     * @implements IImportMappingAttributeTypeWithIndex
     * @constructor
     * @param {IImportMappingAttributeTypeWithIndex=} [properties] Properties to set
     */
    function ImportMappingAttributeTypeWithIndex(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportMappingAttributeTypeWithIndex type.
     * @member {ImportMappingAttributeType} type
     * @memberof ImportMappingAttributeTypeWithIndex
     * @instance
     */
    ImportMappingAttributeTypeWithIndex.prototype.type = 0;

    /**
     * ImportMappingAttributeTypeWithIndex colIndex.
     * @member {number} colIndex
     * @memberof ImportMappingAttributeTypeWithIndex
     * @instance
     */
    ImportMappingAttributeTypeWithIndex.prototype.colIndex = 0;

    /**
     * ImportMappingAttributeTypeWithIndex priority.
     * @member {number} priority
     * @memberof ImportMappingAttributeTypeWithIndex
     * @instance
     */
    ImportMappingAttributeTypeWithIndex.prototype.priority = 0;

    /**
     * Creates a new ImportMappingAttributeTypeWithIndex instance using the specified properties.
     * @function create
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {IImportMappingAttributeTypeWithIndex=} [properties] Properties to set
     * @returns {ImportMappingAttributeTypeWithIndex} ImportMappingAttributeTypeWithIndex instance
     */
    ImportMappingAttributeTypeWithIndex.create = function create(properties) {
        return new ImportMappingAttributeTypeWithIndex(properties);
    };

    /**
     * Encodes the specified ImportMappingAttributeTypeWithIndex message. Does not implicitly {@link ImportMappingAttributeTypeWithIndex.verify|verify} messages.
     * @function encode
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {IImportMappingAttributeTypeWithIndex} message ImportMappingAttributeTypeWithIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportMappingAttributeTypeWithIndex.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.colIndex);
        if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
        return writer;
    };

    /**
     * Encodes the specified ImportMappingAttributeTypeWithIndex message, length delimited. Does not implicitly {@link ImportMappingAttributeTypeWithIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {IImportMappingAttributeTypeWithIndex} message ImportMappingAttributeTypeWithIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportMappingAttributeTypeWithIndex.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportMappingAttributeTypeWithIndex message from the specified reader or buffer.
     * @function decode
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportMappingAttributeTypeWithIndex} ImportMappingAttributeTypeWithIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportMappingAttributeTypeWithIndex.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportMappingAttributeTypeWithIndex();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.colIndex = reader.int32();
                break;
            case 3:
                message.priority = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        if (!message.hasOwnProperty("colIndex"))
            throw $util.ProtocolError("missing required 'colIndex'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportMappingAttributeTypeWithIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportMappingAttributeTypeWithIndex} ImportMappingAttributeTypeWithIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportMappingAttributeTypeWithIndex.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportMappingAttributeTypeWithIndex message.
     * @function verify
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportMappingAttributeTypeWithIndex.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
            break;
        }
        if (!$util.isInteger(message.colIndex))
            return "colIndex: integer expected";
        if (message.priority != null && message.hasOwnProperty("priority"))
            if (!$util.isInteger(message.priority))
                return "priority: integer expected";
        return null;
    };

    /**
     * Creates an ImportMappingAttributeTypeWithIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportMappingAttributeTypeWithIndex} ImportMappingAttributeTypeWithIndex
     */
    ImportMappingAttributeTypeWithIndex.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportMappingAttributeTypeWithIndex)
            return object;
        let message = new $root.ImportMappingAttributeTypeWithIndex();
        switch (object.type) {
        case "CurrencyCode":
        case 0:
            message.type = 0;
            break;
        case "Amount":
        case 1:
            message.type = 1;
            break;
        case "Note":
        case 2:
            message.type = 2;
            break;
        case "RecordDate":
        case 3:
            message.type = 3;
            break;
        case "Payee":
        case 4:
            message.type = 4;
            break;
        case "Unknown":
        case 5:
            message.type = 5;
            break;
        case "Expense":
        case 6:
            message.type = 6;
            break;
        case "Fee":
        case 7:
            message.type = 7;
            break;
        case "CategoryMapping":
        case 8:
            message.type = 8;
            break;
        }
        if (object.colIndex != null)
            message.colIndex = object.colIndex | 0;
        if (object.priority != null)
            message.priority = object.priority | 0;
        return message;
    };

    /**
     * Creates a plain object from an ImportMappingAttributeTypeWithIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportMappingAttributeTypeWithIndex
     * @static
     * @param {ImportMappingAttributeTypeWithIndex} message ImportMappingAttributeTypeWithIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportMappingAttributeTypeWithIndex.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "CurrencyCode" : 0;
            object.colIndex = 0;
            object.priority = 0;
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.ImportMappingAttributeType[message.type] : message.type;
        if (message.colIndex != null && message.hasOwnProperty("colIndex"))
            object.colIndex = message.colIndex;
        if (message.priority != null && message.hasOwnProperty("priority"))
            object.priority = message.priority;
        return object;
    };

    /**
     * Converts this ImportMappingAttributeTypeWithIndex to JSON.
     * @function toJSON
     * @memberof ImportMappingAttributeTypeWithIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportMappingAttributeTypeWithIndex.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportMappingAttributeTypeWithIndex;
})();

export const ImportDeleteRequest = $root.ImportDeleteRequest = (() => {

    /**
     * Properties of an ImportDeleteRequest.
     * @exports IImportDeleteRequest
     * @interface IImportDeleteRequest
     * @property {string} accountId ImportDeleteRequest accountId
     */

    /**
     * Constructs a new ImportDeleteRequest.
     * @exports ImportDeleteRequest
     * @classdesc Represents an ImportDeleteRequest.
     * @implements IImportDeleteRequest
     * @constructor
     * @param {IImportDeleteRequest=} [properties] Properties to set
     */
    function ImportDeleteRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportDeleteRequest accountId.
     * @member {string} accountId
     * @memberof ImportDeleteRequest
     * @instance
     */
    ImportDeleteRequest.prototype.accountId = "";

    /**
     * Creates a new ImportDeleteRequest instance using the specified properties.
     * @function create
     * @memberof ImportDeleteRequest
     * @static
     * @param {IImportDeleteRequest=} [properties] Properties to set
     * @returns {ImportDeleteRequest} ImportDeleteRequest instance
     */
    ImportDeleteRequest.create = function create(properties) {
        return new ImportDeleteRequest(properties);
    };

    /**
     * Encodes the specified ImportDeleteRequest message. Does not implicitly {@link ImportDeleteRequest.verify|verify} messages.
     * @function encode
     * @memberof ImportDeleteRequest
     * @static
     * @param {IImportDeleteRequest} message ImportDeleteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportDeleteRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.accountId);
        return writer;
    };

    /**
     * Encodes the specified ImportDeleteRequest message, length delimited. Does not implicitly {@link ImportDeleteRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportDeleteRequest
     * @static
     * @param {IImportDeleteRequest} message ImportDeleteRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportDeleteRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportDeleteRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ImportDeleteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportDeleteRequest} ImportDeleteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportDeleteRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportDeleteRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.accountId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("accountId"))
            throw $util.ProtocolError("missing required 'accountId'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportDeleteRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportDeleteRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportDeleteRequest} ImportDeleteRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportDeleteRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportDeleteRequest message.
     * @function verify
     * @memberof ImportDeleteRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportDeleteRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.accountId))
            return "accountId: string expected";
        return null;
    };

    /**
     * Creates an ImportDeleteRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportDeleteRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportDeleteRequest} ImportDeleteRequest
     */
    ImportDeleteRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportDeleteRequest)
            return object;
        let message = new $root.ImportDeleteRequest();
        if (object.accountId != null)
            message.accountId = String(object.accountId);
        return message;
    };

    /**
     * Creates a plain object from an ImportDeleteRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportDeleteRequest
     * @static
     * @param {ImportDeleteRequest} message ImportDeleteRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportDeleteRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.accountId = "";
        if (message.accountId != null && message.hasOwnProperty("accountId"))
            object.accountId = message.accountId;
        return object;
    };

    /**
     * Converts this ImportDeleteRequest to JSON.
     * @function toJSON
     * @memberof ImportDeleteRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportDeleteRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportDeleteRequest;
})();

export const ImportAllowedDelimiters = $root.ImportAllowedDelimiters = (() => {

    /**
     * Properties of an ImportAllowedDelimiters.
     * @exports IImportAllowedDelimiters
     * @interface IImportAllowedDelimiters
     * @property {Array.<string>|null} [delimiters] ImportAllowedDelimiters delimiters
     */

    /**
     * Constructs a new ImportAllowedDelimiters.
     * @exports ImportAllowedDelimiters
     * @classdesc Represents an ImportAllowedDelimiters.
     * @implements IImportAllowedDelimiters
     * @constructor
     * @param {IImportAllowedDelimiters=} [properties] Properties to set
     */
    function ImportAllowedDelimiters(properties) {
        this.delimiters = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportAllowedDelimiters delimiters.
     * @member {Array.<string>} delimiters
     * @memberof ImportAllowedDelimiters
     * @instance
     */
    ImportAllowedDelimiters.prototype.delimiters = $util.emptyArray;

    /**
     * Creates a new ImportAllowedDelimiters instance using the specified properties.
     * @function create
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {IImportAllowedDelimiters=} [properties] Properties to set
     * @returns {ImportAllowedDelimiters} ImportAllowedDelimiters instance
     */
    ImportAllowedDelimiters.create = function create(properties) {
        return new ImportAllowedDelimiters(properties);
    };

    /**
     * Encodes the specified ImportAllowedDelimiters message. Does not implicitly {@link ImportAllowedDelimiters.verify|verify} messages.
     * @function encode
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {IImportAllowedDelimiters} message ImportAllowedDelimiters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportAllowedDelimiters.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.delimiters != null && message.delimiters.length)
            for (let i = 0; i < message.delimiters.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.delimiters[i]);
        return writer;
    };

    /**
     * Encodes the specified ImportAllowedDelimiters message, length delimited. Does not implicitly {@link ImportAllowedDelimiters.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {IImportAllowedDelimiters} message ImportAllowedDelimiters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportAllowedDelimiters.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportAllowedDelimiters message from the specified reader or buffer.
     * @function decode
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportAllowedDelimiters} ImportAllowedDelimiters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportAllowedDelimiters.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportAllowedDelimiters();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.delimiters && message.delimiters.length))
                    message.delimiters = [];
                message.delimiters.push(reader.string());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ImportAllowedDelimiters message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportAllowedDelimiters} ImportAllowedDelimiters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportAllowedDelimiters.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportAllowedDelimiters message.
     * @function verify
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportAllowedDelimiters.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.delimiters != null && message.hasOwnProperty("delimiters")) {
            if (!Array.isArray(message.delimiters))
                return "delimiters: array expected";
            for (let i = 0; i < message.delimiters.length; ++i)
                if (!$util.isString(message.delimiters[i]))
                    return "delimiters: string[] expected";
        }
        return null;
    };

    /**
     * Creates an ImportAllowedDelimiters message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportAllowedDelimiters} ImportAllowedDelimiters
     */
    ImportAllowedDelimiters.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportAllowedDelimiters)
            return object;
        let message = new $root.ImportAllowedDelimiters();
        if (object.delimiters) {
            if (!Array.isArray(object.delimiters))
                throw TypeError(".ImportAllowedDelimiters.delimiters: array expected");
            message.delimiters = [];
            for (let i = 0; i < object.delimiters.length; ++i)
                message.delimiters[i] = String(object.delimiters[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from an ImportAllowedDelimiters message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportAllowedDelimiters
     * @static
     * @param {ImportAllowedDelimiters} message ImportAllowedDelimiters
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportAllowedDelimiters.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.delimiters = [];
        if (message.delimiters && message.delimiters.length) {
            object.delimiters = [];
            for (let j = 0; j < message.delimiters.length; ++j)
                object.delimiters[j] = message.delimiters[j];
        }
        return object;
    };

    /**
     * Converts this ImportAllowedDelimiters to JSON.
     * @function toJSON
     * @memberof ImportAllowedDelimiters
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportAllowedDelimiters.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportAllowedDelimiters;
})();

export const ImportAccountCreationRequest = $root.ImportAccountCreationRequest = (() => {

    /**
     * Properties of an ImportAccountCreationRequest.
     * @exports IImportAccountCreationRequest
     * @interface IImportAccountCreationRequest
     * @property {string} name ImportAccountCreationRequest name
     * @property {number|Long} initialValue ImportAccountCreationRequest initialValue
     * @property {string} currencyId ImportAccountCreationRequest currencyId
     * @property {string} color ImportAccountCreationRequest color
     * @property {boolean} excludeFromStats ImportAccountCreationRequest excludeFromStats
     * @property {boolean} gps ImportAccountCreationRequest gps
     */

    /**
     * Constructs a new ImportAccountCreationRequest.
     * @exports ImportAccountCreationRequest
     * @classdesc Represents an ImportAccountCreationRequest.
     * @implements IImportAccountCreationRequest
     * @constructor
     * @param {IImportAccountCreationRequest=} [properties] Properties to set
     */
    function ImportAccountCreationRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportAccountCreationRequest name.
     * @member {string} name
     * @memberof ImportAccountCreationRequest
     * @instance
     */
    ImportAccountCreationRequest.prototype.name = "";

    /**
     * ImportAccountCreationRequest initialValue.
     * @member {number|Long} initialValue
     * @memberof ImportAccountCreationRequest
     * @instance
     */
    ImportAccountCreationRequest.prototype.initialValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ImportAccountCreationRequest currencyId.
     * @member {string} currencyId
     * @memberof ImportAccountCreationRequest
     * @instance
     */
    ImportAccountCreationRequest.prototype.currencyId = "";

    /**
     * ImportAccountCreationRequest color.
     * @member {string} color
     * @memberof ImportAccountCreationRequest
     * @instance
     */
    ImportAccountCreationRequest.prototype.color = "";

    /**
     * ImportAccountCreationRequest excludeFromStats.
     * @member {boolean} excludeFromStats
     * @memberof ImportAccountCreationRequest
     * @instance
     */
    ImportAccountCreationRequest.prototype.excludeFromStats = false;

    /**
     * ImportAccountCreationRequest gps.
     * @member {boolean} gps
     * @memberof ImportAccountCreationRequest
     * @instance
     */
    ImportAccountCreationRequest.prototype.gps = false;

    /**
     * Creates a new ImportAccountCreationRequest instance using the specified properties.
     * @function create
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {IImportAccountCreationRequest=} [properties] Properties to set
     * @returns {ImportAccountCreationRequest} ImportAccountCreationRequest instance
     */
    ImportAccountCreationRequest.create = function create(properties) {
        return new ImportAccountCreationRequest(properties);
    };

    /**
     * Encodes the specified ImportAccountCreationRequest message. Does not implicitly {@link ImportAccountCreationRequest.verify|verify} messages.
     * @function encode
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {IImportAccountCreationRequest} message ImportAccountCreationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportAccountCreationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.initialValue);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.currencyId);
        writer.uint32(/* id 4, wireType 2 =*/34).string(message.color);
        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.excludeFromStats);
        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.gps);
        return writer;
    };

    /**
     * Encodes the specified ImportAccountCreationRequest message, length delimited. Does not implicitly {@link ImportAccountCreationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {IImportAccountCreationRequest} message ImportAccountCreationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportAccountCreationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportAccountCreationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportAccountCreationRequest} ImportAccountCreationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportAccountCreationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportAccountCreationRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.name = reader.string();
                break;
            case 2:
                message.initialValue = reader.int64();
                break;
            case 3:
                message.currencyId = reader.string();
                break;
            case 4:
                message.color = reader.string();
                break;
            case 5:
                message.excludeFromStats = reader.bool();
                break;
            case 6:
                message.gps = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("name"))
            throw $util.ProtocolError("missing required 'name'", { instance: message });
        if (!message.hasOwnProperty("initialValue"))
            throw $util.ProtocolError("missing required 'initialValue'", { instance: message });
        if (!message.hasOwnProperty("currencyId"))
            throw $util.ProtocolError("missing required 'currencyId'", { instance: message });
        if (!message.hasOwnProperty("color"))
            throw $util.ProtocolError("missing required 'color'", { instance: message });
        if (!message.hasOwnProperty("excludeFromStats"))
            throw $util.ProtocolError("missing required 'excludeFromStats'", { instance: message });
        if (!message.hasOwnProperty("gps"))
            throw $util.ProtocolError("missing required 'gps'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportAccountCreationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportAccountCreationRequest} ImportAccountCreationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportAccountCreationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportAccountCreationRequest message.
     * @function verify
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportAccountCreationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.name))
            return "name: string expected";
        if (!$util.isInteger(message.initialValue) && !(message.initialValue && $util.isInteger(message.initialValue.low) && $util.isInteger(message.initialValue.high)))
            return "initialValue: integer|Long expected";
        if (!$util.isString(message.currencyId))
            return "currencyId: string expected";
        if (!$util.isString(message.color))
            return "color: string expected";
        if (typeof message.excludeFromStats !== "boolean")
            return "excludeFromStats: boolean expected";
        if (typeof message.gps !== "boolean")
            return "gps: boolean expected";
        return null;
    };

    /**
     * Creates an ImportAccountCreationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportAccountCreationRequest} ImportAccountCreationRequest
     */
    ImportAccountCreationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportAccountCreationRequest)
            return object;
        let message = new $root.ImportAccountCreationRequest();
        if (object.name != null)
            message.name = String(object.name);
        if (object.initialValue != null)
            if ($util.Long)
                (message.initialValue = $util.Long.fromValue(object.initialValue)).unsigned = false;
            else if (typeof object.initialValue === "string")
                message.initialValue = parseInt(object.initialValue, 10);
            else if (typeof object.initialValue === "number")
                message.initialValue = object.initialValue;
            else if (typeof object.initialValue === "object")
                message.initialValue = new $util.LongBits(object.initialValue.low >>> 0, object.initialValue.high >>> 0).toNumber();
        if (object.currencyId != null)
            message.currencyId = String(object.currencyId);
        if (object.color != null)
            message.color = String(object.color);
        if (object.excludeFromStats != null)
            message.excludeFromStats = Boolean(object.excludeFromStats);
        if (object.gps != null)
            message.gps = Boolean(object.gps);
        return message;
    };

    /**
     * Creates a plain object from an ImportAccountCreationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportAccountCreationRequest
     * @static
     * @param {ImportAccountCreationRequest} message ImportAccountCreationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportAccountCreationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.name = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.initialValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.initialValue = options.longs === String ? "0" : 0;
            object.currencyId = "";
            object.color = "";
            object.excludeFromStats = false;
            object.gps = false;
        }
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.initialValue != null && message.hasOwnProperty("initialValue"))
            if (typeof message.initialValue === "number")
                object.initialValue = options.longs === String ? String(message.initialValue) : message.initialValue;
            else
                object.initialValue = options.longs === String ? $util.Long.prototype.toString.call(message.initialValue) : options.longs === Number ? new $util.LongBits(message.initialValue.low >>> 0, message.initialValue.high >>> 0).toNumber() : message.initialValue;
        if (message.currencyId != null && message.hasOwnProperty("currencyId"))
            object.currencyId = message.currencyId;
        if (message.color != null && message.hasOwnProperty("color"))
            object.color = message.color;
        if (message.excludeFromStats != null && message.hasOwnProperty("excludeFromStats"))
            object.excludeFromStats = message.excludeFromStats;
        if (message.gps != null && message.hasOwnProperty("gps"))
            object.gps = message.gps;
        return object;
    };

    /**
     * Converts this ImportAccountCreationRequest to JSON.
     * @function toJSON
     * @memberof ImportAccountCreationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportAccountCreationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportAccountCreationRequest;
})();

export const ImportActivationAutomaticRequest = $root.ImportActivationAutomaticRequest = (() => {

    /**
     * Properties of an ImportActivationAutomaticRequest.
     * @exports IImportActivationAutomaticRequest
     * @interface IImportActivationAutomaticRequest
     * @property {string} settingsId ImportActivationAutomaticRequest settingsId
     */

    /**
     * Constructs a new ImportActivationAutomaticRequest.
     * @exports ImportActivationAutomaticRequest
     * @classdesc Represents an ImportActivationAutomaticRequest.
     * @implements IImportActivationAutomaticRequest
     * @constructor
     * @param {IImportActivationAutomaticRequest=} [properties] Properties to set
     */
    function ImportActivationAutomaticRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportActivationAutomaticRequest settingsId.
     * @member {string} settingsId
     * @memberof ImportActivationAutomaticRequest
     * @instance
     */
    ImportActivationAutomaticRequest.prototype.settingsId = "";

    /**
     * Creates a new ImportActivationAutomaticRequest instance using the specified properties.
     * @function create
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {IImportActivationAutomaticRequest=} [properties] Properties to set
     * @returns {ImportActivationAutomaticRequest} ImportActivationAutomaticRequest instance
     */
    ImportActivationAutomaticRequest.create = function create(properties) {
        return new ImportActivationAutomaticRequest(properties);
    };

    /**
     * Encodes the specified ImportActivationAutomaticRequest message. Does not implicitly {@link ImportActivationAutomaticRequest.verify|verify} messages.
     * @function encode
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {IImportActivationAutomaticRequest} message ImportActivationAutomaticRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportActivationAutomaticRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.settingsId);
        return writer;
    };

    /**
     * Encodes the specified ImportActivationAutomaticRequest message, length delimited. Does not implicitly {@link ImportActivationAutomaticRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {IImportActivationAutomaticRequest} message ImportActivationAutomaticRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportActivationAutomaticRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportActivationAutomaticRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportActivationAutomaticRequest} ImportActivationAutomaticRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportActivationAutomaticRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportActivationAutomaticRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.settingsId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("settingsId"))
            throw $util.ProtocolError("missing required 'settingsId'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportActivationAutomaticRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportActivationAutomaticRequest} ImportActivationAutomaticRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportActivationAutomaticRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportActivationAutomaticRequest message.
     * @function verify
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportActivationAutomaticRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.settingsId))
            return "settingsId: string expected";
        return null;
    };

    /**
     * Creates an ImportActivationAutomaticRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportActivationAutomaticRequest} ImportActivationAutomaticRequest
     */
    ImportActivationAutomaticRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportActivationAutomaticRequest)
            return object;
        let message = new $root.ImportActivationAutomaticRequest();
        if (object.settingsId != null)
            message.settingsId = String(object.settingsId);
        return message;
    };

    /**
     * Creates a plain object from an ImportActivationAutomaticRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportActivationAutomaticRequest
     * @static
     * @param {ImportActivationAutomaticRequest} message ImportActivationAutomaticRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportActivationAutomaticRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.settingsId = "";
        if (message.settingsId != null && message.hasOwnProperty("settingsId"))
            object.settingsId = message.settingsId;
        return object;
    };

    /**
     * Converts this ImportActivationAutomaticRequest to JSON.
     * @function toJSON
     * @memberof ImportActivationAutomaticRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportActivationAutomaticRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportActivationAutomaticRequest;
})();

export const ImportDeactivationAutomaticRequest = $root.ImportDeactivationAutomaticRequest = (() => {

    /**
     * Properties of an ImportDeactivationAutomaticRequest.
     * @exports IImportDeactivationAutomaticRequest
     * @interface IImportDeactivationAutomaticRequest
     * @property {string} settingsId ImportDeactivationAutomaticRequest settingsId
     */

    /**
     * Constructs a new ImportDeactivationAutomaticRequest.
     * @exports ImportDeactivationAutomaticRequest
     * @classdesc Represents an ImportDeactivationAutomaticRequest.
     * @implements IImportDeactivationAutomaticRequest
     * @constructor
     * @param {IImportDeactivationAutomaticRequest=} [properties] Properties to set
     */
    function ImportDeactivationAutomaticRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportDeactivationAutomaticRequest settingsId.
     * @member {string} settingsId
     * @memberof ImportDeactivationAutomaticRequest
     * @instance
     */
    ImportDeactivationAutomaticRequest.prototype.settingsId = "";

    /**
     * Creates a new ImportDeactivationAutomaticRequest instance using the specified properties.
     * @function create
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {IImportDeactivationAutomaticRequest=} [properties] Properties to set
     * @returns {ImportDeactivationAutomaticRequest} ImportDeactivationAutomaticRequest instance
     */
    ImportDeactivationAutomaticRequest.create = function create(properties) {
        return new ImportDeactivationAutomaticRequest(properties);
    };

    /**
     * Encodes the specified ImportDeactivationAutomaticRequest message. Does not implicitly {@link ImportDeactivationAutomaticRequest.verify|verify} messages.
     * @function encode
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {IImportDeactivationAutomaticRequest} message ImportDeactivationAutomaticRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportDeactivationAutomaticRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.settingsId);
        return writer;
    };

    /**
     * Encodes the specified ImportDeactivationAutomaticRequest message, length delimited. Does not implicitly {@link ImportDeactivationAutomaticRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {IImportDeactivationAutomaticRequest} message ImportDeactivationAutomaticRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportDeactivationAutomaticRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportDeactivationAutomaticRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportDeactivationAutomaticRequest} ImportDeactivationAutomaticRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportDeactivationAutomaticRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportDeactivationAutomaticRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.settingsId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("settingsId"))
            throw $util.ProtocolError("missing required 'settingsId'", { instance: message });
        return message;
    };

    /**
     * Decodes an ImportDeactivationAutomaticRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportDeactivationAutomaticRequest} ImportDeactivationAutomaticRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportDeactivationAutomaticRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportDeactivationAutomaticRequest message.
     * @function verify
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportDeactivationAutomaticRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.settingsId))
            return "settingsId: string expected";
        return null;
    };

    /**
     * Creates an ImportDeactivationAutomaticRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportDeactivationAutomaticRequest} ImportDeactivationAutomaticRequest
     */
    ImportDeactivationAutomaticRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportDeactivationAutomaticRequest)
            return object;
        let message = new $root.ImportDeactivationAutomaticRequest();
        if (object.settingsId != null)
            message.settingsId = String(object.settingsId);
        return message;
    };

    /**
     * Creates a plain object from an ImportDeactivationAutomaticRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportDeactivationAutomaticRequest
     * @static
     * @param {ImportDeactivationAutomaticRequest} message ImportDeactivationAutomaticRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportDeactivationAutomaticRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.settingsId = "";
        if (message.settingsId != null && message.hasOwnProperty("settingsId"))
            object.settingsId = message.settingsId;
        return object;
    };

    /**
     * Converts this ImportDeactivationAutomaticRequest to JSON.
     * @function toJSON
     * @memberof ImportDeactivationAutomaticRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportDeactivationAutomaticRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportDeactivationAutomaticRequest;
})();

export const ImportRowValues = $root.ImportRowValues = (() => {

    /**
     * Properties of an ImportRowValues.
     * @exports IImportRowValues
     * @interface IImportRowValues
     * @property {Array.<string>|null} [value] ImportRowValues value
     */

    /**
     * Constructs a new ImportRowValues.
     * @exports ImportRowValues
     * @classdesc Represents an ImportRowValues.
     * @implements IImportRowValues
     * @constructor
     * @param {IImportRowValues=} [properties] Properties to set
     */
    function ImportRowValues(properties) {
        this.value = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ImportRowValues value.
     * @member {Array.<string>} value
     * @memberof ImportRowValues
     * @instance
     */
    ImportRowValues.prototype.value = $util.emptyArray;

    /**
     * Creates a new ImportRowValues instance using the specified properties.
     * @function create
     * @memberof ImportRowValues
     * @static
     * @param {IImportRowValues=} [properties] Properties to set
     * @returns {ImportRowValues} ImportRowValues instance
     */
    ImportRowValues.create = function create(properties) {
        return new ImportRowValues(properties);
    };

    /**
     * Encodes the specified ImportRowValues message. Does not implicitly {@link ImportRowValues.verify|verify} messages.
     * @function encode
     * @memberof ImportRowValues
     * @static
     * @param {IImportRowValues} message ImportRowValues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportRowValues.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.value != null && message.value.length)
            for (let i = 0; i < message.value.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value[i]);
        return writer;
    };

    /**
     * Encodes the specified ImportRowValues message, length delimited. Does not implicitly {@link ImportRowValues.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ImportRowValues
     * @static
     * @param {IImportRowValues} message ImportRowValues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ImportRowValues.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ImportRowValues message from the specified reader or buffer.
     * @function decode
     * @memberof ImportRowValues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ImportRowValues} ImportRowValues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportRowValues.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ImportRowValues();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.value && message.value.length))
                    message.value = [];
                message.value.push(reader.string());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ImportRowValues message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ImportRowValues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ImportRowValues} ImportRowValues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ImportRowValues.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ImportRowValues message.
     * @function verify
     * @memberof ImportRowValues
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ImportRowValues.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.value != null && message.hasOwnProperty("value")) {
            if (!Array.isArray(message.value))
                return "value: array expected";
            for (let i = 0; i < message.value.length; ++i)
                if (!$util.isString(message.value[i]))
                    return "value: string[] expected";
        }
        return null;
    };

    /**
     * Creates an ImportRowValues message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ImportRowValues
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ImportRowValues} ImportRowValues
     */
    ImportRowValues.fromObject = function fromObject(object) {
        if (object instanceof $root.ImportRowValues)
            return object;
        let message = new $root.ImportRowValues();
        if (object.value) {
            if (!Array.isArray(object.value))
                throw TypeError(".ImportRowValues.value: array expected");
            message.value = [];
            for (let i = 0; i < object.value.length; ++i)
                message.value[i] = String(object.value[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from an ImportRowValues message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ImportRowValues
     * @static
     * @param {ImportRowValues} message ImportRowValues
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ImportRowValues.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.value = [];
        if (message.value && message.value.length) {
            object.value = [];
            for (let j = 0; j < message.value.length; ++j)
                object.value[j] = message.value[j];
        }
        return object;
    };

    /**
     * Converts this ImportRowValues to JSON.
     * @function toJSON
     * @memberof ImportRowValues
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ImportRowValues.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ImportRowValues;
})();

export const ManualStandingOrderRequest = $root.ManualStandingOrderRequest = (() => {

    /**
     * Properties of a ManualStandingOrderRequest.
     * @exports IManualStandingOrderRequest
     * @interface IManualStandingOrderRequest
     * @property {string} standingOrderId ManualStandingOrderRequest standingOrderId
     * @property {number|Long} validForDay ManualStandingOrderRequest validForDay
     */

    /**
     * Constructs a new ManualStandingOrderRequest.
     * @exports ManualStandingOrderRequest
     * @classdesc Represents a ManualStandingOrderRequest.
     * @implements IManualStandingOrderRequest
     * @constructor
     * @param {IManualStandingOrderRequest=} [properties] Properties to set
     */
    function ManualStandingOrderRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ManualStandingOrderRequest standingOrderId.
     * @member {string} standingOrderId
     * @memberof ManualStandingOrderRequest
     * @instance
     */
    ManualStandingOrderRequest.prototype.standingOrderId = "";

    /**
     * ManualStandingOrderRequest validForDay.
     * @member {number|Long} validForDay
     * @memberof ManualStandingOrderRequest
     * @instance
     */
    ManualStandingOrderRequest.prototype.validForDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new ManualStandingOrderRequest instance using the specified properties.
     * @function create
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {IManualStandingOrderRequest=} [properties] Properties to set
     * @returns {ManualStandingOrderRequest} ManualStandingOrderRequest instance
     */
    ManualStandingOrderRequest.create = function create(properties) {
        return new ManualStandingOrderRequest(properties);
    };

    /**
     * Encodes the specified ManualStandingOrderRequest message. Does not implicitly {@link ManualStandingOrderRequest.verify|verify} messages.
     * @function encode
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {IManualStandingOrderRequest} message ManualStandingOrderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ManualStandingOrderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.standingOrderId);
        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.validForDay);
        return writer;
    };

    /**
     * Encodes the specified ManualStandingOrderRequest message, length delimited. Does not implicitly {@link ManualStandingOrderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {IManualStandingOrderRequest} message ManualStandingOrderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ManualStandingOrderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ManualStandingOrderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ManualStandingOrderRequest} ManualStandingOrderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ManualStandingOrderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ManualStandingOrderRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.standingOrderId = reader.string();
                break;
            case 2:
                message.validForDay = reader.int64();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("standingOrderId"))
            throw $util.ProtocolError("missing required 'standingOrderId'", { instance: message });
        if (!message.hasOwnProperty("validForDay"))
            throw $util.ProtocolError("missing required 'validForDay'", { instance: message });
        return message;
    };

    /**
     * Decodes a ManualStandingOrderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ManualStandingOrderRequest} ManualStandingOrderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ManualStandingOrderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ManualStandingOrderRequest message.
     * @function verify
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ManualStandingOrderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.standingOrderId))
            return "standingOrderId: string expected";
        if (!$util.isInteger(message.validForDay) && !(message.validForDay && $util.isInteger(message.validForDay.low) && $util.isInteger(message.validForDay.high)))
            return "validForDay: integer|Long expected";
        return null;
    };

    /**
     * Creates a ManualStandingOrderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ManualStandingOrderRequest} ManualStandingOrderRequest
     */
    ManualStandingOrderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ManualStandingOrderRequest)
            return object;
        let message = new $root.ManualStandingOrderRequest();
        if (object.standingOrderId != null)
            message.standingOrderId = String(object.standingOrderId);
        if (object.validForDay != null)
            if ($util.Long)
                (message.validForDay = $util.Long.fromValue(object.validForDay)).unsigned = false;
            else if (typeof object.validForDay === "string")
                message.validForDay = parseInt(object.validForDay, 10);
            else if (typeof object.validForDay === "number")
                message.validForDay = object.validForDay;
            else if (typeof object.validForDay === "object")
                message.validForDay = new $util.LongBits(object.validForDay.low >>> 0, object.validForDay.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a ManualStandingOrderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ManualStandingOrderRequest
     * @static
     * @param {ManualStandingOrderRequest} message ManualStandingOrderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ManualStandingOrderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.standingOrderId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.validForDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.validForDay = options.longs === String ? "0" : 0;
        }
        if (message.standingOrderId != null && message.hasOwnProperty("standingOrderId"))
            object.standingOrderId = message.standingOrderId;
        if (message.validForDay != null && message.hasOwnProperty("validForDay"))
            if (typeof message.validForDay === "number")
                object.validForDay = options.longs === String ? String(message.validForDay) : message.validForDay;
            else
                object.validForDay = options.longs === String ? $util.Long.prototype.toString.call(message.validForDay) : options.longs === Number ? new $util.LongBits(message.validForDay.low >>> 0, message.validForDay.high >>> 0).toNumber() : message.validForDay;
        return object;
    };

    /**
     * Converts this ManualStandingOrderRequest to JSON.
     * @function toJSON
     * @memberof ManualStandingOrderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ManualStandingOrderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ManualStandingOrderRequest;
})();

export const UserConsent = $root.UserConsent = (() => {

    /**
     * Properties of a UserConsent.
     * @exports IUserConsent
     * @interface IUserConsent
     * @property {boolean} policy UserConsent policy
     * @property {boolean} gps UserConsent gps
     * @property {boolean} segmentation UserConsent segmentation
     * @property {boolean} emailing UserConsent emailing
     * @property {boolean} bankSynchronization UserConsent bankSynchronization
     */

    /**
     * Constructs a new UserConsent.
     * @exports UserConsent
     * @classdesc Represents a UserConsent.
     * @implements IUserConsent
     * @constructor
     * @param {IUserConsent=} [properties] Properties to set
     */
    function UserConsent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserConsent policy.
     * @member {boolean} policy
     * @memberof UserConsent
     * @instance
     */
    UserConsent.prototype.policy = false;

    /**
     * UserConsent gps.
     * @member {boolean} gps
     * @memberof UserConsent
     * @instance
     */
    UserConsent.prototype.gps = false;

    /**
     * UserConsent segmentation.
     * @member {boolean} segmentation
     * @memberof UserConsent
     * @instance
     */
    UserConsent.prototype.segmentation = false;

    /**
     * UserConsent emailing.
     * @member {boolean} emailing
     * @memberof UserConsent
     * @instance
     */
    UserConsent.prototype.emailing = false;

    /**
     * UserConsent bankSynchronization.
     * @member {boolean} bankSynchronization
     * @memberof UserConsent
     * @instance
     */
    UserConsent.prototype.bankSynchronization = false;

    /**
     * Creates a new UserConsent instance using the specified properties.
     * @function create
     * @memberof UserConsent
     * @static
     * @param {IUserConsent=} [properties] Properties to set
     * @returns {UserConsent} UserConsent instance
     */
    UserConsent.create = function create(properties) {
        return new UserConsent(properties);
    };

    /**
     * Encodes the specified UserConsent message. Does not implicitly {@link UserConsent.verify|verify} messages.
     * @function encode
     * @memberof UserConsent
     * @static
     * @param {IUserConsent} message UserConsent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserConsent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.policy);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.gps);
        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.segmentation);
        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.emailing);
        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.bankSynchronization);
        return writer;
    };

    /**
     * Encodes the specified UserConsent message, length delimited. Does not implicitly {@link UserConsent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UserConsent
     * @static
     * @param {IUserConsent} message UserConsent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserConsent.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserConsent message from the specified reader or buffer.
     * @function decode
     * @memberof UserConsent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UserConsent} UserConsent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserConsent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserConsent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.policy = reader.bool();
                break;
            case 2:
                message.gps = reader.bool();
                break;
            case 3:
                message.segmentation = reader.bool();
                break;
            case 4:
                message.emailing = reader.bool();
                break;
            case 5:
                message.bankSynchronization = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("policy"))
            throw $util.ProtocolError("missing required 'policy'", { instance: message });
        if (!message.hasOwnProperty("gps"))
            throw $util.ProtocolError("missing required 'gps'", { instance: message });
        if (!message.hasOwnProperty("segmentation"))
            throw $util.ProtocolError("missing required 'segmentation'", { instance: message });
        if (!message.hasOwnProperty("emailing"))
            throw $util.ProtocolError("missing required 'emailing'", { instance: message });
        if (!message.hasOwnProperty("bankSynchronization"))
            throw $util.ProtocolError("missing required 'bankSynchronization'", { instance: message });
        return message;
    };

    /**
     * Decodes a UserConsent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UserConsent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UserConsent} UserConsent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserConsent.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserConsent message.
     * @function verify
     * @memberof UserConsent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserConsent.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (typeof message.policy !== "boolean")
            return "policy: boolean expected";
        if (typeof message.gps !== "boolean")
            return "gps: boolean expected";
        if (typeof message.segmentation !== "boolean")
            return "segmentation: boolean expected";
        if (typeof message.emailing !== "boolean")
            return "emailing: boolean expected";
        if (typeof message.bankSynchronization !== "boolean")
            return "bankSynchronization: boolean expected";
        return null;
    };

    /**
     * Creates a UserConsent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UserConsent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UserConsent} UserConsent
     */
    UserConsent.fromObject = function fromObject(object) {
        if (object instanceof $root.UserConsent)
            return object;
        let message = new $root.UserConsent();
        if (object.policy != null)
            message.policy = Boolean(object.policy);
        if (object.gps != null)
            message.gps = Boolean(object.gps);
        if (object.segmentation != null)
            message.segmentation = Boolean(object.segmentation);
        if (object.emailing != null)
            message.emailing = Boolean(object.emailing);
        if (object.bankSynchronization != null)
            message.bankSynchronization = Boolean(object.bankSynchronization);
        return message;
    };

    /**
     * Creates a plain object from a UserConsent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UserConsent
     * @static
     * @param {UserConsent} message UserConsent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserConsent.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.policy = false;
            object.gps = false;
            object.segmentation = false;
            object.emailing = false;
            object.bankSynchronization = false;
        }
        if (message.policy != null && message.hasOwnProperty("policy"))
            object.policy = message.policy;
        if (message.gps != null && message.hasOwnProperty("gps"))
            object.gps = message.gps;
        if (message.segmentation != null && message.hasOwnProperty("segmentation"))
            object.segmentation = message.segmentation;
        if (message.emailing != null && message.hasOwnProperty("emailing"))
            object.emailing = message.emailing;
        if (message.bankSynchronization != null && message.hasOwnProperty("bankSynchronization"))
            object.bankSynchronization = message.bankSynchronization;
        return object;
    };

    /**
     * Converts this UserConsent to JSON.
     * @function toJSON
     * @memberof UserConsent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserConsent.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserConsent;
})();

export const ConsentChangeRequest = $root.ConsentChangeRequest = (() => {

    /**
     * Properties of a ConsentChangeRequest.
     * @exports IConsentChangeRequest
     * @interface IConsentChangeRequest
     * @property {boolean} consentGranted ConsentChangeRequest consentGranted
     */

    /**
     * Constructs a new ConsentChangeRequest.
     * @exports ConsentChangeRequest
     * @classdesc Represents a ConsentChangeRequest.
     * @implements IConsentChangeRequest
     * @constructor
     * @param {IConsentChangeRequest=} [properties] Properties to set
     */
    function ConsentChangeRequest(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ConsentChangeRequest consentGranted.
     * @member {boolean} consentGranted
     * @memberof ConsentChangeRequest
     * @instance
     */
    ConsentChangeRequest.prototype.consentGranted = false;

    /**
     * Creates a new ConsentChangeRequest instance using the specified properties.
     * @function create
     * @memberof ConsentChangeRequest
     * @static
     * @param {IConsentChangeRequest=} [properties] Properties to set
     * @returns {ConsentChangeRequest} ConsentChangeRequest instance
     */
    ConsentChangeRequest.create = function create(properties) {
        return new ConsentChangeRequest(properties);
    };

    /**
     * Encodes the specified ConsentChangeRequest message. Does not implicitly {@link ConsentChangeRequest.verify|verify} messages.
     * @function encode
     * @memberof ConsentChangeRequest
     * @static
     * @param {IConsentChangeRequest} message ConsentChangeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConsentChangeRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.consentGranted);
        return writer;
    };

    /**
     * Encodes the specified ConsentChangeRequest message, length delimited. Does not implicitly {@link ConsentChangeRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ConsentChangeRequest
     * @static
     * @param {IConsentChangeRequest} message ConsentChangeRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConsentChangeRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ConsentChangeRequest message from the specified reader or buffer.
     * @function decode
     * @memberof ConsentChangeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ConsentChangeRequest} ConsentChangeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConsentChangeRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ConsentChangeRequest();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.consentGranted = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("consentGranted"))
            throw $util.ProtocolError("missing required 'consentGranted'", { instance: message });
        return message;
    };

    /**
     * Decodes a ConsentChangeRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ConsentChangeRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ConsentChangeRequest} ConsentChangeRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConsentChangeRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ConsentChangeRequest message.
     * @function verify
     * @memberof ConsentChangeRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ConsentChangeRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (typeof message.consentGranted !== "boolean")
            return "consentGranted: boolean expected";
        return null;
    };

    /**
     * Creates a ConsentChangeRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ConsentChangeRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ConsentChangeRequest} ConsentChangeRequest
     */
    ConsentChangeRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.ConsentChangeRequest)
            return object;
        let message = new $root.ConsentChangeRequest();
        if (object.consentGranted != null)
            message.consentGranted = Boolean(object.consentGranted);
        return message;
    };

    /**
     * Creates a plain object from a ConsentChangeRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ConsentChangeRequest
     * @static
     * @param {ConsentChangeRequest} message ConsentChangeRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ConsentChangeRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.consentGranted = false;
        if (message.consentGranted != null && message.hasOwnProperty("consentGranted"))
            object.consentGranted = message.consentGranted;
        return object;
    };

    /**
     * Converts this ConsentChangeRequest to JSON.
     * @function toJSON
     * @memberof ConsentChangeRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ConsentChangeRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ConsentChangeRequest;
})();

export const VerifiedProfile = $root.VerifiedProfile = (() => {

    /**
     * Properties of a VerifiedProfile.
     * @exports IVerifiedProfile
     * @interface IVerifiedProfile
     * @property {string} fullName VerifiedProfile fullName
     * @property {number|Long|null} [birthday] VerifiedProfile birthday
     * @property {string|null} [streetAddress] VerifiedProfile streetAddress
     * @property {string|null} [companyId] VerifiedProfile companyId
     * @property {string|null} [companyName] VerifiedProfile companyName
     */

    /**
     * Constructs a new VerifiedProfile.
     * @exports VerifiedProfile
     * @classdesc Represents a VerifiedProfile.
     * @implements IVerifiedProfile
     * @constructor
     * @param {IVerifiedProfile=} [properties] Properties to set
     */
    function VerifiedProfile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * VerifiedProfile fullName.
     * @member {string} fullName
     * @memberof VerifiedProfile
     * @instance
     */
    VerifiedProfile.prototype.fullName = "";

    /**
     * VerifiedProfile birthday.
     * @member {number|Long} birthday
     * @memberof VerifiedProfile
     * @instance
     */
    VerifiedProfile.prototype.birthday = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * VerifiedProfile streetAddress.
     * @member {string} streetAddress
     * @memberof VerifiedProfile
     * @instance
     */
    VerifiedProfile.prototype.streetAddress = "";

    /**
     * VerifiedProfile companyId.
     * @member {string} companyId
     * @memberof VerifiedProfile
     * @instance
     */
    VerifiedProfile.prototype.companyId = "";

    /**
     * VerifiedProfile companyName.
     * @member {string} companyName
     * @memberof VerifiedProfile
     * @instance
     */
    VerifiedProfile.prototype.companyName = "";

    /**
     * Creates a new VerifiedProfile instance using the specified properties.
     * @function create
     * @memberof VerifiedProfile
     * @static
     * @param {IVerifiedProfile=} [properties] Properties to set
     * @returns {VerifiedProfile} VerifiedProfile instance
     */
    VerifiedProfile.create = function create(properties) {
        return new VerifiedProfile(properties);
    };

    /**
     * Encodes the specified VerifiedProfile message. Does not implicitly {@link VerifiedProfile.verify|verify} messages.
     * @function encode
     * @memberof VerifiedProfile
     * @static
     * @param {IVerifiedProfile} message VerifiedProfile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VerifiedProfile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.fullName);
        if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.birthday);
        if (message.streetAddress != null && Object.hasOwnProperty.call(message, "streetAddress"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.streetAddress);
        if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.companyId);
        if (message.companyName != null && Object.hasOwnProperty.call(message, "companyName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.companyName);
        return writer;
    };

    /**
     * Encodes the specified VerifiedProfile message, length delimited. Does not implicitly {@link VerifiedProfile.verify|verify} messages.
     * @function encodeDelimited
     * @memberof VerifiedProfile
     * @static
     * @param {IVerifiedProfile} message VerifiedProfile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VerifiedProfile.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VerifiedProfile message from the specified reader or buffer.
     * @function decode
     * @memberof VerifiedProfile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {VerifiedProfile} VerifiedProfile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VerifiedProfile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VerifiedProfile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.fullName = reader.string();
                break;
            case 2:
                message.birthday = reader.int64();
                break;
            case 3:
                message.streetAddress = reader.string();
                break;
            case 4:
                message.companyId = reader.string();
                break;
            case 5:
                message.companyName = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("fullName"))
            throw $util.ProtocolError("missing required 'fullName'", { instance: message });
        return message;
    };

    /**
     * Decodes a VerifiedProfile message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof VerifiedProfile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {VerifiedProfile} VerifiedProfile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VerifiedProfile.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VerifiedProfile message.
     * @function verify
     * @memberof VerifiedProfile
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VerifiedProfile.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.fullName))
            return "fullName: string expected";
        if (message.birthday != null && message.hasOwnProperty("birthday"))
            if (!$util.isInteger(message.birthday) && !(message.birthday && $util.isInteger(message.birthday.low) && $util.isInteger(message.birthday.high)))
                return "birthday: integer|Long expected";
        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
            if (!$util.isString(message.streetAddress))
                return "streetAddress: string expected";
        if (message.companyId != null && message.hasOwnProperty("companyId"))
            if (!$util.isString(message.companyId))
                return "companyId: string expected";
        if (message.companyName != null && message.hasOwnProperty("companyName"))
            if (!$util.isString(message.companyName))
                return "companyName: string expected";
        return null;
    };

    /**
     * Creates a VerifiedProfile message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof VerifiedProfile
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {VerifiedProfile} VerifiedProfile
     */
    VerifiedProfile.fromObject = function fromObject(object) {
        if (object instanceof $root.VerifiedProfile)
            return object;
        let message = new $root.VerifiedProfile();
        if (object.fullName != null)
            message.fullName = String(object.fullName);
        if (object.birthday != null)
            if ($util.Long)
                (message.birthday = $util.Long.fromValue(object.birthday)).unsigned = false;
            else if (typeof object.birthday === "string")
                message.birthday = parseInt(object.birthday, 10);
            else if (typeof object.birthday === "number")
                message.birthday = object.birthday;
            else if (typeof object.birthday === "object")
                message.birthday = new $util.LongBits(object.birthday.low >>> 0, object.birthday.high >>> 0).toNumber();
        if (object.streetAddress != null)
            message.streetAddress = String(object.streetAddress);
        if (object.companyId != null)
            message.companyId = String(object.companyId);
        if (object.companyName != null)
            message.companyName = String(object.companyName);
        return message;
    };

    /**
     * Creates a plain object from a VerifiedProfile message. Also converts values to other types if specified.
     * @function toObject
     * @memberof VerifiedProfile
     * @static
     * @param {VerifiedProfile} message VerifiedProfile
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VerifiedProfile.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.fullName = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.birthday = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.birthday = options.longs === String ? "0" : 0;
            object.streetAddress = "";
            object.companyId = "";
            object.companyName = "";
        }
        if (message.fullName != null && message.hasOwnProperty("fullName"))
            object.fullName = message.fullName;
        if (message.birthday != null && message.hasOwnProperty("birthday"))
            if (typeof message.birthday === "number")
                object.birthday = options.longs === String ? String(message.birthday) : message.birthday;
            else
                object.birthday = options.longs === String ? $util.Long.prototype.toString.call(message.birthday) : options.longs === Number ? new $util.LongBits(message.birthday.low >>> 0, message.birthday.high >>> 0).toNumber() : message.birthday;
        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
            object.streetAddress = message.streetAddress;
        if (message.companyId != null && message.hasOwnProperty("companyId"))
            object.companyId = message.companyId;
        if (message.companyName != null && message.hasOwnProperty("companyName"))
            object.companyName = message.companyName;
        return object;
    };

    /**
     * Converts this VerifiedProfile to JSON.
     * @function toJSON
     * @memberof VerifiedProfile
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VerifiedProfile.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return VerifiedProfile;
})();

export const Board = $root.Board = (() => {

    /**
     * Properties of a Board.
     * @exports IBoard
     * @interface IBoard
     */

    /**
     * Constructs a new Board.
     * @exports Board
     * @classdesc Represents a Board.
     * @implements IBoard
     * @constructor
     * @param {IBoard=} [properties] Properties to set
     */
    function Board(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new Board instance using the specified properties.
     * @function create
     * @memberof Board
     * @static
     * @param {IBoard=} [properties] Properties to set
     * @returns {Board} Board instance
     */
    Board.create = function create(properties) {
        return new Board(properties);
    };

    /**
     * Encodes the specified Board message. Does not implicitly {@link Board.verify|verify} messages.
     * @function encode
     * @memberof Board
     * @static
     * @param {IBoard} message Board message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Board.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified Board message, length delimited. Does not implicitly {@link Board.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Board
     * @static
     * @param {IBoard} message Board message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Board.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Board message from the specified reader or buffer.
     * @function decode
     * @memberof Board
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Board} Board
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Board.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Board();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Board message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Board
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Board} Board
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Board.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Board message.
     * @function verify
     * @memberof Board
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Board.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a Board message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Board
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Board} Board
     */
    Board.fromObject = function fromObject(object) {
        if (object instanceof $root.Board)
            return object;
        return new $root.Board();
    };

    /**
     * Creates a plain object from a Board message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Board
     * @static
     * @param {Board} message Board
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Board.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Board to JSON.
     * @function toJSON
     * @memberof Board
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Board.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Board.CompanyInfo = (function() {

        /**
         * Properties of a CompanyInfo.
         * @memberof Board
         * @interface ICompanyInfo
         * @property {string|null} [name] CompanyInfo name
         * @property {string|null} [industry] CompanyInfo industry
         * @property {string|null} [role] CompanyInfo role
         */

        /**
         * Constructs a new CompanyInfo.
         * @memberof Board
         * @classdesc Represents a CompanyInfo.
         * @implements ICompanyInfo
         * @constructor
         * @param {Board.ICompanyInfo=} [properties] Properties to set
         */
        function CompanyInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompanyInfo name.
         * @member {string} name
         * @memberof Board.CompanyInfo
         * @instance
         */
        CompanyInfo.prototype.name = "";

        /**
         * CompanyInfo industry.
         * @member {string} industry
         * @memberof Board.CompanyInfo
         * @instance
         */
        CompanyInfo.prototype.industry = "";

        /**
         * CompanyInfo role.
         * @member {string} role
         * @memberof Board.CompanyInfo
         * @instance
         */
        CompanyInfo.prototype.role = "";

        /**
         * Creates a new CompanyInfo instance using the specified properties.
         * @function create
         * @memberof Board.CompanyInfo
         * @static
         * @param {Board.ICompanyInfo=} [properties] Properties to set
         * @returns {Board.CompanyInfo} CompanyInfo instance
         */
        CompanyInfo.create = function create(properties) {
            return new CompanyInfo(properties);
        };

        /**
         * Encodes the specified CompanyInfo message. Does not implicitly {@link Board.CompanyInfo.verify|verify} messages.
         * @function encode
         * @memberof Board.CompanyInfo
         * @static
         * @param {Board.ICompanyInfo} message CompanyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.industry != null && Object.hasOwnProperty.call(message, "industry"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.industry);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.role);
            return writer;
        };

        /**
         * Encodes the specified CompanyInfo message, length delimited. Does not implicitly {@link Board.CompanyInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Board.CompanyInfo
         * @static
         * @param {Board.ICompanyInfo} message CompanyInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CompanyInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CompanyInfo message from the specified reader or buffer.
         * @function decode
         * @memberof Board.CompanyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Board.CompanyInfo} CompanyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Board.CompanyInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.industry = reader.string();
                    break;
                case 3:
                    message.role = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CompanyInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Board.CompanyInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Board.CompanyInfo} CompanyInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CompanyInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CompanyInfo message.
         * @function verify
         * @memberof Board.CompanyInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CompanyInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.industry != null && message.hasOwnProperty("industry"))
                if (!$util.isString(message.industry))
                    return "industry: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                if (!$util.isString(message.role))
                    return "role: string expected";
            return null;
        };

        /**
         * Creates a CompanyInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Board.CompanyInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Board.CompanyInfo} CompanyInfo
         */
        CompanyInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.Board.CompanyInfo)
                return object;
            let message = new $root.Board.CompanyInfo();
            if (object.name != null)
                message.name = String(object.name);
            if (object.industry != null)
                message.industry = String(object.industry);
            if (object.role != null)
                message.role = String(object.role);
            return message;
        };

        /**
         * Creates a plain object from a CompanyInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Board.CompanyInfo
         * @static
         * @param {Board.CompanyInfo} message CompanyInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CompanyInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.industry = "";
                object.role = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.industry != null && message.hasOwnProperty("industry"))
                object.industry = message.industry;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            return object;
        };

        /**
         * Converts this CompanyInfo to JSON.
         * @function toJSON
         * @memberof Board.CompanyInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CompanyInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompanyInfo;
    })();

    return Board;
})();

export const Tracking = $root.Tracking = (() => {

    /**
     * Properties of a Tracking.
     * @exports ITracking
     * @interface ITracking
     */

    /**
     * Constructs a new Tracking.
     * @exports Tracking
     * @classdesc Represents a Tracking.
     * @implements ITracking
     * @constructor
     * @param {ITracking=} [properties] Properties to set
     */
    function Tracking(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new Tracking instance using the specified properties.
     * @function create
     * @memberof Tracking
     * @static
     * @param {ITracking=} [properties] Properties to set
     * @returns {Tracking} Tracking instance
     */
    Tracking.create = function create(properties) {
        return new Tracking(properties);
    };

    /**
     * Encodes the specified Tracking message. Does not implicitly {@link Tracking.verify|verify} messages.
     * @function encode
     * @memberof Tracking
     * @static
     * @param {ITracking} message Tracking message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tracking.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified Tracking message, length delimited. Does not implicitly {@link Tracking.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Tracking
     * @static
     * @param {ITracking} message Tracking message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Tracking.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Tracking message from the specified reader or buffer.
     * @function decode
     * @memberof Tracking
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Tracking} Tracking
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tracking.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Tracking();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a Tracking message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Tracking
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Tracking} Tracking
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Tracking.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Tracking message.
     * @function verify
     * @memberof Tracking
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Tracking.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a Tracking message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Tracking
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Tracking} Tracking
     */
    Tracking.fromObject = function fromObject(object) {
        if (object instanceof $root.Tracking)
            return object;
        return new $root.Tracking();
    };

    /**
     * Creates a plain object from a Tracking message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Tracking
     * @static
     * @param {Tracking} message Tracking
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Tracking.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this Tracking to JSON.
     * @function toJSON
     * @memberof Tracking
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Tracking.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * SeenFrom enum.
     * @name Tracking.SeenFrom
     * @enum {number}
     * @property {number} TIP=0 TIP value
     * @property {number} CARD=1 CARD value
     */
    Tracking.SeenFrom = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TIP"] = 0;
        values[valuesById[1] = "CARD"] = 1;
        return values;
    })();

    /**
     * ProductType enum.
     * @name Tracking.ProductType
     * @enum {number}
     * @property {number} LOAN=0 LOAN value
     * @property {number} ACCOUNT=1 ACCOUNT value
     */
    Tracking.ProductType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LOAN"] = 0;
        values[valuesById[1] = "ACCOUNT"] = 1;
        return values;
    })();

    Tracking.Impression = (function() {

        /**
         * Properties of an Impression.
         * @memberof Tracking
         * @interface IImpression
         * @property {ReferralSource|null} [referralSource] Impression referralSource
         * @property {Tracking.SeenFrom|null} [seenFrom] Impression seenFrom
         * @property {Tracking.ProductType|null} [productType] Impression productType
         */

        /**
         * Constructs a new Impression.
         * @memberof Tracking
         * @classdesc Represents an Impression.
         * @implements IImpression
         * @constructor
         * @param {Tracking.IImpression=} [properties] Properties to set
         */
        function Impression(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Impression referralSource.
         * @member {ReferralSource} referralSource
         * @memberof Tracking.Impression
         * @instance
         */
        Impression.prototype.referralSource = 0;

        /**
         * Impression seenFrom.
         * @member {Tracking.SeenFrom} seenFrom
         * @memberof Tracking.Impression
         * @instance
         */
        Impression.prototype.seenFrom = 0;

        /**
         * Impression productType.
         * @member {Tracking.ProductType} productType
         * @memberof Tracking.Impression
         * @instance
         */
        Impression.prototype.productType = 0;

        /**
         * Creates a new Impression instance using the specified properties.
         * @function create
         * @memberof Tracking.Impression
         * @static
         * @param {Tracking.IImpression=} [properties] Properties to set
         * @returns {Tracking.Impression} Impression instance
         */
        Impression.create = function create(properties) {
            return new Impression(properties);
        };

        /**
         * Encodes the specified Impression message. Does not implicitly {@link Tracking.Impression.verify|verify} messages.
         * @function encode
         * @memberof Tracking.Impression
         * @static
         * @param {Tracking.IImpression} message Impression message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Impression.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.referralSource != null && Object.hasOwnProperty.call(message, "referralSource"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.referralSource);
            if (message.seenFrom != null && Object.hasOwnProperty.call(message, "seenFrom"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.seenFrom);
            if (message.productType != null && Object.hasOwnProperty.call(message, "productType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.productType);
            return writer;
        };

        /**
         * Encodes the specified Impression message, length delimited. Does not implicitly {@link Tracking.Impression.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Tracking.Impression
         * @static
         * @param {Tracking.IImpression} message Impression message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Impression.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Impression message from the specified reader or buffer.
         * @function decode
         * @memberof Tracking.Impression
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Tracking.Impression} Impression
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Impression.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Tracking.Impression();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.referralSource = reader.int32();
                    break;
                case 2:
                    message.seenFrom = reader.int32();
                    break;
                case 3:
                    message.productType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Impression message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Tracking.Impression
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Tracking.Impression} Impression
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Impression.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Impression message.
         * @function verify
         * @memberof Tracking.Impression
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Impression.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.referralSource != null && message.hasOwnProperty("referralSource"))
                switch (message.referralSource) {
                default:
                    return "referralSource: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.seenFrom != null && message.hasOwnProperty("seenFrom"))
                switch (message.seenFrom) {
                default:
                    return "seenFrom: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.productType != null && message.hasOwnProperty("productType"))
                switch (message.productType) {
                default:
                    return "productType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates an Impression message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Tracking.Impression
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Tracking.Impression} Impression
         */
        Impression.fromObject = function fromObject(object) {
            if (object instanceof $root.Tracking.Impression)
                return object;
            let message = new $root.Tracking.Impression();
            switch (object.referralSource) {
            case "KB":
            case 0:
                message.referralSource = 0;
                break;
            case "MALL_PAY":
            case 1:
                message.referralSource = 1;
                break;
            }
            switch (object.seenFrom) {
            case "TIP":
            case 0:
                message.seenFrom = 0;
                break;
            case "CARD":
            case 1:
                message.seenFrom = 1;
                break;
            }
            switch (object.productType) {
            case "LOAN":
            case 0:
                message.productType = 0;
                break;
            case "ACCOUNT":
            case 1:
                message.productType = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an Impression message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Tracking.Impression
         * @static
         * @param {Tracking.Impression} message Impression
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Impression.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.referralSource = options.enums === String ? "KB" : 0;
                object.seenFrom = options.enums === String ? "TIP" : 0;
                object.productType = options.enums === String ? "LOAN" : 0;
            }
            if (message.referralSource != null && message.hasOwnProperty("referralSource"))
                object.referralSource = options.enums === String ? $root.ReferralSource[message.referralSource] : message.referralSource;
            if (message.seenFrom != null && message.hasOwnProperty("seenFrom"))
                object.seenFrom = options.enums === String ? $root.Tracking.SeenFrom[message.seenFrom] : message.seenFrom;
            if (message.productType != null && message.hasOwnProperty("productType"))
                object.productType = options.enums === String ? $root.Tracking.ProductType[message.productType] : message.productType;
            return object;
        };

        /**
         * Converts this Impression to JSON.
         * @function toJSON
         * @memberof Tracking.Impression
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Impression.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Impression;
    })();

    return Tracking;
})();

export const FreePremium = $root.FreePremium = (() => {

    /**
     * Properties of a FreePremium.
     * @exports IFreePremium
     * @interface IFreePremium
     * @property {string} storeCountryCode FreePremium storeCountryCode
     * @property {boolean|null} [premiumFeaturesForFree] FreePremium premiumFeaturesForFree
     * @property {FreePremium.ICustomerCare|null} [customerCare] FreePremium customerCare
     * @property {boolean|null} [freemiumAccess] FreePremium freemiumAccess
     * @property {number|null} [freeAccountsLimit] FreePremium freeAccountsLimit
     */

    /**
     * Constructs a new FreePremium.
     * @exports FreePremium
     * @classdesc Represents a FreePremium.
     * @implements IFreePremium
     * @constructor
     * @param {IFreePremium=} [properties] Properties to set
     */
    function FreePremium(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FreePremium storeCountryCode.
     * @member {string} storeCountryCode
     * @memberof FreePremium
     * @instance
     */
    FreePremium.prototype.storeCountryCode = "";

    /**
     * FreePremium premiumFeaturesForFree.
     * @member {boolean} premiumFeaturesForFree
     * @memberof FreePremium
     * @instance
     */
    FreePremium.prototype.premiumFeaturesForFree = false;

    /**
     * FreePremium customerCare.
     * @member {FreePremium.ICustomerCare|null|undefined} customerCare
     * @memberof FreePremium
     * @instance
     */
    FreePremium.prototype.customerCare = null;

    /**
     * FreePremium freemiumAccess.
     * @member {boolean} freemiumAccess
     * @memberof FreePremium
     * @instance
     */
    FreePremium.prototype.freemiumAccess = false;

    /**
     * FreePremium freeAccountsLimit.
     * @member {number} freeAccountsLimit
     * @memberof FreePremium
     * @instance
     */
    FreePremium.prototype.freeAccountsLimit = 0;

    /**
     * Creates a new FreePremium instance using the specified properties.
     * @function create
     * @memberof FreePremium
     * @static
     * @param {IFreePremium=} [properties] Properties to set
     * @returns {FreePremium} FreePremium instance
     */
    FreePremium.create = function create(properties) {
        return new FreePremium(properties);
    };

    /**
     * Encodes the specified FreePremium message. Does not implicitly {@link FreePremium.verify|verify} messages.
     * @function encode
     * @memberof FreePremium
     * @static
     * @param {IFreePremium} message FreePremium message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FreePremium.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.storeCountryCode);
        if (message.premiumFeaturesForFree != null && Object.hasOwnProperty.call(message, "premiumFeaturesForFree"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.premiumFeaturesForFree);
        if (message.customerCare != null && Object.hasOwnProperty.call(message, "customerCare"))
            $root.FreePremium.CustomerCare.encode(message.customerCare, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.freemiumAccess != null && Object.hasOwnProperty.call(message, "freemiumAccess"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.freemiumAccess);
        if (message.freeAccountsLimit != null && Object.hasOwnProperty.call(message, "freeAccountsLimit"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.freeAccountsLimit);
        return writer;
    };

    /**
     * Encodes the specified FreePremium message, length delimited. Does not implicitly {@link FreePremium.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FreePremium
     * @static
     * @param {IFreePremium} message FreePremium message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FreePremium.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FreePremium message from the specified reader or buffer.
     * @function decode
     * @memberof FreePremium
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FreePremium} FreePremium
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FreePremium.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FreePremium();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.storeCountryCode = reader.string();
                break;
            case 2:
                message.premiumFeaturesForFree = reader.bool();
                break;
            case 3:
                message.customerCare = $root.FreePremium.CustomerCare.decode(reader, reader.uint32());
                break;
            case 4:
                message.freemiumAccess = reader.bool();
                break;
            case 5:
                message.freeAccountsLimit = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("storeCountryCode"))
            throw $util.ProtocolError("missing required 'storeCountryCode'", { instance: message });
        return message;
    };

    /**
     * Decodes a FreePremium message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FreePremium
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FreePremium} FreePremium
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FreePremium.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FreePremium message.
     * @function verify
     * @memberof FreePremium
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FreePremium.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.storeCountryCode))
            return "storeCountryCode: string expected";
        if (message.premiumFeaturesForFree != null && message.hasOwnProperty("premiumFeaturesForFree"))
            if (typeof message.premiumFeaturesForFree !== "boolean")
                return "premiumFeaturesForFree: boolean expected";
        if (message.customerCare != null && message.hasOwnProperty("customerCare")) {
            let error = $root.FreePremium.CustomerCare.verify(message.customerCare);
            if (error)
                return "customerCare." + error;
        }
        if (message.freemiumAccess != null && message.hasOwnProperty("freemiumAccess"))
            if (typeof message.freemiumAccess !== "boolean")
                return "freemiumAccess: boolean expected";
        if (message.freeAccountsLimit != null && message.hasOwnProperty("freeAccountsLimit"))
            if (!$util.isInteger(message.freeAccountsLimit))
                return "freeAccountsLimit: integer expected";
        return null;
    };

    /**
     * Creates a FreePremium message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FreePremium
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FreePremium} FreePremium
     */
    FreePremium.fromObject = function fromObject(object) {
        if (object instanceof $root.FreePremium)
            return object;
        let message = new $root.FreePremium();
        if (object.storeCountryCode != null)
            message.storeCountryCode = String(object.storeCountryCode);
        if (object.premiumFeaturesForFree != null)
            message.premiumFeaturesForFree = Boolean(object.premiumFeaturesForFree);
        if (object.customerCare != null) {
            if (typeof object.customerCare !== "object")
                throw TypeError(".FreePremium.customerCare: object expected");
            message.customerCare = $root.FreePremium.CustomerCare.fromObject(object.customerCare);
        }
        if (object.freemiumAccess != null)
            message.freemiumAccess = Boolean(object.freemiumAccess);
        if (object.freeAccountsLimit != null)
            message.freeAccountsLimit = object.freeAccountsLimit | 0;
        return message;
    };

    /**
     * Creates a plain object from a FreePremium message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FreePremium
     * @static
     * @param {FreePremium} message FreePremium
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FreePremium.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.storeCountryCode = "";
            object.premiumFeaturesForFree = false;
            object.customerCare = null;
            object.freemiumAccess = false;
            object.freeAccountsLimit = 0;
        }
        if (message.storeCountryCode != null && message.hasOwnProperty("storeCountryCode"))
            object.storeCountryCode = message.storeCountryCode;
        if (message.premiumFeaturesForFree != null && message.hasOwnProperty("premiumFeaturesForFree"))
            object.premiumFeaturesForFree = message.premiumFeaturesForFree;
        if (message.customerCare != null && message.hasOwnProperty("customerCare"))
            object.customerCare = $root.FreePremium.CustomerCare.toObject(message.customerCare, options);
        if (message.freemiumAccess != null && message.hasOwnProperty("freemiumAccess"))
            object.freemiumAccess = message.freemiumAccess;
        if (message.freeAccountsLimit != null && message.hasOwnProperty("freeAccountsLimit"))
            object.freeAccountsLimit = message.freeAccountsLimit;
        return object;
    };

    /**
     * Converts this FreePremium to JSON.
     * @function toJSON
     * @memberof FreePremium
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FreePremium.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    FreePremium.UpdateStoreCountryCodeRequest = (function() {

        /**
         * Properties of an UpdateStoreCountryCodeRequest.
         * @memberof FreePremium
         * @interface IUpdateStoreCountryCodeRequest
         * @property {string} storeCountryCode UpdateStoreCountryCodeRequest storeCountryCode
         */

        /**
         * Constructs a new UpdateStoreCountryCodeRequest.
         * @memberof FreePremium
         * @classdesc Represents an UpdateStoreCountryCodeRequest.
         * @implements IUpdateStoreCountryCodeRequest
         * @constructor
         * @param {FreePremium.IUpdateStoreCountryCodeRequest=} [properties] Properties to set
         */
        function UpdateStoreCountryCodeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateStoreCountryCodeRequest storeCountryCode.
         * @member {string} storeCountryCode
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @instance
         */
        UpdateStoreCountryCodeRequest.prototype.storeCountryCode = "";

        /**
         * Creates a new UpdateStoreCountryCodeRequest instance using the specified properties.
         * @function create
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {FreePremium.IUpdateStoreCountryCodeRequest=} [properties] Properties to set
         * @returns {FreePremium.UpdateStoreCountryCodeRequest} UpdateStoreCountryCodeRequest instance
         */
        UpdateStoreCountryCodeRequest.create = function create(properties) {
            return new UpdateStoreCountryCodeRequest(properties);
        };

        /**
         * Encodes the specified UpdateStoreCountryCodeRequest message. Does not implicitly {@link FreePremium.UpdateStoreCountryCodeRequest.verify|verify} messages.
         * @function encode
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {FreePremium.IUpdateStoreCountryCodeRequest} message UpdateStoreCountryCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateStoreCountryCodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.storeCountryCode);
            return writer;
        };

        /**
         * Encodes the specified UpdateStoreCountryCodeRequest message, length delimited. Does not implicitly {@link FreePremium.UpdateStoreCountryCodeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {FreePremium.IUpdateStoreCountryCodeRequest} message UpdateStoreCountryCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateStoreCountryCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateStoreCountryCodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {FreePremium.UpdateStoreCountryCodeRequest} UpdateStoreCountryCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateStoreCountryCodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FreePremium.UpdateStoreCountryCodeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.storeCountryCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            if (!message.hasOwnProperty("storeCountryCode"))
                throw $util.ProtocolError("missing required 'storeCountryCode'", { instance: message });
            return message;
        };

        /**
         * Decodes an UpdateStoreCountryCodeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {FreePremium.UpdateStoreCountryCodeRequest} UpdateStoreCountryCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateStoreCountryCodeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateStoreCountryCodeRequest message.
         * @function verify
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateStoreCountryCodeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (!$util.isString(message.storeCountryCode))
                return "storeCountryCode: string expected";
            return null;
        };

        /**
         * Creates an UpdateStoreCountryCodeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {FreePremium.UpdateStoreCountryCodeRequest} UpdateStoreCountryCodeRequest
         */
        UpdateStoreCountryCodeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.FreePremium.UpdateStoreCountryCodeRequest)
                return object;
            let message = new $root.FreePremium.UpdateStoreCountryCodeRequest();
            if (object.storeCountryCode != null)
                message.storeCountryCode = String(object.storeCountryCode);
            return message;
        };

        /**
         * Creates a plain object from an UpdateStoreCountryCodeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @static
         * @param {FreePremium.UpdateStoreCountryCodeRequest} message UpdateStoreCountryCodeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateStoreCountryCodeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.storeCountryCode = "";
            if (message.storeCountryCode != null && message.hasOwnProperty("storeCountryCode"))
                object.storeCountryCode = message.storeCountryCode;
            return object;
        };

        /**
         * Converts this UpdateStoreCountryCodeRequest to JSON.
         * @function toJSON
         * @memberof FreePremium.UpdateStoreCountryCodeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateStoreCountryCodeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateStoreCountryCodeRequest;
    })();

    FreePremium.CustomerCare = (function() {

        /**
         * Properties of a CustomerCare.
         * @memberof FreePremium
         * @interface ICustomerCare
         * @property {string|null} [url] CustomerCare url
         * @property {string|null} [phone] CustomerCare phone
         */

        /**
         * Constructs a new CustomerCare.
         * @memberof FreePremium
         * @classdesc Represents a CustomerCare.
         * @implements ICustomerCare
         * @constructor
         * @param {FreePremium.ICustomerCare=} [properties] Properties to set
         */
        function CustomerCare(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerCare url.
         * @member {string} url
         * @memberof FreePremium.CustomerCare
         * @instance
         */
        CustomerCare.prototype.url = "";

        /**
         * CustomerCare phone.
         * @member {string} phone
         * @memberof FreePremium.CustomerCare
         * @instance
         */
        CustomerCare.prototype.phone = "";

        /**
         * Creates a new CustomerCare instance using the specified properties.
         * @function create
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {FreePremium.ICustomerCare=} [properties] Properties to set
         * @returns {FreePremium.CustomerCare} CustomerCare instance
         */
        CustomerCare.create = function create(properties) {
            return new CustomerCare(properties);
        };

        /**
         * Encodes the specified CustomerCare message. Does not implicitly {@link FreePremium.CustomerCare.verify|verify} messages.
         * @function encode
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {FreePremium.ICustomerCare} message CustomerCare message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerCare.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            return writer;
        };

        /**
         * Encodes the specified CustomerCare message, length delimited. Does not implicitly {@link FreePremium.CustomerCare.verify|verify} messages.
         * @function encodeDelimited
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {FreePremium.ICustomerCare} message CustomerCare message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerCare.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomerCare message from the specified reader or buffer.
         * @function decode
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {FreePremium.CustomerCare} CustomerCare
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerCare.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.FreePremium.CustomerCare();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.phone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CustomerCare message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {FreePremium.CustomerCare} CustomerCare
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerCare.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomerCare message.
         * @function verify
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomerCare.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            return null;
        };

        /**
         * Creates a CustomerCare message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {FreePremium.CustomerCare} CustomerCare
         */
        CustomerCare.fromObject = function fromObject(object) {
            if (object instanceof $root.FreePremium.CustomerCare)
                return object;
            let message = new $root.FreePremium.CustomerCare();
            if (object.url != null)
                message.url = String(object.url);
            if (object.phone != null)
                message.phone = String(object.phone);
            return message;
        };

        /**
         * Creates a plain object from a CustomerCare message. Also converts values to other types if specified.
         * @function toObject
         * @memberof FreePremium.CustomerCare
         * @static
         * @param {FreePremium.CustomerCare} message CustomerCare
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomerCare.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                object.phone = "";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            return object;
        };

        /**
         * Converts this CustomerCare to JSON.
         * @function toJSON
         * @memberof FreePremium.CustomerCare
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomerCare.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CustomerCare;
    })();

    return FreePremium;
})();

/**
 * CreditCardType enum.
 * @exports CreditCardType
 * @enum {number}
 * @property {number} VISA=0 VISA value
 * @property {number} MASTER_CARD=1 MASTER_CARD value
 * @property {number} AMEX=2 AMEX value
 * @property {number} CARTE_BLANCHE=3 CARTE_BLANCHE value
 * @property {number} CHINA_UNION_PAY=4 CHINA_UNION_PAY value
 * @property {number} DINERS_CLUB_INTERNATIONAL=5 DINERS_CLUB_INTERNATIONAL value
 * @property {number} DISCOVER=6 DISCOVER value
 * @property {number} JCB=7 JCB value
 * @property {number} LASER=8 LASER value
 * @property {number} UK_MAESTRO=9 UK_MAESTRO value
 * @property {number} MAESTRO=10 MAESTRO value
 * @property {number} SOLO=11 SOLO value
 * @property {number} SWITCH=12 SWITCH value
 * @property {number} UNKNOWN=13 UNKNOWN value
 */
export const CreditCardType = $root.CreditCardType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "VISA"] = 0;
    values[valuesById[1] = "MASTER_CARD"] = 1;
    values[valuesById[2] = "AMEX"] = 2;
    values[valuesById[3] = "CARTE_BLANCHE"] = 3;
    values[valuesById[4] = "CHINA_UNION_PAY"] = 4;
    values[valuesById[5] = "DINERS_CLUB_INTERNATIONAL"] = 5;
    values[valuesById[6] = "DISCOVER"] = 6;
    values[valuesById[7] = "JCB"] = 7;
    values[valuesById[8] = "LASER"] = 8;
    values[valuesById[9] = "UK_MAESTRO"] = 9;
    values[valuesById[10] = "MAESTRO"] = 10;
    values[valuesById[11] = "SOLO"] = 11;
    values[valuesById[12] = "SWITCH"] = 12;
    values[valuesById[13] = "UNKNOWN"] = 13;
    return values;
})();

export const AvailableProducts = $root.AvailableProducts = (() => {

    /**
     * Properties of an AvailableProducts.
     * @exports IAvailableProducts
     * @interface IAvailableProducts
     * @property {Array.<IProduct>|null} [products] AvailableProducts products
     * @property {IProduct|null} [nativeTrial] AvailableProducts nativeTrial
     * @property {IProduct|null} [lifetime] AvailableProducts lifetime
     */

    /**
     * Constructs a new AvailableProducts.
     * @exports AvailableProducts
     * @classdesc Represents an AvailableProducts.
     * @implements IAvailableProducts
     * @constructor
     * @param {IAvailableProducts=} [properties] Properties to set
     */
    function AvailableProducts(properties) {
        this.products = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AvailableProducts products.
     * @member {Array.<IProduct>} products
     * @memberof AvailableProducts
     * @instance
     */
    AvailableProducts.prototype.products = $util.emptyArray;

    /**
     * AvailableProducts nativeTrial.
     * @member {IProduct|null|undefined} nativeTrial
     * @memberof AvailableProducts
     * @instance
     */
    AvailableProducts.prototype.nativeTrial = null;

    /**
     * AvailableProducts lifetime.
     * @member {IProduct|null|undefined} lifetime
     * @memberof AvailableProducts
     * @instance
     */
    AvailableProducts.prototype.lifetime = null;

    /**
     * Creates a new AvailableProducts instance using the specified properties.
     * @function create
     * @memberof AvailableProducts
     * @static
     * @param {IAvailableProducts=} [properties] Properties to set
     * @returns {AvailableProducts} AvailableProducts instance
     */
    AvailableProducts.create = function create(properties) {
        return new AvailableProducts(properties);
    };

    /**
     * Encodes the specified AvailableProducts message. Does not implicitly {@link AvailableProducts.verify|verify} messages.
     * @function encode
     * @memberof AvailableProducts
     * @static
     * @param {IAvailableProducts} message AvailableProducts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailableProducts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.products != null && message.products.length)
            for (let i = 0; i < message.products.length; ++i)
                $root.Product.encode(message.products[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.nativeTrial != null && Object.hasOwnProperty.call(message, "nativeTrial"))
            $root.Product.encode(message.nativeTrial, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.lifetime != null && Object.hasOwnProperty.call(message, "lifetime"))
            $root.Product.encode(message.lifetime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AvailableProducts message, length delimited. Does not implicitly {@link AvailableProducts.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AvailableProducts
     * @static
     * @param {IAvailableProducts} message AvailableProducts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailableProducts.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AvailableProducts message from the specified reader or buffer.
     * @function decode
     * @memberof AvailableProducts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AvailableProducts} AvailableProducts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailableProducts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailableProducts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 3:
                if (!(message.products && message.products.length))
                    message.products = [];
                message.products.push($root.Product.decode(reader, reader.uint32()));
                break;
            case 4:
                message.nativeTrial = $root.Product.decode(reader, reader.uint32());
                break;
            case 5:
                message.lifetime = $root.Product.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AvailableProducts message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AvailableProducts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AvailableProducts} AvailableProducts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailableProducts.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AvailableProducts message.
     * @function verify
     * @memberof AvailableProducts
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AvailableProducts.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.products != null && message.hasOwnProperty("products")) {
            if (!Array.isArray(message.products))
                return "products: array expected";
            for (let i = 0; i < message.products.length; ++i) {
                let error = $root.Product.verify(message.products[i]);
                if (error)
                    return "products." + error;
            }
        }
        if (message.nativeTrial != null && message.hasOwnProperty("nativeTrial")) {
            let error = $root.Product.verify(message.nativeTrial);
            if (error)
                return "nativeTrial." + error;
        }
        if (message.lifetime != null && message.hasOwnProperty("lifetime")) {
            let error = $root.Product.verify(message.lifetime);
            if (error)
                return "lifetime." + error;
        }
        return null;
    };

    /**
     * Creates an AvailableProducts message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AvailableProducts
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AvailableProducts} AvailableProducts
     */
    AvailableProducts.fromObject = function fromObject(object) {
        if (object instanceof $root.AvailableProducts)
            return object;
        let message = new $root.AvailableProducts();
        if (object.products) {
            if (!Array.isArray(object.products))
                throw TypeError(".AvailableProducts.products: array expected");
            message.products = [];
            for (let i = 0; i < object.products.length; ++i) {
                if (typeof object.products[i] !== "object")
                    throw TypeError(".AvailableProducts.products: object expected");
                message.products[i] = $root.Product.fromObject(object.products[i]);
            }
        }
        if (object.nativeTrial != null) {
            if (typeof object.nativeTrial !== "object")
                throw TypeError(".AvailableProducts.nativeTrial: object expected");
            message.nativeTrial = $root.Product.fromObject(object.nativeTrial);
        }
        if (object.lifetime != null) {
            if (typeof object.lifetime !== "object")
                throw TypeError(".AvailableProducts.lifetime: object expected");
            message.lifetime = $root.Product.fromObject(object.lifetime);
        }
        return message;
    };

    /**
     * Creates a plain object from an AvailableProducts message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AvailableProducts
     * @static
     * @param {AvailableProducts} message AvailableProducts
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AvailableProducts.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.products = [];
        if (options.defaults) {
            object.nativeTrial = null;
            object.lifetime = null;
        }
        if (message.products && message.products.length) {
            object.products = [];
            for (let j = 0; j < message.products.length; ++j)
                object.products[j] = $root.Product.toObject(message.products[j], options);
        }
        if (message.nativeTrial != null && message.hasOwnProperty("nativeTrial"))
            object.nativeTrial = $root.Product.toObject(message.nativeTrial, options);
        if (message.lifetime != null && message.hasOwnProperty("lifetime"))
            object.lifetime = $root.Product.toObject(message.lifetime, options);
        return object;
    };

    /**
     * Converts this AvailableProducts to JSON.
     * @function toJSON
     * @memberof AvailableProducts
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AvailableProducts.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return AvailableProducts;
})();

export const Product = $root.Product = (() => {

    /**
     * Properties of a Product.
     * @exports IProduct
     * @interface IProduct
     * @property {PlanType} planType Product planType
     * @property {string} productId Product productId
     * @property {Period} period Product period
     */

    /**
     * Constructs a new Product.
     * @exports Product
     * @classdesc Represents a Product.
     * @implements IProduct
     * @constructor
     * @param {IProduct=} [properties] Properties to set
     */
    function Product(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Product planType.
     * @member {PlanType} planType
     * @memberof Product
     * @instance
     */
    Product.prototype.planType = 0;

    /**
     * Product productId.
     * @member {string} productId
     * @memberof Product
     * @instance
     */
    Product.prototype.productId = "";

    /**
     * Product period.
     * @member {Period} period
     * @memberof Product
     * @instance
     */
    Product.prototype.period = 0;

    /**
     * Creates a new Product instance using the specified properties.
     * @function create
     * @memberof Product
     * @static
     * @param {IProduct=} [properties] Properties to set
     * @returns {Product} Product instance
     */
    Product.create = function create(properties) {
        return new Product(properties);
    };

    /**
     * Encodes the specified Product message. Does not implicitly {@link Product.verify|verify} messages.
     * @function encode
     * @memberof Product
     * @static
     * @param {IProduct} message Product message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Product.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.planType);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.productId);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.period);
        return writer;
    };

    /**
     * Encodes the specified Product message, length delimited. Does not implicitly {@link Product.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Product
     * @static
     * @param {IProduct} message Product message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Product.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Product message from the specified reader or buffer.
     * @function decode
     * @memberof Product
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Product} Product
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Product.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Product();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.planType = reader.int32();
                break;
            case 2:
                message.productId = reader.string();
                break;
            case 3:
                message.period = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("planType"))
            throw $util.ProtocolError("missing required 'planType'", { instance: message });
        if (!message.hasOwnProperty("productId"))
            throw $util.ProtocolError("missing required 'productId'", { instance: message });
        if (!message.hasOwnProperty("period"))
            throw $util.ProtocolError("missing required 'period'", { instance: message });
        return message;
    };

    /**
     * Decodes a Product message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Product
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Product} Product
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Product.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Product message.
     * @function verify
     * @memberof Product
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Product.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.planType) {
        default:
            return "planType: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            break;
        }
        if (!$util.isString(message.productId))
            return "productId: string expected";
        switch (message.period) {
        default:
            return "period: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            break;
        }
        return null;
    };

    /**
     * Creates a Product message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Product
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Product} Product
     */
    Product.fromObject = function fromObject(object) {
        if (object instanceof $root.Product)
            return object;
        let message = new $root.Product();
        switch (object.planType) {
        case "FREE":
        case 0:
            message.planType = 0;
            break;
        case "FREE_PRE_TRIAL":
        case 1:
            message.planType = 1;
            break;
        case "FREE_POST_TRIAL":
        case 2:
            message.planType = 2;
            break;
        case "BASIC":
        case 3:
            message.planType = 3;
            break;
        case "ADVANCED":
        case 4:
            message.planType = 4;
            break;
        case "PRO":
        case 5:
            message.planType = 5;
            break;
        case "VIP":
        case 6:
            message.planType = 6;
            break;
        case "BUSINESS":
        case 7:
            message.planType = 7;
            break;
        }
        if (object.productId != null)
            message.productId = String(object.productId);
        switch (object.period) {
        case "WEEK":
        case 0:
            message.period = 0;
            break;
        case "MONTH":
        case 1:
            message.period = 1;
            break;
        case "QUARTER":
        case 2:
            message.period = 2;
            break;
        case "YEAR":
        case 3:
            message.period = 3;
            break;
        case "UNLIMITED":
        case 4:
            message.period = 4;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a Product message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Product
     * @static
     * @param {Product} message Product
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Product.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.planType = options.enums === String ? "FREE" : 0;
            object.productId = "";
            object.period = options.enums === String ? "WEEK" : 0;
        }
        if (message.planType != null && message.hasOwnProperty("planType"))
            object.planType = options.enums === String ? $root.PlanType[message.planType] : message.planType;
        if (message.productId != null && message.hasOwnProperty("productId"))
            object.productId = message.productId;
        if (message.period != null && message.hasOwnProperty("period"))
            object.period = options.enums === String ? $root.Period[message.period] : message.period;
        return object;
    };

    /**
     * Converts this Product to JSON.
     * @function toJSON
     * @memberof Product
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Product.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Product;
})();

export const Transaction = $root.Transaction = (() => {

    /**
     * Properties of a Transaction.
     * @exports ITransaction
     * @interface ITransaction
     * @property {IProduct} product Transaction product
     * @property {string} token Transaction token
     * @property {string} transactionId Transaction transactionId
     * @property {Array.<IKeyValueItem>|null} [customFields] Transaction customFields
     */

    /**
     * Constructs a new Transaction.
     * @exports Transaction
     * @classdesc Represents a Transaction.
     * @implements ITransaction
     * @constructor
     * @param {ITransaction=} [properties] Properties to set
     */
    function Transaction(properties) {
        this.customFields = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Transaction product.
     * @member {IProduct} product
     * @memberof Transaction
     * @instance
     */
    Transaction.prototype.product = null;

    /**
     * Transaction token.
     * @member {string} token
     * @memberof Transaction
     * @instance
     */
    Transaction.prototype.token = "";

    /**
     * Transaction transactionId.
     * @member {string} transactionId
     * @memberof Transaction
     * @instance
     */
    Transaction.prototype.transactionId = "";

    /**
     * Transaction customFields.
     * @member {Array.<IKeyValueItem>} customFields
     * @memberof Transaction
     * @instance
     */
    Transaction.prototype.customFields = $util.emptyArray;

    /**
     * Creates a new Transaction instance using the specified properties.
     * @function create
     * @memberof Transaction
     * @static
     * @param {ITransaction=} [properties] Properties to set
     * @returns {Transaction} Transaction instance
     */
    Transaction.create = function create(properties) {
        return new Transaction(properties);
    };

    /**
     * Encodes the specified Transaction message. Does not implicitly {@link Transaction.verify|verify} messages.
     * @function encode
     * @memberof Transaction
     * @static
     * @param {ITransaction} message Transaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Transaction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.Product.encode(message.product, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
        writer.uint32(/* id 4, wireType 2 =*/34).string(message.transactionId);
        if (message.customFields != null && message.customFields.length)
            for (let i = 0; i < message.customFields.length; ++i)
                $root.KeyValueItem.encode(message.customFields[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified Transaction message, length delimited. Does not implicitly {@link Transaction.verify|verify} messages.
     * @function encodeDelimited
     * @memberof Transaction
     * @static
     * @param {ITransaction} message Transaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Transaction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Transaction message from the specified reader or buffer.
     * @function decode
     * @memberof Transaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Transaction} Transaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Transaction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Transaction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.product = $root.Product.decode(reader, reader.uint32());
                break;
            case 2:
                message.token = reader.string();
                break;
            case 4:
                message.transactionId = reader.string();
                break;
            case 5:
                if (!(message.customFields && message.customFields.length))
                    message.customFields = [];
                message.customFields.push($root.KeyValueItem.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("product"))
            throw $util.ProtocolError("missing required 'product'", { instance: message });
        if (!message.hasOwnProperty("token"))
            throw $util.ProtocolError("missing required 'token'", { instance: message });
        if (!message.hasOwnProperty("transactionId"))
            throw $util.ProtocolError("missing required 'transactionId'", { instance: message });
        return message;
    };

    /**
     * Decodes a Transaction message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof Transaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {Transaction} Transaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Transaction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Transaction message.
     * @function verify
     * @memberof Transaction
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Transaction.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        {
            let error = $root.Product.verify(message.product);
            if (error)
                return "product." + error;
        }
        if (!$util.isString(message.token))
            return "token: string expected";
        if (!$util.isString(message.transactionId))
            return "transactionId: string expected";
        if (message.customFields != null && message.hasOwnProperty("customFields")) {
            if (!Array.isArray(message.customFields))
                return "customFields: array expected";
            for (let i = 0; i < message.customFields.length; ++i) {
                let error = $root.KeyValueItem.verify(message.customFields[i]);
                if (error)
                    return "customFields." + error;
            }
        }
        return null;
    };

    /**
     * Creates a Transaction message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof Transaction
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {Transaction} Transaction
     */
    Transaction.fromObject = function fromObject(object) {
        if (object instanceof $root.Transaction)
            return object;
        let message = new $root.Transaction();
        if (object.product != null) {
            if (typeof object.product !== "object")
                throw TypeError(".Transaction.product: object expected");
            message.product = $root.Product.fromObject(object.product);
        }
        if (object.token != null)
            message.token = String(object.token);
        if (object.transactionId != null)
            message.transactionId = String(object.transactionId);
        if (object.customFields) {
            if (!Array.isArray(object.customFields))
                throw TypeError(".Transaction.customFields: array expected");
            message.customFields = [];
            for (let i = 0; i < object.customFields.length; ++i) {
                if (typeof object.customFields[i] !== "object")
                    throw TypeError(".Transaction.customFields: object expected");
                message.customFields[i] = $root.KeyValueItem.fromObject(object.customFields[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a Transaction message. Also converts values to other types if specified.
     * @function toObject
     * @memberof Transaction
     * @static
     * @param {Transaction} message Transaction
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Transaction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.customFields = [];
        if (options.defaults) {
            object.product = null;
            object.token = "";
            object.transactionId = "";
        }
        if (message.product != null && message.hasOwnProperty("product"))
            object.product = $root.Product.toObject(message.product, options);
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
            object.transactionId = message.transactionId;
        if (message.customFields && message.customFields.length) {
            object.customFields = [];
            for (let j = 0; j < message.customFields.length; ++j)
                object.customFields[j] = $root.KeyValueItem.toObject(message.customFields[j], options);
        }
        return object;
    };

    /**
     * Converts this Transaction to JSON.
     * @function toJSON
     * @memberof Transaction
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Transaction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Transaction;
})();

export const UpgradeTransaction = $root.UpgradeTransaction = (() => {

    /**
     * Properties of an UpgradeTransaction.
     * @exports IUpgradeTransaction
     * @interface IUpgradeTransaction
     * @property {ITransaction} transaction UpgradeTransaction transaction
     * @property {Array.<string>|null} [productIdsToRemove] UpgradeTransaction productIdsToRemove
     */

    /**
     * Constructs a new UpgradeTransaction.
     * @exports UpgradeTransaction
     * @classdesc Represents an UpgradeTransaction.
     * @implements IUpgradeTransaction
     * @constructor
     * @param {IUpgradeTransaction=} [properties] Properties to set
     */
    function UpgradeTransaction(properties) {
        this.productIdsToRemove = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpgradeTransaction transaction.
     * @member {ITransaction} transaction
     * @memberof UpgradeTransaction
     * @instance
     */
    UpgradeTransaction.prototype.transaction = null;

    /**
     * UpgradeTransaction productIdsToRemove.
     * @member {Array.<string>} productIdsToRemove
     * @memberof UpgradeTransaction
     * @instance
     */
    UpgradeTransaction.prototype.productIdsToRemove = $util.emptyArray;

    /**
     * Creates a new UpgradeTransaction instance using the specified properties.
     * @function create
     * @memberof UpgradeTransaction
     * @static
     * @param {IUpgradeTransaction=} [properties] Properties to set
     * @returns {UpgradeTransaction} UpgradeTransaction instance
     */
    UpgradeTransaction.create = function create(properties) {
        return new UpgradeTransaction(properties);
    };

    /**
     * Encodes the specified UpgradeTransaction message. Does not implicitly {@link UpgradeTransaction.verify|verify} messages.
     * @function encode
     * @memberof UpgradeTransaction
     * @static
     * @param {IUpgradeTransaction} message UpgradeTransaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpgradeTransaction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.Transaction.encode(message.transaction, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.productIdsToRemove != null && message.productIdsToRemove.length)
            for (let i = 0; i < message.productIdsToRemove.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productIdsToRemove[i]);
        return writer;
    };

    /**
     * Encodes the specified UpgradeTransaction message, length delimited. Does not implicitly {@link UpgradeTransaction.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpgradeTransaction
     * @static
     * @param {IUpgradeTransaction} message UpgradeTransaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpgradeTransaction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpgradeTransaction message from the specified reader or buffer.
     * @function decode
     * @memberof UpgradeTransaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpgradeTransaction} UpgradeTransaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpgradeTransaction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpgradeTransaction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.transaction = $root.Transaction.decode(reader, reader.uint32());
                break;
            case 2:
                if (!(message.productIdsToRemove && message.productIdsToRemove.length))
                    message.productIdsToRemove = [];
                message.productIdsToRemove.push(reader.string());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("transaction"))
            throw $util.ProtocolError("missing required 'transaction'", { instance: message });
        return message;
    };

    /**
     * Decodes an UpgradeTransaction message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpgradeTransaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpgradeTransaction} UpgradeTransaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpgradeTransaction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpgradeTransaction message.
     * @function verify
     * @memberof UpgradeTransaction
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpgradeTransaction.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        {
            let error = $root.Transaction.verify(message.transaction);
            if (error)
                return "transaction." + error;
        }
        if (message.productIdsToRemove != null && message.hasOwnProperty("productIdsToRemove")) {
            if (!Array.isArray(message.productIdsToRemove))
                return "productIdsToRemove: array expected";
            for (let i = 0; i < message.productIdsToRemove.length; ++i)
                if (!$util.isString(message.productIdsToRemove[i]))
                    return "productIdsToRemove: string[] expected";
        }
        return null;
    };

    /**
     * Creates an UpgradeTransaction message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpgradeTransaction
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpgradeTransaction} UpgradeTransaction
     */
    UpgradeTransaction.fromObject = function fromObject(object) {
        if (object instanceof $root.UpgradeTransaction)
            return object;
        let message = new $root.UpgradeTransaction();
        if (object.transaction != null) {
            if (typeof object.transaction !== "object")
                throw TypeError(".UpgradeTransaction.transaction: object expected");
            message.transaction = $root.Transaction.fromObject(object.transaction);
        }
        if (object.productIdsToRemove) {
            if (!Array.isArray(object.productIdsToRemove))
                throw TypeError(".UpgradeTransaction.productIdsToRemove: array expected");
            message.productIdsToRemove = [];
            for (let i = 0; i < object.productIdsToRemove.length; ++i)
                message.productIdsToRemove[i] = String(object.productIdsToRemove[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpgradeTransaction message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpgradeTransaction
     * @static
     * @param {UpgradeTransaction} message UpgradeTransaction
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpgradeTransaction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.productIdsToRemove = [];
        if (options.defaults)
            object.transaction = null;
        if (message.transaction != null && message.hasOwnProperty("transaction"))
            object.transaction = $root.Transaction.toObject(message.transaction, options);
        if (message.productIdsToRemove && message.productIdsToRemove.length) {
            object.productIdsToRemove = [];
            for (let j = 0; j < message.productIdsToRemove.length; ++j)
                object.productIdsToRemove[j] = message.productIdsToRemove[j];
        }
        return object;
    };

    /**
     * Converts this UpgradeTransaction to JSON.
     * @function toJSON
     * @memberof UpgradeTransaction
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpgradeTransaction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UpgradeTransaction;
})();

export const VoucherTransaction = $root.VoucherTransaction = (() => {

    /**
     * Properties of a VoucherTransaction.
     * @exports IVoucherTransaction
     * @interface IVoucherTransaction
     * @property {string} code VoucherTransaction code
     */

    /**
     * Constructs a new VoucherTransaction.
     * @exports VoucherTransaction
     * @classdesc Represents a VoucherTransaction.
     * @implements IVoucherTransaction
     * @constructor
     * @param {IVoucherTransaction=} [properties] Properties to set
     */
    function VoucherTransaction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * VoucherTransaction code.
     * @member {string} code
     * @memberof VoucherTransaction
     * @instance
     */
    VoucherTransaction.prototype.code = "";

    /**
     * Creates a new VoucherTransaction instance using the specified properties.
     * @function create
     * @memberof VoucherTransaction
     * @static
     * @param {IVoucherTransaction=} [properties] Properties to set
     * @returns {VoucherTransaction} VoucherTransaction instance
     */
    VoucherTransaction.create = function create(properties) {
        return new VoucherTransaction(properties);
    };

    /**
     * Encodes the specified VoucherTransaction message. Does not implicitly {@link VoucherTransaction.verify|verify} messages.
     * @function encode
     * @memberof VoucherTransaction
     * @static
     * @param {IVoucherTransaction} message VoucherTransaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VoucherTransaction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
        return writer;
    };

    /**
     * Encodes the specified VoucherTransaction message, length delimited. Does not implicitly {@link VoucherTransaction.verify|verify} messages.
     * @function encodeDelimited
     * @memberof VoucherTransaction
     * @static
     * @param {IVoucherTransaction} message VoucherTransaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VoucherTransaction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VoucherTransaction message from the specified reader or buffer.
     * @function decode
     * @memberof VoucherTransaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {VoucherTransaction} VoucherTransaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VoucherTransaction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VoucherTransaction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.code = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    /**
     * Decodes a VoucherTransaction message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof VoucherTransaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {VoucherTransaction} VoucherTransaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VoucherTransaction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VoucherTransaction message.
     * @function verify
     * @memberof VoucherTransaction
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VoucherTransaction.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.code))
            return "code: string expected";
        return null;
    };

    /**
     * Creates a VoucherTransaction message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof VoucherTransaction
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {VoucherTransaction} VoucherTransaction
     */
    VoucherTransaction.fromObject = function fromObject(object) {
        if (object instanceof $root.VoucherTransaction)
            return object;
        let message = new $root.VoucherTransaction();
        if (object.code != null)
            message.code = String(object.code);
        return message;
    };

    /**
     * Creates a plain object from a VoucherTransaction message. Also converts values to other types if specified.
     * @function toObject
     * @memberof VoucherTransaction
     * @static
     * @param {VoucherTransaction} message VoucherTransaction
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VoucherTransaction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.code = "";
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        return object;
    };

    /**
     * Converts this VoucherTransaction to JSON.
     * @function toJSON
     * @memberof VoucherTransaction
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VoucherTransaction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return VoucherTransaction;
})();

/**
 * Period enum.
 * @exports Period
 * @enum {number}
 * @property {number} WEEK=0 WEEK value
 * @property {number} MONTH=1 MONTH value
 * @property {number} QUARTER=2 QUARTER value
 * @property {number} YEAR=3 YEAR value
 * @property {number} UNLIMITED=4 UNLIMITED value
 */
export const Period = $root.Period = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "WEEK"] = 0;
    values[valuesById[1] = "MONTH"] = 1;
    values[valuesById[2] = "QUARTER"] = 2;
    values[valuesById[3] = "YEAR"] = 3;
    values[valuesById[4] = "UNLIMITED"] = 4;
    return values;
})();

/**
 * VoucherType enum.
 * @exports VoucherType
 * @enum {number}
 * @property {number} PREMIUM=0 PREMIUM value
 * @property {number} DISCOUNT=1 DISCOUNT value
 * @property {number} NATIVE_TRIAL=2 NATIVE_TRIAL value
 */
export const VoucherType = $root.VoucherType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "PREMIUM"] = 0;
    values[valuesById[1] = "DISCOUNT"] = 1;
    values[valuesById[2] = "NATIVE_TRIAL"] = 2;
    return values;
})();

export const VoucherTransactionResponse = $root.VoucherTransactionResponse = (() => {

    /**
     * Properties of a VoucherTransactionResponse.
     * @exports IVoucherTransactionResponse
     * @interface IVoucherTransactionResponse
     * @property {VoucherType} voucherType VoucherTransactionResponse voucherType
     * @property {string|null} [productId] VoucherTransactionResponse productId
     * @property {number|null} [price] VoucherTransactionResponse price
     * @property {number|null} [taxAmount] VoucherTransactionResponse taxAmount
     * @property {string|null} [currencyCode] VoucherTransactionResponse currencyCode
     * @property {Period|null} [period] VoucherTransactionResponse period
     */

    /**
     * Constructs a new VoucherTransactionResponse.
     * @exports VoucherTransactionResponse
     * @classdesc Represents a VoucherTransactionResponse.
     * @implements IVoucherTransactionResponse
     * @constructor
     * @param {IVoucherTransactionResponse=} [properties] Properties to set
     */
    function VoucherTransactionResponse(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * VoucherTransactionResponse voucherType.
     * @member {VoucherType} voucherType
     * @memberof VoucherTransactionResponse
     * @instance
     */
    VoucherTransactionResponse.prototype.voucherType = 0;

    /**
     * VoucherTransactionResponse productId.
     * @member {string} productId
     * @memberof VoucherTransactionResponse
     * @instance
     */
    VoucherTransactionResponse.prototype.productId = "";

    /**
     * VoucherTransactionResponse price.
     * @member {number} price
     * @memberof VoucherTransactionResponse
     * @instance
     */
    VoucherTransactionResponse.prototype.price = 0;

    /**
     * VoucherTransactionResponse taxAmount.
     * @member {number} taxAmount
     * @memberof VoucherTransactionResponse
     * @instance
     */
    VoucherTransactionResponse.prototype.taxAmount = 0;

    /**
     * VoucherTransactionResponse currencyCode.
     * @member {string} currencyCode
     * @memberof VoucherTransactionResponse
     * @instance
     */
    VoucherTransactionResponse.prototype.currencyCode = "";

    /**
     * VoucherTransactionResponse period.
     * @member {Period} period
     * @memberof VoucherTransactionResponse
     * @instance
     */
    VoucherTransactionResponse.prototype.period = 0;

    /**
     * Creates a new VoucherTransactionResponse instance using the specified properties.
     * @function create
     * @memberof VoucherTransactionResponse
     * @static
     * @param {IVoucherTransactionResponse=} [properties] Properties to set
     * @returns {VoucherTransactionResponse} VoucherTransactionResponse instance
     */
    VoucherTransactionResponse.create = function create(properties) {
        return new VoucherTransactionResponse(properties);
    };

    /**
     * Encodes the specified VoucherTransactionResponse message. Does not implicitly {@link VoucherTransactionResponse.verify|verify} messages.
     * @function encode
     * @memberof VoucherTransactionResponse
     * @static
     * @param {IVoucherTransactionResponse} message VoucherTransactionResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VoucherTransactionResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.voucherType);
        if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.productId);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.price);
        if (message.taxAmount != null && Object.hasOwnProperty.call(message, "taxAmount"))
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.taxAmount);
        if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.currencyCode);
        if (message.period != null && Object.hasOwnProperty.call(message, "period"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.period);
        return writer;
    };

    /**
     * Encodes the specified VoucherTransactionResponse message, length delimited. Does not implicitly {@link VoucherTransactionResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof VoucherTransactionResponse
     * @static
     * @param {IVoucherTransactionResponse} message VoucherTransactionResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VoucherTransactionResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VoucherTransactionResponse message from the specified reader or buffer.
     * @function decode
     * @memberof VoucherTransactionResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {VoucherTransactionResponse} VoucherTransactionResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VoucherTransactionResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VoucherTransactionResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.voucherType = reader.int32();
                break;
            case 2:
                message.productId = reader.string();
                break;
            case 3:
                message.price = reader.double();
                break;
            case 4:
                message.taxAmount = reader.double();
                break;
            case 5:
                message.currencyCode = reader.string();
                break;
            case 6:
                message.period = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("voucherType"))
            throw $util.ProtocolError("missing required 'voucherType'", { instance: message });
        return message;
    };

    /**
     * Decodes a VoucherTransactionResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof VoucherTransactionResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {VoucherTransactionResponse} VoucherTransactionResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VoucherTransactionResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VoucherTransactionResponse message.
     * @function verify
     * @memberof VoucherTransactionResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VoucherTransactionResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.voucherType) {
        default:
            return "voucherType: enum value expected";
        case 0:
        case 1:
        case 2:
            break;
        }
        if (message.productId != null && message.hasOwnProperty("productId"))
            if (!$util.isString(message.productId))
                return "productId: string expected";
        if (message.price != null && message.hasOwnProperty("price"))
            if (typeof message.price !== "number")
                return "price: number expected";
        if (message.taxAmount != null && message.hasOwnProperty("taxAmount"))
            if (typeof message.taxAmount !== "number")
                return "taxAmount: number expected";
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            if (!$util.isString(message.currencyCode))
                return "currencyCode: string expected";
        if (message.period != null && message.hasOwnProperty("period"))
            switch (message.period) {
            default:
                return "period: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        return null;
    };

    /**
     * Creates a VoucherTransactionResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof VoucherTransactionResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {VoucherTransactionResponse} VoucherTransactionResponse
     */
    VoucherTransactionResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.VoucherTransactionResponse)
            return object;
        let message = new $root.VoucherTransactionResponse();
        switch (object.voucherType) {
        case "PREMIUM":
        case 0:
            message.voucherType = 0;
            break;
        case "DISCOUNT":
        case 1:
            message.voucherType = 1;
            break;
        case "NATIVE_TRIAL":
        case 2:
            message.voucherType = 2;
            break;
        }
        if (object.productId != null)
            message.productId = String(object.productId);
        if (object.price != null)
            message.price = Number(object.price);
        if (object.taxAmount != null)
            message.taxAmount = Number(object.taxAmount);
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        switch (object.period) {
        case "WEEK":
        case 0:
            message.period = 0;
            break;
        case "MONTH":
        case 1:
            message.period = 1;
            break;
        case "QUARTER":
        case 2:
            message.period = 2;
            break;
        case "YEAR":
        case 3:
            message.period = 3;
            break;
        case "UNLIMITED":
        case 4:
            message.period = 4;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from a VoucherTransactionResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof VoucherTransactionResponse
     * @static
     * @param {VoucherTransactionResponse} message VoucherTransactionResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VoucherTransactionResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.voucherType = options.enums === String ? "PREMIUM" : 0;
            object.productId = "";
            object.price = 0;
            object.taxAmount = 0;
            object.currencyCode = "";
            object.period = options.enums === String ? "WEEK" : 0;
        }
        if (message.voucherType != null && message.hasOwnProperty("voucherType"))
            object.voucherType = options.enums === String ? $root.VoucherType[message.voucherType] : message.voucherType;
        if (message.productId != null && message.hasOwnProperty("productId"))
            object.productId = message.productId;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
        if (message.taxAmount != null && message.hasOwnProperty("taxAmount"))
            object.taxAmount = options.json && !isFinite(message.taxAmount) ? String(message.taxAmount) : message.taxAmount;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        if (message.period != null && message.hasOwnProperty("period"))
            object.period = options.enums === String ? $root.Period[message.period] : message.period;
        return object;
    };

    /**
     * Converts this VoucherTransactionResponse to JSON.
     * @function toJSON
     * @memberof VoucherTransactionResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VoucherTransactionResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return VoucherTransactionResponse;
})();

/**
 * PlanType enum.
 * @exports PlanType
 * @enum {number}
 * @property {number} FREE=0 FREE value
 * @property {number} FREE_PRE_TRIAL=1 FREE_PRE_TRIAL value
 * @property {number} FREE_POST_TRIAL=2 FREE_POST_TRIAL value
 * @property {number} BASIC=3 BASIC value
 * @property {number} ADVANCED=4 ADVANCED value
 * @property {number} PRO=5 PRO value
 * @property {number} VIP=6 VIP value
 * @property {number} BUSINESS=7 BUSINESS value
 */
export const PlanType = $root.PlanType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FREE"] = 0;
    values[valuesById[1] = "FREE_PRE_TRIAL"] = 1;
    values[valuesById[2] = "FREE_POST_TRIAL"] = 2;
    values[valuesById[3] = "BASIC"] = 3;
    values[valuesById[4] = "ADVANCED"] = 4;
    values[valuesById[5] = "PRO"] = 5;
    values[valuesById[6] = "VIP"] = 6;
    values[valuesById[7] = "BUSINESS"] = 7;
    return values;
})();

/**
 * Method enum.
 * @exports Method
 * @enum {number}
 * @property {number} GOOGLE_PLAY=0 GOOGLE_PLAY value
 * @property {number} APPLE_STORE=1 APPLE_STORE value
 * @property {number} PAYPAL=2 PAYPAL value
 * @property {number} PAYMILL=3 PAYMILL value
 * @property {number} CREDIT_CARD=4 CREDIT_CARD value
 */
export const Method = $root.Method = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "GOOGLE_PLAY"] = 0;
    values[valuesById[1] = "APPLE_STORE"] = 1;
    values[valuesById[2] = "PAYPAL"] = 2;
    values[valuesById[3] = "PAYMILL"] = 3;
    values[valuesById[4] = "CREDIT_CARD"] = 4;
    return values;
})();

export const ActivePlan = $root.ActivePlan = (() => {

    /**
     * Properties of an ActivePlan.
     * @exports IActivePlan
     * @interface IActivePlan
     * @property {PlanType} planType ActivePlan planType
     * @property {number|Long|null} [validUntil] ActivePlan validUntil
     * @property {Array.<IProduct>|null} [activeProducts] ActivePlan activeProducts
     * @property {number|Long|null} [gracePeriodValidUntil] ActivePlan gracePeriodValidUntil
     * @property {number|Long|null} [createdAt] ActivePlan createdAt
     * @property {IProduct|null} [currentProduct] ActivePlan currentProduct
     * @property {Period|null} [nextRenewPeriod] ActivePlan nextRenewPeriod
     */

    /**
     * Constructs a new ActivePlan.
     * @exports ActivePlan
     * @classdesc Represents an ActivePlan.
     * @implements IActivePlan
     * @constructor
     * @param {IActivePlan=} [properties] Properties to set
     */
    function ActivePlan(properties) {
        this.activeProducts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivePlan planType.
     * @member {PlanType} planType
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.planType = 0;

    /**
     * ActivePlan validUntil.
     * @member {number|Long} validUntil
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.validUntil = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ActivePlan activeProducts.
     * @member {Array.<IProduct>} activeProducts
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.activeProducts = $util.emptyArray;

    /**
     * ActivePlan gracePeriodValidUntil.
     * @member {number|Long} gracePeriodValidUntil
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.gracePeriodValidUntil = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ActivePlan createdAt.
     * @member {number|Long} createdAt
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ActivePlan currentProduct.
     * @member {IProduct|null|undefined} currentProduct
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.currentProduct = null;

    /**
     * ActivePlan nextRenewPeriod.
     * @member {Period} nextRenewPeriod
     * @memberof ActivePlan
     * @instance
     */
    ActivePlan.prototype.nextRenewPeriod = 0;

    /**
     * Creates a new ActivePlan instance using the specified properties.
     * @function create
     * @memberof ActivePlan
     * @static
     * @param {IActivePlan=} [properties] Properties to set
     * @returns {ActivePlan} ActivePlan instance
     */
    ActivePlan.create = function create(properties) {
        return new ActivePlan(properties);
    };

    /**
     * Encodes the specified ActivePlan message. Does not implicitly {@link ActivePlan.verify|verify} messages.
     * @function encode
     * @memberof ActivePlan
     * @static
     * @param {IActivePlan} message ActivePlan message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivePlan.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.planType);
        if (message.validUntil != null && Object.hasOwnProperty.call(message, "validUntil"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.validUntil);
        if (message.activeProducts != null && message.activeProducts.length)
            for (let i = 0; i < message.activeProducts.length; ++i)
                $root.Product.encode(message.activeProducts[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.gracePeriodValidUntil != null && Object.hasOwnProperty.call(message, "gracePeriodValidUntil"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.gracePeriodValidUntil);
        if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createdAt);
        if (message.currentProduct != null && Object.hasOwnProperty.call(message, "currentProduct"))
            $root.Product.encode(message.currentProduct, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.nextRenewPeriod != null && Object.hasOwnProperty.call(message, "nextRenewPeriod"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.nextRenewPeriod);
        return writer;
    };

    /**
     * Encodes the specified ActivePlan message, length delimited. Does not implicitly {@link ActivePlan.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ActivePlan
     * @static
     * @param {IActivePlan} message ActivePlan message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivePlan.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivePlan message from the specified reader or buffer.
     * @function decode
     * @memberof ActivePlan
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ActivePlan} ActivePlan
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivePlan.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ActivePlan();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.planType = reader.int32();
                break;
            case 2:
                message.validUntil = reader.int64();
                break;
            case 3:
                if (!(message.activeProducts && message.activeProducts.length))
                    message.activeProducts = [];
                message.activeProducts.push($root.Product.decode(reader, reader.uint32()));
                break;
            case 4:
                message.gracePeriodValidUntil = reader.int64();
                break;
            case 5:
                message.createdAt = reader.int64();
                break;
            case 6:
                message.currentProduct = $root.Product.decode(reader, reader.uint32());
                break;
            case 7:
                message.nextRenewPeriod = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("planType"))
            throw $util.ProtocolError("missing required 'planType'", { instance: message });
        return message;
    };

    /**
     * Decodes an ActivePlan message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ActivePlan
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ActivePlan} ActivePlan
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivePlan.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivePlan message.
     * @function verify
     * @memberof ActivePlan
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivePlan.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.planType) {
        default:
            return "planType: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            break;
        }
        if (message.validUntil != null && message.hasOwnProperty("validUntil"))
            if (!$util.isInteger(message.validUntil) && !(message.validUntil && $util.isInteger(message.validUntil.low) && $util.isInteger(message.validUntil.high)))
                return "validUntil: integer|Long expected";
        if (message.activeProducts != null && message.hasOwnProperty("activeProducts")) {
            if (!Array.isArray(message.activeProducts))
                return "activeProducts: array expected";
            for (let i = 0; i < message.activeProducts.length; ++i) {
                let error = $root.Product.verify(message.activeProducts[i]);
                if (error)
                    return "activeProducts." + error;
            }
        }
        if (message.gracePeriodValidUntil != null && message.hasOwnProperty("gracePeriodValidUntil"))
            if (!$util.isInteger(message.gracePeriodValidUntil) && !(message.gracePeriodValidUntil && $util.isInteger(message.gracePeriodValidUntil.low) && $util.isInteger(message.gracePeriodValidUntil.high)))
                return "gracePeriodValidUntil: integer|Long expected";
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
                return "createdAt: integer|Long expected";
        if (message.currentProduct != null && message.hasOwnProperty("currentProduct")) {
            let error = $root.Product.verify(message.currentProduct);
            if (error)
                return "currentProduct." + error;
        }
        if (message.nextRenewPeriod != null && message.hasOwnProperty("nextRenewPeriod"))
            switch (message.nextRenewPeriod) {
            default:
                return "nextRenewPeriod: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
                break;
            }
        return null;
    };

    /**
     * Creates an ActivePlan message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ActivePlan
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ActivePlan} ActivePlan
     */
    ActivePlan.fromObject = function fromObject(object) {
        if (object instanceof $root.ActivePlan)
            return object;
        let message = new $root.ActivePlan();
        switch (object.planType) {
        case "FREE":
        case 0:
            message.planType = 0;
            break;
        case "FREE_PRE_TRIAL":
        case 1:
            message.planType = 1;
            break;
        case "FREE_POST_TRIAL":
        case 2:
            message.planType = 2;
            break;
        case "BASIC":
        case 3:
            message.planType = 3;
            break;
        case "ADVANCED":
        case 4:
            message.planType = 4;
            break;
        case "PRO":
        case 5:
            message.planType = 5;
            break;
        case "VIP":
        case 6:
            message.planType = 6;
            break;
        case "BUSINESS":
        case 7:
            message.planType = 7;
            break;
        }
        if (object.validUntil != null)
            if ($util.Long)
                (message.validUntil = $util.Long.fromValue(object.validUntil)).unsigned = false;
            else if (typeof object.validUntil === "string")
                message.validUntil = parseInt(object.validUntil, 10);
            else if (typeof object.validUntil === "number")
                message.validUntil = object.validUntil;
            else if (typeof object.validUntil === "object")
                message.validUntil = new $util.LongBits(object.validUntil.low >>> 0, object.validUntil.high >>> 0).toNumber();
        if (object.activeProducts) {
            if (!Array.isArray(object.activeProducts))
                throw TypeError(".ActivePlan.activeProducts: array expected");
            message.activeProducts = [];
            for (let i = 0; i < object.activeProducts.length; ++i) {
                if (typeof object.activeProducts[i] !== "object")
                    throw TypeError(".ActivePlan.activeProducts: object expected");
                message.activeProducts[i] = $root.Product.fromObject(object.activeProducts[i]);
            }
        }
        if (object.gracePeriodValidUntil != null)
            if ($util.Long)
                (message.gracePeriodValidUntil = $util.Long.fromValue(object.gracePeriodValidUntil)).unsigned = false;
            else if (typeof object.gracePeriodValidUntil === "string")
                message.gracePeriodValidUntil = parseInt(object.gracePeriodValidUntil, 10);
            else if (typeof object.gracePeriodValidUntil === "number")
                message.gracePeriodValidUntil = object.gracePeriodValidUntil;
            else if (typeof object.gracePeriodValidUntil === "object")
                message.gracePeriodValidUntil = new $util.LongBits(object.gracePeriodValidUntil.low >>> 0, object.gracePeriodValidUntil.high >>> 0).toNumber();
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.currentProduct != null) {
            if (typeof object.currentProduct !== "object")
                throw TypeError(".ActivePlan.currentProduct: object expected");
            message.currentProduct = $root.Product.fromObject(object.currentProduct);
        }
        switch (object.nextRenewPeriod) {
        case "WEEK":
        case 0:
            message.nextRenewPeriod = 0;
            break;
        case "MONTH":
        case 1:
            message.nextRenewPeriod = 1;
            break;
        case "QUARTER":
        case 2:
            message.nextRenewPeriod = 2;
            break;
        case "YEAR":
        case 3:
            message.nextRenewPeriod = 3;
            break;
        case "UNLIMITED":
        case 4:
            message.nextRenewPeriod = 4;
            break;
        }
        return message;
    };

    /**
     * Creates a plain object from an ActivePlan message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ActivePlan
     * @static
     * @param {ActivePlan} message ActivePlan
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivePlan.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.activeProducts = [];
        if (options.defaults) {
            object.planType = options.enums === String ? "FREE" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.validUntil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.validUntil = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.gracePeriodValidUntil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.gracePeriodValidUntil = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            object.currentProduct = null;
            object.nextRenewPeriod = options.enums === String ? "WEEK" : 0;
        }
        if (message.planType != null && message.hasOwnProperty("planType"))
            object.planType = options.enums === String ? $root.PlanType[message.planType] : message.planType;
        if (message.validUntil != null && message.hasOwnProperty("validUntil"))
            if (typeof message.validUntil === "number")
                object.validUntil = options.longs === String ? String(message.validUntil) : message.validUntil;
            else
                object.validUntil = options.longs === String ? $util.Long.prototype.toString.call(message.validUntil) : options.longs === Number ? new $util.LongBits(message.validUntil.low >>> 0, message.validUntil.high >>> 0).toNumber() : message.validUntil;
        if (message.activeProducts && message.activeProducts.length) {
            object.activeProducts = [];
            for (let j = 0; j < message.activeProducts.length; ++j)
                object.activeProducts[j] = $root.Product.toObject(message.activeProducts[j], options);
        }
        if (message.gracePeriodValidUntil != null && message.hasOwnProperty("gracePeriodValidUntil"))
            if (typeof message.gracePeriodValidUntil === "number")
                object.gracePeriodValidUntil = options.longs === String ? String(message.gracePeriodValidUntil) : message.gracePeriodValidUntil;
            else
                object.gracePeriodValidUntil = options.longs === String ? $util.Long.prototype.toString.call(message.gracePeriodValidUntil) : options.longs === Number ? new $util.LongBits(message.gracePeriodValidUntil.low >>> 0, message.gracePeriodValidUntil.high >>> 0).toNumber() : message.gracePeriodValidUntil;
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.currentProduct != null && message.hasOwnProperty("currentProduct"))
            object.currentProduct = $root.Product.toObject(message.currentProduct, options);
        if (message.nextRenewPeriod != null && message.hasOwnProperty("nextRenewPeriod"))
            object.nextRenewPeriod = options.enums === String ? $root.Period[message.nextRenewPeriod] : message.nextRenewPeriod;
        return object;
    };

    /**
     * Converts this ActivePlan to JSON.
     * @function toJSON
     * @memberof ActivePlan
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivePlan.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ActivePlan;
})();

export const KeyValueItem = $root.KeyValueItem = (() => {

    /**
     * Properties of a KeyValueItem.
     * @exports IKeyValueItem
     * @interface IKeyValueItem
     * @property {string} key KeyValueItem key
     * @property {string} value KeyValueItem value
     */

    /**
     * Constructs a new KeyValueItem.
     * @exports KeyValueItem
     * @classdesc Represents a KeyValueItem.
     * @implements IKeyValueItem
     * @constructor
     * @param {IKeyValueItem=} [properties] Properties to set
     */
    function KeyValueItem(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * KeyValueItem key.
     * @member {string} key
     * @memberof KeyValueItem
     * @instance
     */
    KeyValueItem.prototype.key = "";

    /**
     * KeyValueItem value.
     * @member {string} value
     * @memberof KeyValueItem
     * @instance
     */
    KeyValueItem.prototype.value = "";

    /**
     * Creates a new KeyValueItem instance using the specified properties.
     * @function create
     * @memberof KeyValueItem
     * @static
     * @param {IKeyValueItem=} [properties] Properties to set
     * @returns {KeyValueItem} KeyValueItem instance
     */
    KeyValueItem.create = function create(properties) {
        return new KeyValueItem(properties);
    };

    /**
     * Encodes the specified KeyValueItem message. Does not implicitly {@link KeyValueItem.verify|verify} messages.
     * @function encode
     * @memberof KeyValueItem
     * @static
     * @param {IKeyValueItem} message KeyValueItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyValueItem.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
        return writer;
    };

    /**
     * Encodes the specified KeyValueItem message, length delimited. Does not implicitly {@link KeyValueItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof KeyValueItem
     * @static
     * @param {IKeyValueItem} message KeyValueItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyValueItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KeyValueItem message from the specified reader or buffer.
     * @function decode
     * @memberof KeyValueItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {KeyValueItem} KeyValueItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyValueItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.KeyValueItem();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.key = reader.string();
                break;
            case 2:
                message.value = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("key"))
            throw $util.ProtocolError("missing required 'key'", { instance: message });
        if (!message.hasOwnProperty("value"))
            throw $util.ProtocolError("missing required 'value'", { instance: message });
        return message;
    };

    /**
     * Decodes a KeyValueItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof KeyValueItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {KeyValueItem} KeyValueItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyValueItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KeyValueItem message.
     * @function verify
     * @memberof KeyValueItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KeyValueItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.key))
            return "key: string expected";
        if (!$util.isString(message.value))
            return "value: string expected";
        return null;
    };

    /**
     * Creates a KeyValueItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof KeyValueItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {KeyValueItem} KeyValueItem
     */
    KeyValueItem.fromObject = function fromObject(object) {
        if (object instanceof $root.KeyValueItem)
            return object;
        let message = new $root.KeyValueItem();
        if (object.key != null)
            message.key = String(object.key);
        if (object.value != null)
            message.value = String(object.value);
        return message;
    };

    /**
     * Creates a plain object from a KeyValueItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof KeyValueItem
     * @static
     * @param {KeyValueItem} message KeyValueItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KeyValueItem.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.key = "";
            object.value = "";
        }
        if (message.key != null && message.hasOwnProperty("key"))
            object.key = message.key;
        if (message.value != null && message.hasOwnProperty("value"))
            object.value = message.value;
        return object;
    };

    /**
     * Converts this KeyValueItem to JSON.
     * @function toJSON
     * @memberof KeyValueItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KeyValueItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return KeyValueItem;
})();

export const BraintreeProduct = $root.BraintreeProduct = (() => {

    /**
     * Properties of a BraintreeProduct.
     * @exports IBraintreeProduct
     * @interface IBraintreeProduct
     * @property {IProduct} product BraintreeProduct product
     * @property {number} price BraintreeProduct price
     * @property {number|null} [taxAmount] BraintreeProduct taxAmount
     * @property {string} currencyCode BraintreeProduct currencyCode
     */

    /**
     * Constructs a new BraintreeProduct.
     * @exports BraintreeProduct
     * @classdesc Represents a BraintreeProduct.
     * @implements IBraintreeProduct
     * @constructor
     * @param {IBraintreeProduct=} [properties] Properties to set
     */
    function BraintreeProduct(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeProduct product.
     * @member {IProduct} product
     * @memberof BraintreeProduct
     * @instance
     */
    BraintreeProduct.prototype.product = null;

    /**
     * BraintreeProduct price.
     * @member {number} price
     * @memberof BraintreeProduct
     * @instance
     */
    BraintreeProduct.prototype.price = 0;

    /**
     * BraintreeProduct taxAmount.
     * @member {number} taxAmount
     * @memberof BraintreeProduct
     * @instance
     */
    BraintreeProduct.prototype.taxAmount = 0;

    /**
     * BraintreeProduct currencyCode.
     * @member {string} currencyCode
     * @memberof BraintreeProduct
     * @instance
     */
    BraintreeProduct.prototype.currencyCode = "";

    /**
     * Creates a new BraintreeProduct instance using the specified properties.
     * @function create
     * @memberof BraintreeProduct
     * @static
     * @param {IBraintreeProduct=} [properties] Properties to set
     * @returns {BraintreeProduct} BraintreeProduct instance
     */
    BraintreeProduct.create = function create(properties) {
        return new BraintreeProduct(properties);
    };

    /**
     * Encodes the specified BraintreeProduct message. Does not implicitly {@link BraintreeProduct.verify|verify} messages.
     * @function encode
     * @memberof BraintreeProduct
     * @static
     * @param {IBraintreeProduct} message BraintreeProduct message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeProduct.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.Product.encode(message.product, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        writer.uint32(/* id 2, wireType 1 =*/17).double(message.price);
        if (message.taxAmount != null && Object.hasOwnProperty.call(message, "taxAmount"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.taxAmount);
        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currencyCode);
        return writer;
    };

    /**
     * Encodes the specified BraintreeProduct message, length delimited. Does not implicitly {@link BraintreeProduct.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeProduct
     * @static
     * @param {IBraintreeProduct} message BraintreeProduct message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeProduct.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeProduct message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeProduct
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeProduct} BraintreeProduct
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeProduct.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeProduct();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.product = $root.Product.decode(reader, reader.uint32());
                break;
            case 2:
                message.price = reader.double();
                break;
            case 3:
                message.taxAmount = reader.double();
                break;
            case 4:
                message.currencyCode = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("product"))
            throw $util.ProtocolError("missing required 'product'", { instance: message });
        if (!message.hasOwnProperty("price"))
            throw $util.ProtocolError("missing required 'price'", { instance: message });
        if (!message.hasOwnProperty("currencyCode"))
            throw $util.ProtocolError("missing required 'currencyCode'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreeProduct message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeProduct
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeProduct} BraintreeProduct
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeProduct.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeProduct message.
     * @function verify
     * @memberof BraintreeProduct
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeProduct.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        {
            let error = $root.Product.verify(message.product);
            if (error)
                return "product." + error;
        }
        if (typeof message.price !== "number")
            return "price: number expected";
        if (message.taxAmount != null && message.hasOwnProperty("taxAmount"))
            if (typeof message.taxAmount !== "number")
                return "taxAmount: number expected";
        if (!$util.isString(message.currencyCode))
            return "currencyCode: string expected";
        return null;
    };

    /**
     * Creates a BraintreeProduct message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeProduct
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeProduct} BraintreeProduct
     */
    BraintreeProduct.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeProduct)
            return object;
        let message = new $root.BraintreeProduct();
        if (object.product != null) {
            if (typeof object.product !== "object")
                throw TypeError(".BraintreeProduct.product: object expected");
            message.product = $root.Product.fromObject(object.product);
        }
        if (object.price != null)
            message.price = Number(object.price);
        if (object.taxAmount != null)
            message.taxAmount = Number(object.taxAmount);
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        return message;
    };

    /**
     * Creates a plain object from a BraintreeProduct message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeProduct
     * @static
     * @param {BraintreeProduct} message BraintreeProduct
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeProduct.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.product = null;
            object.price = 0;
            object.taxAmount = 0;
            object.currencyCode = "";
        }
        if (message.product != null && message.hasOwnProperty("product"))
            object.product = $root.Product.toObject(message.product, options);
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
        if (message.taxAmount != null && message.hasOwnProperty("taxAmount"))
            object.taxAmount = options.json && !isFinite(message.taxAmount) ? String(message.taxAmount) : message.taxAmount;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        return object;
    };

    /**
     * Converts this BraintreeProduct to JSON.
     * @function toJSON
     * @memberof BraintreeProduct
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeProduct.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeProduct;
})();

export const BraintreePaymentInfo = $root.BraintreePaymentInfo = (() => {

    /**
     * Properties of a BraintreePaymentInfo.
     * @exports IBraintreePaymentInfo
     * @interface IBraintreePaymentInfo
     * @property {string|null} [token] BraintreePaymentInfo token
     * @property {Array.<IBraintreeProduct>|null} [recurrentProducts] BraintreePaymentInfo recurrentProducts
     * @property {IBraintreeProduct|null} [lifetimeProduct] BraintreePaymentInfo lifetimeProduct
     * @property {number|Long|null} [vat] BraintreePaymentInfo vat
     */

    /**
     * Constructs a new BraintreePaymentInfo.
     * @exports BraintreePaymentInfo
     * @classdesc Represents a BraintreePaymentInfo.
     * @implements IBraintreePaymentInfo
     * @constructor
     * @param {IBraintreePaymentInfo=} [properties] Properties to set
     */
    function BraintreePaymentInfo(properties) {
        this.recurrentProducts = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreePaymentInfo token.
     * @member {string} token
     * @memberof BraintreePaymentInfo
     * @instance
     */
    BraintreePaymentInfo.prototype.token = "";

    /**
     * BraintreePaymentInfo recurrentProducts.
     * @member {Array.<IBraintreeProduct>} recurrentProducts
     * @memberof BraintreePaymentInfo
     * @instance
     */
    BraintreePaymentInfo.prototype.recurrentProducts = $util.emptyArray;

    /**
     * BraintreePaymentInfo lifetimeProduct.
     * @member {IBraintreeProduct|null|undefined} lifetimeProduct
     * @memberof BraintreePaymentInfo
     * @instance
     */
    BraintreePaymentInfo.prototype.lifetimeProduct = null;

    /**
     * BraintreePaymentInfo vat.
     * @member {number|Long} vat
     * @memberof BraintreePaymentInfo
     * @instance
     */
    BraintreePaymentInfo.prototype.vat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new BraintreePaymentInfo instance using the specified properties.
     * @function create
     * @memberof BraintreePaymentInfo
     * @static
     * @param {IBraintreePaymentInfo=} [properties] Properties to set
     * @returns {BraintreePaymentInfo} BraintreePaymentInfo instance
     */
    BraintreePaymentInfo.create = function create(properties) {
        return new BraintreePaymentInfo(properties);
    };

    /**
     * Encodes the specified BraintreePaymentInfo message. Does not implicitly {@link BraintreePaymentInfo.verify|verify} messages.
     * @function encode
     * @memberof BraintreePaymentInfo
     * @static
     * @param {IBraintreePaymentInfo} message BraintreePaymentInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreePaymentInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
        if (message.recurrentProducts != null && message.recurrentProducts.length)
            for (let i = 0; i < message.recurrentProducts.length; ++i)
                $root.BraintreeProduct.encode(message.recurrentProducts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.lifetimeProduct != null && Object.hasOwnProperty.call(message, "lifetimeProduct"))
            $root.BraintreeProduct.encode(message.lifetimeProduct, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.vat != null && Object.hasOwnProperty.call(message, "vat"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.vat);
        return writer;
    };

    /**
     * Encodes the specified BraintreePaymentInfo message, length delimited. Does not implicitly {@link BraintreePaymentInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreePaymentInfo
     * @static
     * @param {IBraintreePaymentInfo} message BraintreePaymentInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreePaymentInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreePaymentInfo message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreePaymentInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreePaymentInfo} BraintreePaymentInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreePaymentInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreePaymentInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.token = reader.string();
                break;
            case 2:
                if (!(message.recurrentProducts && message.recurrentProducts.length))
                    message.recurrentProducts = [];
                message.recurrentProducts.push($root.BraintreeProduct.decode(reader, reader.uint32()));
                break;
            case 3:
                message.lifetimeProduct = $root.BraintreeProduct.decode(reader, reader.uint32());
                break;
            case 4:
                message.vat = reader.int64();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BraintreePaymentInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreePaymentInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreePaymentInfo} BraintreePaymentInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreePaymentInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreePaymentInfo message.
     * @function verify
     * @memberof BraintreePaymentInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreePaymentInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.token != null && message.hasOwnProperty("token"))
            if (!$util.isString(message.token))
                return "token: string expected";
        if (message.recurrentProducts != null && message.hasOwnProperty("recurrentProducts")) {
            if (!Array.isArray(message.recurrentProducts))
                return "recurrentProducts: array expected";
            for (let i = 0; i < message.recurrentProducts.length; ++i) {
                let error = $root.BraintreeProduct.verify(message.recurrentProducts[i]);
                if (error)
                    return "recurrentProducts." + error;
            }
        }
        if (message.lifetimeProduct != null && message.hasOwnProperty("lifetimeProduct")) {
            let error = $root.BraintreeProduct.verify(message.lifetimeProduct);
            if (error)
                return "lifetimeProduct." + error;
        }
        if (message.vat != null && message.hasOwnProperty("vat"))
            if (!$util.isInteger(message.vat) && !(message.vat && $util.isInteger(message.vat.low) && $util.isInteger(message.vat.high)))
                return "vat: integer|Long expected";
        return null;
    };

    /**
     * Creates a BraintreePaymentInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreePaymentInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreePaymentInfo} BraintreePaymentInfo
     */
    BraintreePaymentInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreePaymentInfo)
            return object;
        let message = new $root.BraintreePaymentInfo();
        if (object.token != null)
            message.token = String(object.token);
        if (object.recurrentProducts) {
            if (!Array.isArray(object.recurrentProducts))
                throw TypeError(".BraintreePaymentInfo.recurrentProducts: array expected");
            message.recurrentProducts = [];
            for (let i = 0; i < object.recurrentProducts.length; ++i) {
                if (typeof object.recurrentProducts[i] !== "object")
                    throw TypeError(".BraintreePaymentInfo.recurrentProducts: object expected");
                message.recurrentProducts[i] = $root.BraintreeProduct.fromObject(object.recurrentProducts[i]);
            }
        }
        if (object.lifetimeProduct != null) {
            if (typeof object.lifetimeProduct !== "object")
                throw TypeError(".BraintreePaymentInfo.lifetimeProduct: object expected");
            message.lifetimeProduct = $root.BraintreeProduct.fromObject(object.lifetimeProduct);
        }
        if (object.vat != null)
            if ($util.Long)
                (message.vat = $util.Long.fromValue(object.vat)).unsigned = false;
            else if (typeof object.vat === "string")
                message.vat = parseInt(object.vat, 10);
            else if (typeof object.vat === "number")
                message.vat = object.vat;
            else if (typeof object.vat === "object")
                message.vat = new $util.LongBits(object.vat.low >>> 0, object.vat.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a BraintreePaymentInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreePaymentInfo
     * @static
     * @param {BraintreePaymentInfo} message BraintreePaymentInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreePaymentInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.recurrentProducts = [];
        if (options.defaults) {
            object.token = "";
            object.lifetimeProduct = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.vat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.vat = options.longs === String ? "0" : 0;
        }
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.recurrentProducts && message.recurrentProducts.length) {
            object.recurrentProducts = [];
            for (let j = 0; j < message.recurrentProducts.length; ++j)
                object.recurrentProducts[j] = $root.BraintreeProduct.toObject(message.recurrentProducts[j], options);
        }
        if (message.lifetimeProduct != null && message.hasOwnProperty("lifetimeProduct"))
            object.lifetimeProduct = $root.BraintreeProduct.toObject(message.lifetimeProduct, options);
        if (message.vat != null && message.hasOwnProperty("vat"))
            if (typeof message.vat === "number")
                object.vat = options.longs === String ? String(message.vat) : message.vat;
            else
                object.vat = options.longs === String ? $util.Long.prototype.toString.call(message.vat) : options.longs === Number ? new $util.LongBits(message.vat.low >>> 0, message.vat.high >>> 0).toNumber() : message.vat;
        return object;
    };

    /**
     * Converts this BraintreePaymentInfo to JSON.
     * @function toJSON
     * @memberof BraintreePaymentInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreePaymentInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreePaymentInfo;
})();

export const BraintreePaymentMethod = $root.BraintreePaymentMethod = (() => {

    /**
     * Properties of a BraintreePaymentMethod.
     * @exports IBraintreePaymentMethod
     * @interface IBraintreePaymentMethod
     * @property {Method} type BraintreePaymentMethod type
     * @property {CreditCardType|null} [creditCardType] BraintreePaymentMethod creditCardType
     * @property {string|null} [maskedNumber] BraintreePaymentMethod maskedNumber
     * @property {string|null} [expirationDate] BraintreePaymentMethod expirationDate
     * @property {boolean|null} [isExpired] BraintreePaymentMethod isExpired
     * @property {string|null} [payPalEmail] BraintreePaymentMethod payPalEmail
     * @property {string|null} [token] BraintreePaymentMethod token
     */

    /**
     * Constructs a new BraintreePaymentMethod.
     * @exports BraintreePaymentMethod
     * @classdesc Represents a BraintreePaymentMethod.
     * @implements IBraintreePaymentMethod
     * @constructor
     * @param {IBraintreePaymentMethod=} [properties] Properties to set
     */
    function BraintreePaymentMethod(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreePaymentMethod type.
     * @member {Method} type
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.type = 0;

    /**
     * BraintreePaymentMethod creditCardType.
     * @member {CreditCardType} creditCardType
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.creditCardType = 0;

    /**
     * BraintreePaymentMethod maskedNumber.
     * @member {string} maskedNumber
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.maskedNumber = "";

    /**
     * BraintreePaymentMethod expirationDate.
     * @member {string} expirationDate
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.expirationDate = "";

    /**
     * BraintreePaymentMethod isExpired.
     * @member {boolean} isExpired
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.isExpired = false;

    /**
     * BraintreePaymentMethod payPalEmail.
     * @member {string} payPalEmail
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.payPalEmail = "";

    /**
     * BraintreePaymentMethod token.
     * @member {string} token
     * @memberof BraintreePaymentMethod
     * @instance
     */
    BraintreePaymentMethod.prototype.token = "";

    /**
     * Creates a new BraintreePaymentMethod instance using the specified properties.
     * @function create
     * @memberof BraintreePaymentMethod
     * @static
     * @param {IBraintreePaymentMethod=} [properties] Properties to set
     * @returns {BraintreePaymentMethod} BraintreePaymentMethod instance
     */
    BraintreePaymentMethod.create = function create(properties) {
        return new BraintreePaymentMethod(properties);
    };

    /**
     * Encodes the specified BraintreePaymentMethod message. Does not implicitly {@link BraintreePaymentMethod.verify|verify} messages.
     * @function encode
     * @memberof BraintreePaymentMethod
     * @static
     * @param {IBraintreePaymentMethod} message BraintreePaymentMethod message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreePaymentMethod.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
        if (message.creditCardType != null && Object.hasOwnProperty.call(message, "creditCardType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.creditCardType);
        if (message.maskedNumber != null && Object.hasOwnProperty.call(message, "maskedNumber"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.maskedNumber);
        if (message.expirationDate != null && Object.hasOwnProperty.call(message, "expirationDate"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.expirationDate);
        if (message.isExpired != null && Object.hasOwnProperty.call(message, "isExpired"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isExpired);
        if (message.payPalEmail != null && Object.hasOwnProperty.call(message, "payPalEmail"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.payPalEmail);
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.token);
        return writer;
    };

    /**
     * Encodes the specified BraintreePaymentMethod message, length delimited. Does not implicitly {@link BraintreePaymentMethod.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreePaymentMethod
     * @static
     * @param {IBraintreePaymentMethod} message BraintreePaymentMethod message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreePaymentMethod.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreePaymentMethod message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreePaymentMethod
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreePaymentMethod} BraintreePaymentMethod
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreePaymentMethod.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreePaymentMethod();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.type = reader.int32();
                break;
            case 2:
                message.creditCardType = reader.int32();
                break;
            case 3:
                message.maskedNumber = reader.string();
                break;
            case 4:
                message.expirationDate = reader.string();
                break;
            case 5:
                message.isExpired = reader.bool();
                break;
            case 6:
                message.payPalEmail = reader.string();
                break;
            case 7:
                message.token = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("type"))
            throw $util.ProtocolError("missing required 'type'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreePaymentMethod message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreePaymentMethod
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreePaymentMethod} BraintreePaymentMethod
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreePaymentMethod.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreePaymentMethod message.
     * @function verify
     * @memberof BraintreePaymentMethod
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreePaymentMethod.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        switch (message.type) {
        default:
            return "type: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            break;
        }
        if (message.creditCardType != null && message.hasOwnProperty("creditCardType"))
            switch (message.creditCardType) {
            default:
                return "creditCardType: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
                break;
            }
        if (message.maskedNumber != null && message.hasOwnProperty("maskedNumber"))
            if (!$util.isString(message.maskedNumber))
                return "maskedNumber: string expected";
        if (message.expirationDate != null && message.hasOwnProperty("expirationDate"))
            if (!$util.isString(message.expirationDate))
                return "expirationDate: string expected";
        if (message.isExpired != null && message.hasOwnProperty("isExpired"))
            if (typeof message.isExpired !== "boolean")
                return "isExpired: boolean expected";
        if (message.payPalEmail != null && message.hasOwnProperty("payPalEmail"))
            if (!$util.isString(message.payPalEmail))
                return "payPalEmail: string expected";
        if (message.token != null && message.hasOwnProperty("token"))
            if (!$util.isString(message.token))
                return "token: string expected";
        return null;
    };

    /**
     * Creates a BraintreePaymentMethod message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreePaymentMethod
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreePaymentMethod} BraintreePaymentMethod
     */
    BraintreePaymentMethod.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreePaymentMethod)
            return object;
        let message = new $root.BraintreePaymentMethod();
        switch (object.type) {
        case "GOOGLE_PLAY":
        case 0:
            message.type = 0;
            break;
        case "APPLE_STORE":
        case 1:
            message.type = 1;
            break;
        case "PAYPAL":
        case 2:
            message.type = 2;
            break;
        case "PAYMILL":
        case 3:
            message.type = 3;
            break;
        case "CREDIT_CARD":
        case 4:
            message.type = 4;
            break;
        }
        switch (object.creditCardType) {
        case "VISA":
        case 0:
            message.creditCardType = 0;
            break;
        case "MASTER_CARD":
        case 1:
            message.creditCardType = 1;
            break;
        case "AMEX":
        case 2:
            message.creditCardType = 2;
            break;
        case "CARTE_BLANCHE":
        case 3:
            message.creditCardType = 3;
            break;
        case "CHINA_UNION_PAY":
        case 4:
            message.creditCardType = 4;
            break;
        case "DINERS_CLUB_INTERNATIONAL":
        case 5:
            message.creditCardType = 5;
            break;
        case "DISCOVER":
        case 6:
            message.creditCardType = 6;
            break;
        case "JCB":
        case 7:
            message.creditCardType = 7;
            break;
        case "LASER":
        case 8:
            message.creditCardType = 8;
            break;
        case "UK_MAESTRO":
        case 9:
            message.creditCardType = 9;
            break;
        case "MAESTRO":
        case 10:
            message.creditCardType = 10;
            break;
        case "SOLO":
        case 11:
            message.creditCardType = 11;
            break;
        case "SWITCH":
        case 12:
            message.creditCardType = 12;
            break;
        case "UNKNOWN":
        case 13:
            message.creditCardType = 13;
            break;
        }
        if (object.maskedNumber != null)
            message.maskedNumber = String(object.maskedNumber);
        if (object.expirationDate != null)
            message.expirationDate = String(object.expirationDate);
        if (object.isExpired != null)
            message.isExpired = Boolean(object.isExpired);
        if (object.payPalEmail != null)
            message.payPalEmail = String(object.payPalEmail);
        if (object.token != null)
            message.token = String(object.token);
        return message;
    };

    /**
     * Creates a plain object from a BraintreePaymentMethod message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreePaymentMethod
     * @static
     * @param {BraintreePaymentMethod} message BraintreePaymentMethod
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreePaymentMethod.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.type = options.enums === String ? "GOOGLE_PLAY" : 0;
            object.creditCardType = options.enums === String ? "VISA" : 0;
            object.maskedNumber = "";
            object.expirationDate = "";
            object.isExpired = false;
            object.payPalEmail = "";
            object.token = "";
        }
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = options.enums === String ? $root.Method[message.type] : message.type;
        if (message.creditCardType != null && message.hasOwnProperty("creditCardType"))
            object.creditCardType = options.enums === String ? $root.CreditCardType[message.creditCardType] : message.creditCardType;
        if (message.maskedNumber != null && message.hasOwnProperty("maskedNumber"))
            object.maskedNumber = message.maskedNumber;
        if (message.expirationDate != null && message.hasOwnProperty("expirationDate"))
            object.expirationDate = message.expirationDate;
        if (message.isExpired != null && message.hasOwnProperty("isExpired"))
            object.isExpired = message.isExpired;
        if (message.payPalEmail != null && message.hasOwnProperty("payPalEmail"))
            object.payPalEmail = message.payPalEmail;
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        return object;
    };

    /**
     * Converts this BraintreePaymentMethod to JSON.
     * @function toJSON
     * @memberof BraintreePaymentMethod
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreePaymentMethod.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreePaymentMethod;
})();

export const BraintreeNonce = $root.BraintreeNonce = (() => {

    /**
     * Properties of a BraintreeNonce.
     * @exports IBraintreeNonce
     * @interface IBraintreeNonce
     * @property {string} nonce BraintreeNonce nonce
     */

    /**
     * Constructs a new BraintreeNonce.
     * @exports BraintreeNonce
     * @classdesc Represents a BraintreeNonce.
     * @implements IBraintreeNonce
     * @constructor
     * @param {IBraintreeNonce=} [properties] Properties to set
     */
    function BraintreeNonce(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeNonce nonce.
     * @member {string} nonce
     * @memberof BraintreeNonce
     * @instance
     */
    BraintreeNonce.prototype.nonce = "";

    /**
     * Creates a new BraintreeNonce instance using the specified properties.
     * @function create
     * @memberof BraintreeNonce
     * @static
     * @param {IBraintreeNonce=} [properties] Properties to set
     * @returns {BraintreeNonce} BraintreeNonce instance
     */
    BraintreeNonce.create = function create(properties) {
        return new BraintreeNonce(properties);
    };

    /**
     * Encodes the specified BraintreeNonce message. Does not implicitly {@link BraintreeNonce.verify|verify} messages.
     * @function encode
     * @memberof BraintreeNonce
     * @static
     * @param {IBraintreeNonce} message BraintreeNonce message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeNonce.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.nonce);
        return writer;
    };

    /**
     * Encodes the specified BraintreeNonce message, length delimited. Does not implicitly {@link BraintreeNonce.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeNonce
     * @static
     * @param {IBraintreeNonce} message BraintreeNonce message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeNonce.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeNonce message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeNonce
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeNonce} BraintreeNonce
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeNonce.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeNonce();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.nonce = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("nonce"))
            throw $util.ProtocolError("missing required 'nonce'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreeNonce message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeNonce
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeNonce} BraintreeNonce
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeNonce.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeNonce message.
     * @function verify
     * @memberof BraintreeNonce
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeNonce.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.nonce))
            return "nonce: string expected";
        return null;
    };

    /**
     * Creates a BraintreeNonce message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeNonce
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeNonce} BraintreeNonce
     */
    BraintreeNonce.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeNonce)
            return object;
        let message = new $root.BraintreeNonce();
        if (object.nonce != null)
            message.nonce = String(object.nonce);
        return message;
    };

    /**
     * Creates a plain object from a BraintreeNonce message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeNonce
     * @static
     * @param {BraintreeNonce} message BraintreeNonce
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeNonce.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.nonce = "";
        if (message.nonce != null && message.hasOwnProperty("nonce"))
            object.nonce = message.nonce;
        return object;
    };

    /**
     * Converts this BraintreeNonce to JSON.
     * @function toJSON
     * @memberof BraintreeNonce
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeNonce.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeNonce;
})();

export const BraintreeBillingAddress = $root.BraintreeBillingAddress = (() => {

    /**
     * Properties of a BraintreeBillingAddress.
     * @exports IBraintreeBillingAddress
     * @interface IBraintreeBillingAddress
     * @property {string|null} [id] BraintreeBillingAddress id
     * @property {string|null} [company] BraintreeBillingAddress company
     * @property {string|null} [countryCodeAlpha2] BraintreeBillingAddress countryCodeAlpha2
     * @property {string|null} [streetAddress] BraintreeBillingAddress streetAddress
     * @property {string|null} [extendedAddress] BraintreeBillingAddress extendedAddress
     * @property {string|null} [city] BraintreeBillingAddress city
     * @property {string|null} [postalCode] BraintreeBillingAddress postalCode
     * @property {string|null} [vatNumber] BraintreeBillingAddress vatNumber
     * @property {string|null} [businessId] BraintreeBillingAddress businessId
     */

    /**
     * Constructs a new BraintreeBillingAddress.
     * @exports BraintreeBillingAddress
     * @classdesc Represents a BraintreeBillingAddress.
     * @implements IBraintreeBillingAddress
     * @constructor
     * @param {IBraintreeBillingAddress=} [properties] Properties to set
     */
    function BraintreeBillingAddress(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeBillingAddress id.
     * @member {string} id
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.id = "";

    /**
     * BraintreeBillingAddress company.
     * @member {string} company
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.company = "";

    /**
     * BraintreeBillingAddress countryCodeAlpha2.
     * @member {string} countryCodeAlpha2
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.countryCodeAlpha2 = "";

    /**
     * BraintreeBillingAddress streetAddress.
     * @member {string} streetAddress
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.streetAddress = "";

    /**
     * BraintreeBillingAddress extendedAddress.
     * @member {string} extendedAddress
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.extendedAddress = "";

    /**
     * BraintreeBillingAddress city.
     * @member {string} city
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.city = "";

    /**
     * BraintreeBillingAddress postalCode.
     * @member {string} postalCode
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.postalCode = "";

    /**
     * BraintreeBillingAddress vatNumber.
     * @member {string} vatNumber
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.vatNumber = "";

    /**
     * BraintreeBillingAddress businessId.
     * @member {string} businessId
     * @memberof BraintreeBillingAddress
     * @instance
     */
    BraintreeBillingAddress.prototype.businessId = "";

    /**
     * Creates a new BraintreeBillingAddress instance using the specified properties.
     * @function create
     * @memberof BraintreeBillingAddress
     * @static
     * @param {IBraintreeBillingAddress=} [properties] Properties to set
     * @returns {BraintreeBillingAddress} BraintreeBillingAddress instance
     */
    BraintreeBillingAddress.create = function create(properties) {
        return new BraintreeBillingAddress(properties);
    };

    /**
     * Encodes the specified BraintreeBillingAddress message. Does not implicitly {@link BraintreeBillingAddress.verify|verify} messages.
     * @function encode
     * @memberof BraintreeBillingAddress
     * @static
     * @param {IBraintreeBillingAddress} message BraintreeBillingAddress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeBillingAddress.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        if (message.company != null && Object.hasOwnProperty.call(message, "company"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.company);
        if (message.countryCodeAlpha2 != null && Object.hasOwnProperty.call(message, "countryCodeAlpha2"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.countryCodeAlpha2);
        if (message.streetAddress != null && Object.hasOwnProperty.call(message, "streetAddress"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.streetAddress);
        if (message.extendedAddress != null && Object.hasOwnProperty.call(message, "extendedAddress"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.extendedAddress);
        if (message.city != null && Object.hasOwnProperty.call(message, "city"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.city);
        if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.postalCode);
        if (message.vatNumber != null && Object.hasOwnProperty.call(message, "vatNumber"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.vatNumber);
        if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.businessId);
        return writer;
    };

    /**
     * Encodes the specified BraintreeBillingAddress message, length delimited. Does not implicitly {@link BraintreeBillingAddress.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeBillingAddress
     * @static
     * @param {IBraintreeBillingAddress} message BraintreeBillingAddress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeBillingAddress.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeBillingAddress message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeBillingAddress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeBillingAddress} BraintreeBillingAddress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeBillingAddress.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeBillingAddress();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.company = reader.string();
                break;
            case 3:
                message.countryCodeAlpha2 = reader.string();
                break;
            case 4:
                message.streetAddress = reader.string();
                break;
            case 5:
                message.extendedAddress = reader.string();
                break;
            case 6:
                message.city = reader.string();
                break;
            case 7:
                message.postalCode = reader.string();
                break;
            case 8:
                message.vatNumber = reader.string();
                break;
            case 9:
                message.businessId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BraintreeBillingAddress message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeBillingAddress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeBillingAddress} BraintreeBillingAddress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeBillingAddress.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeBillingAddress message.
     * @function verify
     * @memberof BraintreeBillingAddress
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeBillingAddress.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
            if (!$util.isString(message.id))
                return "id: string expected";
        if (message.company != null && message.hasOwnProperty("company"))
            if (!$util.isString(message.company))
                return "company: string expected";
        if (message.countryCodeAlpha2 != null && message.hasOwnProperty("countryCodeAlpha2"))
            if (!$util.isString(message.countryCodeAlpha2))
                return "countryCodeAlpha2: string expected";
        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
            if (!$util.isString(message.streetAddress))
                return "streetAddress: string expected";
        if (message.extendedAddress != null && message.hasOwnProperty("extendedAddress"))
            if (!$util.isString(message.extendedAddress))
                return "extendedAddress: string expected";
        if (message.city != null && message.hasOwnProperty("city"))
            if (!$util.isString(message.city))
                return "city: string expected";
        if (message.postalCode != null && message.hasOwnProperty("postalCode"))
            if (!$util.isString(message.postalCode))
                return "postalCode: string expected";
        if (message.vatNumber != null && message.hasOwnProperty("vatNumber"))
            if (!$util.isString(message.vatNumber))
                return "vatNumber: string expected";
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            if (!$util.isString(message.businessId))
                return "businessId: string expected";
        return null;
    };

    /**
     * Creates a BraintreeBillingAddress message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeBillingAddress
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeBillingAddress} BraintreeBillingAddress
     */
    BraintreeBillingAddress.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeBillingAddress)
            return object;
        let message = new $root.BraintreeBillingAddress();
        if (object.id != null)
            message.id = String(object.id);
        if (object.company != null)
            message.company = String(object.company);
        if (object.countryCodeAlpha2 != null)
            message.countryCodeAlpha2 = String(object.countryCodeAlpha2);
        if (object.streetAddress != null)
            message.streetAddress = String(object.streetAddress);
        if (object.extendedAddress != null)
            message.extendedAddress = String(object.extendedAddress);
        if (object.city != null)
            message.city = String(object.city);
        if (object.postalCode != null)
            message.postalCode = String(object.postalCode);
        if (object.vatNumber != null)
            message.vatNumber = String(object.vatNumber);
        if (object.businessId != null)
            message.businessId = String(object.businessId);
        return message;
    };

    /**
     * Creates a plain object from a BraintreeBillingAddress message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeBillingAddress
     * @static
     * @param {BraintreeBillingAddress} message BraintreeBillingAddress
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeBillingAddress.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.company = "";
            object.countryCodeAlpha2 = "";
            object.streetAddress = "";
            object.extendedAddress = "";
            object.city = "";
            object.postalCode = "";
            object.vatNumber = "";
            object.businessId = "";
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.company != null && message.hasOwnProperty("company"))
            object.company = message.company;
        if (message.countryCodeAlpha2 != null && message.hasOwnProperty("countryCodeAlpha2"))
            object.countryCodeAlpha2 = message.countryCodeAlpha2;
        if (message.streetAddress != null && message.hasOwnProperty("streetAddress"))
            object.streetAddress = message.streetAddress;
        if (message.extendedAddress != null && message.hasOwnProperty("extendedAddress"))
            object.extendedAddress = message.extendedAddress;
        if (message.city != null && message.hasOwnProperty("city"))
            object.city = message.city;
        if (message.postalCode != null && message.hasOwnProperty("postalCode"))
            object.postalCode = message.postalCode;
        if (message.vatNumber != null && message.hasOwnProperty("vatNumber"))
            object.vatNumber = message.vatNumber;
        if (message.businessId != null && message.hasOwnProperty("businessId"))
            object.businessId = message.businessId;
        return object;
    };

    /**
     * Converts this BraintreeBillingAddress to JSON.
     * @function toJSON
     * @memberof BraintreeBillingAddress
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeBillingAddress.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeBillingAddress;
})();

/**
 * BraintreeTransactionStatus enum.
 * @exports BraintreeTransactionStatus
 * @enum {number}
 * @property {number} AUTHORIZATION_EXPIRED=1 AUTHORIZATION_EXPIRED value
 * @property {number} AUTHORIZED=2 AUTHORIZED value
 * @property {number} AUTHORIZING=3 AUTHORIZING value
 * @property {number} FAILED=4 FAILED value
 * @property {number} GATEWAY_REJECTED=5 GATEWAY_REJECTED value
 * @property {number} PROCESSOR_DECLINED=6 PROCESSOR_DECLINED value
 * @property {number} SETTLED=7 SETTLED value
 * @property {number} SETTLEMENT_CONFIRMED=8 SETTLEMENT_CONFIRMED value
 * @property {number} SETTLEMENT_DECLINED=9 SETTLEMENT_DECLINED value
 * @property {number} SETTLEMENT_PENDING=10 SETTLEMENT_PENDING value
 * @property {number} SETTLING=11 SETTLING value
 * @property {number} SUBMITTED_FOR_SETTLEMENT=12 SUBMITTED_FOR_SETTLEMENT value
 * @property {number} UNRECOGNIZED=13 UNRECOGNIZED value
 * @property {number} VOIDED=14 VOIDED value
 */
export const BraintreeTransactionStatus = $root.BraintreeTransactionStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "AUTHORIZATION_EXPIRED"] = 1;
    values[valuesById[2] = "AUTHORIZED"] = 2;
    values[valuesById[3] = "AUTHORIZING"] = 3;
    values[valuesById[4] = "FAILED"] = 4;
    values[valuesById[5] = "GATEWAY_REJECTED"] = 5;
    values[valuesById[6] = "PROCESSOR_DECLINED"] = 6;
    values[valuesById[7] = "SETTLED"] = 7;
    values[valuesById[8] = "SETTLEMENT_CONFIRMED"] = 8;
    values[valuesById[9] = "SETTLEMENT_DECLINED"] = 9;
    values[valuesById[10] = "SETTLEMENT_PENDING"] = 10;
    values[valuesById[11] = "SETTLING"] = 11;
    values[valuesById[12] = "SUBMITTED_FOR_SETTLEMENT"] = 12;
    values[valuesById[13] = "UNRECOGNIZED"] = 13;
    values[valuesById[14] = "VOIDED"] = 14;
    return values;
})();

export const BraintreeTransaction = $root.BraintreeTransaction = (() => {

    /**
     * Properties of a BraintreeTransaction.
     * @exports IBraintreeTransaction
     * @interface IBraintreeTransaction
     * @property {string} id BraintreeTransaction id
     * @property {number} amount BraintreeTransaction amount
     * @property {IBraintreeBillingAddress|null} [billingAddress] BraintreeTransaction billingAddress
     * @property {number|Long} createdAt BraintreeTransaction createdAt
     * @property {string} currencyCode BraintreeTransaction currencyCode
     * @property {string|null} [gatewayRejectionReason] BraintreeTransaction gatewayRejectionReason
     * @property {string|null} [planId] BraintreeTransaction planId
     * @property {boolean|null} [recurring] BraintreeTransaction recurring
     * @property {BraintreeTransactionStatus} status BraintreeTransaction status
     * @property {number|null} [taxAmount] BraintreeTransaction taxAmount
     * @property {string|null} [vatNumber] BraintreeTransaction vatNumber
     * @property {IBraintreePaymentMethod} paymentMethod BraintreeTransaction paymentMethod
     * @property {number|Long|null} [billingStartDate] BraintreeTransaction billingStartDate
     * @property {number|Long|null} [billingEndDate] BraintreeTransaction billingEndDate
     */

    /**
     * Constructs a new BraintreeTransaction.
     * @exports BraintreeTransaction
     * @classdesc Represents a BraintreeTransaction.
     * @implements IBraintreeTransaction
     * @constructor
     * @param {IBraintreeTransaction=} [properties] Properties to set
     */
    function BraintreeTransaction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeTransaction id.
     * @member {string} id
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.id = "";

    /**
     * BraintreeTransaction amount.
     * @member {number} amount
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.amount = 0;

    /**
     * BraintreeTransaction billingAddress.
     * @member {IBraintreeBillingAddress|null|undefined} billingAddress
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.billingAddress = null;

    /**
     * BraintreeTransaction createdAt.
     * @member {number|Long} createdAt
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeTransaction currencyCode.
     * @member {string} currencyCode
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.currencyCode = "";

    /**
     * BraintreeTransaction gatewayRejectionReason.
     * @member {string} gatewayRejectionReason
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.gatewayRejectionReason = "";

    /**
     * BraintreeTransaction planId.
     * @member {string} planId
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.planId = "";

    /**
     * BraintreeTransaction recurring.
     * @member {boolean} recurring
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.recurring = false;

    /**
     * BraintreeTransaction status.
     * @member {BraintreeTransactionStatus} status
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.status = 1;

    /**
     * BraintreeTransaction taxAmount.
     * @member {number} taxAmount
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.taxAmount = 0;

    /**
     * BraintreeTransaction vatNumber.
     * @member {string} vatNumber
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.vatNumber = "";

    /**
     * BraintreeTransaction paymentMethod.
     * @member {IBraintreePaymentMethod} paymentMethod
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.paymentMethod = null;

    /**
     * BraintreeTransaction billingStartDate.
     * @member {number|Long} billingStartDate
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.billingStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeTransaction billingEndDate.
     * @member {number|Long} billingEndDate
     * @memberof BraintreeTransaction
     * @instance
     */
    BraintreeTransaction.prototype.billingEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new BraintreeTransaction instance using the specified properties.
     * @function create
     * @memberof BraintreeTransaction
     * @static
     * @param {IBraintreeTransaction=} [properties] Properties to set
     * @returns {BraintreeTransaction} BraintreeTransaction instance
     */
    BraintreeTransaction.create = function create(properties) {
        return new BraintreeTransaction(properties);
    };

    /**
     * Encodes the specified BraintreeTransaction message. Does not implicitly {@link BraintreeTransaction.verify|verify} messages.
     * @function encode
     * @memberof BraintreeTransaction
     * @static
     * @param {IBraintreeTransaction} message BraintreeTransaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeTransaction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 1 =*/17).double(message.amount);
        if (message.billingAddress != null && Object.hasOwnProperty.call(message, "billingAddress"))
            $root.BraintreeBillingAddress.encode(message.billingAddress, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createdAt);
        writer.uint32(/* id 5, wireType 2 =*/42).string(message.currencyCode);
        if (message.gatewayRejectionReason != null && Object.hasOwnProperty.call(message, "gatewayRejectionReason"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.gatewayRejectionReason);
        if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.planId);
        if (message.recurring != null && Object.hasOwnProperty.call(message, "recurring"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.recurring);
        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.status);
        if (message.taxAmount != null && Object.hasOwnProperty.call(message, "taxAmount"))
            writer.uint32(/* id 11, wireType 1 =*/89).double(message.taxAmount);
        if (message.vatNumber != null && Object.hasOwnProperty.call(message, "vatNumber"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.vatNumber);
        $root.BraintreePaymentMethod.encode(message.paymentMethod, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.billingStartDate != null && Object.hasOwnProperty.call(message, "billingStartDate"))
            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.billingStartDate);
        if (message.billingEndDate != null && Object.hasOwnProperty.call(message, "billingEndDate"))
            writer.uint32(/* id 15, wireType 0 =*/120).int64(message.billingEndDate);
        return writer;
    };

    /**
     * Encodes the specified BraintreeTransaction message, length delimited. Does not implicitly {@link BraintreeTransaction.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeTransaction
     * @static
     * @param {IBraintreeTransaction} message BraintreeTransaction message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeTransaction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeTransaction message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeTransaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeTransaction} BraintreeTransaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeTransaction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeTransaction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.amount = reader.double();
                break;
            case 3:
                message.billingAddress = $root.BraintreeBillingAddress.decode(reader, reader.uint32());
                break;
            case 4:
                message.createdAt = reader.int64();
                break;
            case 5:
                message.currencyCode = reader.string();
                break;
            case 6:
                message.gatewayRejectionReason = reader.string();
                break;
            case 7:
                message.planId = reader.string();
                break;
            case 8:
                message.recurring = reader.bool();
                break;
            case 9:
                message.status = reader.int32();
                break;
            case 11:
                message.taxAmount = reader.double();
                break;
            case 12:
                message.vatNumber = reader.string();
                break;
            case 13:
                message.paymentMethod = $root.BraintreePaymentMethod.decode(reader, reader.uint32());
                break;
            case 14:
                message.billingStartDate = reader.int64();
                break;
            case 15:
                message.billingEndDate = reader.int64();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("amount"))
            throw $util.ProtocolError("missing required 'amount'", { instance: message });
        if (!message.hasOwnProperty("createdAt"))
            throw $util.ProtocolError("missing required 'createdAt'", { instance: message });
        if (!message.hasOwnProperty("currencyCode"))
            throw $util.ProtocolError("missing required 'currencyCode'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        if (!message.hasOwnProperty("paymentMethod"))
            throw $util.ProtocolError("missing required 'paymentMethod'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreeTransaction message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeTransaction
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeTransaction} BraintreeTransaction
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeTransaction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeTransaction message.
     * @function verify
     * @memberof BraintreeTransaction
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeTransaction.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        if (typeof message.amount !== "number")
            return "amount: number expected";
        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
            let error = $root.BraintreeBillingAddress.verify(message.billingAddress);
            if (error)
                return "billingAddress." + error;
        }
        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
            return "createdAt: integer|Long expected";
        if (!$util.isString(message.currencyCode))
            return "currencyCode: string expected";
        if (message.gatewayRejectionReason != null && message.hasOwnProperty("gatewayRejectionReason"))
            if (!$util.isString(message.gatewayRejectionReason))
                return "gatewayRejectionReason: string expected";
        if (message.planId != null && message.hasOwnProperty("planId"))
            if (!$util.isString(message.planId))
                return "planId: string expected";
        if (message.recurring != null && message.hasOwnProperty("recurring"))
            if (typeof message.recurring !== "boolean")
                return "recurring: boolean expected";
        switch (message.status) {
        default:
            return "status: enum value expected";
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
            break;
        }
        if (message.taxAmount != null && message.hasOwnProperty("taxAmount"))
            if (typeof message.taxAmount !== "number")
                return "taxAmount: number expected";
        if (message.vatNumber != null && message.hasOwnProperty("vatNumber"))
            if (!$util.isString(message.vatNumber))
                return "vatNumber: string expected";
        {
            let error = $root.BraintreePaymentMethod.verify(message.paymentMethod);
            if (error)
                return "paymentMethod." + error;
        }
        if (message.billingStartDate != null && message.hasOwnProperty("billingStartDate"))
            if (!$util.isInteger(message.billingStartDate) && !(message.billingStartDate && $util.isInteger(message.billingStartDate.low) && $util.isInteger(message.billingStartDate.high)))
                return "billingStartDate: integer|Long expected";
        if (message.billingEndDate != null && message.hasOwnProperty("billingEndDate"))
            if (!$util.isInteger(message.billingEndDate) && !(message.billingEndDate && $util.isInteger(message.billingEndDate.low) && $util.isInteger(message.billingEndDate.high)))
                return "billingEndDate: integer|Long expected";
        return null;
    };

    /**
     * Creates a BraintreeTransaction message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeTransaction
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeTransaction} BraintreeTransaction
     */
    BraintreeTransaction.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeTransaction)
            return object;
        let message = new $root.BraintreeTransaction();
        if (object.id != null)
            message.id = String(object.id);
        if (object.amount != null)
            message.amount = Number(object.amount);
        if (object.billingAddress != null) {
            if (typeof object.billingAddress !== "object")
                throw TypeError(".BraintreeTransaction.billingAddress: object expected");
            message.billingAddress = $root.BraintreeBillingAddress.fromObject(object.billingAddress);
        }
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        if (object.gatewayRejectionReason != null)
            message.gatewayRejectionReason = String(object.gatewayRejectionReason);
        if (object.planId != null)
            message.planId = String(object.planId);
        if (object.recurring != null)
            message.recurring = Boolean(object.recurring);
        switch (object.status) {
        case "AUTHORIZATION_EXPIRED":
        case 1:
            message.status = 1;
            break;
        case "AUTHORIZED":
        case 2:
            message.status = 2;
            break;
        case "AUTHORIZING":
        case 3:
            message.status = 3;
            break;
        case "FAILED":
        case 4:
            message.status = 4;
            break;
        case "GATEWAY_REJECTED":
        case 5:
            message.status = 5;
            break;
        case "PROCESSOR_DECLINED":
        case 6:
            message.status = 6;
            break;
        case "SETTLED":
        case 7:
            message.status = 7;
            break;
        case "SETTLEMENT_CONFIRMED":
        case 8:
            message.status = 8;
            break;
        case "SETTLEMENT_DECLINED":
        case 9:
            message.status = 9;
            break;
        case "SETTLEMENT_PENDING":
        case 10:
            message.status = 10;
            break;
        case "SETTLING":
        case 11:
            message.status = 11;
            break;
        case "SUBMITTED_FOR_SETTLEMENT":
        case 12:
            message.status = 12;
            break;
        case "UNRECOGNIZED":
        case 13:
            message.status = 13;
            break;
        case "VOIDED":
        case 14:
            message.status = 14;
            break;
        }
        if (object.taxAmount != null)
            message.taxAmount = Number(object.taxAmount);
        if (object.vatNumber != null)
            message.vatNumber = String(object.vatNumber);
        if (object.paymentMethod != null) {
            if (typeof object.paymentMethod !== "object")
                throw TypeError(".BraintreeTransaction.paymentMethod: object expected");
            message.paymentMethod = $root.BraintreePaymentMethod.fromObject(object.paymentMethod);
        }
        if (object.billingStartDate != null)
            if ($util.Long)
                (message.billingStartDate = $util.Long.fromValue(object.billingStartDate)).unsigned = false;
            else if (typeof object.billingStartDate === "string")
                message.billingStartDate = parseInt(object.billingStartDate, 10);
            else if (typeof object.billingStartDate === "number")
                message.billingStartDate = object.billingStartDate;
            else if (typeof object.billingStartDate === "object")
                message.billingStartDate = new $util.LongBits(object.billingStartDate.low >>> 0, object.billingStartDate.high >>> 0).toNumber();
        if (object.billingEndDate != null)
            if ($util.Long)
                (message.billingEndDate = $util.Long.fromValue(object.billingEndDate)).unsigned = false;
            else if (typeof object.billingEndDate === "string")
                message.billingEndDate = parseInt(object.billingEndDate, 10);
            else if (typeof object.billingEndDate === "number")
                message.billingEndDate = object.billingEndDate;
            else if (typeof object.billingEndDate === "object")
                message.billingEndDate = new $util.LongBits(object.billingEndDate.low >>> 0, object.billingEndDate.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a BraintreeTransaction message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeTransaction
     * @static
     * @param {BraintreeTransaction} message BraintreeTransaction
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeTransaction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.amount = 0;
            object.billingAddress = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            object.currencyCode = "";
            object.gatewayRejectionReason = "";
            object.planId = "";
            object.recurring = false;
            object.status = options.enums === String ? "AUTHORIZATION_EXPIRED" : 1;
            object.taxAmount = 0;
            object.vatNumber = "";
            object.paymentMethod = null;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.billingStartDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.billingStartDate = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.billingEndDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.billingEndDate = options.longs === String ? "0" : 0;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.amount != null && message.hasOwnProperty("amount"))
            object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
            object.billingAddress = $root.BraintreeBillingAddress.toObject(message.billingAddress, options);
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        if (message.gatewayRejectionReason != null && message.hasOwnProperty("gatewayRejectionReason"))
            object.gatewayRejectionReason = message.gatewayRejectionReason;
        if (message.planId != null && message.hasOwnProperty("planId"))
            object.planId = message.planId;
        if (message.recurring != null && message.hasOwnProperty("recurring"))
            object.recurring = message.recurring;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.BraintreeTransactionStatus[message.status] : message.status;
        if (message.taxAmount != null && message.hasOwnProperty("taxAmount"))
            object.taxAmount = options.json && !isFinite(message.taxAmount) ? String(message.taxAmount) : message.taxAmount;
        if (message.vatNumber != null && message.hasOwnProperty("vatNumber"))
            object.vatNumber = message.vatNumber;
        if (message.paymentMethod != null && message.hasOwnProperty("paymentMethod"))
            object.paymentMethod = $root.BraintreePaymentMethod.toObject(message.paymentMethod, options);
        if (message.billingStartDate != null && message.hasOwnProperty("billingStartDate"))
            if (typeof message.billingStartDate === "number")
                object.billingStartDate = options.longs === String ? String(message.billingStartDate) : message.billingStartDate;
            else
                object.billingStartDate = options.longs === String ? $util.Long.prototype.toString.call(message.billingStartDate) : options.longs === Number ? new $util.LongBits(message.billingStartDate.low >>> 0, message.billingStartDate.high >>> 0).toNumber() : message.billingStartDate;
        if (message.billingEndDate != null && message.hasOwnProperty("billingEndDate"))
            if (typeof message.billingEndDate === "number")
                object.billingEndDate = options.longs === String ? String(message.billingEndDate) : message.billingEndDate;
            else
                object.billingEndDate = options.longs === String ? $util.Long.prototype.toString.call(message.billingEndDate) : options.longs === Number ? new $util.LongBits(message.billingEndDate.low >>> 0, message.billingEndDate.high >>> 0).toNumber() : message.billingEndDate;
        return object;
    };

    /**
     * Converts this BraintreeTransaction to JSON.
     * @function toJSON
     * @memberof BraintreeTransaction
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeTransaction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeTransaction;
})();

export const BraintreeTransactionList = $root.BraintreeTransactionList = (() => {

    /**
     * Properties of a BraintreeTransactionList.
     * @exports IBraintreeTransactionList
     * @interface IBraintreeTransactionList
     * @property {Array.<IBraintreeTransaction>|null} [transactions] BraintreeTransactionList transactions
     */

    /**
     * Constructs a new BraintreeTransactionList.
     * @exports BraintreeTransactionList
     * @classdesc Represents a BraintreeTransactionList.
     * @implements IBraintreeTransactionList
     * @constructor
     * @param {IBraintreeTransactionList=} [properties] Properties to set
     */
    function BraintreeTransactionList(properties) {
        this.transactions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeTransactionList transactions.
     * @member {Array.<IBraintreeTransaction>} transactions
     * @memberof BraintreeTransactionList
     * @instance
     */
    BraintreeTransactionList.prototype.transactions = $util.emptyArray;

    /**
     * Creates a new BraintreeTransactionList instance using the specified properties.
     * @function create
     * @memberof BraintreeTransactionList
     * @static
     * @param {IBraintreeTransactionList=} [properties] Properties to set
     * @returns {BraintreeTransactionList} BraintreeTransactionList instance
     */
    BraintreeTransactionList.create = function create(properties) {
        return new BraintreeTransactionList(properties);
    };

    /**
     * Encodes the specified BraintreeTransactionList message. Does not implicitly {@link BraintreeTransactionList.verify|verify} messages.
     * @function encode
     * @memberof BraintreeTransactionList
     * @static
     * @param {IBraintreeTransactionList} message BraintreeTransactionList message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeTransactionList.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.transactions != null && message.transactions.length)
            for (let i = 0; i < message.transactions.length; ++i)
                $root.BraintreeTransaction.encode(message.transactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BraintreeTransactionList message, length delimited. Does not implicitly {@link BraintreeTransactionList.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeTransactionList
     * @static
     * @param {IBraintreeTransactionList} message BraintreeTransactionList message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeTransactionList.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeTransactionList message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeTransactionList
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeTransactionList} BraintreeTransactionList
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeTransactionList.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeTransactionList();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                if (!(message.transactions && message.transactions.length))
                    message.transactions = [];
                message.transactions.push($root.BraintreeTransaction.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BraintreeTransactionList message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeTransactionList
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeTransactionList} BraintreeTransactionList
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeTransactionList.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeTransactionList message.
     * @function verify
     * @memberof BraintreeTransactionList
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeTransactionList.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.transactions != null && message.hasOwnProperty("transactions")) {
            if (!Array.isArray(message.transactions))
                return "transactions: array expected";
            for (let i = 0; i < message.transactions.length; ++i) {
                let error = $root.BraintreeTransaction.verify(message.transactions[i]);
                if (error)
                    return "transactions." + error;
            }
        }
        return null;
    };

    /**
     * Creates a BraintreeTransactionList message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeTransactionList
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeTransactionList} BraintreeTransactionList
     */
    BraintreeTransactionList.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeTransactionList)
            return object;
        let message = new $root.BraintreeTransactionList();
        if (object.transactions) {
            if (!Array.isArray(object.transactions))
                throw TypeError(".BraintreeTransactionList.transactions: array expected");
            message.transactions = [];
            for (let i = 0; i < object.transactions.length; ++i) {
                if (typeof object.transactions[i] !== "object")
                    throw TypeError(".BraintreeTransactionList.transactions: object expected");
                message.transactions[i] = $root.BraintreeTransaction.fromObject(object.transactions[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a BraintreeTransactionList message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeTransactionList
     * @static
     * @param {BraintreeTransactionList} message BraintreeTransactionList
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeTransactionList.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.transactions = [];
        if (message.transactions && message.transactions.length) {
            object.transactions = [];
            for (let j = 0; j < message.transactions.length; ++j)
                object.transactions[j] = $root.BraintreeTransaction.toObject(message.transactions[j], options);
        }
        return object;
    };

    /**
     * Converts this BraintreeTransactionList to JSON.
     * @function toJSON
     * @memberof BraintreeTransactionList
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeTransactionList.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeTransactionList;
})();

export const BraintreeValidationError = $root.BraintreeValidationError = (() => {

    /**
     * Properties of a BraintreeValidationError.
     * @exports IBraintreeValidationError
     * @interface IBraintreeValidationError
     * @property {string} attribute BraintreeValidationError attribute
     * @property {string} code BraintreeValidationError code
     * @property {string} message BraintreeValidationError message
     */

    /**
     * Constructs a new BraintreeValidationError.
     * @exports BraintreeValidationError
     * @classdesc Represents a BraintreeValidationError.
     * @implements IBraintreeValidationError
     * @constructor
     * @param {IBraintreeValidationError=} [properties] Properties to set
     */
    function BraintreeValidationError(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeValidationError attribute.
     * @member {string} attribute
     * @memberof BraintreeValidationError
     * @instance
     */
    BraintreeValidationError.prototype.attribute = "";

    /**
     * BraintreeValidationError code.
     * @member {string} code
     * @memberof BraintreeValidationError
     * @instance
     */
    BraintreeValidationError.prototype.code = "";

    /**
     * BraintreeValidationError message.
     * @member {string} message
     * @memberof BraintreeValidationError
     * @instance
     */
    BraintreeValidationError.prototype.message = "";

    /**
     * Creates a new BraintreeValidationError instance using the specified properties.
     * @function create
     * @memberof BraintreeValidationError
     * @static
     * @param {IBraintreeValidationError=} [properties] Properties to set
     * @returns {BraintreeValidationError} BraintreeValidationError instance
     */
    BraintreeValidationError.create = function create(properties) {
        return new BraintreeValidationError(properties);
    };

    /**
     * Encodes the specified BraintreeValidationError message. Does not implicitly {@link BraintreeValidationError.verify|verify} messages.
     * @function encode
     * @memberof BraintreeValidationError
     * @static
     * @param {IBraintreeValidationError} message BraintreeValidationError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeValidationError.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.attribute);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.message);
        return writer;
    };

    /**
     * Encodes the specified BraintreeValidationError message, length delimited. Does not implicitly {@link BraintreeValidationError.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeValidationError
     * @static
     * @param {IBraintreeValidationError} message BraintreeValidationError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeValidationError.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeValidationError message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeValidationError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeValidationError} BraintreeValidationError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeValidationError.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeValidationError();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.attribute = reader.string();
                break;
            case 2:
                message.code = reader.string();
                break;
            case 3:
                message.message = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("attribute"))
            throw $util.ProtocolError("missing required 'attribute'", { instance: message });
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        if (!message.hasOwnProperty("message"))
            throw $util.ProtocolError("missing required 'message'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreeValidationError message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeValidationError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeValidationError} BraintreeValidationError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeValidationError.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeValidationError message.
     * @function verify
     * @memberof BraintreeValidationError
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeValidationError.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.attribute))
            return "attribute: string expected";
        if (!$util.isString(message.code))
            return "code: string expected";
        if (!$util.isString(message.message))
            return "message: string expected";
        return null;
    };

    /**
     * Creates a BraintreeValidationError message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeValidationError
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeValidationError} BraintreeValidationError
     */
    BraintreeValidationError.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeValidationError)
            return object;
        let message = new $root.BraintreeValidationError();
        if (object.attribute != null)
            message.attribute = String(object.attribute);
        if (object.code != null)
            message.code = String(object.code);
        if (object.message != null)
            message.message = String(object.message);
        return message;
    };

    /**
     * Creates a plain object from a BraintreeValidationError message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeValidationError
     * @static
     * @param {BraintreeValidationError} message BraintreeValidationError
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeValidationError.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.attribute = "";
            object.code = "";
            object.message = "";
        }
        if (message.attribute != null && message.hasOwnProperty("attribute"))
            object.attribute = message.attribute;
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        return object;
    };

    /**
     * Converts this BraintreeValidationError to JSON.
     * @function toJSON
     * @memberof BraintreeValidationError
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeValidationError.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeValidationError;
})();

export const BraintreeErrorResponse = $root.BraintreeErrorResponse = (() => {

    /**
     * Properties of a BraintreeErrorResponse.
     * @exports IBraintreeErrorResponse
     * @interface IBraintreeErrorResponse
     * @property {string} code BraintreeErrorResponse code
     * @property {string|null} [message] BraintreeErrorResponse message
     * @property {string|null} [transactionStatus] BraintreeErrorResponse transactionStatus
     * @property {string|null} [processorResponseCode] BraintreeErrorResponse processorResponseCode
     * @property {string|null} [gatewayRejectionReason] BraintreeErrorResponse gatewayRejectionReason
     * @property {string|null} [threeDSecureStatus] BraintreeErrorResponse threeDSecureStatus
     * @property {Array.<IBraintreeValidationError>|null} [validationErrors] BraintreeErrorResponse validationErrors
     */

    /**
     * Constructs a new BraintreeErrorResponse.
     * @exports BraintreeErrorResponse
     * @classdesc Represents a BraintreeErrorResponse.
     * @implements IBraintreeErrorResponse
     * @constructor
     * @param {IBraintreeErrorResponse=} [properties] Properties to set
     */
    function BraintreeErrorResponse(properties) {
        this.validationErrors = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeErrorResponse code.
     * @member {string} code
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.code = "";

    /**
     * BraintreeErrorResponse message.
     * @member {string} message
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.message = "";

    /**
     * BraintreeErrorResponse transactionStatus.
     * @member {string} transactionStatus
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.transactionStatus = "";

    /**
     * BraintreeErrorResponse processorResponseCode.
     * @member {string} processorResponseCode
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.processorResponseCode = "";

    /**
     * BraintreeErrorResponse gatewayRejectionReason.
     * @member {string} gatewayRejectionReason
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.gatewayRejectionReason = "";

    /**
     * BraintreeErrorResponse threeDSecureStatus.
     * @member {string} threeDSecureStatus
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.threeDSecureStatus = "";

    /**
     * BraintreeErrorResponse validationErrors.
     * @member {Array.<IBraintreeValidationError>} validationErrors
     * @memberof BraintreeErrorResponse
     * @instance
     */
    BraintreeErrorResponse.prototype.validationErrors = $util.emptyArray;

    /**
     * Creates a new BraintreeErrorResponse instance using the specified properties.
     * @function create
     * @memberof BraintreeErrorResponse
     * @static
     * @param {IBraintreeErrorResponse=} [properties] Properties to set
     * @returns {BraintreeErrorResponse} BraintreeErrorResponse instance
     */
    BraintreeErrorResponse.create = function create(properties) {
        return new BraintreeErrorResponse(properties);
    };

    /**
     * Encodes the specified BraintreeErrorResponse message. Does not implicitly {@link BraintreeErrorResponse.verify|verify} messages.
     * @function encode
     * @memberof BraintreeErrorResponse
     * @static
     * @param {IBraintreeErrorResponse} message BraintreeErrorResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeErrorResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
        if (message.message != null && Object.hasOwnProperty.call(message, "message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
        if (message.transactionStatus != null && Object.hasOwnProperty.call(message, "transactionStatus"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.transactionStatus);
        if (message.processorResponseCode != null && Object.hasOwnProperty.call(message, "processorResponseCode"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.processorResponseCode);
        if (message.gatewayRejectionReason != null && Object.hasOwnProperty.call(message, "gatewayRejectionReason"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.gatewayRejectionReason);
        if (message.threeDSecureStatus != null && Object.hasOwnProperty.call(message, "threeDSecureStatus"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.threeDSecureStatus);
        if (message.validationErrors != null && message.validationErrors.length)
            for (let i = 0; i < message.validationErrors.length; ++i)
                $root.BraintreeValidationError.encode(message.validationErrors[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BraintreeErrorResponse message, length delimited. Does not implicitly {@link BraintreeErrorResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeErrorResponse
     * @static
     * @param {IBraintreeErrorResponse} message BraintreeErrorResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeErrorResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeErrorResponse message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeErrorResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeErrorResponse} BraintreeErrorResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeErrorResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeErrorResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.code = reader.string();
                break;
            case 2:
                message.message = reader.string();
                break;
            case 3:
                message.transactionStatus = reader.string();
                break;
            case 4:
                message.processorResponseCode = reader.string();
                break;
            case 5:
                message.gatewayRejectionReason = reader.string();
                break;
            case 6:
                message.threeDSecureStatus = reader.string();
                break;
            case 7:
                if (!(message.validationErrors && message.validationErrors.length))
                    message.validationErrors = [];
                message.validationErrors.push($root.BraintreeValidationError.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreeErrorResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeErrorResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeErrorResponse} BraintreeErrorResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeErrorResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeErrorResponse message.
     * @function verify
     * @memberof BraintreeErrorResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeErrorResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.code))
            return "code: string expected";
        if (message.message != null && message.hasOwnProperty("message"))
            if (!$util.isString(message.message))
                return "message: string expected";
        if (message.transactionStatus != null && message.hasOwnProperty("transactionStatus"))
            if (!$util.isString(message.transactionStatus))
                return "transactionStatus: string expected";
        if (message.processorResponseCode != null && message.hasOwnProperty("processorResponseCode"))
            if (!$util.isString(message.processorResponseCode))
                return "processorResponseCode: string expected";
        if (message.gatewayRejectionReason != null && message.hasOwnProperty("gatewayRejectionReason"))
            if (!$util.isString(message.gatewayRejectionReason))
                return "gatewayRejectionReason: string expected";
        if (message.threeDSecureStatus != null && message.hasOwnProperty("threeDSecureStatus"))
            if (!$util.isString(message.threeDSecureStatus))
                return "threeDSecureStatus: string expected";
        if (message.validationErrors != null && message.hasOwnProperty("validationErrors")) {
            if (!Array.isArray(message.validationErrors))
                return "validationErrors: array expected";
            for (let i = 0; i < message.validationErrors.length; ++i) {
                let error = $root.BraintreeValidationError.verify(message.validationErrors[i]);
                if (error)
                    return "validationErrors." + error;
            }
        }
        return null;
    };

    /**
     * Creates a BraintreeErrorResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeErrorResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeErrorResponse} BraintreeErrorResponse
     */
    BraintreeErrorResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeErrorResponse)
            return object;
        let message = new $root.BraintreeErrorResponse();
        if (object.code != null)
            message.code = String(object.code);
        if (object.message != null)
            message.message = String(object.message);
        if (object.transactionStatus != null)
            message.transactionStatus = String(object.transactionStatus);
        if (object.processorResponseCode != null)
            message.processorResponseCode = String(object.processorResponseCode);
        if (object.gatewayRejectionReason != null)
            message.gatewayRejectionReason = String(object.gatewayRejectionReason);
        if (object.threeDSecureStatus != null)
            message.threeDSecureStatus = String(object.threeDSecureStatus);
        if (object.validationErrors) {
            if (!Array.isArray(object.validationErrors))
                throw TypeError(".BraintreeErrorResponse.validationErrors: array expected");
            message.validationErrors = [];
            for (let i = 0; i < object.validationErrors.length; ++i) {
                if (typeof object.validationErrors[i] !== "object")
                    throw TypeError(".BraintreeErrorResponse.validationErrors: object expected");
                message.validationErrors[i] = $root.BraintreeValidationError.fromObject(object.validationErrors[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a BraintreeErrorResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeErrorResponse
     * @static
     * @param {BraintreeErrorResponse} message BraintreeErrorResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeErrorResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.validationErrors = [];
        if (options.defaults) {
            object.code = "";
            object.message = "";
            object.transactionStatus = "";
            object.processorResponseCode = "";
            object.gatewayRejectionReason = "";
            object.threeDSecureStatus = "";
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = message.code;
        if (message.message != null && message.hasOwnProperty("message"))
            object.message = message.message;
        if (message.transactionStatus != null && message.hasOwnProperty("transactionStatus"))
            object.transactionStatus = message.transactionStatus;
        if (message.processorResponseCode != null && message.hasOwnProperty("processorResponseCode"))
            object.processorResponseCode = message.processorResponseCode;
        if (message.gatewayRejectionReason != null && message.hasOwnProperty("gatewayRejectionReason"))
            object.gatewayRejectionReason = message.gatewayRejectionReason;
        if (message.threeDSecureStatus != null && message.hasOwnProperty("threeDSecureStatus"))
            object.threeDSecureStatus = message.threeDSecureStatus;
        if (message.validationErrors && message.validationErrors.length) {
            object.validationErrors = [];
            for (let j = 0; j < message.validationErrors.length; ++j)
                object.validationErrors[j] = $root.BraintreeValidationError.toObject(message.validationErrors[j], options);
        }
        return object;
    };

    /**
     * Converts this BraintreeErrorResponse to JSON.
     * @function toJSON
     * @memberof BraintreeErrorResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeErrorResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeErrorResponse;
})();

export const BraintreeSubscription = $root.BraintreeSubscription = (() => {

    /**
     * Properties of a BraintreeSubscription.
     * @exports IBraintreeSubscription
     * @interface IBraintreeSubscription
     * @property {string} id BraintreeSubscription id
     * @property {BraintreeSubscription.BraintreeSubscriptionStatus} status BraintreeSubscription status
     * @property {number|Long} createdAt BraintreeSubscription createdAt
     * @property {number|Long} firstBillingDate BraintreeSubscription firstBillingDate
     * @property {number|Long|null} [billingPeriodEndDate] BraintreeSubscription billingPeriodEndDate
     * @property {number|Long|null} [billingPeriodStartDate] BraintreeSubscription billingPeriodStartDate
     * @property {number|Long} nextBillingDate BraintreeSubscription nextBillingDate
     * @property {number} nextBillingPeriodAmount BraintreeSubscription nextBillingPeriodAmount
     * @property {string} planId BraintreeSubscription planId
     * @property {number} price BraintreeSubscription price
     * @property {string} currencyCode BraintreeSubscription currencyCode
     * @property {boolean} hasTrialPeriod BraintreeSubscription hasTrialPeriod
     */

    /**
     * Constructs a new BraintreeSubscription.
     * @exports BraintreeSubscription
     * @classdesc Represents a BraintreeSubscription.
     * @implements IBraintreeSubscription
     * @constructor
     * @param {IBraintreeSubscription=} [properties] Properties to set
     */
    function BraintreeSubscription(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeSubscription id.
     * @member {string} id
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.id = "";

    /**
     * BraintreeSubscription status.
     * @member {BraintreeSubscription.BraintreeSubscriptionStatus} status
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.status = 0;

    /**
     * BraintreeSubscription createdAt.
     * @member {number|Long} createdAt
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.createdAt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeSubscription firstBillingDate.
     * @member {number|Long} firstBillingDate
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.firstBillingDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeSubscription billingPeriodEndDate.
     * @member {number|Long} billingPeriodEndDate
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.billingPeriodEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeSubscription billingPeriodStartDate.
     * @member {number|Long} billingPeriodStartDate
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.billingPeriodStartDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeSubscription nextBillingDate.
     * @member {number|Long} nextBillingDate
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.nextBillingDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * BraintreeSubscription nextBillingPeriodAmount.
     * @member {number} nextBillingPeriodAmount
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.nextBillingPeriodAmount = 0;

    /**
     * BraintreeSubscription planId.
     * @member {string} planId
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.planId = "";

    /**
     * BraintreeSubscription price.
     * @member {number} price
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.price = 0;

    /**
     * BraintreeSubscription currencyCode.
     * @member {string} currencyCode
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.currencyCode = "";

    /**
     * BraintreeSubscription hasTrialPeriod.
     * @member {boolean} hasTrialPeriod
     * @memberof BraintreeSubscription
     * @instance
     */
    BraintreeSubscription.prototype.hasTrialPeriod = false;

    /**
     * Creates a new BraintreeSubscription instance using the specified properties.
     * @function create
     * @memberof BraintreeSubscription
     * @static
     * @param {IBraintreeSubscription=} [properties] Properties to set
     * @returns {BraintreeSubscription} BraintreeSubscription instance
     */
    BraintreeSubscription.create = function create(properties) {
        return new BraintreeSubscription(properties);
    };

    /**
     * Encodes the specified BraintreeSubscription message. Does not implicitly {@link BraintreeSubscription.verify|verify} messages.
     * @function encode
     * @memberof BraintreeSubscription
     * @static
     * @param {IBraintreeSubscription} message BraintreeSubscription message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeSubscription.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.createdAt);
        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.firstBillingDate);
        if (message.billingPeriodEndDate != null && Object.hasOwnProperty.call(message, "billingPeriodEndDate"))
            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.billingPeriodEndDate);
        if (message.billingPeriodStartDate != null && Object.hasOwnProperty.call(message, "billingPeriodStartDate"))
            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.billingPeriodStartDate);
        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.nextBillingDate);
        writer.uint32(/* id 8, wireType 1 =*/65).double(message.nextBillingPeriodAmount);
        writer.uint32(/* id 9, wireType 2 =*/74).string(message.planId);
        writer.uint32(/* id 10, wireType 1 =*/81).double(message.price);
        writer.uint32(/* id 11, wireType 2 =*/90).string(message.currencyCode);
        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.hasTrialPeriod);
        return writer;
    };

    /**
     * Encodes the specified BraintreeSubscription message, length delimited. Does not implicitly {@link BraintreeSubscription.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeSubscription
     * @static
     * @param {IBraintreeSubscription} message BraintreeSubscription message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeSubscription.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeSubscription message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeSubscription
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeSubscription} BraintreeSubscription
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeSubscription.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeSubscription();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.string();
                break;
            case 2:
                message.status = reader.int32();
                break;
            case 3:
                message.createdAt = reader.int64();
                break;
            case 4:
                message.firstBillingDate = reader.int64();
                break;
            case 5:
                message.billingPeriodEndDate = reader.int64();
                break;
            case 6:
                message.billingPeriodStartDate = reader.int64();
                break;
            case 7:
                message.nextBillingDate = reader.int64();
                break;
            case 8:
                message.nextBillingPeriodAmount = reader.double();
                break;
            case 9:
                message.planId = reader.string();
                break;
            case 10:
                message.price = reader.double();
                break;
            case 11:
                message.currencyCode = reader.string();
                break;
            case 12:
                message.hasTrialPeriod = reader.bool();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("status"))
            throw $util.ProtocolError("missing required 'status'", { instance: message });
        if (!message.hasOwnProperty("createdAt"))
            throw $util.ProtocolError("missing required 'createdAt'", { instance: message });
        if (!message.hasOwnProperty("firstBillingDate"))
            throw $util.ProtocolError("missing required 'firstBillingDate'", { instance: message });
        if (!message.hasOwnProperty("nextBillingDate"))
            throw $util.ProtocolError("missing required 'nextBillingDate'", { instance: message });
        if (!message.hasOwnProperty("nextBillingPeriodAmount"))
            throw $util.ProtocolError("missing required 'nextBillingPeriodAmount'", { instance: message });
        if (!message.hasOwnProperty("planId"))
            throw $util.ProtocolError("missing required 'planId'", { instance: message });
        if (!message.hasOwnProperty("price"))
            throw $util.ProtocolError("missing required 'price'", { instance: message });
        if (!message.hasOwnProperty("currencyCode"))
            throw $util.ProtocolError("missing required 'currencyCode'", { instance: message });
        if (!message.hasOwnProperty("hasTrialPeriod"))
            throw $util.ProtocolError("missing required 'hasTrialPeriod'", { instance: message });
        return message;
    };

    /**
     * Decodes a BraintreeSubscription message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeSubscription
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeSubscription} BraintreeSubscription
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeSubscription.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeSubscription message.
     * @function verify
     * @memberof BraintreeSubscription
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeSubscription.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (!$util.isString(message.id))
            return "id: string expected";
        switch (message.status) {
        default:
            return "status: enum value expected";
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            break;
        }
        if (!$util.isInteger(message.createdAt) && !(message.createdAt && $util.isInteger(message.createdAt.low) && $util.isInteger(message.createdAt.high)))
            return "createdAt: integer|Long expected";
        if (!$util.isInteger(message.firstBillingDate) && !(message.firstBillingDate && $util.isInteger(message.firstBillingDate.low) && $util.isInteger(message.firstBillingDate.high)))
            return "firstBillingDate: integer|Long expected";
        if (message.billingPeriodEndDate != null && message.hasOwnProperty("billingPeriodEndDate"))
            if (!$util.isInteger(message.billingPeriodEndDate) && !(message.billingPeriodEndDate && $util.isInteger(message.billingPeriodEndDate.low) && $util.isInteger(message.billingPeriodEndDate.high)))
                return "billingPeriodEndDate: integer|Long expected";
        if (message.billingPeriodStartDate != null && message.hasOwnProperty("billingPeriodStartDate"))
            if (!$util.isInteger(message.billingPeriodStartDate) && !(message.billingPeriodStartDate && $util.isInteger(message.billingPeriodStartDate.low) && $util.isInteger(message.billingPeriodStartDate.high)))
                return "billingPeriodStartDate: integer|Long expected";
        if (!$util.isInteger(message.nextBillingDate) && !(message.nextBillingDate && $util.isInteger(message.nextBillingDate.low) && $util.isInteger(message.nextBillingDate.high)))
            return "nextBillingDate: integer|Long expected";
        if (typeof message.nextBillingPeriodAmount !== "number")
            return "nextBillingPeriodAmount: number expected";
        if (!$util.isString(message.planId))
            return "planId: string expected";
        if (typeof message.price !== "number")
            return "price: number expected";
        if (!$util.isString(message.currencyCode))
            return "currencyCode: string expected";
        if (typeof message.hasTrialPeriod !== "boolean")
            return "hasTrialPeriod: boolean expected";
        return null;
    };

    /**
     * Creates a BraintreeSubscription message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeSubscription
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeSubscription} BraintreeSubscription
     */
    BraintreeSubscription.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeSubscription)
            return object;
        let message = new $root.BraintreeSubscription();
        if (object.id != null)
            message.id = String(object.id);
        switch (object.status) {
        case "ACTIVE":
        case 0:
            message.status = 0;
            break;
        case "CANCELED":
        case 1:
            message.status = 1;
            break;
        case "EXPIRED":
        case 2:
            message.status = 2;
            break;
        case "PAST_DUE":
        case 3:
            message.status = 3;
            break;
        case "PENDING":
        case 4:
            message.status = 4;
            break;
        case "UNRECOGNIZED":
        case 5:
            message.status = 5;
            break;
        }
        if (object.createdAt != null)
            if ($util.Long)
                (message.createdAt = $util.Long.fromValue(object.createdAt)).unsigned = false;
            else if (typeof object.createdAt === "string")
                message.createdAt = parseInt(object.createdAt, 10);
            else if (typeof object.createdAt === "number")
                message.createdAt = object.createdAt;
            else if (typeof object.createdAt === "object")
                message.createdAt = new $util.LongBits(object.createdAt.low >>> 0, object.createdAt.high >>> 0).toNumber();
        if (object.firstBillingDate != null)
            if ($util.Long)
                (message.firstBillingDate = $util.Long.fromValue(object.firstBillingDate)).unsigned = false;
            else if (typeof object.firstBillingDate === "string")
                message.firstBillingDate = parseInt(object.firstBillingDate, 10);
            else if (typeof object.firstBillingDate === "number")
                message.firstBillingDate = object.firstBillingDate;
            else if (typeof object.firstBillingDate === "object")
                message.firstBillingDate = new $util.LongBits(object.firstBillingDate.low >>> 0, object.firstBillingDate.high >>> 0).toNumber();
        if (object.billingPeriodEndDate != null)
            if ($util.Long)
                (message.billingPeriodEndDate = $util.Long.fromValue(object.billingPeriodEndDate)).unsigned = false;
            else if (typeof object.billingPeriodEndDate === "string")
                message.billingPeriodEndDate = parseInt(object.billingPeriodEndDate, 10);
            else if (typeof object.billingPeriodEndDate === "number")
                message.billingPeriodEndDate = object.billingPeriodEndDate;
            else if (typeof object.billingPeriodEndDate === "object")
                message.billingPeriodEndDate = new $util.LongBits(object.billingPeriodEndDate.low >>> 0, object.billingPeriodEndDate.high >>> 0).toNumber();
        if (object.billingPeriodStartDate != null)
            if ($util.Long)
                (message.billingPeriodStartDate = $util.Long.fromValue(object.billingPeriodStartDate)).unsigned = false;
            else if (typeof object.billingPeriodStartDate === "string")
                message.billingPeriodStartDate = parseInt(object.billingPeriodStartDate, 10);
            else if (typeof object.billingPeriodStartDate === "number")
                message.billingPeriodStartDate = object.billingPeriodStartDate;
            else if (typeof object.billingPeriodStartDate === "object")
                message.billingPeriodStartDate = new $util.LongBits(object.billingPeriodStartDate.low >>> 0, object.billingPeriodStartDate.high >>> 0).toNumber();
        if (object.nextBillingDate != null)
            if ($util.Long)
                (message.nextBillingDate = $util.Long.fromValue(object.nextBillingDate)).unsigned = false;
            else if (typeof object.nextBillingDate === "string")
                message.nextBillingDate = parseInt(object.nextBillingDate, 10);
            else if (typeof object.nextBillingDate === "number")
                message.nextBillingDate = object.nextBillingDate;
            else if (typeof object.nextBillingDate === "object")
                message.nextBillingDate = new $util.LongBits(object.nextBillingDate.low >>> 0, object.nextBillingDate.high >>> 0).toNumber();
        if (object.nextBillingPeriodAmount != null)
            message.nextBillingPeriodAmount = Number(object.nextBillingPeriodAmount);
        if (object.planId != null)
            message.planId = String(object.planId);
        if (object.price != null)
            message.price = Number(object.price);
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        if (object.hasTrialPeriod != null)
            message.hasTrialPeriod = Boolean(object.hasTrialPeriod);
        return message;
    };

    /**
     * Creates a plain object from a BraintreeSubscription message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeSubscription
     * @static
     * @param {BraintreeSubscription} message BraintreeSubscription
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeSubscription.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.status = options.enums === String ? "ACTIVE" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.createdAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.createdAt = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.firstBillingDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.firstBillingDate = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.billingPeriodEndDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.billingPeriodEndDate = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.billingPeriodStartDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.billingPeriodStartDate = options.longs === String ? "0" : 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.nextBillingDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.nextBillingDate = options.longs === String ? "0" : 0;
            object.nextBillingPeriodAmount = 0;
            object.planId = "";
            object.price = 0;
            object.currencyCode = "";
            object.hasTrialPeriod = false;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.BraintreeSubscription.BraintreeSubscriptionStatus[message.status] : message.status;
        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
            if (typeof message.createdAt === "number")
                object.createdAt = options.longs === String ? String(message.createdAt) : message.createdAt;
            else
                object.createdAt = options.longs === String ? $util.Long.prototype.toString.call(message.createdAt) : options.longs === Number ? new $util.LongBits(message.createdAt.low >>> 0, message.createdAt.high >>> 0).toNumber() : message.createdAt;
        if (message.firstBillingDate != null && message.hasOwnProperty("firstBillingDate"))
            if (typeof message.firstBillingDate === "number")
                object.firstBillingDate = options.longs === String ? String(message.firstBillingDate) : message.firstBillingDate;
            else
                object.firstBillingDate = options.longs === String ? $util.Long.prototype.toString.call(message.firstBillingDate) : options.longs === Number ? new $util.LongBits(message.firstBillingDate.low >>> 0, message.firstBillingDate.high >>> 0).toNumber() : message.firstBillingDate;
        if (message.billingPeriodEndDate != null && message.hasOwnProperty("billingPeriodEndDate"))
            if (typeof message.billingPeriodEndDate === "number")
                object.billingPeriodEndDate = options.longs === String ? String(message.billingPeriodEndDate) : message.billingPeriodEndDate;
            else
                object.billingPeriodEndDate = options.longs === String ? $util.Long.prototype.toString.call(message.billingPeriodEndDate) : options.longs === Number ? new $util.LongBits(message.billingPeriodEndDate.low >>> 0, message.billingPeriodEndDate.high >>> 0).toNumber() : message.billingPeriodEndDate;
        if (message.billingPeriodStartDate != null && message.hasOwnProperty("billingPeriodStartDate"))
            if (typeof message.billingPeriodStartDate === "number")
                object.billingPeriodStartDate = options.longs === String ? String(message.billingPeriodStartDate) : message.billingPeriodStartDate;
            else
                object.billingPeriodStartDate = options.longs === String ? $util.Long.prototype.toString.call(message.billingPeriodStartDate) : options.longs === Number ? new $util.LongBits(message.billingPeriodStartDate.low >>> 0, message.billingPeriodStartDate.high >>> 0).toNumber() : message.billingPeriodStartDate;
        if (message.nextBillingDate != null && message.hasOwnProperty("nextBillingDate"))
            if (typeof message.nextBillingDate === "number")
                object.nextBillingDate = options.longs === String ? String(message.nextBillingDate) : message.nextBillingDate;
            else
                object.nextBillingDate = options.longs === String ? $util.Long.prototype.toString.call(message.nextBillingDate) : options.longs === Number ? new $util.LongBits(message.nextBillingDate.low >>> 0, message.nextBillingDate.high >>> 0).toNumber() : message.nextBillingDate;
        if (message.nextBillingPeriodAmount != null && message.hasOwnProperty("nextBillingPeriodAmount"))
            object.nextBillingPeriodAmount = options.json && !isFinite(message.nextBillingPeriodAmount) ? String(message.nextBillingPeriodAmount) : message.nextBillingPeriodAmount;
        if (message.planId != null && message.hasOwnProperty("planId"))
            object.planId = message.planId;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        if (message.hasTrialPeriod != null && message.hasOwnProperty("hasTrialPeriod"))
            object.hasTrialPeriod = message.hasTrialPeriod;
        return object;
    };

    /**
     * Converts this BraintreeSubscription to JSON.
     * @function toJSON
     * @memberof BraintreeSubscription
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeSubscription.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * BraintreeSubscriptionStatus enum.
     * @name BraintreeSubscription.BraintreeSubscriptionStatus
     * @enum {number}
     * @property {number} ACTIVE=0 ACTIVE value
     * @property {number} CANCELED=1 CANCELED value
     * @property {number} EXPIRED=2 EXPIRED value
     * @property {number} PAST_DUE=3 PAST_DUE value
     * @property {number} PENDING=4 PENDING value
     * @property {number} UNRECOGNIZED=5 UNRECOGNIZED value
     */
    BraintreeSubscription.BraintreeSubscriptionStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ACTIVE"] = 0;
        values[valuesById[1] = "CANCELED"] = 1;
        values[valuesById[2] = "EXPIRED"] = 2;
        values[valuesById[3] = "PAST_DUE"] = 3;
        values[valuesById[4] = "PENDING"] = 4;
        values[valuesById[5] = "UNRECOGNIZED"] = 5;
        return values;
    })();

    return BraintreeSubscription;
})();

export const BraintreeDetails = $root.BraintreeDetails = (() => {

    /**
     * Properties of a BraintreeDetails.
     * @exports IBraintreeDetails
     * @interface IBraintreeDetails
     * @property {IBraintreeBillingAddress|null} [billingAddress] BraintreeDetails billingAddress
     * @property {IBraintreePaymentMethod|null} [defaultPaymentMethod] BraintreeDetails defaultPaymentMethod
     * @property {IBraintreeSubscription|null} [subscription] BraintreeDetails subscription
     */

    /**
     * Constructs a new BraintreeDetails.
     * @exports BraintreeDetails
     * @classdesc Represents a BraintreeDetails.
     * @implements IBraintreeDetails
     * @constructor
     * @param {IBraintreeDetails=} [properties] Properties to set
     */
    function BraintreeDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BraintreeDetails billingAddress.
     * @member {IBraintreeBillingAddress|null|undefined} billingAddress
     * @memberof BraintreeDetails
     * @instance
     */
    BraintreeDetails.prototype.billingAddress = null;

    /**
     * BraintreeDetails defaultPaymentMethod.
     * @member {IBraintreePaymentMethod|null|undefined} defaultPaymentMethod
     * @memberof BraintreeDetails
     * @instance
     */
    BraintreeDetails.prototype.defaultPaymentMethod = null;

    /**
     * BraintreeDetails subscription.
     * @member {IBraintreeSubscription|null|undefined} subscription
     * @memberof BraintreeDetails
     * @instance
     */
    BraintreeDetails.prototype.subscription = null;

    /**
     * Creates a new BraintreeDetails instance using the specified properties.
     * @function create
     * @memberof BraintreeDetails
     * @static
     * @param {IBraintreeDetails=} [properties] Properties to set
     * @returns {BraintreeDetails} BraintreeDetails instance
     */
    BraintreeDetails.create = function create(properties) {
        return new BraintreeDetails(properties);
    };

    /**
     * Encodes the specified BraintreeDetails message. Does not implicitly {@link BraintreeDetails.verify|verify} messages.
     * @function encode
     * @memberof BraintreeDetails
     * @static
     * @param {IBraintreeDetails} message BraintreeDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.billingAddress != null && Object.hasOwnProperty.call(message, "billingAddress"))
            $root.BraintreeBillingAddress.encode(message.billingAddress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.defaultPaymentMethod != null && Object.hasOwnProperty.call(message, "defaultPaymentMethod"))
            $root.BraintreePaymentMethod.encode(message.defaultPaymentMethod, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.BraintreeSubscription.encode(message.subscription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BraintreeDetails message, length delimited. Does not implicitly {@link BraintreeDetails.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BraintreeDetails
     * @static
     * @param {IBraintreeDetails} message BraintreeDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BraintreeDetails.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BraintreeDetails message from the specified reader or buffer.
     * @function decode
     * @memberof BraintreeDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BraintreeDetails} BraintreeDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.BraintreeDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.billingAddress = $root.BraintreeBillingAddress.decode(reader, reader.uint32());
                break;
            case 2:
                message.defaultPaymentMethod = $root.BraintreePaymentMethod.decode(reader, reader.uint32());
                break;
            case 3:
                message.subscription = $root.BraintreeSubscription.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BraintreeDetails message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BraintreeDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BraintreeDetails} BraintreeDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BraintreeDetails.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BraintreeDetails message.
     * @function verify
     * @memberof BraintreeDetails
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BraintreeDetails.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
            let error = $root.BraintreeBillingAddress.verify(message.billingAddress);
            if (error)
                return "billingAddress." + error;
        }
        if (message.defaultPaymentMethod != null && message.hasOwnProperty("defaultPaymentMethod")) {
            let error = $root.BraintreePaymentMethod.verify(message.defaultPaymentMethod);
            if (error)
                return "defaultPaymentMethod." + error;
        }
        if (message.subscription != null && message.hasOwnProperty("subscription")) {
            let error = $root.BraintreeSubscription.verify(message.subscription);
            if (error)
                return "subscription." + error;
        }
        return null;
    };

    /**
     * Creates a BraintreeDetails message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BraintreeDetails
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BraintreeDetails} BraintreeDetails
     */
    BraintreeDetails.fromObject = function fromObject(object) {
        if (object instanceof $root.BraintreeDetails)
            return object;
        let message = new $root.BraintreeDetails();
        if (object.billingAddress != null) {
            if (typeof object.billingAddress !== "object")
                throw TypeError(".BraintreeDetails.billingAddress: object expected");
            message.billingAddress = $root.BraintreeBillingAddress.fromObject(object.billingAddress);
        }
        if (object.defaultPaymentMethod != null) {
            if (typeof object.defaultPaymentMethod !== "object")
                throw TypeError(".BraintreeDetails.defaultPaymentMethod: object expected");
            message.defaultPaymentMethod = $root.BraintreePaymentMethod.fromObject(object.defaultPaymentMethod);
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".BraintreeDetails.subscription: object expected");
            message.subscription = $root.BraintreeSubscription.fromObject(object.subscription);
        }
        return message;
    };

    /**
     * Creates a plain object from a BraintreeDetails message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BraintreeDetails
     * @static
     * @param {BraintreeDetails} message BraintreeDetails
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BraintreeDetails.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.billingAddress = null;
            object.defaultPaymentMethod = null;
            object.subscription = null;
        }
        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
            object.billingAddress = $root.BraintreeBillingAddress.toObject(message.billingAddress, options);
        if (message.defaultPaymentMethod != null && message.hasOwnProperty("defaultPaymentMethod"))
            object.defaultPaymentMethod = $root.BraintreePaymentMethod.toObject(message.defaultPaymentMethod, options);
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.BraintreeSubscription.toObject(message.subscription, options);
        return object;
    };

    /**
     * Converts this BraintreeDetails to JSON.
     * @function toJSON
     * @memberof BraintreeDetails
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BraintreeDetails.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return BraintreeDetails;
})();

export { $root as default };
